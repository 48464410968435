<template>
  <div class="relative inline-block align-middle self-center rounded-lg text-left transform transition-all duration-300 w-full">
      <LoadingSpinnerHub v-if="loading"/>
      <div class="z-10 flex-1 " v-auto-animate>
        <div v-if="orders.length && !loading" class="duration-300 transition-all sm:rounded-md overflow-visible">
          <div :ref="`refOrder-${order.id}`" v-for="(order, index) in orders" :key="index" :class="[order.statusId === 300 ? 'bg-red-200' : '', order.statusId === 6 ? 'bg-green-100' : '', 'select-none flex flex-col sm:flex-row w-full rounded-lg border-gray-200 mb-5 bg-white shadow-md']">
            <div v-if="order.statusId === 6" class="absolute top-0 right-0 bottom-0 left-0 m-auto w-60 h-6 z-50">
              <div class="flex justify-center items-center" v-tooltip="`Zamówienie zostało dostarczone do klienta.`" >
                <CheckIcon class="transition-all duration-300 w-11 h-11  hover:text-gray-400 text-gray-600 cursor-pointer" />
              </div>
            </div>
            <div :class="[order.statusId === 300 || order.statusId === 6 ? 'opacity-20 pointer-events-none' : '', 'flex text-xs sm:text-sm']">
              <div class="sm:space-y-4 px-3 py-2 h-full grid sm:flex sm:flex-col" :class="['grid-cols-3']">

                <div @click="order.halfHourBlocked || order.sending ? '' : setMessageType(order, 'halfHour')" :class="['cursor-pointer col-span-1 relative transition-all duration-300 group flex flex-col border-l-4 py-2 px-2', order.messageType === 'halfHour' ? 'text-green-500 hover:text-green-400 border-green-300 hover:border-green-200' : 'text-gray-500 hover:text-gray-400', order.halfHourBlocked || order.sending ? 'opacity-30 cursor-not-allowed hover:text-gray-500' : '']">
                  <span :class="['font-medium']">
                    <ChatAltIcon :class="['w-5 h-5']" />
                  </span>
                  <span v-auto-animate :class="['font-medium md:whitespace-nowrap']">
                    <span>Za 30 min. dostawa</span>
                  </span>
                </div>

                <div @click="order.changeDeliveryTimeBlocked || order.sending ? '' : setMessageType(order, 'changeDeliveryTime')" :class="['cursor-pointer col-span-1 relative transition-all duration-300 group flex flex-col border-l-4 py-2 px-2 text-gray-500', order.messageType === 'changeDeliveryTime' ? 'text-green-500 hover:text-green-400 border-green-300 hover:border-green-200' : 'text-gray-500 hover:text-gray-400', order.changeDeliveryTimeBlocked || order.sending ? 'opacity-30 cursor-not-allowed hover:text-gray-500' : '']">
                  <span :class="['font-medium']">
                    <ChatAltIcon :class="['w-5 h-5']" />
                  </span>
                  <span v-auto-animate :class="['font-medium md:whitespace-nowrap']">
                    <span>Zmiana godziny dostawy</span>
                  </span>
                </div>

                <div @click="order.delivery.dateConfirmed || order.sending ? '' : openConfirmModal(order)" :class="['cursor-pointer col-span-1 relative transition-all duration-300 group flex flex-col border-l-4 py-2 px-2 text-gray-500', 'text-gray-500 hover:text-gray-400', order.changeDeliveryTimeBlocked || order.sending ? 'opacity-30 cursor-not-allowed hover:text-gray-500' : '']">
                  <span :class="['font-medium']">
                    <ChatAltIcon :class="['w-5 h-5']" />
                  </span>
                  <span v-auto-animate :class="['font-medium']">
                    <span>Manualne potwierdzenie dostawy</span>
                  </span>
                </div>

              </div>
            </div>
            <div class="w-full">
              <div class="text-xs sm:text-sm flex flex-col sm:border-l border-gray-200 h-full px-3 py-2 divide-y divide-gray-200 w-full">
                <div class="text-sm font-medium leading-6 text-gray-900 select-none flex justify-between pb-2">
                  <div>
                    {{order.client.name}} <span v-if="order.client.phone.full.length > 0">- {{order.client.phone.full}}</span>
                  </div>
                  <div :class="order.delivery.date.dateConfirmed !== undefined && order.delivery.date.dateConfirmed ? 'bg-green-50 text-green-500' : order.delivery.date.dateConfirmationSent !== undefined && order.delivery.date.dateConfirmationSent ? 'bg-blue-50 text-blue-500' : 'bg-red-50 text-red-500'" class="rounded-md px-2.5 py-1 bg-gray-100 text-gray-800 text-xs font-medium w-fit">{{order.delivery.date.dateConfirmed !== undefined && order.delivery.date.dateConfirmed ? "Potwierdzono dostawę" : order.delivery.date.dateConfirmationSent !== undefined && order.delivery.date.dateConfirmationSent ? "Wysłano potwierdzenie" : "Nie wysłano potwierdzenia"}}</div>
                </div>
                <div v-auto-animate :id="`chatarea${index}`" class="flex flex-col-reverse font-medium leading-6 text-gray-900 select-none w-full gap-2 py-2 h-72 overflow-y-scroll overflow-x-hidden">
                  <div v-if="order.loading" class="flex flex-col w-full h-full items-center justify-center whitespace-nowrap">
                    <LoadingSpinnerHub />
                  </div>
                  <div v-if="!order.loading && order.messages.length === 0" class="flex flex-col w-full h-full items-center justify-center text-gray-400 whitespace-nowrap" >
                    <MailOpenIcon class="h-8 w-8" />
                    Brak wiadomości
                  </div>
                  <div v-if="!order.loading && order.messages.length > 0" v-auto-animate v-for="message in order.messages" :class="message.direction === 'outcoming' ? 'justify-end' : ''" class="flex w-full">
                    <div class="md:w-4/5 flex" :class="message.direction === 'outcoming' ? 'justify-end' : ''">
                      <div v-if="message.direction === 'incoming'" class="h-full flex items-end pr-1">
                        <div class="h-7 w-7 rounded-full bg-gray-200 flex items-center justify-center text-gray-400">
                          <UserIcon class="h-5 w-5" />
                        </div>
                      </div>
                      <div @click="message.translate = !message.translate" class="flex flex-col cursor-pointer">
                        <div :class="message.direction === 'outcoming' ? 'bg-indigo-600 text-white' : 'bg-gray-200'" class="py-2 px-2 break-words rounded-xl">{{message.translate ? message.polishMessage : message.message}}</div>
                        <div v-if="message.direction === 'outcoming'" class="text-xxs text-right">{{moment.unix(message.meta.lastModificationDate.seconds).format("DD-MM-YYYY")}} <span :class="message.status > 3 ? 'text-red-500 font-bold' : ''"> {{smsStatuses[message.status].name}}</span></div>
                      </div>
                      <div v-if="message.direction === 'outcoming'" class="h-full flex items-end pl-1">
                        <div class="h-7 w-7 rounded-full bg-gray-200 flex items-center justify-center text-gray-400">
                          <img class="h-7 w-7 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${$store.state.userData.name} ${$store.state.userData.surname}`" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full items-center">
                  <div v-auto-animate class="w-full py-2 flex">
                    <div class="text-gray-400 flex items-center w-full" v-if="order.messageType === ''">Tutaj pojawi się wybrana wiadomość</div>
                    <div class="flex items-center w-full" v-if="order.messageType === 'halfHour'">Twoje zamówienie zostanie dostarczone w ciągu 30 minut.</div>
                    <div class="flex items-center w-full" v-if="order.messageType === 'changeDeliveryTime'">Przepraszamy za utrudnienia. Nowa godzina dostawy:  
                      <div class="datePickerDiv">
                        <VueDatePicker v-model="order.selectedTime" hide-input-icon auto-apply time-picker :clearable="false" locale="pl"/>
                      </div>                           
                      .
                    </div>
                  </div>
                  <div v-if="!order.sending" @click="order.messageType == '' ? '' : openAlertModal(order)" class="pl-2 py-2 flex items-end"><PaperAirplaneIcon :class="[order.messageType === '' ? 'text-gray-400 cursor-not-allowed' : 'text-indigo-600 hover:text-indigo-500 cursor-pointer']" class="h-6 w-6 sm:h-8 sm:w-8 transform rotate-90" /></div>
                  <div v-if="order.sending" class="spinner ml-2 my-2"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <button v-if="allOrdersDelivered" @click="finishRoute" type="button" class="transition-all duration-300 inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Zakończ trasę
          </button> -->
        </div>
    </div>
  </div>
  <AlertModal v-if="alertModal.open" @closeModal="alertModal.open = false" @accept="sendMessage()" :modalData="alertModal.data" buttonColor="green" iconColor="yellow"></AlertModal>
  <AlertModal v-if="manualConfirm.open" @closeModal="manualConfirm.open = false" @accept="confirmDelivery()" :modalData="manualConfirm.data" buttonColor="green" iconColor="yellow"></AlertModal>
</template>

<script>
import axios from 'axios';
import {db} from "@/firebase/gfbconf.js";
import { PencilAltIcon, MailIcon, PhotographIcon, CheckIcon, LockOpenIcon, QrcodeIcon, LocationMarkerIcon, ReplyIcon, ChatAltIcon, MailOpenIcon} from '@heroicons/vue/outline'
import { ChevronDownIcon, XIcon, DotsVerticalIcon, LockClosedIcon, BanIcon, CheckCircleIcon, UserIcon, PaperAirplaneIcon,  } from '@heroicons/vue/solid'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, MenuButton, MenuItem, MenuItems, Menu } from '@headlessui/vue';
import AlertModal from './AlertModal.vue'
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import scrollIntoView from 'scroll-into-view-if-needed'
import moment from 'moment';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
import smsTemplates from '../smsTemplates.js'

export default {
  components: {
    MenuButton, CheckCircleIcon, LocationMarkerIcon,  BanIcon, LockClosedIcon, QrcodeIcon, MenuItem, MenuItems, Menu, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, LoadingSpinnerHub, PencilAltIcon, MailIcon, PhotographIcon, ChevronDownIcon, XIcon,CheckIcon,LockOpenIcon, scrollIntoView, DotsVerticalIcon, ReplyIcon, ChatAltIcon, UserIcon, PaperAirplaneIcon, AlertModal, VueDatePicker, MailOpenIcon
  },
  props: ['ordersProp', 'routeId', 'isRefs'],
  emits:['closeModal', 'getRoutes'],
  data() {
    return {
      open: true,
      orders: [],
      loading: false,
      selectedOrder: null,
      alertModal: {
        open: false,
        data: {
          headerText: "Wysyłanie wiadomości",
          bodyText: "Czy na pewno chcesz wysłać wiadomość? Po wysłaniu wiadomości nie można już jej usunąć.",
          acceptButtonText: "Wyślij"
        }
      },
      manualConfirm: {
        open: false,
        data: {
          headerText: "Manualne potwierdzenie dostawy",
          bodyText: "Czy na pewno chcesz potwierdzić dostawę? Po potwierdzeniu dostawy nie można już jej cofnąć.",
          acceptButtonText: "Potwierdź"
        }
      },
      moment: moment
    }
  },
  async mounted()
  {
    await this.getSMSHelpers()
    this.prepareOrders()
  },
  beforeUnmount() {
    this.orders.forEach(order => {
      if(order.dbListener !== null) {
        order.dbListener()
        order.dbListener = null 
      }
    })
  },
  methods: {
    async confirmDelivery() {
      try {
        this.selectedOrder.sending = true
        const result = await axios.post(`${this.$store.state.apiLink}/delivery/confirm`, {
          id: this.selectedOrder.id,
          routeId: this.routeId
        })
        this.selectedOrder.delivery.date.dateConfirmed = true
        this.selectedOrder.sending = false
        this.selectedOrder = null
      } catch(error) {
        console.log(error);
        this.selectedOrder.sending = false
      }
    },
    openAlertModal(order) {
      this.alertModal.open = true
      this.selectedOrder = order
    },
    openConfirmModal(order) {
      this.manualConfirm.open = true
      this.selectedOrder = order
    },
    async getSMSHelpers() {
      this.smsStatuses = (await db.collection("SMSHelpers").doc("helpers").get()).data().statuses
    },
    async downloadSMSChat(order) {
      order.loading = true
      order.dbListener = db.collection("SMSList").where('orderId', '==', order.id).orderBy("meta.createdDate", "desc")
      .onSnapshot((querySnapshot) => {
        let messages = [];
        order.halfHourBlocked = true
        order.changeDeliveryTimeBlocked = true
        let changeDeliveryTimeFound = false
        let changeDeliveryTimeBlocked = false
        let halfHourFound = false
        let halfHourBlocked = false
        querySnapshot.forEach((doc) => {
          if(doc.data().type == '1' && (doc.data().status == '1' || doc.data().status == '2' || doc.data().status == '3')) {
            changeDeliveryTimeBlocked = true
            changeDeliveryTimeFound = true
          } else if(doc.data().type == '1') {
            changeDeliveryTimeFound = true
          }
          if(doc.data().type == '2' && (doc.data().status == '1' || doc.data().status == '2' || doc.data().status == '3')) {
            halfHourBlocked = true
            halfHourFound = true
          } else if(doc.data().type == '2') {
            halfHourFound = true
          }
          doc.data().translate = false
          messages.push(doc.data());
        });

        if(!changeDeliveryTimeFound || (changeDeliveryTimeFound && !changeDeliveryTimeBlocked)) {
          order.changeDeliveryTimeBlocked = false
        }

        if(!halfHourFound || (halfHourFound && !halfHourBlocked)) {
          order.halfHourBlocked = false
        }

        order.messages = messages
        setTimeout(() => {
          order.loading = false
        }, 200);
      });
    },
    downloadSMSChats() {
      this.orders.forEach(order => {
        this.downloadSMSChat(order)
      })
    },
    async sendMessage() {
      try {
        this.selectedOrder.sending = true
        let data = {
          messageType: this.selectedOrder.messageType,
          prefix: this.selectedOrder.delivery.phone.prefix.length == 0 ? this.selectedOrder.client.phone.prefix : this.selectedOrder.delivery.phone.prefix,
          time: `${this.selectedOrder.selectedTime.hours < 10 ? '0'+this.selectedOrder.selectedTime.hours : this.selectedOrder.selectedTime.hours}:${this.selectedOrder.selectedTime.minutes < 10 ? '0'+this.selectedOrder.selectedTime.minutes : this.selectedOrder.selectedTime.minutes}`
        }
        const template = smsTemplates(data);
        data.prefix = "+48"
        const polishTemplate = smsTemplates(data)
        if(template.length === 0) throw "Nie znaleziono szablonu wiadomości"

        const result = await axios.post(`${this.$store.state.apiLink}/sms/send`, {
          to: this.selectedOrder.delivery.phone.full.length == 0 ? this.selectedOrder.client.phone.full : this.selectedOrder.delivery.phone.full,
          type: this.selectedOrder.messageType === 'halfHour' ? 2 : 1,
          message: template,
          polishMessage: polishTemplate,
          orderId: this.selectedOrder.id,
          prefix: this.selectedOrder.delivery.phone.prefix.length == 0 ? this.selectedOrder.client.phone.prefix : this.selectedOrder.delivery.phone.prefix,
        })
        
        this.selectedOrder.sending = false
        
        this.selectedOrder.messageType = ""
        this.selectedOrder.selectedTime = {
          hours: parseInt(moment().format('HH')),
          minutes: parseInt(moment().format('mm')),
          seconds: 0
        }
        this.selectedOrder = null
      } catch(error) {
        console.log(error);
        this.selectedOrder.sending = false
      }
    },
    setMessageType(order, type) {
      if(type === order.messageType) {
        order.messageType = ""
      } else {
        order.messageType = type
      }
    },
    async getOrdersInfo(value)
    {
      this.loading = true;
      this.orders = [];
      let ordersRefs = [];

      for(let i=0; i<this.ordersProp.length; i++)
      {
        ordersRefs.push(this.ordersProp[i].id)
      }

      const result = await axios.post(`${this.$store.state.apiLink}/route/packages`, {
        orders: ordersRefs,
      })

      if(result.data.success && result.data.orders.length > 0)
      {
        result.data.orders.forEach(order => {
          order.messageType = ""
          order.selectedTime = {
            hours: parseInt(moment().format('HH')),
            minutes: parseInt(moment().format('mm')),
            seconds: 0
          },
          order.messages = []
          order.dbListener = null
          order.loading = true
          order.halfHourBlocked = true
          order.changeDeliveryTimeBlocked = true
          order.sending = false
        })
        this.orders = result.data.orders;
        if(value === 'empty')
        {
          this.$store.commit('setNotification',{
              show: true,
              head: "Zamówienie zostało zablokowane.",
              subheader: ``,
              success: true
          });
        }
        else if(value === 'unlock')
        {
          this.$store.commit('setNotification',{
              show: true,
              head: "Zamówienie zostało odblokowane.",
              subheader: ``,
              success: true
          });
        }
        this.loading = false;
      }
    },
    async prepareOrders() {
      let preparedOrders = []
      this.ordersProp.forEach(order => {
        let orderData = JSON.parse(JSON.stringify(order.order))
        orderData.messageType = ""
        orderData.selectedTime = {
          hours: parseInt(moment().format('HH')),
          minutes: parseInt(moment().format('mm')),
          seconds: 0
        },
        orderData.messages = []
        orderData.dbListener = null
        orderData.loading = true
        orderData.halfHourBlocked = true
        orderData.changeDeliveryTimeBlocked = true
        orderData.sending = false

        preparedOrders.push(orderData)
      })
      this.orders = preparedOrders
      this.downloadSMSChats()
    },
    emitClose()
    {
      this.$emit("closeModal");
    },
    emitAccept()
    {
        this.$emit("accept");
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
        this.$store.state.route = null;
    },
  }
}
</script>

<style scoped>
  * {
      -webkit-tap-highlight-color: transparent;
  }

  .fade-enter-active,
  .fade-leave-active {
  transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
  opacity: 0;
  }

  .datePickerDiv :deep() input {
    border: 1px solid gray;
    background: transparent;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 3rem;
    margin: 0px;
    padding: 0px !important;
    text-align: center;
    text-transform: uppercase;
    user-select: none !important;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
  .datePickerDiv:hover :deep() input {
    background: #f3f4f6;
  }
  .datePickerDiv :deep() input::selection {
    background: transparent;
  }

  .spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3.8px solid #4f46e5;
    animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
          spinner-oaa3wk 2.2399999999999998s infinite linear;
  }

  @media (min-width: 640px) {
    .spinner {
      width: 32px;
      height: 32px;
    }
  }

  @keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }

  @keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
  }
</style>