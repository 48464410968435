<template>
    <nav class="flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Menu tras</div>
            </div>
        </li>
        </ol>
    </nav>
    <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div :class="['transition ease-in-out duration-300 rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-teal-50 text-teal-700 group-hover:bg-teal-100 group-hover:text-teal-700 rounded-lg inline-flex p-3']">
                    <ViewListIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                    <router-link to="routes/list" class="focus:outline-none">
                <div class="focus:outline-none cursor-pointer">
                    <!-- Extend touch target to entire panel -->
                    <router-link to="routes/list">
                        <span class="absolute inset-0" aria-hidden="true" />
                        Lista tras
                    </router-link>
                </div>
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj lub zarządzaj aktualnie dodanymi trasami.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 sm:rounded-tr-lg relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-teal-50 text-teal-700 group-hover:bg-teal-100 group-hover:text-teal-700 rounded-lg inline-flex p-3']">
                    <FolderAddIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/routes/create" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Tworzenie trasy
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Zaplanuj oraz dodaj trasę.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <ViewListIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/postcode/list" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Lista kodów pocztowych
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj lub zarządzaj dostępnymi kodami pocztowymi.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <FolderAddIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/postcode/add" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Dodaj grupę kodów pocztowych
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj lub zarządzaj dostępnymi grupami kodów.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-green-50 text-green-700 group-hover:bg-green-100 group-hover:text-green-900 rounded-lg inline-flex p-3']">
                 <ViewListIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/vehicles/list" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Lista pojazdów
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Sprawdź listę dostępnych pojazdów firmy oraz zarządzaj nimi.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-green-50 text-green-700 group-hover:bg-green-100 group-hover:text-green-900 rounded-lg inline-flex p-3']">
                    <FolderAddIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/vehicles/add" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Dodaj pojazd
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj nowy pojazd do systemu.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>

        <!-- Kalendarz transportowy -->

        <div :class="['transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-yellow-50 text-yellow-700 group-hover:bg-yellow-100 group-hover:text-yellow-700 rounded-lg inline-flex p-3']">
                    <CalendarIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                    <router-link to="routes/calendar" class="focus:outline-none">
                        <div class="focus:outline-none cursor-pointer">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true" />
                            Kalendarz transportowy
                        </div>
                    </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj lub zarządzaj aktualnie dodanymi trasami.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>

        <!-- Kalendarz transportowy -->

        <div :class="['transition  ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-cyan-50 text-cyan-700 group-hover:bg-cyan-100 group-hover:text-cyan-700 rounded-lg inline-flex p-3']">
                    <PrinterIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                    <router-link to="routes/print" class="focus:outline-none">
                        <div class="focus:outline-none cursor-pointer">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true" />
                            Menedżer wydruków
                        </div>
                    </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Wydrukuj dokumenty dla utworzonych tras.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    // import {db} from "@/firebase/gfbconf.js"
    import {
        ViewListIcon,
        CalendarIcon,
        DocumentAddIcon,
        CollectionIcon,
        PuzzleIcon,
        ViewGridIcon,
        FolderAddIcon,
        PrinterIcon
    } from '@heroicons/vue/outline'
    import { HomeIcon } from '@heroicons/vue/solid'
    
    export default {
        name: "productHub",
        data()
        {
            return {
                askForAdd: false,
                modalData: {
                    headerText: "Usuwanie produktu",
                    bodyText: "Czy na pewno chcesz usunąć ten wariant? Po aktualizacji produktu jego dane zostaną permamentnie usunięte.",
                    acceptButtonText: "Akceptuj"
                },
            }
        },
        components: {
            ViewListIcon,
            DocumentAddIcon,
            CollectionIcon,
            PuzzleIcon,
            HomeIcon,
            ViewGridIcon,
            CalendarIcon,
            FolderAddIcon,
            PrinterIcon
            },
        async created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes)
            {
                this.$router.push("/hub")
            }
        },
        methods:
        {
        },
        computed:
        {

        },
        beforeUnmount() {
        }
    }
</script>
<style lang="scss" scoped>

</style>