<template>
  <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
    <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
      <li class="flex">
        <div class="flex items-center">
        <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
          <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
          <span class="sr-only">Home</span>
        </router-link>
        </div>
      </li>
      <li class="flex">
        <div class="flex items-center">
        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
        <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
        </div>
      </li>
      <li class="flex">
        <div class="flex items-center">
        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
        <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Skaner kodów QR</div>
        </div>
      </li>
    </ol>
  </nav>
  <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
    &larr; Cofnij
  </router-link>
  <div :class="[!fullscreen ? 'flex flex-col justify-between gap-4 mt-10' : '', 'flex justify-center']">
    <!-- camera error alert. -->
    <div v-if="error" class="border-l-4 border-red-400 bg-red-50 p-4 mb-4 select-none">
      <div class="flex">
        <div class="flex items-center">
          <ExclamationIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <p class="text-sm text-red-700">
            ERROR:
            {{ ' ' }}
            <span class="font-medium text-red-700 underline hover:text-red-600">{{errorLog.log}}</span>
          </p>
        </div>
      </div>
    </div>

    <div v-if="!fullscreen" class="flex flex-col justify-center items-center gap-4 mt-8">
      <span class="uppercase text-gray-500 font-bold text-2xl select-none text-center">Wybierz tryb do skanowania</span>
      <div class="flex gap-10">
        <div @click="fullscreen = false" class="flex flex-col items-center group text-gray-400">
          <QrcodeIcon :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
          <span :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">Skaner</span>
        </div>
        <div @click="error ? '' : fullscreen = true" :class="[error ? 'opacity-30 pointer-events-none' : '', 'flex flex-col items-center group text-gray-400']">
          <CameraIcon :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
          <span :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">Kamera</span>
        </div>
      </div>
      <p class="text-xs italic text-gray-600 select-none">Skaner działa tylko z urządzeniami z podczerwienią.</p>
      <div @click="clearData('scanner')" :class="[scannerDecode.length > 0 ? 'select-none rounded-md bg-indigo-200 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 items-center text-center flex justify-center my-2' : '', 'transition-all duration-300']">
        <span v-if="scannerDecode.length > 0" class="absolute font-semibold text-lg">INNA PACZKA</span>
        <input v-if="fullscreen != null ? !fullscreen : false" @focus="focus = true" @blur="focus = false" type="text" v-model="scannerDecode" ref="scanner" name="scanner" id="scanner" style="caret-color: transparent;" class="opacity-0 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
      </div>
    </div>

    <!-- camera -->
    <div v-if="fullscreen">
      <div class="flex flex-col p-2">
          <div :class="{'pt-4': error}">
            <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
              <qrcode-stream v-if="fullscreen" @detect="onDecode" @init="onInit" @click="this.$refs.scanner.focus()">
                <input ref="scanner" v-model="scannerDecode" type="text" class="hidden">
                <!-- <input ref="scanner" v-model="scannerDecode" type="text" class=""> -->
                <!-- <ExternalLinkIcon @click="testScan" class="w-6 h-6 bg-white" /> -->
                <div v-if="acceptedPackage" class="bg-white select-none py-1 px-2 rounded-lg filter fullscreen-button-check relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input v-model="haveQRCode" id="qr" aria-describedby="qr-code" name="qr" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 transition-all duration-300" />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="qr" class="text-gray-900">
                      Mam <span class="font-semibold">QR magazynu</span> lub <span class="font-semibold"> segmentu</span>
                    </label>
                  </div>
              </div>
              <div v-if="!error">
                <button v-if="!notification.success" @click="fullscreen = false" :class="[fullscreen ? 'rotate-180' : '','filter fullscreen-button bg-white rounded-lg hover:text-gray-400 transition-all duration-300 transform -scale-x-100']">
                  <ExternalLinkIcon class="w-6 h-6 bg-transparent" />
                </button>
              </div>

              <div v-if="fullscreen">
                <!-- custom notification -->
                <Transition name="fade2">
                <div v-if="fullscreen && notification.show" :class="{ fullscreen: fullscreen }" class="shadow-lg">
                  <div class="flex min-h-full items-end p-4">
                    <div :class="[notification.success ? 'bg-green-50' : 'bg-red-50', 'rounded-md p-4 w-full']">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <CheckCircleIcon :class="[notification.success ? 'text-green-400' : 'text-red-400', 'h-5 w-5']" aria-hidden="true" />
                        </div>
                        <div class="ml-3">
                          <h3 :class="[notification.success ? 'text-green-800' : 'text-red-800', 'text-sm font-medium']">{{ notification.head }}</h3>
                          <div :class="[notification.success ? ' text-green-700' : ' text-red-700', 'mt-1 text-sm']">
                            <p>{{ notification.subheader }}</p>
                          </div>
                        </div>
                        <div class="ml-auto pl-3">
                          <div class="-mx-1.5 -my-1.5">
                            <button @click.prevent="notification.show = false;" type="button" :class="[notification.success ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50' : 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50', 'inline-flex rounded-md  p-1.5  focus:outline-none focus:ring-2focus:ring-offset-2']">
                              <span class="sr-only">Dismiss</span>
                              <XIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Transition>
                <!-- 1st step -->
                <Transition name="fade">
                  <div :class="{ fullscreen: fullscreen }" class="relative" v-if="modalData.accept && step === 1 && fullscreen">
                    <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </template>
                    <div :class="[fullscreen ? 'fullscreen' : 'flex justify-center w-full', 'overflow-y-auto shadow-lg']">
                      <div :class="[preventFromSpam ? 'opacity-50 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center p-4 sm:p-0']">
                        <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                          <div v-if="modalData.found">
                            <div class="text-center select-none">
                              <div v-if="isUnique" class="pb-4">
                                  <div class="rounded-md bg-red-50 p-3 flex justify-center flex-col">
                                    <div class="flex justify-center">
                                      <div class="flex-shrink-0">
                                        <ExclamationIcon class="h-7 w-7 text-red-400 mr-2" aria-hidden="true" />
                                      </div>
                                      <h3 class="font-semibold text-red-800 whitespace-nowrap">WYKRYTO NIEAKTUALNĄ ETYKIETĘ</h3>
                                    </div>
                                    <p class="font-semibold uppercase text-sm">Proszę o wydrukowanie aktualnej etykiety.</p>
                                  </div>
                              </div>
                              <div :class="[isUnique ? 'opacity-50' : '']">
                                <h3 class="text-base font-semibold leading-6 text-gray-900">
                                  {{modalData.bodyText}}
                                </h3>
                                <div v-if="modalData.headerText.length > 0" class="mt-2">
                                  <p class="text-sm text-gray-500">Paczka: 
                                    <span class="font-bold ">{{modalData.headerText}}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div :class="[isUnique ? 'opacity-50' : '', 'mx-auto flex items-center justify-center']">
                              <img v-if="modalData.found" :class="modalData.image.url.length > 0 ? 'h-40 my-4' : 'h-32 my-4'" :src="modalData.image.url.length > 0 ? modalData.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${modalData.bodyText}`" alt="">
                            </div>
                          </div>
                          <div v-if="!modalData.found">
                            <div class="mt-3 text-center sm:mt-5 select-none">
                              <h3 class="text-base font-semibold leading-6 text-gray-900">
                                {{modalData.bodyText}}
                              </h3>
                              <div v-if="modalData.headerText.length > 0" class="mt-2">
                                <p class="text-sm text-gray-500">Paczka: 
                                  <span class="font-bold">{{modalData.headerText}}</span>
                                </p>
                              </div>
                            </div>
                            <div class="mx-auto flex items-center justify-center">
                              <component :is="modalData.image" v-if="!modalData.found" class="h-40 text-indigo-500" />
                            </div>
                          </div>
                          <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                            <button :disabled="isScanned" @click.prevent="modalData.accept = false, clearData('camera');" type="button" :class="[isScanned ? 'opacity-20 pointer-events-none' : '','transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                              <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                              Anuluj
                            </button>
                            <button :disabled="isScanned || isUnique" v-if="modalData.found" @click.prevent="changeStep(2)" type="button" :class="[isUnique ? 'opacity-20 pointer-events-none' : '', isScanned ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                              <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              Przejdź dalej
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
                <!-- 2nd step -->
                <Transition name="fade">
                  <div class="relative z-10" v-if="modalData.accept && step === 2">
                    <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </template>

                    <div class="fixed inset-0 z-10 w-screen overflow-y-auto shadow-lg">
                      <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center p-4 sm:p-0']">
                          <div class="w-96 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <div>
                              <h2 class="text-sm font-medium text-gray-500 select-none">Przypisz nazwę paczki dla produktu:</h2>
                              <div>
                                <div class="mt-2 flex rounded-md shadow-sm">
                                  <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{selected.num}}</span>
                                  <input @keypress="blockSpecialCharacters($event)" v-model="selected.part" type="text" name="package-name" id="package-name" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: Zagłowek" />
                                </div>
                              </div>
                              <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                                <button :disabled="selected.part.length === 0" @click.prevent="changeStep(3)" type="button" :class="[selected.part.length === 0 ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                  Przejdź dalej
                                  <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </Transition>
                <!-- 3th step -->
                <Transition name="fade">
                <div class="relative z-10" v-if="modalData.accept && step === 3">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>
                  <div class="fixed inset-0 z-10 w-screen overflow-y-auto shadow-lg">
                    <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center p-4 sm:p-0']">
                        <div class="w-96 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                          <h2 class="uppercase text-sm font-semibold text-gray-500 select-none">Wymiary:</h2>
                            <div class="mt-1 sm:mt-0">
                                <span class="text-gray-500 text-xs pl-1">Długość</span>
                                <div>
                                    <div class="max-w-lg relative rounded-md shadow-sm">
                                    <input type="number" @change="checkLength()" name="dimension" v-model="selected.dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-1 sm:mt-0">
                                <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                                <div>
                                    <div class="max-w-lg relative rounded-md shadow-sm">
                                    <input type="number" @change="checkHeight()" name="dimension" v-model="selected.dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-1 sm:mt-0">
                                <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                                <div>
                                    <div class="max-w-lg relative rounded-md shadow-sm">
                                    <input type="number" @change="checkWidth()" name="dimension" v-model="selected.dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                          <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                              <button @click.prevent="modalData.accept = false; this.$refs.scanner.focus();" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                                <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                                Anuluj
                              </button>
                              <button :disabled="!checkDimensions()" @click="changeStep(4), checkDimensions()" :class="[checkDimensions() ? '' : 'opacity-30 pointer-events-none', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                Przejdź dalej
                                <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                </Transition>
                <!-- 4th step -->
                <Transition name="fade">
                <div class="relative z-10" v-if="modalData.accept && step === 4">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>
                  <div class="fixed inset-0 z-10 w-screen overflow-y-auto shadow-lg">
                    <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center p-4 sm:p-0']">
                        <div class="w-96 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                          <h2 class="uppercase text-sm font-semibold text-gray-500 select-none">WAGA:</h2>
                            <div class="mt-1 sm:mt-0">
                              <div>
                                <div class="max-w-lg relative rounded-md shadow-sm">
                                  <input type="number" @change="checkWeight()" name="weight" v-model="selected.weight.amount" id="weight" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Waga" aria-describedby="weight-unit" />
                                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                      <span class="text-gray-500 sm:text-sm" id="price-currency"> kg </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                              <button @click.prevent="changeStep(5, false)" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                Przejdź dalej
                                <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                </Transition>
                <!-- 5rd step -->
                <Transition name="fade">
                <div class="relative z-10" v-if="modalData.accept && step === 5">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-10 w-screen overflow-y-auto shadow-lg">
                    <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center p-4 sm:p-0']">
                        <div class="w-96 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                          <div>
                            <h2 class="uppercase text-sm font-medium text-gray-500 select-none">Faktura:</h2>
                            <div>
                              <div class="mt-2 flex rounded-md shadow-sm">
                                <!-- <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{selected.num}}</span> -->
                                <input v-model="selected.invoice" type="text" name="invoice-check" id="invoice-check" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: FV/2014/01/1 lub 201401001" />
                              </div>
                            </div>
                            <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                              <button @click.prevent="changeStep(6)" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                Przejdź dalej
                                <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                </Transition>
                <!-- 6th step -->
                <Transition name="fade">
                <div v-if="!haveQRCode && modalData.accept && step === 6">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>
                  <div class="fixed inset-0 z-50 w-screen overflow-y-auto shadow-lg">
                    <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
                      <div class="min-w-full sm:min-w-0 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                          <h2 class="text-sm font-medium text-gray-500 select-none">Wybierz magazyn dla paczki:</h2>
                            <ul role="list" :class="[storage.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                              <li  v-for="(strg, index) in storage" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                                <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                                  <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.name}&scale=50`">
                                </div>
                                <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                  <div class="flex-1 truncate px-4 py-2 text-sm">
                                    <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.name}}</a>
                                  </div>
                                  <div class="flex-shrink-0 pr-4">
                                  <Transition name="fade">
                                    <div class="flex h-6 items-center">
                                      <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg)" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                  </Transition>
                                  </div>
                                </div>
                              </li>
                            </ul>
                        </div>
                        <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                          <button :disabled="isSelectedWarehouse" @click.prevent="changeStep(7, strg)" type="button" :class="[isSelectedWarehouse ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                            <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Akceptuj
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Transition>
                <!-- 7th step -->
                <Transition name="fade">
                  <div class="relative z-50" v-if="!haveQRCode && modalData.accept && step === 7">
                    <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </template>

                    <div class="fixed inset-0 z-50 w-screen overflow-y-auto shadow-lg">
                      <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center p-4 sm:p-0']">
                          <div :class="['min-w-full z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
                            <div>
                              <div v-if="!segmentLoading">
                                <h2 class="text-sm font-medium text-gray-500 select-none">Wybierz segment dla paczki:</h2>
                                  <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4" :class="[segments.length > 5 ? 'h-80  overflow-y-scroll' : '', ]">
                                    <li v-for="(sgmnt, index) in segments" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                                      <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                                        <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${sgmnt.name}&scale=50`">
                                      </div>
                                      <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                        <div class="flex-1 truncate px-4 py-2 text-sm">
                                          <a class="font-medium text-gray-900 hover:text-gray-600">{{sgmnt.name}}</a>
                                        </div>
                                          <div class="flex-shrink-0 pr-4">
                                          <Transition name="fade">
                                            <div class="flex h-6 items-center">
                                              <input v-if="!sgmnt.blocked" v-model="sgmnt.selected" @click="selectSegmentLocation($event, sgmnt)" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                            </div>
                                          </Transition>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                                      <button :disabled="isSelectedSegment" @click.prevent="acceptDecode(modalData.product !== null ? modalData.product : '')" type="button" :class="[isSelectedSegment ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        Akceptuj
                                      </button>
                                    </div>
                                </div>
                              </div>
                              <LoadingSpinnerHub v-if="segmentLoading" />
                            </div>
                          </div>
                      </div>
                  </div>
                </Transition>
              </div>
            </qrcode-stream>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!fullscreen">
      <!-- 1st step !fullscreen -->
      <Transition name="fade">
      <div v-if="modalData.accept && step === 1 && !fullscreen">
        <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </template>
        <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
          <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div v-if="modalData.found">
              <div class="text-center select-none">
                <div v-if="isUnique" class="pb-4">
                    <div class="rounded-md bg-red-50 p-3 flex justify-center flex-col">
                      <div class="flex justify-center">
                        <div class="flex-shrink-0">
                          <ExclamationIcon class="h-7 w-7 text-red-400 mr-2" aria-hidden="true" />
                        </div>
                        <h3 class="font-semibold text-red-800">WYKRYTO NIEAKTUALNĄ ETYKIETĘ</h3>
                      </div>
                      <p class="font-semibold uppercase text-sm">Proszę o wydrukowanie aktualnej etykiety.</p>
                    </div>
                </div>
                <div :class="[isUnique ? 'opacity-50' : '']">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">
                    {{modalData.bodyText}}
                  </h3>
                  <div v-if="modalData.headerText.length > 0" class="mt-2">
                    <p class="text-sm text-gray-500">Paczka: 
                      <span class="font-bold ">{{modalData.headerText}}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div :class="[isUnique ? 'opacity-50' : '', 'mx-auto flex items-center justify-center']">
                <img v-if="modalData.found" :class="modalData.image.url.length > 0 ? 'h-40 my-4' : 'h-32 my-4'" :src="modalData.image.url.length > 0 ? modalData.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${modalData.bodyText}`" alt="">
              </div>
            </div>
            <div v-if="!modalData.found">
              <div class="mt-3 text-center sm:mt-5 select-none">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  {{modalData.bodyText}}
                </h3>
                <div v-if="modalData.headerText.length > 0" class="mt-2">
                  <p class="text-sm text-gray-500">Paczka: 
                    <span class="font-bold">{{modalData.headerText}}</span>
                  </p>
                </div>
              </div>
              <div class="mx-auto flex items-center justify-center">
                <component :is="modalData.image" v-if="!modalData.found" class="h-40 text-indigo-500" />
              </div>
            </div>
            <div class="mt-5 sm:mt-6 flex gap-2 select-none">
              <button :disabled="isScanned" @click.prevent="modalData.accept = false, clearData('scanner');" type="button" :class="[isScanned ? 'opacity-20 pointer-events-none' : '','transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                Anuluj
              </button>
              <button :disabled="isScanned || isUnique" v-if="modalData.found" @click.prevent="changeStep(2)" type="button" :class="[isUnique ? 'opacity-20 pointer-events-none' : '', isScanned ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Przejdź dalej
              </button>
            </div>
          </div>
        </div>
      </div>
      </Transition>
      <!-- 2nd step !fullscreen -->
      <Transition name="fade">
      <div class="relative z-10" v-if="modalData.accept && step === 2">
        <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </template>
        <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
          <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
              <h2 class="text-sm font-medium text-gray-500 select-none">Przypisz nazwę paczki dla produktu:</h2>
              <div>
                <div class="mt-2 flex rounded-md shadow-sm">
                  <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{selected.num}}</span>
                  <input @keypress="blockSpecialCharacters($event)" v-model="selected.part" type="text" name="package-name" id="package-name" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: Zagłowek" />
                </div>
              </div>
              <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                <button :disabled="selected.part.length === 0" @click.prevent="changeStep(3)" type="button" :class="[selected.part.length === 0 ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                  Przejdź dalej
                  <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Transition>
      <!-- 3rd step !fullscreen -->
      <Transition name="fade">
      <div class="relative z-10" v-if="modalData.accept && step === 3">
        <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </template>
        <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
          <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <h2 class="uppercase text-sm font-semibold text-gray-500 select-none">Wymiary:</h2>
                  <div class="mt-1 sm:mt-0">
                      <span class="text-gray-500 text-xs pl-1">Długość</span>
                      <div>
                          <div class="max-w-lg relative rounded-md shadow-sm">
                          <input type="number" @change="checkLength()" name="dimension" v-model="selected.dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div class="mt-1 sm:mt-0">
                      <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                      <div>
                          <div class="max-w-lg relative rounded-md shadow-sm">
                          <input type="number" @change="checkHeight()" name="dimension" v-model="selected.dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div class="mt-1 sm:mt-0">
                      <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                      <div>
                          <div class="max-w-lg relative rounded-md shadow-sm">
                          <input type="number" @change="checkWidth()" name="dimension" v-model="selected.dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                          </div>
                          </div>
                      </div>
                  </div>
                <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                    <button @click.prevent="modalData.accept = false; this.$refs.scanner.focus();" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                      <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                      Anuluj
                    </button>
                    <button :disabled="!checkDimensions()" @click="changeStep(4), checkDimensions()" :class="[checkDimensions() ? '' : 'opacity-30 pointer-events-none', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                      Przejdź dalej
                      <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
          </div>
        </div>
      </div>
      </Transition>
      <!-- 4th step !fullscreen -->
      <Transition name="fade">
      <div class="relative z-10" v-if="modalData.accept && step === 4">
        <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </template>
        <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
          <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <h2 class="uppercase text-sm font-semibold text-gray-500 select-none">WAGA:</h2>
              <div class="mt-1 sm:mt-0">
                <div>
                  <div class="max-w-lg relative rounded-md shadow-sm">
                    <input type="number" @change="checkWeight()" name="weight" v-model="selected.weight.amount" id="weight" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Waga" aria-describedby="weight-unit" />
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm" id="price-currency"> kg </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                <button @click.prevent="changeStep(5, false)" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                  Przejdź dalej
                  <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </div>
          </div>
        </div>
      </div>
      </Transition>
      <!-- 5h step !fullscreen -->
      <Transition name="fade">
      <div class="relative z-10" v-if="modalData.accept && step === 5">
        <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </template>
        <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
          <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
              <h2 class="uppercase text-sm font-medium text-gray-500 select-none">Faktura:</h2>
              <div>
                <div class="mt-2 flex rounded-md shadow-sm">
                  <!-- <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{selected.num}}</span> -->
                  <input v-model="selected.invoice" type="text" name="invoice-check" id="invoice-check" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: FV/2014/01/1 lub 201401001" />
                </div>
              </div>
              <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                <button @click.prevent="changeStep(6)" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                  Przejdź dalej
                  <ChevronDoubleRightIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Transition>
      <!-- 6th step !fullscreen -->
      <Transition name="fade">
      <div v-if="!haveQRCode && modalData.accept && step === 6">
        <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </template>
        <div :class="[preventFromSpam ? 'opacity-30 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
          <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
              <h2 class="text-sm font-medium text-gray-500 select-none">Wybierz magazyn dla paczki:</h2>
                <ul role="list" :class="[storage.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                  <li  v-for="(strg, index) in storage" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                    <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                      <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.name}&scale=50`">
                    </div>
                    <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                      <div class="flex-1 truncate px-4 py-2 text-sm">
                        <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.name}}</a>
                      </div>
                      <div class="flex-shrink-0 pr-4">
                      <Transition name="fade">
                        <div class="flex h-6 items-center">
                          <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg)" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                        </div>
                      </Transition>
                      </div>
                    </div>
                  </li>
                </ul>
            </div>
            <div class="mt-5 sm:mt-6 flex gap-2 select-none">
              <button :disabled="isSelectedWarehouse" @click.prevent="changeStep(7, strg)" type="button" :class="[isSelectedWarehouse ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Akceptuj
              </button>
            </div>
          </div>
        </div>
      </div>
      </Transition>
      <!-- 7th step !fullscreen -->
      <Transition name="fade">
      <div class="relative z-50" v-if="!haveQRCode && modalData.accept && step === 7">
        <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </template>

        <div :class="[preventFromSpam ? 'opacity-50 pointer-events-none' : '', 'flex min-h-full items-end justify-center text-center sm:items-center']">
          <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
              <div v-if="!segmentLoading">
                <h2 class="text-sm font-medium text-gray-500 select-none">Wybierz segment dla paczki:</h2>
                  <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4" :class="[segments.length > 5 ? 'h-80  overflow-y-scroll' : '', ]">
                    <li v-for="(sgmnt, index) in segments" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                      <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                        <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${sgmnt.name}&scale=50`">
                      </div>
                      <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                        <div class="flex-1 truncate px-4 py-2 text-sm">
                          <a class="font-medium text-gray-900 hover:text-gray-600">{{sgmnt.name}}</a>
                        </div>
                          <div class="flex-shrink-0 pr-4">
                          <Transition name="fade">
                            <div class="flex h-6 items-center">
                              <input v-if="!sgmnt.blocked" v-model="sgmnt.selected" @click="selectSegmentLocation($event, sgmnt)" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                            </div>
                          </Transition>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                      <button :disabled="isSelectedSegment" @click.prevent="acceptDecode(modalData.product !== null ? modalData.product : '')" type="button" :class="[isSelectedSegment ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Akceptuj
                      </button>
                    </div>
                
                <!-- ??? -->
              </div>
                <!-- ??? -->
                
            </div>
            <LoadingSpinnerHub v-if="segmentLoading" />
          </div>
        </div>
      </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import { QrcodeStream } from 'vue3-qrcode-reader'
import moment from 'moment';
import axios from 'axios';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { QuestionMarkCircleIcon, HomeIcon, CheckIcon, CameraIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, EyeIcon, EyeOffIcon, QrcodeIcon, ExclamationIcon, XIcon, ExternalLinkIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import { LogoutIcon } from '@heroicons/vue/outline';
export default {
  components: {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, QuestionMarkCircleIcon, LogoutIcon, ExternalLinkIcon, XIcon, ExclamationIcon, QrcodeIcon, LoadingSpinnerHub, EyeIcon, EyeOffIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, QrcodeStream, HomeIcon, CheckIcon, CameraIcon, ChevronDoubleRightIcon
  },
  data () {
    return {
      isUnique: false,
      focus: false,
      watchers: {
        error: null,
      },
      haveQRCode: false,
      open: true,
      moment: moment,
      error: false,
      errorLog: {
        name: '',
        log: '',
        block: false
      },
      loading: true,
      modalData: {
        accept: false,
        found: false,
        headerText: '',
        bodyText: '',
        product: null,
        num: null,
      },
      scannerDecode: '',
      fullscreen: null,
      notification: {
        show: false,
        head: "",
        subheader: "",
        success: false,
      },
      resultDecode: null,
      isScanned: false,
      step: 1,
      storage: [],
      singleSelect: false,
      selected: { 
        segment: null,
        warehouse: null,
        part: '',
        invoice: '',
        dimensions: {
          unit: 'cm',
          height: 0,
          width: 0,
          length: 0,
        },
        weight: {
          unit: 'kg',
          amount: 0
        },
        num: '',
        quantity: 1
      },
      connectedVariants: [],
      segments: [],
      segmentLoading: false,
      isSelectedWarehouse: true,
      isSelectedSegment: true,
      acceptedPackage: false,
      scanned: {
        warehouse: null,
        segment: null,
        package: '',
      },
      preventFromSpam: false,
    }
  },
  methods: {
    blockSpecialCharacters(event) {
      let regex = new RegExp("^[a-zA-Z0-9-]+$");
      let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if(!regex.test(key))
      {
        event.preventDefault();
        return false;
      }
    },
    checkDimensions()
    {
      let isCompleted = false
      if(this.selected.dimensions.height > 0 && this.selected.dimensions.width > 0 && this.selected.dimensions.length > 0)
      {
        isCompleted = true;
      }
      return isCompleted;
    },
    checkHeight()
    {
      if(this.selected.dimensions.height.length === 0)
      {
        this.selected.dimensions.height = 0;
      }
      if(this.selected.dimensions.height < 0)
      {
        this.selected.dimensions.height = 0;
      }
      else
      {
        this.selected.dimensions.height = parseFloat(this.selected.dimensions.height);
      }
      this.selected.dimensions.height = this.selected.dimensions.height.toFixed(2);
      this.checkDimensions();
    },
    checkWidth()
    {
      if(this.selected.dimensions.width.length === 0)
      {
        this.selected.dimensions.width = 0;
      }
      if(this.selected.dimensions.width < 0)
      {
        this.selected.dimensions.width = 0;
      }
      else
      {
      this.selected.dimensions.width = parseFloat(this.selected.dimensions.width);
      }
      this.selected.dimensions.width = this.selected.dimensions.width.toFixed(2);
      this.checkDimensions()
    },
    checkLength()
    {
      if(this.selected.dimensions.length.length === 0)
      {
        this.selected.dimensions.length = 0;
      }
      if(this.selected.dimensions.length < 0)
      {
        this.selected.dimensions.length = 0;
      }
      else
      {
        this.selected.dimensions.length = parseFloat(this.selected.dimensions.length);
      }
      this.selected.dimensions.length = this.selected.dimensions.length.toFixed(2);
      this.checkDimensions();
    },
    checkWeight()
    {
      if(this.selected.weight.amount.length === 0)
      {
        this.selected.weight.amount = 0;
      }
      if(this.selected.weight.amount < 0)
      {
        this.selected.weight.amount = 0;
      }
      else
      {
        this.selected.weight.amount = parseFloat(this.selected.weight.amount);
      }
      this.selected.weight.amount = this.selected.weight.amount.toFixed(2);
    },
    selectLocation(event, val)
    {
      val.selected = event.target.checked;
      this.selected.warehouse = val;
      if(val.selected)
      {
        this.isSelectedWarehouse = false;
        for(let i=0; i<this.storage.length; i++)
        {
          if(!this.storage[i].selected)
          {
            this.storage[i].blocked = true;
          }
        }
      }
      else
      {
        this.isSelectedWarehouse = true;
        for(let i=0; i<this.storage.length; i++)
        {
          this.storage[i].blocked = false;
        }
      }
    },
    selectSegmentLocation(event, val)
    {
      val.selected = event.target.checked;
      this.selected.segment = val;
      if(val.selected)
      {
        this.isSelectedSegment = false;
        for(let i=0; i<this.segments.length; i++)
        {
          if(!this.segments[i].selected)
          {
            this.segments[i].blocked = true;
          }
        }
      }
      else
      {
        this.isSelectedSegment = true;
        for(let i=0; i<this.segments.length; i++)
        {
          this.segments[i].blocked = false;
        }
      }
    },
    async getSegmentData(val, exampleId, via)
    {
      try {
        this.segmentLoading = true;
        const sgmnt = await db.collection('Storage').doc(val.id.toString()).collection('Segments').orderBy("meta.created.unix", "desc").get()
        sgmnt.docs.forEach(doc => {
          let element = doc.data();
          element.selected = false;
          element.block = false;
          this.segments.push(element);
        });
        if(via === 'accept')
        {
          for(let i=0; i<this.segments.length; i++)
          {
            if(exampleId === this.segments[i].id)
            {
              this.selected.segment = this.segments[i];
              break;
            }
          }
          this.acceptDecode(this.modalData.product)
        }
        this.segmentLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllStorageLocations()
    {
      try {
        const storageElements = await db.collection('Storage').orderBy("meta.created.unix", "desc").get()
        storageElements.forEach(doc => {
          let element = doc.data();
          element.selected = false;
          element.block = false;
          this.storage.push(element)
        });
      }
      catch (error) {
        console.log(error);
      }
    },
    async changeStep(val, exists)
    {
      switch(val)
      {
        case 1:
          this.step = 1;
          break;
        case 2:
          this.isScanned = true;
          this.acceptedPackage = true;
          if(!await this.checkIfPartsExists())
          {
            this.step = 2;
          }
          else
          {
            this.step = 5;
          }
          break;
        case 3:
          this.step = 3;
          this.selected.part = (this.selected.part.trim() + '-' + this.selected.num)
          break;
        case 4:
          this.step = 4;
          break;
        case 5:
          this.step = 5;
          if(!exists)
          {
            await this.createProductParts();
          }
          break;
        case 6:
          this.step = 6;
          if(this.selected.invoice.length === 0)
          {
            this.selected.invoice = '---';
          }
          break;
        case 7:
          this.haveQRCode = false;
          this.step = 7;
          this.getSegmentData(this.selected.warehouse);
      }
    },
    async createProductParts()
    {
      try {
        const res = await axios.post(`${this.$store.state.apiLink}/scan/createPartsInVariant`, {
          package: this.selected,
          variant: this.resultDecode,
        })
        if(!res.data.success)
        {
          throw res.data.log
        }
      } 
      catch (error) {
        console.log(error);
        this.clearData('camera');
        this.clearData('scanner');
        this.modalData.accept = false;
        this.notification = {
          show: true,
          head: 'Akcja zakończona niepowodzeniem!',
          subheader: error,
          success: false
        }
        setTimeout(() => {
          this.clearData('camera');
          this.clearData('scanner');
        }, 2500);
      }
    },
    clearData(type)
    {
      if(type === 'camera')
      {
        this.scannerDecode = '';
        this.error = false;
      }
      else if(type === 'scanner')
      {
        this.error = false;
        this.scannerDecode = '';
        this.$refs.scanner.readOnly = false;
        this.$refs.scanner.focus();
      }
      this.preventFromSpam = false;
      this.isUnique = false;
      this.acceptedPackage = false;
      this.modalData =  {
        accept: false,
        found: false,
        headerText: '',
        bodyText: '',
        product: null,
        num: null,
      };
      this.isScanned = false;
      this.isSelectedWarehouse = true;
      this.isSelectedSegment = true;
      this.haveQRCode = false;
      this.notification = {
        show: false,
        head: "",
        subheader: "",
        success: false,
      };
      this.selected = {
        segment: null,
        warehouse: null,
        part: '',
        num: '',
        invoice: '',
        quantity: 1,
        dimensions: {
          unit: 'cm',
          height: 0,
          width: 0,
          length: 0,
        },
        weight: {
          unit: 'kg',
          amount: 0
        },
      };
      this.errorLog = {
        name: '',
        log: ''
      };
      for(let i=0; i<this.storage.length; i++)
      {
        this.storage[i].selected = false;
        this.storage[i].blocked = false;
      }
      this.segments = [];
      this.step = 1;
    },
    async checkIfPartsExists()
    {
      try {
        const res = await axios.post(`${this.$store.state.apiLink}/scan/checkIfPartsExists`, {
          variant: this.resultDecode,
        })
        if(res.data.success)
        {
          this.selected.part = res.data.part.name;
          return true;
        }
        else
        {
          return false;
        }
      } 
      catch (error) {
        console.log(error);  
      }
    },
    async onDecode(result)
    {
      try {
        let decodedResult = await result;
        if(decodedResult.content.length === 0) throw 'ERROR: The length of the result is equal to 0;';

        if(!this.acceptedPackage)
        {
          this.clearData('camera');
          this.resultDecode = decodedResult.content;
          let str = this.resultDecode;
          let result = null;
          let numOfPackage  = null;

          //varaint id with number of package to show which package is scanned.

          let n = str.lastIndexOf('-');
          if(n !== -1)
          {
            this.isUnique = false;
            result = str.slice(0, n);
            let p = result.lastIndexOf('_');
            if(p === -1) throw 'ERROR;'
            numOfPackage = result.substring(p+1);
          }
          else
          {
            this.isUnique = true;
            let p = str.lastIndexOf('_');
            if(p === -1) throw 'ERROR;'
            numOfPackage = str.substring(p+1);
          }

          const res = await axios.post(`${this.$store.state.apiLink}/scan/checkIfCorrect`, {
            id: this.resultDecode,
          })
          if(res.data.success)
          {
            this.selected.num = numOfPackage;
            this.modalData = {
              accept: true,
              found: true,
              bodyText: res.data.variant.name,
              headerText: `${res.data.variant.num + '/' + res.data.variant.amountOfPackages}`,
              image: res.data.variant.image,
              product: res.data.product,
            }
          }
          else if(res.data.variant == null)
          {
            this.modalData = {
              accept: true,
              found: false,
              bodyText: 'BRAK DANYCH',
              headerText: ``,
              image: QuestionMarkCircleIcon,
              product: null,
            }
          }
          else
          {
            this.modalData = {
              accept: true,
              found: false,
              bodyText: 'BRAK DANYCH',
              headerText: '',
              image: QuestionMarkCircleIcon,
              product: null,
            }
          }
        }
        else
        {
          if(decodedResult.content.match(/{|}|\[|\]/g) !== null && this.haveQRCode)
          {
            let bracketNumber = '';
            let warehouseId = '';
            let segmentId = '';
            let str = decodedResult.content;
            bracketNumber = str.match(/{|}|\[|\]/g).length;
            if(bracketNumber === 2)
            {
              let warehouseFirstIndex = str.indexOf('[');
              if(warehouseFirstIndex !== -1)
              {
                let warehouseSecondindex = str.indexOf(']');
                warehouseId = str.slice(warehouseFirstIndex+1, warehouseSecondindex) 
              }
              this.selected.warehouse = {
                id: parseInt(warehouseId)
              }

              this.changeStep(5)
            }

            if(bracketNumber === 4)
            {
              let warehouseFirstIndex = str.indexOf('[');
              if(warehouseFirstIndex !== -1)
              {
                let warehouseSecondindex = str.indexOf(']');
                warehouseId = str.slice(warehouseFirstIndex+1, warehouseSecondindex) 
              }
              let segmentFirstIndex = str.lastIndexOf('[');
              if(segmentFirstIndex !== -1)
              {
                let segmentSecondIndex = str.lastIndexOf(']');
                segmentId = str.slice(segmentFirstIndex+1, segmentSecondIndex) 
              }
              this.selected.warehouse = {
                id: parseInt(warehouseId)
              }
              this.selected.segment = {
                id: parseInt(segmentId),
              }
              this.getSegmentData(this.selected.warehouse, parseInt(segmentId), 'accept')
            }

          }
        }
      }
      catch (error)
      {
        console.log(error);
      }
    },
    async onDecodeScanner(val)
    {
      try {
        if(!this.acceptedPackage)
        {
          this.clearData('scanner');
          this.resultDecode = val;
          let str = this.resultDecode;
          let result = null;
          let numOfPackage  = null;

          //varaint id with number of package to show which package is scanned.
          let n = str.lastIndexOf('-');
          if(n !== -1)
          {
            this.isUnique = false;
            result = str.slice(0, n);
            let p = result.lastIndexOf('_');
            if(p === -1) throw 'ERROR;'
            numOfPackage = result.substring(p+1);
          }
          else
          {
            this.isUnique = true;
            let p = str.lastIndexOf('_');
            if(p === -1) throw 'ERROR;'
            numOfPackage = str.substring(p+1);
          }
          
          const res = await axios.post(`${this.$store.state.apiLink}/scan/checkIfCorrect`, {
            id: this.resultDecode,
          })
          if(res.data.success)
          {
            this.selected.num = numOfPackage;
            this.modalData = {
              accept: true,
              found: true,
              bodyText: res.data.variant.name,
              headerText: `${res.data.variant.num + '/' + res.data.variant.amountOfPackages}`,
              image: res.data.variant.image,
              product: res.data.product,
            }
          }
          else
          {
            this.modalData = {
              accept: true,
              found: false,
              bodyText: 'BRAK DANYCH',
              headerText: ``,
              image: QuestionMarkCircleIcon,
              product: null,
            }
          }
        }
        else
        {
          if(val.match(/{|}|\[|\]/g) !== null && this.haveQRCode)
          {
            let bracketNumber = '';
            let warehouseId = '';
            let segmentId = '';
            let str = val;
            bracketNumber = str.match(/{|}|\[|\]/g).length;
            if(bracketNumber === 2)
            {
              let warehouseFirstIndex = str.indexOf('[');
              if(warehouseFirstIndex !== -1)
              {
                let warehouseSecondindex = str.indexOf(']');
                warehouseId = str.slice(warehouseFirstIndex+1, warehouseSecondindex) 
              }
              this.selected.warehouse = {
                id: parseInt(warehouseId)
              }

              this.changeStep(5)
            }

            if(bracketNumber === 4)
            {
              let warehouseFirstIndex = str.indexOf('[');
              if(warehouseFirstIndex !== -1)
              {
                let warehouseSecondindex = str.indexOf(']');
                warehouseId = str.slice(warehouseFirstIndex+1, warehouseSecondindex) 
              }
              let segmentFirstIndex = str.lastIndexOf('[');
              if(segmentFirstIndex !== -1)
              {
                let segmentSecondIndex = str.lastIndexOf(']');
                segmentId = str.slice(segmentFirstIndex+1, segmentSecondIndex) 
              }
              this.selected.warehouse = {
                id: parseInt(warehouseId)
              }
              this.selected.segment = {
                id: parseInt(segmentId),
              }
              this.getSegmentData(this.selected.warehouse, parseInt(segmentId), 'accept')
            }

          }
        }
      }
      catch (error) {
        console.log(error);  
      }
    },
    async acceptDecode(val)
    {
      try {
        this.preventFromSpam = true;
        this.isSelectedSegment = true;
        this.isScanned = true;
        const res = await axios.post(`${this.$store.state.apiLink}/scan/receive`, {
          id: this.resultDecode,
          location: this.selected,
          user: this.$store.state.userData
        })
        if(!res.data.success)
        {
          throw res.data.log;
        }
        else
        {
          if(!this.fullscreen)
          {
            this.$store.commit('setNotification', {
              show: true,
              head: res.data.received ? 'Akcja zakończona sukcesem!' : "Akcja zakończona niepowodzeniem!",
              subheader: res.data.log,
              success: res.data.received
            }); 
          }
          else
          {
            this.modalData.accept = false;
            this.notification = {
              show: true,
              head: res.data.received ? 'Akcja zakończona sukcesem!' : "Akcja zakończona niepowodzeniem!",
              subheader: res.data.log,
              success: res.data.received
            }
          }
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Fsuccess.mp3?alt=media&token=d15ba5f1-9521-4d55-b905-3c38499c3b27');
          audio.play();
        }
        if(this.fullscreen)
        {
          setTimeout(() => {
            this.clearData('camera')
          }, 3500);
        }
        else
        {
          this.clearData('scanner')
          this.$refs.scanner.focus();
        }
      }
      catch (error) {
        console.log(error);
        if(!this.fullscreen)
        {
          this.$store.commit('setNotification', {
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error,
            success: false
          }); 
          this.clearData('scanner')
          this.$refs.scanner.focus();
        }
        else
        {
          this.modalData.accept = false;
          this.notification = {
            show: true,
            head: 'Akcja zakończona niepowodzeniem!',
            subheader: error,
            success: false
          }
          setTimeout(() => {
            this.clearData('camera')
          }, 3500);
        }
        let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Ferror.mp3?alt=media&token=a1fabac7-7082-412f-9b64-25d54c0efe04');
        audio.play();
      }
    },
    onFullscreenChange(event) {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement != null;
    },
    exitFullscreen() {
      if (!document.fullscreenElement) {
        return;
      }

      if (!document.hidden) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    requestFullscreen() {
      const elem = this.$refs.wrapper
      this.watchers.error = this.$watch('error', (newVal, oldVal) => {
        if(newVal) {
          this.error = true;
          this.fullscreen = false;
        }
      });
      while(elem != null)
      {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
        elem = elem.parentElement;
      };
    },
    async onInit (promise)
    {
      try {
        let res = await promise
      } catch(err)
      {
        this.error = true;
        if(err.name === 'NotAllowedError')
        {
          this.errorLog = {
            name: 'NotAllowedError',
            log: "Musisz przyznać uprawnienia dostępu do kamery.",
          }
        }
        else if (err.name === 'NotFoundError')
        {
          this.errorLog = {
            name: 'NotFoundError',
            log: "Brak kamery w tym urządzeniu."
          }
        }
        else if (err.name === 'NotSupportedError')
        {
          this.errorLog = {
            name: 'NotSupportedError',
            log: "Wymagany bezpieczny kontekst (HTTPS, localhost)."
          }
        }
        else if (err.name === 'NotReadableError')
        {
          this.errorLog = {
            name: 'NotReadableError',
            log: "Kamera jest zajęta."
          }
        }
        else if (err.name === 'OverconstrainedError')
        {
          this.errorLog = {
            name: 'OverconstrainedError',
            log: "Brak kamery spełniającej wymagania."
          }
        }
        else if (err.name === 'StreamApiNotSupportedError')
        {
          this.errorLog = {
            name: 'StreamApiNotSupportedError',
            log: "Stream API nie jest obsługiwany w tej przeglądarce."
          }
        }
        else if (err.name === 'InsecureContextError')
        {
          this.errorLog = {
            name: 'InsecureContextError',
            log: "Dostęp do kamery jest dozwolony tylko w bezpiecznym kontekście. Należy używać protokołu HTTPS."
          }
        }
        else
        {
          this.errorLog = {
            name: err.name,
            log: err.name,
          }
        }
      }
    },
    testScan()
    {
      this.scannerDecode = '';
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 10) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      this.scannerDecode = 'v_JUhh7t13jMaBDVhL_p_Yxdqu1Zwnj54FvOPdEI8_1-'+result;
    },
  },
  watch: {
    focus(isFocused)
    {
      if(!this.modalData.accept)
      {
        if(!isFocused)
        {
          this.$refs.scanner.focus();
        }
      }
    },
    scannerDecode(newVal)
    {
      if(newVal)
      {
        let input = this.$refs.scanner;
        if(newVal.length === 0)
        {
          input.readOnly = false;
        }
        else if(newVal.length > 1)
        {
          input.readOnly = true;
          this.onDecodeScanner(newVal);
        }
      }
    },
    fullscreen(enterFullscreen) {
      if(enterFullscreen)
      {
        this.requestFullscreen()
      }
      else
      {
        this.exitFullscreen();
        this.$nextTick(() => {
          this.$refs.scanner.focus(); 
        })
      }
    },
  },
  created()
  {
    moment.locale('pl')
    this.getAllStorageLocations();
  }
}
</script>
<style scoped>
.qr-container {
  background:
    linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

.fullscreen {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fullscreen-button{
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  margin: 1rem;
}

.fullscreen-notification {
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  margin: 1rem;
}

.fullscreen-button-check {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  margin: 1rem;
}

.fullscreen-button img {
  width: 2rem;
}
.fullscreen-z {
  z-index: 1000;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

</style>