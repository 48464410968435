<template>
        <div class="wrap flex items-center justify-center mt-4">
            <div class="loading">
                <div class="bounceball"></div>
                <div class="text ml-4">Ładowanie..</div>
            </div>
        </div>
</template>
<style lang="scss" scoped>

$width: 15px;
$height: 15px;
$bounce_height: 30px;



.text {
  color: #4f5aac;
  display: inline-block;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #4f5aac;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
</style>