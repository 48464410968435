<template>
    <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Centrum wydruków</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <!-- <label for="search" class="block text-sm font-medium text-gray-700 mt-4">Search</label> -->

        <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-7 lg:gap-8">
          <!-- Left column -->
          <div class="grid grid-cols-1 gap-4 lg:col-span-5">
            <div>
                <!-- SEARCHER -->
        <div class="min-w-0 flex-1 xl:col-span-6">
            <div :class="{'flex items-center justify-between': true}">
                <div :class="{'w-full flex': true, 'justify-between': true, 'flex-col space-y-4 lg:space-y-0 lg:flex-row': this.showStatusFilters}">
                    <Transition name="fade"> 
                    <div v-if="!this.showStatusFilters" class="w-2/3 sm:w-1/3 flex">
                        <label for="search" class="sr-only">Szukaj</label>
                        <div class="relative">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                        </div>
                        <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                    </div>
                    </Transition>
                    <Transition name="fade"> 
                    <div v-if="showStatusFilters === true" class="mr-4 flex flex-col lg:flex-row w-full justify-between lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                        <!-- <select @change="pickStatus()" v-model="pickedStatus" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                            <option value="all">Wszystkie</option>
                            <option v-for="status in statuses" :value="status.id" :key="status.id">{{status.name}}</option>
                        </select> -->
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                        <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Status</label>
                            <select v-model="pickedStatus" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                <option value="all">Wszystkie</option>
                                <option v-for="status in statuses" :value="status.id" :key="status.id">{{status.name}}</option>
                            </select>
                        </div>
                        <div class="flex items-center">
                            <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data utworzenia</label>
                                <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfCreate($event)" v-model="pickedDateOfCreate" ref="pickedCreatedDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                            </div>
                            <Transition name="fade2"> 
                            <div v-if="pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null" class="ml-2 mr-2">
                                <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                            </div>
                            </Transition>
                        </div>
                        <div class="flex items-center">
                            <div :class="{'relative w-full rounded-md border border-gray-300 lg:w-44 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null}">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data planowanej dostawy</label>
                                <input :disabled="pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null" type="date" @change="pickDateOfDelivery($event)" v-model="pickedDateOfDelivery" ref="suggestedDeliveryDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                            </div>
                            <Transition name="fade2"> 
                            <div v-if="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" class="ml-2 mr-2">
                                <span @click="resetDateOfDelivery" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                            </div>
                            </Transition>
                        </div>
                        </div>
                        <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                        <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                            <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                <option value="creationAsc">Utworzono: Od najstarszych</option>
                                <option value="creationDesc">Utworzono: Od najnowszych</option>
                                <option value="suggestedDeliveryAsc">P. dostawa: Od najwcześniejszych</option>
                                <option value="suggestedDeliveryDesc">P. dostawa: Od najpóźniejszych</option>
                                <!-- <option v-for="status in statuses" :value="status.id" :key="status.id">{{status.name}}</option> -->
                            </select>
                        </div>
                        <!-- <div class="transition ease-in-out duration-300 relative inline-flex items-center px-4 py-2 rounded-md border border-blue-500 bg-blue-500 text-sm font-medium text-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">Zastosuj filtry</div> -->
                        <button type="button" @click="this.pickStatus()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
                        </div>
                    </div>
                    </Transition>
                    <div v-if="searcher.found.length === 0" :class="{'flex justify-between items-end lg:items-center lg:justify-start transition-all duration-1000 w-auto': true, 'min-w-filters': showStatusFilters}">
                        <div @click="showFilters()" class="mr-4 cursor-pointer flex justify-center items-center flex-col select-none">
                            <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                            <span class="text-xs font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                        </div>
                        <div v-if="showStatusFilters" :class="{'flex lg:hidden items-center justify-center': true}">
                            <router-link to="/dashboard/orders/add" type="button"  class="transition ease-in-out duration-300 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                    Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                    <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>           
            </div>
        </div>

        <!-- <Spinner v-if="loading"></Spinner> -->
        <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 lg:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Kraj</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kod pocztowy</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nazwa mebla</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Producent</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Sugerowana dostawa</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wykonane czynności</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Drukowanie</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                
                                <tr v-for="order in searcher.found.length > 0 ? searcher.found : orders" :key="order.id" @click="pickToPrint(order)" :class="{'cursor-pointer transition ease-in-out duration-300': true, 'hover:bg-gray-100': !order.pickedToPrint ,'bg-blue-50 hover:bg-red-50': order.pickedToPrint}">
                                    <td class="whitespace-nowrap py-4 pr-3 pl-4 text-xs sm:pl-6">
                                        <div class="flex items-center">
                                            <img v-if="order.countryFlagURL.length>0" :src="order.countryFlagURL" v-tooltip="`${order.delivery.country.code}`" class="h-4 w-6 border border-gray-300">
                                            <span v-if="order.countryFlagURL.length === 0 && order.delivery.countryCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 pl-4 text-xs sm:pl-6">
                                        <div class="flex items-center">
                                            <span v-if="order.delivery.postCode.length>0">{{ order.delivery.postCode }}</span>
                                            <span v-if="order.delivery.postCode.length === 0 && order.delivery.postCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <!-- <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <div class="flex items-center">
                                            <span class="mr-1 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800" v-for="prod in createProductList(order).slice(0,1)" :key="prod" v-tooltip="`${prod}`">{{prod.length > 0 ? prod.length < 20 ? prod : prod.substring(0,20) + '...' : '--'}}</span>
                                            <span v-tooltip="`${createProductList(order)}`" v-if="createProductList(order).length - 1 > 0">+ {{createProductList(order).length - 1}} więcej</span>
                                        </div>
                                    </td> -->
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-900 font-semibold">
                                        <div class="flex items-center">
                                            <div class="inline-flex w-56 truncate items-center">
                                                <span @click.prevent="openCommentsModal(order)" v-tooltip="`Zobacz komentarze wewnętrzne`" :class="[order.statusId === 400 && createCommentList(order)[0].length > 0 ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800', 'mr-1 items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer truncate']" v-for="prod in createProductList(order).slice(0,1)" :key="prod">{{prod}}</span>
                                                <span @click.prevent="openCommentsModal(order)" class="cursor-pointer" v-tooltip="`${createProductList(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="createProductList(order).length - 1 > 0">+ {{createProductList(order).length - 1}} więcej</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <div class="inline-flex w-28 truncate items-center">
                                            <span class="truncate font-semibold text-gray-600" v-for="manu in getManufacturer(order).slice(0, 1)" :key="manu">{{manu.length > 0 ? manu : '---'}}</span>
                                            <span class="text-indigo-500 font-semibold ml-1 cursor-pointer" v-tooltip="`${getManufacturer(order).slice(1).toString().replaceAll(',',' | ').length > 0 ? getManufacturer(order).slice(1).toString().replaceAll(',',' | ') : '---'}`" v-if="getManufacturer(order).length - 1 > 0">+ {{getManufacturer(order).length - 1}} więcej</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <span v-if="order.delivery.date.suggested !== null">{{order.delivery.date.suggested.asText}}</span>
                                        <span v-if="order.delivery.date.suggested === null" class="opacity-60">Nie wskazano</span>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <div class="flex w-16 justify-between">
                                            <div class="flex flex-col items-center">
                                                <div class="text-gray-400 text-xs">P</div>
                                                <div class="h-4 w-4 border border-gray-400 rounded-sm"><CheckIcon v-if="order.manufacturerPrinted" class="h-3.5 w-3.5 text-green-500 flex-shrink-0" aria-hidden="true" /></div>
                                            </div>

                                        </div>
                                    </td>
                                    <td  class="relative whitespace-nowrap py-4 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <div class="flex justify-end w-full transition ease-in-out duration-300 text-blue-600 hover:text-blue-900"
                                        >
                                        <div class="flex h-5 w-5 items-center">
                                            <CheckIcon v-if="order.pickedToPrint"  class="h-5 w-5 text-green-500 flex-shrink-0" aria-hidden="true" />
                                        </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                       
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="orders.length === 0"></EmptyState>
        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
            <button type="button" @click.prevent="loadMoreOrders" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
        </div>  
            </div>
          </div>

          <!-- Right column -->
          <div class="grid grid-cols-1 lg:col-span-2 gap-1">
            <div class="mb-1">
                <h2 class="text-base font-medium -mb-1">Zarządzaj wydrukiem</h2>
                <span class="text-xs">Zaznaczono: {{this.pickedToPrint.length}} zamówień</span>
            </div>
            <div class="w-full bg-white rounded-lg shadow">
                <fieldset class="border-b border-gray-200">
                    <legend class="sr-only">Wydruki</legend>
                    <div class="divide-y divide-gray-200">
                    <div @click.prevent="printType.manufacturer = !printType.manufacturer" class="relative flex items-start cursor-pointer py-4 px-4">
                        <div class="min-w-0 flex-1 text-sm">
                        <label for="comments" class="font-medium text-sm text-gray-700">Producent</label>
                        <p id="comments-description" class="text-gray-500">Lista zawierająca produkty do odebrania od producentów.</p>
                        </div>
                        <div class="ml-3 flex h-5 items-center">
                        <input v-model="printType.manufacturer" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 pointer-events-none rounded border-gray-300 text-blue-600 focus:ring-blue-500" />
                        </div>
                    </div>
                    </div>
                </fieldset>
                <div class="flex justify-center items-center"><span @click.prevent="showOrderModal = true" :class="{'mt-2 text-xs transition ease-in-out duration-300 cursor-pointer': true, 'text-blue-500 hover:text-blue-600': this.pickedToPrint.length > 1, 'text-gray-200 pointer-events-none': this.pickedToPrint.length <= 1}">Zarządzaj kolejnością zamówień</span></div>
                <div class="mt-2">
                    <button @click.prevent="printPdf()" type="button" :class="{'w-full transition ease-in-out duration-300 inline-flex items-center justify-center rounded-b-md border border-transparent px-6 py-3 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2': true, 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': this.pickedToPrint.length > 0 && (this.printType.manufacturer || this.printType.load || this.printType.driver), 'bg-gray-100 text-gray-300 pointer-events-none': this.pickedToPrint.length === 0 || (!this.printType.manufacturer && !this.printType.load && !this.printType.driver)}">
                        <PrinterIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                        Drukuj
                    </button>
                </div>
            </div>
          </div>
        </div>  
    </div>
    <PrintOrderModal v-if="showOrderModal" @closeModal="updateQueue" :pickedToPrint="this.pickedToPrint"></PrintOrderModal>
    <CommentsModal v-if="commentsModal.toggle" :order="commentsModal.data" @close="closeCommentsModal" />

</template>

<script>
// import Spinner  from '@/components/loadingSpinner.vue';
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import EmptyState from '../components/EmptyState.vue';
import { CheckIcon,SearchIcon, PlusSmIcon, XIcon, FilterIcon, PrinterIcon  } from '@heroicons/vue/outline';
import { HomeIcon  } from '@heroicons/vue/solid';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PrintOrderModal from '@/components/PrintOrderModal.vue';
import CommentsModal from "@/components/CommentsInOrder.vue";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
    name: "OrdersList",
    data() {
        return {
            showOrderModal: false,
            pickedToPrint: [],
            printType: {
                manufacturer: false,
                load: false,
                driver: false
            },
            loadHeader: "",
            query: null,
            pickedStatus: "all",
            sortName: "creationDesc",
            sortType: {
                order: "desc",
                type: "meta.created.date"
            },
            pickedDateOfCreate: null,
            pickedDateOfCreateUnix: null,
            pickedDateOfDelivery: null,
            pickedDateOfDeliveryUnix: null,
            showStatusFilters: false,
            countriesCodes: [],
            countriesData: [],
            statuses:[],
            loading: false,
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            orders: [],
            queryLimit: 25,
            dbListener: null,
            lastLoaded: null,
            queryLimitReached: false,
            printedManufacturer: [],
            printedLoad: [],
            printedDriver: [],
            commentsModal: {
                toggle: false,
                data: null
            }
        }
    },
    components: {
        CommentsModal, SearchIcon, CheckIcon, PlusSmIcon, XIcon, FilterIcon, PrinterIcon, HomeIcon, PrintOrderModal, EmptyState,
        // Spinner,
    },
    async created()
    {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.prints)
        {
            this.$router.push("/hub")
        }
        await this.getPrinted()
        await this.getStatuses()
        await this.getCountries()
        await this.getAllOrders()
    },
    methods:
    {
        createCommentList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfComments.push(element[1].infos.onOrderCreation);
                }
            })

            return translatedListOfComments
        },
        openCommentsModal(order){
            this.commentsModal.toggle = true
            this.commentsModal.data = order
        },
        closeCommentsModal(){
            this.commentsModal.toggle = false
            this.commentsModal.data = null
        },
        async getPrinted(){
            let res = await db.collection("PrintCenter").doc("PrintedOrders").get()
            let data = res.data()
            this.printedManufacturer = data.manufacturerPdfs
            this.printedLoad = data.loadPdfs
            this.printedDriver = data.driverPdfs
        },
        async printPdf(){
            if(this.printType.manufacturer){
                await this.printManufacturerPdf()
            }
            if(this.printType.load){
                await this.printLoadPdf()
            }
            if(this.printType.driver){
                await this.printDriverPdf()
            }

            let preparedBody = {
                manufacturerPdfs: this.printedManufacturer,
                loadPdfs: this.printedLoad,
                driverPdfs: this.printedDriver
            }
            await db.collection("PrintCenter").doc("PrintedOrders").set(preparedBody)
        },
        printManufacturerPdf(){
            {
                let preparedContent = []
                let preparedProducts = {}
                this.pickedToPrint.forEach((order, index) => {
                    for (let i = 0; i < Object.keys(order.positions).length; i++) {
                        let preparedName =  order.positions[i+1].product.name
                        
                        //old version
                        // if(preparedProducts[preparedName] === undefined){
                        //     preparedProducts[preparedName] = {
                        //         name: preparedName,
                        //         quantity: parseFloat(order.positions[i+1].product.quantity),
                        //         manufacturer: typeof order.positions[i+1].product.manufacturer === "string" ? order.positions[i+1].product.manufacturer.trim() : order.positions[i+1].product.manufacturer.name.trim(),
                        //         comment: order.positions[i+1].infos.onOrderCreation,
                        //     }
                        // }
                        // else
                        // {
                        //     preparedProducts[preparedName].quantity += parseFloat(order.positions[i+1].product.quantity)
                        // }
                        
                        // new version
                        let counter = 0;
                        while(preparedProducts[`${preparedName}_${counter}`] !== undefined) {
                            counter++;
                        }

                        preparedProducts[`${preparedName}_${counter}`] = {
                            name: preparedName,
                            quantity: parseFloat(order.positions[i+1].product.quantity),
                            manufacturer: typeof order.positions[i+1].product.manufacturer === "string" ? order.positions[i+1].product.manufacturer.trim() : order.positions[i+1].product.manufacturer.name.trim(),
                            comment: order.positions[i+1].infos.onOrderCreation,
                            postCode: order.delivery.postCode,
                        }
                        
                    }
                    order.manufacturerPrinted = true
                    this.printedManufacturer.push(order.id)
                });

                let manufacturersProducts = {}

                for (let i = 0; i < Object.keys(preparedProducts).length; i++) {
                    let currentName = Object.keys(preparedProducts)[i]
                    let currentProduct = preparedProducts[currentName]
                    let manufacturer = typeof currentProduct.manufacturer === "string" ? currentProduct.manufacturer.trim() : currentProduct.manufacturer.name.trim();
                    if(manufacturersProducts[manufacturer] === undefined){
                        manufacturersProducts[manufacturer] = []
                    }
                    manufacturersProducts[manufacturer].push(currentProduct)
                }

                for (let i = 0; i < Object.keys(manufacturersProducts).length; i++) {
                    let currentName = Object.keys(manufacturersProducts)[i]
                    let currentManufacturer = manufacturersProducts[currentName]
                    let preparedBody = []
                    currentManufacturer.forEach(product => {
                        let preparedProductData = [`${product.quantity}x ${product.name} ${product.comment.length > 0 ? ('\n KOMENTARZ: '+''+product.comment) : ''}`, {text: product.postCode, alignment: 'right', bold: true}]
                        preparedBody.push(preparedProductData)
                    });
                
                let preparedContentToPush = [
                    {
                        text: `${currentName}: `,
                        style: "header"
                    },
                    {
                        table: {
                            widths: [300, "*"],
                            body: preparedBody
                        },
                        layout: {
                            
                            paddingTop: function (i) {
                                return 15
                            },
                            paddingBottom: function (i) {
                                return 15
                            },
                            paddingRight: function (i, node) {
                                return 15;
                            },
                            paddingLeft: function (i, node) {
                                return 15;
                            },
                            hLineWidth: function(i, node) {
                                return (i === 0 || i === node.table.body.length) ? 0 : 1;
                                //return 0;
                            },
                            vLineWidth: function(i, node) {
                                return 0;
                            },
                        },
                        margin: [0,0,0,30],
                    }
                ]
                preparedContent.push(preparedContentToPush)
                
                }

                var dd = {
                    content: preparedContent,
                    styles: {
                        header: {
                            fontSize: 16,
                            bold: true,
                            margin: [0, 0, 0, 10]
                        },
                    }
                }
                pdfMake.createPdf(dd).download(`Producenci_odbiór_${this.generateName(8)}.pdf`);
            }
        },
        printLoadPdf(){
            let preparedBody = [
                [
                    {
                    text: 'Lp.',
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [-4, 7, -4, 7],
                    fillColor: '#F4F4F4',
                    textTransform: 'uppercase',
                    fontSize: 10,
                    bold: true
                    },
                    {
                    text: 'Nazwa mebla',
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [0, 7, 0, 7],
                    fillColor: '#F4F4F4',
                    textTransform: 'uppercase',
                    fontSize: 10,
                    bold: true
                    },
                    {
                    text: 'Klient',
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [0, 7, 0, 7],
                    fillColor: '#F4F4F4',
                    textTransform: 'uppercase',
                    fontSize: 10,
                    bold: true
                    },
                    {
                    text: 'L. pacz.',
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [-4, 7, -4, 7],
                    fillColor: '#F4F4F4',
                    textTransform: 'uppercase',
                    fontSize: 10,
                    bold: true
                    },
                    {
                    text: 'Dostawa',
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [-4, 7, -4, 7],
                    fillColor: '#F4F4F4',
                    textTransform: 'uppercase',
                    fontSize: 10,
                    bold: true
                    }
                ]
            ]

            this.pickedToPrint.forEach((order, index) => {

                let preparedNumberOfPackages = 0
                let preparedProductNames = ""
                
                for (let i = 0; i < Object.keys(order.positions).length; i++) {
                    let preparedName = order.positions[i+1].product.quantity.toString() + 'x ' + order.positions[i+1].product.name + '\n'
                    preparedProductNames = preparedProductNames + preparedName
                    preparedNumberOfPackages += parseFloat(order.positions[i+1].product.amountOfPackages) * parseFloat(order.positions[i+1].product.quantity)
                }

                let preparedOrder = [
                    {
                    text: `${index+1}`,
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [-4, 5, -4, 5],
                    textTransform: 'uppercase',
                    fontSize: 10,
                    bold: true
                    },
                    {
                    text: `${preparedProductNames}`,
                    border: [true, true, true, true],
                    alignment: 'left',
                    margin: [0, 5, 0, 5],
                    textTransform: 'uppercase',
                    fontSize: 10,
                    },
                    {
                    text: `${order.client.company.length > 0 ? order.client.company : order.client.name}`,
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [0, 5, 0, 5],
                    textTransform: 'uppercase',
                    fontSize: 10,
                    },
                    {
                    text: `${preparedNumberOfPackages}`,
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [-4, 5, -4, 5],
                    textTransform: 'uppercase',
                    fontSize: 10,
                    },
                    {
                    text: `${order.delivery.date.confirmed !== null ? order.delivery.date.confirmed.asText : order.delivery.date.suggested !== null ? order.delivery.date.suggested.asText : 'Nie wskazano'}`,
                    border: [true, true, true, true],
                    alignment: 'center',
                    margin: [-4, 5, -4, 5],
                    textTransform: 'uppercase',
                    fontSize: 10,
                    }
                ]
                preparedBody.push(preparedOrder)
                order.loadPrinted = true
                this.printedLoad.push(order.id)
            });

            var dd = {
                content: [
                    {text: `${this.loadHeader.length === 0 ? 'Załadunek bez nazwy' : this.loadHeader}`, style: "header"},
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['4%', '*', '20%', "7%", "13%"],
                            body: preparedBody
                        }
                    },

                ],
                styles: {
                    header: {
                        fontSize: 24,
                        bold: true,
                        margin: [0, 0, 0, 10],
                        alignment: 'center'
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                        fontSize: 12
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                },
                defaultStyle: {
                    // alignment: 'justify'
                }
                
            }
            pdfMake.createPdf(dd).download(`Załadunek_${this.loadHeader.length > 0 ? this.loadHeader : this.generateName(8)}.pdf`);
        },
        printDriverPdf(){
            let preparedBody = []

            this.pickedToPrint.forEach((order, index) => {

                let preparedNumberOfPackages = 0
                let preparedProductNames = ""
                
                for (let i = 0; i < Object.keys(order.positions).length; i++) {
                    let preparedName = order.positions[i+1].product.quantity.toString() + 'x ' + order.positions[i+1].product.name + '\n'
                    preparedProductNames = preparedProductNames + preparedName
                    preparedNumberOfPackages += parseFloat(order.positions[i+1].product.amountOfPackages) * parseFloat(order.positions[i+1].product.quantity)
                }

                let preparedOrder = [
                    {
                        stack: [
                            `${order.client.company.length > 0 ? order.client.company : order.client.name.length > 0 ? order.client.name : 'NIE PODANO'}`,
                            `${order.delivery.address.length > 0 ? order.delivery.address : 'NIE PODANO'}`,
                            `${order.delivery.postCode.length > 0 ? order.delivery.postCode : 'NIE PODANO'} ${order.delivery.city.length > 0 ? order.delivery.city : 'NIE PODANO'}`
                            ],
                            unbreakable: true
                    },
                    {
                        stack: [
                                `${preparedProductNames}`,
                                `${preparedNumberOfPackages} PACZKI, ${order.payment.cashOnDelivery ? 'POBRAĆ PIENIĄDZE' : 'ZAPŁACONE'}`
                            ],
                            unbreakable: true
                    },
                    {
                        stack: [
                            {text: `${order.client.phone.full.length > 0 ? order.client.phone.full : (order.client.phone.prefix.length > 0 && order.client.phone.number.length > 0) ? order.client.phone.prefix + order.client.phone.number : order.client.phone.number.length > 0 ? order.client.phone.number : 'NIE PODANO'}`},
                            {text: ` `},
                            {text: ` `}
                        ],
                        unbreakable: true
                    },
                ]
                preparedBody.push(preparedOrder)
                order.driverPrinted = true
                this.printedDriver.push(order.id)
            });

            var dd = {
                content: [
                    
                {
                style: 'tableExample',

                table: {
                widths: ['auto',"auto", 'auto'],
                body: preparedBody,
                },
                layout: {
                
                        paddingTop: function (i) {
                            return 15
                        },
                        paddingBottom: function (i) {
                            return 15
                        },
                        paddingRight: function (i, node) {
                            return 15;
                        },
                        paddingLeft: function (i, node) {
                            return 15;
                        },
                        hLineWidth: function(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function(i, node) {
                            return 0;
                        },
                    }

                },

                ],
                styles: {
                header: {
                fontSize: 24,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment: 'center'
                },
                subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
                },
                tableExample: {
                margin: [0, 5, 0, 15],
                fontSize: 12
                },
                tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
                },
                },
                defaultStyle: {
                // alignment: 'justify'
                }

                }
            pdfMake.createPdf(dd).download(`Lista_kierowcy_${this.generateName(8)}.pdf`);
        },
        generateName(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
            }
            return result;
        },
        updateQueue(queue){
            this.showOrderModal = false
            this.pickedToPrint = []
            queue.forEach(readyQ => {
            let stringified = JSON.stringify(readyQ)
            let readyToPush = JSON.parse(stringified)
            this.pickedToPrint.push(readyToPush)
            });
        },
        pickToPrint(order){
            let exst = false
            let indx = null
            this.pickedToPrint.forEach((picked, index) => {
                if(picked.id === order.id){
                    exst = true
                    indx = index
                }
            });
            if(exst){
                order.pickedToPrint = false
                this.pickedToPrint.splice(indx, 1)
            }else{
                order.pickedToPrint = true
                this.pickedToPrint.push(order)
            }
        },
        // getManufacturer(order)
        // {
        //     const manufacturer = Object.entries(order.positions)
        //     let translatedListOfManufacturer = [];
        //     manufacturer.forEach(element => {
        //         if(element[0])
        //         {
        //             translatedListOfManufacturer.push(element[1].product.manufacturer);
        //         }
        //     })

        //     return translatedListOfManufacturer
        // },
        getManufacturer(order)
        {
            const manufacturer = Object.entries(order.positions)
            let translatedListOfManufacturer = [];
            manufacturer.forEach(element => {
                if(element[0])
                {
                    if (typeof element[1].product.manufacturer === "string") {
                        element[1].product.manufacturer.trim();
                        if (!translatedListOfManufacturer.includes(element[1].product.manufacturer)) {
                            translatedListOfManufacturer.push(element[1].product.manufacturer);
                        }
                    } else {
                        element[1].product.manufacturer.name.trim();
                        if (!translatedListOfManufacturer.includes(element[1].product.manufacturer.name)) {
                            translatedListOfManufacturer.push(element[1].product.manufacturer.name);
                        }
                    }
                }
            });
            return translatedListOfManufacturer
        },
        createProductList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfProducts = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfProducts.push(element[1].product.name);
                }
            })

            return translatedListOfProducts
        },
        pickDateOfDelivery(event){
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Popraw datę!",
                    subheader: `Wykryto, że data jest niepoprawna.`,
                    success: false
                });
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null
            }
            else
            {
                this.pickedDateOfDeliveryUnix = mDate.unix()
                // this.pickStatus()
            }
        },
        pickDateOfCreate(event){
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Popraw datę!",
                    subheader: `Wykryto, że data jest niepoprawna.`,
                    success: false
                });
                this.pickedDateOfCreate = null;
                this.pickedDateOfCreateUnix = null
            }
            else
            {
                this.pickedDateOfCreateUnix = mDate.unix()
                // this.pickStatus()
            }
        },
        resetDateOfCreate(){
            this.pickedDateOfCreate = null;
            this.pickedDateOfCreateUnix = null
            // this.pickStatus()
        },
        resetDateOfDelivery(){
            this.pickedDateOfDelivery = null;
            this.pickedDateOfDeliveryUnix = null
            // this.pickStatus()
        },
        changeSort(){
            if(this.sortName === "creationAsc"){
                this.sortType = {
                    order: "asc",
                    type: "meta.created.date"
                }
            }
            if(this.sortName === "creationDesc"){
                this.sortType = {
                    order: "desc",
                    type: "meta.created.date"
                }
            }
            if(this.sortName === "suggestedDeliveryAsc"){
                this.sortType = {
                    order: "asc",
                    type: "delivery.date.suggested.dateObj"
                }
            }
            if(this.sortName === "suggestedDeliveryDesc"){
                this.sortType = {
                    order: "desc",
                    type: "delivery.date.suggested.dateObj"
                }
            }
            // this.pickStatus()
        },
        async getAllOrders(){
            this.resetSearch()
            this.query = null;
            this.queryLimit = 25;
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }
            this.query = db.collection("Orders")
            switch(this.pickedStatus)
            {
                case 'all':
                    this.query = this.query
                    break;
                case 1:
                    this.query = this.query.where('statusId', "==", 1)
                    break;
                case 2:
                    this.query = this.query.where('statusId', "==", 2)
                    break;
                case 3:
                    this.query = this.query.where('statusId', "==", 3)
                    break;
                case 4:
                    this.query = this.query.where('statusId', "==", 4)
                    break;
                case 5:
                    this.query = this.query.where('statusId', "==", 5)
                    break;
                case 6:
                    this.query = this.query.where('statusId', "==", 6)
                    break;
                case 404:
                    this.query = this.query.where('statusId', "==", 404)
                    break;
                case 400:
                    this.query = this.query.where('statusId', "==", 400)
                    break;
                default:
                    this.query = this.query
                    break;
            }
            if(this.pickedDateOfDeliveryUnix !== null){
                this.pickedDateOfDeliveryUnix = parseInt(this.pickedDateOfDeliveryUnix)
                let startOfDayDelivery = this.pickedDateOfDeliveryUnix
                let endOfDayDelivery = this.pickedDateOfDeliveryUnix+ 86399
                this.query = this.query.where('delivery.date.suggested.unix', ">=", startOfDayDelivery).where('delivery.date.suggested.unix', "<=",  endOfDayDelivery)
            }
            if(this.pickedDateOfCreateUnix !== null){
                this.pickedDateOfCreateUnix = parseInt(this.pickedDateOfCreateUnix)
                let startOfDay = this.pickedDateOfCreateUnix
                let endOfDay = this.pickedDateOfCreateUnix + 86399
                this.query = this.query.where('meta.created.unix', ">=", startOfDay).where('meta.created.unix', "<=", endOfDay)
            }

            // if(this.pickedDateOfDeliveryUnix !== null && this.pickedDateOfCreateUnix !== null){
            //     this.query = this.query.orderBy(`delivery.date.suggested.unix`, `${this.sortType.order}`).orderBy(`meta.created.unix`, `${this.sortType.order}`)
            // }
            if(this.pickedDateOfDeliveryUnix !== null && this.pickedDateOfCreateUnix === null){
                this.query = this.query.orderBy(`delivery.date.suggested.unix`, `${this.sortType.order}`)
            }
            if(this.pickedDateOfDeliveryUnix === null && this.pickedDateOfCreateUnix !== null){
                this.query = this.query.orderBy(`meta.created.unix`, `${this.sortType.order}`)
            }
            
            this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)
            // let indeksowanie = await this.query.get()
            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
            this.orders = [];
            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            querySnapshot.forEach((doc) => {
                let current = doc.data()
                current.countryFlagURL = "";
                    if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                    {
                        current.delivery.country.code = current.invoice.countryCode;
                    }
                    
                    for(let y=0; y< this.countriesData.length; y++)
                    {
                        if(this.countriesData[y].code === current.delivery.country.code)
                        {
                            current.countryFlagURL = this.countriesData[y].flagURL;
                        }
                    }
                    if(this.pickedToPrint.length>0){
                        this.pickedToPrint.forEach(picked => {
                            if(current.pickedToPrint !== true){
                                if(picked.id === current.id){
                                    current.pickedToPrint = true
                                }else{
                                    current.pickedToPrint = false
                                }
                            }
                        });
                    }else{
                        current.pickedToPrint = false
                    }
                    if(this.printedManufacturer.length>0){
                        this.printedManufacturer.forEach(picked => {
                            if(current.manufacturerPrinted !== true){
                                if(picked === current.id){
                                    current.manufacturerPrinted = true
                                }else{
                                    current.manufacturerPrinted = false
                                }
                            }
                        });
                    }else{
                        current.manufacturerPrinted = false
                    }
                    if(this.printedLoad.length>0){
                        this.printedLoad.forEach(picked => {
                            if(current.loadPrinted !== true){
                                if(picked === current.id){
                                    current.loadPrinted = true
                                }else{
                                    current.loadPrinted = false
                                }
                            }
                        });
                    }else{
                        current.loadPrinted = false
                    }
                    if(this.printedDriver.length>0){
                        this.printedDriver.forEach(picked => {
                            if(current.driverPrinted !== true){
                                if(picked === current.id){
                                    current.driverPrinted = true
                                }else{
                                    current.driverPrinted = false
                                }
                            }
                        });
                    }else{
                        current.driverPrinted = false
                    }
                this.orders.push(current);
                this.lastLoaded = current.meta.created.date;
            });
        });
        },
        showFilters(){
                this.showStatusFilters = !this.showStatusFilters
                if(!this.showStatusFilters){
                    this.pickedStatus = 'all'

                    this.pickedDateOfCreate = null;
                    this.pickedDateOfCreateUnix = null
                    this.pickedDateOfDelivery = null;
                    this.pickedDateOfDeliveryUnix = null

                    this.sortName = "creationDesc",
                    this.sortType = {
                        order: "desc",
                        type: "meta.created.date"
                    }
                    this.pickStatus()
                }
        },
        pickStatus(){
            this.dbListener()
            this.getAllOrders()
        },
        getStatusById(id){
            let currentStatus = {
                name: "Brak",
                info: "Brak",
                id: null
            }
            this.statuses.forEach((status) => {
                if(status.id === id){
                    currentStatus = {
                        name: status.name,
                        info: status.info,
                        id: status.id
                    }
                }
            })
            return currentStatus
        },
        async getStatuses(){
            let res = await db.collection("OrderStatuses").doc("StatusList").get()
            let resData = res.data()
            this.statuses = resData.list
        },
        orderPlacedDateFromUNIX(unix)
        {
            if(unix === undefined) return "--"
            if(unix === null) return "--"
            if(unix === 0) return "Brak"
            let date = moment(unix * 1000).format("DD.MM.YY HH:mm");
            return date;
        },
        amountOfItems(positions)
        {
            let posAm = 0;
            let items = 0;
            for (const pos in positions) {
                posAm = posAm +1
                items = items + positions[pos].product.quantity;
            }

            return `${posAm} poz. (${items} przedmiotów)`
        },
        amountOfItemsForLabel(positions)
        {
            let posAm = 0;
            let items = 0;
            for (const pos in positions) {
                posAm = posAm +1
                items = items + positions[pos].product.quantity;
            }

            return `${posAm}`
        },
        totalPrice(positions, currency, deliveryPrice)
        {
            let totalPrice = 0;
            for (const pos in positions) {
                totalPrice = totalPrice + (positions[pos].product.quantity * positions[pos].product.pricePerItemTaxIncluded)
            }
            if(deliveryPrice > 0)
            {
                totalPrice = totalPrice + deliveryPrice;
            }
            return `${totalPrice} ${currency}`
        },
        async getCountries()
        {
            this.countriesData = [];
            const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
            if(res.data.status === 'SUCCESS')
            {
                let countries = res.data.countries;
                countries.forEach(country => {
                    this.countriesData.push({code: country.countryCode, flagURL: country.flagURL});
                });
            };
        },
        resetSearch()
        {
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.searcher.locked = false
        },
        async search()
        {
            if(this.searcher.phrase.length > 0)
            {
                this.searcher.locked = true;
                const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                    index: "jan-art-orders",
                    collection: "Orders",
                    phrase: this.searcher.phrase,
                    searchFields: ['client.name', 'client.company', 'delivery.name', 'delivery.company', 'id', 'invoice.fullname', 'delivery.postCode', 'externalId']
                })
                if(res.data.success)
                {
                    let allFound = []
                    res.data.found.forEach((found) => {
                    let current = found
                    current.countryFlagURL = "";
                        if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                        {
                            current.delivery.country.code = current.invoice.countryCode;
                        }
                        for(let y=0; y< this.countriesData.length; y++)
                        {
                            if(this.countriesData[y].code === current.delivery.country.code)
                            {
                                current.countryFlagURL = this.countriesData[y].flagURL;
                            }
                        }
                        if(this.pickedToPrint.length>0){
                            this.pickedToPrint.forEach(picked => {
                                if(current.pickedToPrint !== true){
                                    if(picked.id === current.id){
                                        current.pickedToPrint = true
                                    }else{
                                        current.pickedToPrint = false
                                    }
                                }
                            });
                        }else{
                            current.pickedToPrint = false
                        }
                        if(this.printedManufacturer.length>0){
                            this.printedManufacturer.forEach(picked => {
                            if(current.manufacturerPrinted !== true){
                                if(picked === current.id){
                                    current.manufacturerPrinted = true
                                }else{
                                    current.manufacturerPrinted = false
                                }
                            }
                        });
                        if(this.printedLoad.length>0){
                            this.printedLoad.forEach(picked => {
                                if(current.loadPrinted !== true){
                                    if(picked === current.id){
                                        current.loadPrinted = true
                                    }else{
                                        current.loadPrinted = false
                                    }
                                }
                            });
                        }else{
                            current.loadPrinted = false
                        }
                        if(this.printedDriver.length>0){
                            this.printedDriver.forEach(picked => {
                                if(current.driverPrinted !== true){
                                    if(picked === current.id){
                                        current.driverPrinted = true
                                    }else{
                                        current.driverPrinted = false
                                    }
                                }
                            });
                        }else{
                            current.driverPrinted = false
                        }
                    }else{
                        current.manufacturerPrinted = false
                    }
                        allFound.push(current);
                    });
                    this.searcher.found = allFound;
                    if(res.data.found.length > 0)
                    {
                        this.searcher.lastCall = res.data.data;
                        this.dbListener();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone!",
                            subheader: `Znaleziono ${res.data.found.length} zamówień.`,
                            success: true
                        });

                    }else{
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie znaleziono wyników!",
                            subheader: `Spróbuj wyszukać inną frazę.`,
                            success: false
                        });
                    }

                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wyszukiwanie zakończone niepowodzeniem!",
                        subheader: `Coś poszło nie tak...`,
                        success: false
                    });
                }
                setTimeout(()=>
                {
                    // this.searcher.phrase = "";
                    this.searcher.locked = false;
                }, 3000)
            }
            else
            {
                return;
            }
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 10;
            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.orders = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let current = doc.data()
                current.countryFlagURL = "";
                    if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                    {
                        current.delivery.country.code = current.invoice.countryCode;
                    }
                    
                    for(let y=0; y< this.countriesData.length; y++)
                    {
                        if(this.countriesData[y].code === current.delivery.country.code)
                        {
                            current.countryFlagURL = this.countriesData[y].flagURL;
                        }
                    }
                    if(this.pickedToPrint.length>0){
                        this.pickedToPrint.forEach(picked => {
                            if(current.pickedToPrint !== true){
                                if(picked.id === current.id){
                                    current.pickedToPrint = true
                                }else{
                                    current.pickedToPrint = false
                                }
                            }
                        });
                    }else{
                        current.pickedToPrint = false
                    }
                    if(this.printedManufacturer.length>0){
                        this.printedManufacturer.forEach(picked => {
                            if(current.manufacturerPrinted !== true){
                                if(picked === current.id){
                                    current.manufacturerPrinted = true
                                }else{
                                    current.manufacturerPrinted = false
                                }
                            }
                        });
                    }else{
                        current.manufacturerPrinted = false
                    }
                    if(this.printedLoad.length>0){
                        this.printedLoad.forEach(picked => {
                            if(current.loadPrinted !== true){
                                if(picked === current.id){
                                    current.loadPrinted = true
                                }else{
                                    current.loadPrinted = false
                                }
                            }
                        });
                    }else{
                        current.loadPrinted = false
                    }
                    if(this.printedDriver.length>0){
                        this.printedDriver.forEach(picked => {
                            if(current.driverPrinted !== true){
                                if(picked === current.id){
                                    current.driverPrinted = true
                                }else{
                                    current.driverPrinted = false
                                }
                            }
                        });
                    }else{
                        current.driverPrinted = false
                    }
                this.orders.push(current);
                this.lastLoaded = current.meta.created.date;
                });
            });
        },
        loadMoreOrders()
        {
            this.dbListener();
            this.continueListening();      
        },
        copyToClipboard(id)
        {
            navigator.clipboard.writeText(id).then(()=>
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Skopiowano do schowka!",
                    subheader: ``,
                    success: true
                }); 
            })

        },
    },
    computed:
    {
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
    },
}
</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .fade2-enter-active,
    .fade2-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade2-enter-from,
    .fade2-leave-to {
    opacity: 0;
    }
</style>