<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł tras</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Tworzenie grupy kodów</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/routes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>


    <form class="space-y-8 divide-y divide-gray-200 mt-6">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div class="hidden sm:block">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                </div>

                <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nazwa grupy<span class="text-red-500">*</span></label>
                        <div class="mt-1 sm:mt-0 col-span-2">
                            <div class="flex rounded-md shadow-sm">
                                <input v-model.trim="name" type="text" maxlength="40" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="errors.name.show" class="text-red-500 text-xs ml-1 mt-1">{{errors.name.message}}</div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Państwo<span class="text-red-500">*</span></label>
                        <div class="mt-1 sm:mt-0 col-span-2">
                            <SelectInput @selectedItem="selectCountry" :data="countries"></SelectInput>
                            <div v-if="errors.country.show" class="text-red-500 text-xs ml-1 mt-1">{{errors.country.message}}</div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Początek kodu pocztowego<span class="text-red-500">*</span></label>
                        <div class="mt-1 sm:mt-0 col-span-2">
                            <div class="flex rounded-md shadow-sm">
                                <input v-model.trim="postcode" type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' maxlength="2" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="errors.codes.show" class="text-red-500 text-xs ml-1 mt-1">{{errors.codes.message}}</div>
                        </div>
                        <div class="flex justify-center mt-4 sm:mt-0">
                            <button @click.prevent="addPostcode()" type="submit" :class="{'transition ease-in-out duration-300 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 cursor-pointer': true }">Dodaj kod do grupy</button>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </form>

    <form class="divide-y space-y-2 divide-gray-200 mt-8 sm:mt-16">
        <p class="text-gray-500 text-sm font-medium uppercase tracking-wide hidden sm:block">Dodaj grupę kodów</p>
        <div class="sm:grid sm:gap-4 sm:grid-cols-4 pt-5">
            <div>
                <p class="text-sm font-medium text-gray-700 select-none">Kody pocztowe w tej grupie:</p>
                <p class="text-xs mt-1 text-gray-400 select-none">Kliknij w kod aby usunąć</p>
            </div>
            <div class="mt-4 sm:mt-0 col-span-2 flex-1 flex justify-center">
                <div v-if="group.length !== 0" class="flex gap-3 flex-wrap" >
                    <span v-tooltip="`Usuń`" @click="deletePostcode(index)" v-for="postcode, index in group" :key="postcode.id" class="bg-gray-200 text-gray-500 px-2 rounded-lg mt-3 font-semibold hover:bg-red-200 hover:text-red-500 cursor-pointer">{{ postcode }}</span>
                </div>
                <div v-else class="w-full">
                    <EmptyState/>
                </div>
            </div>
            <div class="flex justify-center mt-4 sm:mt-0">
                <div>
                    <button @click.prevent="addGroupToDb()" type="submit" :class="{'transition ease-in-out duration-300 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md cursor-pointer text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true,}">Dodaj grupę</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
    import { HomeIcon } from '@heroicons/vue/solid'
    import { db } from "@/firebase/gfbconf.js";
    import AttribListModal from '@/components/AttribListModal.vue';
    import EmptyState from '../components/EmptyState.vue';
    import SelectInput from '../components/SelectInput.vue'
    import moment from 'moment';
    import axios from 'axios';

    export default {
        name: "postcodeAdd",
        data() {
            return {
                loading: false,
                country: "",
                postcode: "",
                name: "",
                group: [],
                errors: {
                    name: {
                        message: "",
                        show: false
                    },
                    country: {
                        message: "",
                        show: false
                    },
                    codes: {
                        message: "",
                        show: false
                    }
                },
                countries: [
                    {
                        SelectInputDisplayName: 'Polska',
                        name: 'Poland',
                        code: 'PL',
                        flagUrl: 'https://flagcdn.com/w320/pl.png',
                    },
                    {
                        SelectInputDisplayName: 'Niemcy',
                        name: 'Germany',
                        code: 'DE',
                        flagUrl: 'https://flagcdn.com/w320/de.png',
                    },
                    {
                        SelectInputDisplayName: 'Francja',
                        name: 'France',
                        code: 'FR',
                        flagUrl: 'https://flagcdn.com/w320/fr.png',
                    },
                    {
                        SelectInputDisplayName: 'Luksemburg',
                        name: 'Luxembourg',
                        code: 'LU',
                        flagUrl: 'https://flagcdn.com/w320/lu.png',
                    },
                    {
                        SelectInputDisplayName: 'Włochy',
                        name: 'Italy',
                        code: 'IT',
                        flagUrl: 'https://flagcdn.com/w320/it.png',
                    },
                    {
                        SelectInputDisplayName: 'Holandia',
                        name: 'Netherlands',
                        code: 'NL',
                        flagUrl: 'https://flagcdn.com/w320/nl.png',
                    },
                                        {
                        SelectInputDisplayName: 'Belgia',
                        name: 'Belgium',
                        code: 'BE',
                        flagUrl: 'https://flagcdn.com/w320/be.png',
                    },
                    {
                        SelectInputDisplayName: 'Austria',
                        name: 'Austria',
                        code: 'AT',
                        flagUrl: 'https://flagcdn.com/w320/at.png',
                    },
                ],
            }
        },
        components: {
            PlusSmIconOutline,
            HomeIcon,
            AttribListModal,
            EmptyState,
            SelectInput,
        },
        created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes)
            {
                this.$router.push("/hub")
            }
        },
        methods:
        {
            clearErrors() {
                this.errors.name.show = false
                this.errors.country.show = false
                this.errors.codes.show = false
            },
            async validate(){
                this.clearErrors()
                let validationSuccess = true

                if(this.country === '' || this.country.length === 0) {
                    this.errors.country.message = 'Państwo jest wymagane.'
                    this.errors.country.show = true
                    validationSuccess = false
                }
                if(this.group.length === 0) {
                    this.errors.codes.message = 'Grupa musi zawierać przynajmniej jeden kod.'
                    this.errors.codes.show = true
                    validationSuccess= false
                }
                if(this.name === '' || this.name.length === 0) {
                    this.errors.name.message = 'Nazwa jest wymagana.'
                    this.errors.name.show = true
                    validationSuccess = false
                }

                const res = await db.collection("PostcodeGroups").where("name", "==", this.name).get()
                if(res.size) {
                    this.errors.name.message = 'Grupa z taką nazwą już istnieje.'
                    this.errors.name.show = true
                    validationSuccess = false
                }

                return validationSuccess
            },
            validateCode(){
                this.errors.codes.show = false

                //check if 2 characters
                if(this.postcode.length !== 2) {
                    this.errors.codes.message = 'Kod musi zawierać 2 znaki.'
                    this.errors.codes.show = true
                    return false
                }
                
                //create a regex that only contains numbers
                const reg = new RegExp('^[0-9]+$');

                //check if only numbers 
                if(!reg.test(this.postcode)) {
                    this.errors.codes.message = 'Kod może zawierać tylko liczby.'
                    this.errors.codes.show = true
                    return false
                }

                //check if code exists in the array 
                if(this.group.includes(this.postcode)) {
                    this.errors.codes.message = 'Kody w grupie nie mogą się powtarzać.'
                    this.errors.codes.show = true
                    return false
                }

                return true
            },
            selectCountry(country){
                this.country = country
            },
            addPostcode(){
                if(!this.validateCode()) return
                this.group.unshift(this.postcode)
                this.postcode = ""
            },
            deletePostcode(index){
                this.group.splice(index, 1)
            },
            async addGroupToDb(){
                if(!await this.validate()) return

                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Dodawanie nowego kodu pocztowego..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                try {
                    const group = {
                        meta: {}
                    }

                    let groupDoc = await db.collection("PostcodeGroups").doc() 

                    group.id = groupDoc.id
                    group.meta.createdDate = moment().toDate();
                    group.meta.lastModificationDate = moment().toDate();
                    group.meta.createdBy = `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`;
                    group.meta.userId = this.$store.state.userData.id;
                    group.postcodes = this.group
                    group.country = this.country
                    group.name = this.name

                    await db.collection("PostcodeGroups").doc(groupDoc.id).set(group);  

                    let document = this.prepareElasticDocument(group);

                    let elasticRes = await axios.post(`${this.$store.state.apiLink}/elastic/add`, {
                        index: "jan-art-postcodes",
                        collection: "PostcodeGroups",
                        firestoreId: group.id,
                        document: document
                    })

                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Grupa kodów została dodana!",
                        subheader: `Grupa kodów jest gotowa.`,
                        success: true
                    });

                    this.$router.push(`/dashboard/routes`)

                } catch(error) {
                    console.log(error);
                }
            },
            prepareElasticDocument(group)
            {
                let document = {
                    names: [group.name],
                    countries: [group.country.name, group.country.SelectInputDisplayName],
                    id: group.id,
                }

                return document;
            },
        },
    }
</script>