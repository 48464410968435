<template>
    <transition name="fade">
        <div v-if="!loading">
            <nav class="flex mb-4 h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <router-link to="/dashboard/orders" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu zamówień</router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Nowe zamówienie</div>
                    </div>
                </li>
                </ol>
            </nav>
            <div>
                <h4 class="sr-only">Progres</h4>
                <p class="text-gray-500 text-xs font-medium uppercase tracking-wide">Progres</p>
                <div class="mt-2" aria-hidden="true">
                <div class="bg-gray-200 rounded-full overflow-hidden">
                    <div class="h-2 transition-all duration-1000 bg-gradient-to-r from-blue-400 via-indigo-400 to-blue-600 rounded-full" :style="`width: ${progress}%`" />
                </div>
                <div class="hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-1">
                    <div class="text-blue-500">Klient i dostawa</div>
                    <div class="text-center text-blue-500">Faktura</div>
                    <div class="text-center text-blue-500">Pozycje</div>
                    <div class="text-center text-blue-500">Płatność</div>
                    <div class="text-center text-blue-500">Status</div>
                </div>
                </div>
            </div>

            <div class="rounded-md bg-yellow-50 mt-4 mb-4 p-4 border-gray-200 border-2" v-if="blOrder !== null">
                <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationCircleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">Ostrzeżenie o możliwości popełnienia błędu</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                        <p>System postarał się jak najwierniej wypełnić i skorelować dane zamówienia ze źródłem zewnętrznym. Sprawdź poprawność danych!</p>
                    </div>
                    <div class="mt-2 mb-2 text-xs">
                        <div class="font-medium text-yellow-800">Pochodzenie:</div>
                        <div class="text-yellow-700" v-if="blOrder.meta.external.source.name.length>0">Źródło: {{blOrder.meta.external.source.name.toUpperCase()}}</div>
                        <div class="text-yellow-700" v-if="blOrder.meta.external.ids.externalOrderId.length>0">Numer zamówienia: {{blOrder.meta.external.ids.externalOrderId}}</div>
                        <div class="text-yellow-700" v-if="blOrder.meta.external.ids.shopOrderId.length>0">Numer zamówienia: {{blOrder.meta.external.ids.shopOrderId}}</div>
                        <div class="text-yellow-700">ID Baselinker: {{blOrder.meta.external.ids.baselinkerOrderId}}</div>
                        <div class="text-yellow-700" v-if="blOrder.meta.external.ids.userLogin.length>0">Login użytkownika: {{blOrder.meta.external.ids.userLogin}}</div>
                        <div class="text-yellow-700" v-if="blOrder.comments.buyer.length>0">Komentarz: {{blOrder.comments.buyer}}</div>
                    </div>
                    <div class="mt-2 mb-2 text-xs" v-if="blOrder.meta.external.originalProductList.length > 0">
                        <div class="font-medium text-yellow-800 flex items-center">
                            <span>Produkty:</span>
                            <span class="cursor-pointer text-yellow-500 hover:text-yellow-600 select-none ml-4" @click.prevent="originalProductsHidden = !originalProductsHidden">{{originalProductsHidden ? 'Rozwiń' : 'Zwiń'}}</span>
                        </div>
                        <transition name="fade">
                            <div v-if="!originalProductsHidden">
                                <div v-for="p in blOrder.meta.external.originalProductList" class="flex flex-col justify-center mt-1" :key="p.name">
                                    <span class="font-medium">{{p.quantity}} szt. - {{p.name}}</span>
                                    <span v-if="p.attributes.length>0">Atrybuty: {{p.attributes}}</span>
                                    <span v-if="p.sku.length>0">SKU: {{p.sku}}</span>
                                    <span v-if="p.auction_id.length>0">Aukcja: {{p.auction_id}}</span>
                                    <span>Cena brutto: {{p.price_brutto.toFixed(2)}} {{blOrder.currency}}</span>
                                </div>
                            </div>
                        </transition>
                        <div v-if="originalProductsHidden" class="text-xs italic text-yellow-800">
                            Rozwiń, aby zobaczyć..
                        </div>

                    </div>
                    <div class="mt-4">
                    <div class="-mx-2 -my-1.5 flex">
                        <a :href="blOrder.meta.external.urlToOrder" target="_blank" class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">Podgląd oryginału</a>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="rounded-md bg-yellow-50 mt-4 mb-4 p-4 border-gray-200 border-2" v-if="decodedObj !== null && this.$route.query.origin === 'otto'">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <ExclamationCircleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">Ostrzeżenie o możliwości popełnienia błędu</h3>
                        <div class="mt-2 text-sm text-yellow-700">
                            <p>System postarał się jak najwierniej wypełnić i skorelować dane zamówienia ze źródłem zewnętrznym. Sprawdź poprawność danych!</p>
                        </div>
                        <div class="mt-2 mb-2 text-xs">
                            <div class="font-medium text-yellow-800">Pochodzenie:</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.source.name.length>0">Źródło: {{decodedObj.meta.external.source.name.toUpperCase()}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.externalOrderId.length>0">Numer zamówienia: {{decodedObj.meta.external.ids.externalOrderId}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.shopOrderId.length>0">Numer zamówienia: {{decodedObj.meta.external.ids.shopOrderId}}</div>
                            <div class="text-yellow-700">ID Otto: {{decodedObj.meta.external.ids.ottoOrderId}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.userLogin.length>0">Login użytkownika: {{decodedObj.meta.external.ids.userLogin}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.comments.buyer.length>0">Komentarz: {{decodedObj.comments.buyer}}</div>
                        </div>
                        <div class="mt-2 mb-2 text-xs" v-if="decodedObj.meta.external.originalProductList.length > 0">
                            <div class="font-medium text-yellow-800 flex items-center">
                                <span>Produkty:</span>
                                <span class="cursor-pointer text-yellow-500 hover:text-yellow-600 select-none ml-4" @click.prevent="originalProductsHidden = !originalProductsHidden">{{originalProductsHidden ? 'Rozwiń' : 'Zwiń'}}</span>
                            </div>
                            <transition name="fade">
                                <div v-if="!originalProductsHidden">
                                    <div v-for="p in decodedObj.meta.external.originalProductList" class="flex flex-col justify-center mt-1" :key="p.originalName">
                                        <span class="font-medium"><span>{{p.product.quantity != null ? p.product.quantity : 1}} szt. - </span><a :class="[p.product.shopUrl.length > 0 ? 'underline hover:text-indigo-600 transition-all duration-300' : '']" target="_blank" :href="`${p.product.shopUrl.length > 0 ? p.product.shopUrl : ''}`">{{p.product.productTitle}}</a></span>
                                        <span v-if="p.product.sku.length>0">SKU: {{p.product.sku}}</span>
                                        <span>Cena brutto: {{parseFloat(p.itemValueGrossPrice.amount).toFixed(2)}} {{p.itemValueGrossPrice.currency}}</span>
                                    </div>
                                </div>
                            </transition>
                            <div v-if="originalProductsHidden" class="text-xs italic text-yellow-800">
                                Rozwiń, aby zobaczyć..
                            </div>

                        </div>
                        <div class="mt-4" v-if="decodedObj.meta.external.urlToOrder != null">
                        <div class="-mx-2 -my-1.5 flex">
                            <a :href="decodedObj.meta.external.urlToOrder" target="_blank" class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">Podgląd oryginału</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="rounded-md bg-yellow-50 mt-4 mb-4 p-4 border-gray-200 border-2" v-if="decodedObj !== null && this.$route.query.origin === 'apilo'">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <ExclamationCircleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">Ostrzeżenie o możliwości popełnienia błędu</h3>
                        <div class="mt-2 text-sm text-yellow-700">
                            <p>System postarał się jak najwierniej wypełnić i skorelować dane zamówienia ze źródłem zewnętrznym. Sprawdź poprawność danych!</p>
                        </div>
                        <div class="mt-2 mb-2 text-xs">
                            <div class="font-medium text-yellow-800">Pochodzenie:</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.source.name.length>0">Źródło: {{decodedObj.meta.external.source.name.toUpperCase()}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.externalOrderId.length>0">Numer zamówienia: {{decodedObj.meta.external.ids.externalOrderId}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.shopOrderId.length>0">Numer zamówienia: {{decodedObj.meta.external.ids.shopOrderId}}</div>
                            <div class="text-yellow-700">ID Apilo: {{decodedObj.meta.external.ids.apiloOrderId}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.userLogin.length>0">Login użytkownika: {{decodedObj.meta.external.ids.userLogin}}</div>
                            <div class="text-yellow-700" v-if="decodedObj.comments.buyer.length>0">Komentarz: {{decodedObj.comments.buyer}}</div>
                        </div>
                        <div class="mt-2 mb-2 text-xs" v-if="decodedObj.meta.external.originalProductList.length > 0">
                            <div class="font-medium text-yellow-800 flex items-center">
                                <span>Produkty:</span>
                                <span class="cursor-pointer text-yellow-500 hover:text-yellow-600 select-none ml-4" @click.prevent="originalProductsHidden = !originalProductsHidden">{{originalProductsHidden ? 'Rozwiń' : 'Zwiń'}}</span>
                            </div>
                            <transition name="fade">
                                <div v-if="!originalProductsHidden">
                                    <div v-for="p in decodedObj.meta.external.originalProductList" class="flex flex-col justify-center mt-1" :key="p.originalName">
                                        <span class="font-medium">{{p.quantity}} szt. - {{p.originalName}}</span>
                                        <!-- <span v-if="p.attributes.length>0">Atrybuty: {{p.attributes}}</span> -->
                                        <span v-if="p.sku != null ? p.sku.length>0 ? p.sku : '' : ''">SKU: {{p.sku}}</span>
                                        <!-- <span v-if="p.auction_id.length>0">Aukcja: {{p.auction_id}}</span> -->
                                        <span>Cena brutto: {{parseFloat(p.originalPriceWithTax).toFixed(2)}} {{decodedObj.currency}}</span>
                                    </div>
                                </div>
                            </transition>
                            <div v-if="originalProductsHidden" class="text-xs italic text-yellow-800">
                                Rozwiń, aby zobaczyć..
                            </div>

                        </div>
                        <div class="mt-4" v-if="decodedObj.meta.external.urlToOrder != null">
                        <div class="-mx-2 -my-1.5 flex">
                            <a :href="decodedObj.meta.external.urlToOrder" target="_blank" class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">Podgląd oryginału</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="rounded-md bg-yellow-50 mt-4 mb-4 p-4 border-gray-200 border-2" v-if="decodedObj !== null && this.$route.query.origin === 'bol'">
                <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationCircleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">Ostrzeżenie o możliwości popełnienia błędu</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                        <p>System postarał się jak najwierniej wypełnić i skorelować dane zamówienia ze źródłem zewnętrznym. Sprawdź poprawność danych!</p>
                    </div>
                    <div class="mt-2 mb-2 text-xs">
                        <div class="font-medium text-yellow-800">Pochodzenie:</div>
                        <div class="text-yellow-700" v-if="decodedObj.meta.external.source.name.length>0">Źródło: {{decodedObj.meta.external.source.name.toUpperCase()}}</div>
                        <div class="text-yellow-700" v-if="decodedObj.meta.external.source.name.length>0">ID Zamówienia: {{decodedObj.meta.external.ids.bolOrderId}}</div>
                        <div class="text-yellow-700">Data: {{getDateFromUNIX(decodedObj.meta.external.dates.add_TimestampUNIX)}}</div>
                    
                    </div>
                    
                    <div class="mt-2 mb-2 text-xs" v-if="decodedObj.meta.external.originalProductList.length > 0">
                        <div class="font-medium text-yellow-800 flex items-center">
                            <span>Produkty:</span>
                            <span class="cursor-pointer text-yellow-500 hover:text-yellow-600 select-none ml-4" @click.prevent="originalProductsHidden = !originalProductsHidden">{{originalProductsHidden ? 'Rozwiń' : 'Zwiń'}}</span>
                        </div>
                        <transition name="fade">
                            <div v-if="!originalProductsHidden">
                                <div v-for="p in decodedObj.meta.external.originalProductList" class="flex flex-col justify-center mt-1" :key="p.name">
                                    <span class="font-medium">{{p.quantity}} szt. - {{p.product.title}}</span>
                                    <span>ID produktu: {{p.orderItemId}} </span>
                                    <span>Aukcja: {{p.offer.offerId}} ({{p.offer.reference}})</span>
                                    <span>EAN: {{p.product.ean}}</span>
                                    <span>Cena (szt.): {{p.unitPrice}} {{decodedObj.currency}}</span>
                                </div>
                            </div>
                        </transition>
                        <div v-if="originalProductsHidden" class="text-xs italic text-yellow-800">
                            Rozwiń, aby zobaczyć..
                        </div>

                    </div>
                    <div class="mt-4">
                        <div class="-mx-2 -my-1.5 flex">
                            <!-- <a :href="blOrder.meta.external.urlToOrder" target="_blank" class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">Podgląd oryginału</a> -->
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="rounded-md bg-yellow-50 mt-4 mb-4 p-4 border-gray-200 border-2" v-if="decodedObj !== null && this.$route.query.origin === 'wayfair'">
                <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationCircleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">Ostrzeżenie o możliwości popełnienia błędu</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                        <p>System postarał się jak najwierniej wypełnić i skorelować dane zamówienia ze źródłem zewnętrznym. Sprawdź poprawność danych!</p>
                    </div>
                    <div class="mt-2 mb-2 text-xs">
                        <div class="font-medium text-yellow-800">Pochodzenie:</div>
                        <div class="text-yellow-700" v-if="decodedObj.meta.external.source.name.length>0">Źródło: {{decodedObj.meta.external.source.name.toUpperCase()}}</div>
                        <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.wayfairOrderId.toString().length>0">ID: {{decodedObj.meta.external.ids.wayfairOrderId}}</div>
                        <div class="text-yellow-700">Data: {{getDateFromUNIX(decodedObj.meta.external.dates.add_TimestampUNIX)}}</div>
                    </div>
                    
                    <div class="mt-2 mb-2 text-xs" v-if="decodedObj.meta.external.originalProductList.length > 0">
                        <div class="font-medium text-yellow-800 flex items-center">
                            <span>Produkty:</span>
                            <span class="cursor-pointer text-yellow-500 hover:text-yellow-600 select-none ml-4" @click.prevent="originalProductsHidden = !originalProductsHidden">{{originalProductsHidden ? 'Rozwiń' : 'Zwiń'}}</span>
                        </div>
                        <transition name="fade">
                            <div v-if="!originalProductsHidden">
                                <div v-for="p in decodedObj.meta.external.originalProductList" class="flex flex-col justify-center mt-1" :key="p.name">
                                    <span class="font-medium">{{p.quantity}} szt. - {{p.name}}</span>
                                    <span>ID produktu: {{p.partNumber}}</span>
                                    <span>Cena (szt.): {{p.price}} {{decodedObj.currency}}</span>
                                </div>
                            </div>
                        </transition>
                        <div v-if="originalProductsHidden" class="text-xs italic text-yellow-800">
                            Rozwiń, aby zobaczyć..
                        </div>

                    </div>
                    <div class="mt-4">
                        <div class="-mx-2 -my-1.5 flex">
                            <!-- <a :href="blOrder.meta.external.urlToOrder" target="_blank" class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">Podgląd oryginału</a> -->
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="rounded-md bg-yellow-50 mt-4 mb-4 p-4 border-gray-200 border-2" v-if="decodedObj !== null && this.$route.query.origin === 'hood'">
                <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationCircleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">Ostrzeżenie o możliwości popełnienia błędu</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                        <p>System postarał się jak najwierniej wypełnić i skorelować dane zamówienia ze źródłem zewnętrznym. Sprawdź poprawność danych!</p>
                    </div>
                    <div class="mt-2 mb-2 text-xs">
                        <div class="font-medium text-yellow-800">Pochodzenie:</div>
                        <div class="text-yellow-700" v-if="decodedObj.meta.external.source.name.length>0">Źródło: {{decodedObj.meta.external.source.name.toUpperCase()}}</div>
                        <div class="text-yellow-700" v-if="decodedObj.meta.external.ids.hoodOrderId.toString().length>0">ID: {{decodedObj.meta.external.ids.hoodOrderId}}</div>
                        <div class="text-yellow-700">Data: {{getDateFromUNIX(decodedObj.meta.external.dates.add_TimestampUNIX)}}</div>
                    </div>
                    
                    <div class="mt-2 mb-2 text-xs" v-if="decodedObj.meta.external.originalProductList.length > 0">
                        <div class="font-medium text-yellow-800 flex items-center">
                            <span>Produkty:</span>
                            <span class="cursor-pointer text-yellow-500 hover:text-yellow-600 select-none ml-4" @click.prevent="originalProductsHidden = !originalProductsHidden">{{originalProductsHidden ? 'Rozwiń' : 'Zwiń'}}</span>
                        </div>
                        <transition name="fade">
                            <div v-if="!originalProductsHidden">
                                <div v-for="p in decodedObj.meta.external.originalProductList" class="flex flex-col justify-center mt-1" :key="p.name">
                                    <span class="font-medium">{{p.item.quantity}} szt. - {{p.item.prodname}}</span>
                                    <span>ID produktu: {{p.item.itemid}} ({{p.item.itemnumber}})</span>
                                    <span>Cena: {{p.item.price}} {{decodedObj.currency}}</span>
                                </div>
                            </div>
                        </transition>
                        <div v-if="originalProductsHidden" class="text-xs italic text-yellow-800">
                            Rozwiń, aby zobaczyć..
                        </div>

                    </div>
                    <div class="mt-4">
                        <div class="-mx-2 -my-1.5 flex">
                            <!-- <a :href="blOrder.meta.external.urlToOrder" target="_blank" class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">Podgląd oryginału</a> -->
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <transition name="fade2">
                <div class="rounded-md bg-indigo-100 p-4 my-6" v-if="isChangedOrder">
                    <div class="flex items-center">
                    <div class="flex-shrink-0">
                        <InformationCircleIcon class="h-5 w-5 text-indigo-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between items-center text-sm gap-2">
                        <!-- <p class="font-medium text-indigo-700">W zamówieniu zostały zmienione dane przez innego użytkownika, odśwież teraz, aby mieć najnowsze dane.</p> -->
                        <p class="font-medium text-indigo-700">Zamówienie zostało <span class="font-semibold">edytowane</span> przez innego użytkownika. Pobierz najnowsze dane, aby być na bieżąco. </p>
                        <button @click.prevent="refreshOrder()" class="items-center font-semibold inline-flex gap-x-1.5 whitespace-nowrap text-indigo-700 hover:text-indigo-400 transition-all duration-300">
                            <RefreshIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Pobierz aktualne dane
                        </button>
                    </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
    <transition name="fade">
        <div :class="[isChangedOrder ? 'opacity-30 select-none pointer-events-none' : '', 'transition-all duration-500']">
            <LoadingSpinnerHub v-if="!isFullyLoaded" />
            <form class="space-y-8 divide-y divide-gray-200 mt-6" ref="Step1" v-show="step === 1">
                <div class="py-2" v-if="isFullyLoaded" :class="[isDoneTrackerDoc != null && isDoneTrackerDoc[`${this.$route.query.id}`] !== undefined ? 'opacity-30 pointer-events-none select-none' : '']">
                    
                    <div v-if="this.$route.query.origin == null && this.$route.query.id == null && this.$route.query.edit == null" class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div class="mb-10">
                            <div class="flex items-center justify-between">
                                <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Zewnętrzne dane</p>
                            </div>
                            <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Numer zamówienia<span class="text-red-500"></span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" v-model="manualOrderId" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>            
                    </div>
                    
                    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5" ref="Step1">
                        <div>
                            <div class="flex justify-between">
                                <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Klient</p>
                                <div v-if="editMode && this.order !== undefined && this.order.originName === 'Manual' ? (this.order.meta.external != null && this.order.meta.external.ids != null) : (this.order.origin !== undefined && this.order.origin.meta !== undefined)" class="text-gray-400 text-xs">
                                    {{ this.order.originName === 'Manual' ? this.order.meta.external.ids.externalOrderId : this.order !== null && this.order.origin.meta.external.ids.externalOrderId !== undefined && this.order.origin.meta.external.ids.externalOrderId.length > 0 ? this.order.origin.meta.external.ids.externalOrderId : 
                                        this.order !== null && this.order.origin.meta.external.ids.bolOrderId !== undefined && this.order.origin.meta.external.ids.bolOrderId.length > 0 ? this.order.origin.meta.external.ids.bolOrderId : 
                                        this.order !== null && this.order.origin.meta.external.ids.wayfairOrderId !== undefined && this.order.origin.meta.external.ids.wayfairOrderId.length > 0 ? this.order.origin.meta.external.ids.wayfairOrderId : 
                                        this.order !== null && this.order.origin.meta.external.ids.hoodOrderId !== undefined && this.order.origin.meta.external.ids.hoodOrderId.length > 0 ? this.order.origin.meta.external.ids.hoodOrderId : this.order !== null && this.order.origin.meta.external.ids.shopOrderId !== undefined && this.order.origin.meta.external.ids.shopOrderId.length > 0 ? this.order.origin.meta.external.ids.shopOrderId : '' }}
                                </div>
                            </div>
                            <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Imię Nazwisko<span class="text-red-500">*</span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" maxlength="80" v-model="order.client.name" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                        <p class="mt-2 text-xs text-red-500" v-if="errors.step1.client.name">Pole musi być wypełnione.</p>
                                    </div>
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Firma </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" maxlength="80" v-model="order.client.company" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                    </div>
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> E-mail</label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" maxlength="100" v-model="order.client.email" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                    </div>
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Telefon</label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                                        <div>
                                            <Listbox as="div" v-model="order.client.phone.prefix" @update:modelValue="setDeliveryByPhoneFromClient(order.client.phone.prefix)">
                                                <div class="relative">
                                                <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                    <span class="flex items-center">
                                                        <span class="ml-3 block truncate h-5">{{order.client.phone.prefix}}</span>
                                                    </span>
                                                    <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </span>
                                                </ListboxButton>

                                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                    <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        <ListboxOption as="template" v-for="country in phonePrefixes" :key="country.name" :value="country.prefix" v-slot="{ active, selected }">
                                                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                                <div class="flex items-center">
                                                                    <img :src="country.ref.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ country.prefix }} <span class="text-xs">({{country.name}})</span></span>
                                                                </div>

                                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            </li>
                                                        </ListboxOption>
                                                    </ListboxOptions>
                                                </transition>
                                                </div>
                                            </Listbox>
                                        </div>
                                        <div class="flex items-center w-1/2 rounded-md shadow-sm ml-2">
                                            <input type="text" maxlength="12" v-model="order.client.phone.number" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>            
                    </div>

                    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div class="mt-10">
                            <div class="flex items-center justify-between">
                                <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Dostawa</p>
                                <div class="select-none">
                                    <button @click.prevent="openGoogleMaps()" type="button" :class="{'inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2': true, 'opacity-50 pointer-events-none select-none': order.delivery.address.length === 0 || this.order.delivery.city.length === 0 || this.order.delivery.postCode.length === 0}">Weryfikacja adresu</button>
                                    <button @click.prevent="copyFromClientToDelivery" type="button" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">Kopiuj z klienta</button>
                                </div>
                            </div>
                            <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Transport<span class="text-red-500">*</span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div>
                                            <div class="text-xs font-medium text-gray-700 mb-2">Wybierz typ dostawy</div>
                                        </div>
                                        <div class="w-1/2">
                                            <Listbox as="div" v-model="order.delivery.method" @update:modelValue="setDeliveryPrice()">
                                                <div class="relative">
                                                    <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                        <span class="flex items-center">
                                                            <span class="block truncate">{{order.delivery.method}}</span>
                                                        </span>
                                                        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </ListboxButton>

                                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                        <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            <ListboxOption as="template" v-for="transport in couriers" :key="transport.name" :value="transport.name" v-slot="{ active, selected }">
                                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pr-9']">
                                                                    <div class="flex items-center">
                                                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ transport.name }} <span class="text-xs">({{transport.cost}} {{transport.currency}})</span></span>
                                                                    </div>

                                                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                </li>
                                                            </ListboxOption>
                                                        </ListboxOptions>
                                                    </transition>
                                                </div>
                                            </Listbox>
                                        </div>
                                        <div class="select-none">
                                            <div class="text-xs font-medium text-gray-700 mt-2">Wybierz sugerowaną datę dostawy</div>
                                            <div>
                                                <input type="date" ref="suggestedDeliveryDate" @change="setSuggestedDeliveryDate($event)" class="mt-2 text-sm rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Imię Nazwisko<span class="text-red-500">*</span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" maxlength="80" v-model="order.delivery.name" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                        <p class="mt-2 text-xs text-red-500" v-if="errors.step1.delivery.name">Pole musi być wypełnione.</p>
                                    </div>
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Firma </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" maxlength="60" v-model="order.delivery.company"  class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                    </div>
                                </div>


                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Telefon</label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                                        <div>
                                            <Listbox as="div" v-model="order.delivery.phone.prefix" @update:modelValue="setDeliveryByPhoneFromDelivery(order.delivery.phone.prefix)">
                                                <div class="relative">
                                                    <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                        <span class="flex items-center">
                                                            <span class="ml-3 h-5 block truncate">{{order.delivery.phone.prefix}}</span>
                                                        </span>
                                                        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </ListboxButton>

                                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                        <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            <ListboxOption as="template" v-for="country in phonePrefixes" :key="country.name" :value="country.prefix" v-slot="{ active, selected }">
                                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                                <div class="flex items-center">
                                                                    <img :src="country.ref.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ country.prefix }} <span class="text-xs">({{country.name}})</span></span>
                                                                </div>

                                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                                </li>
                                                            </ListboxOption>
                                                        </ListboxOptions>
                                                    </transition>
                                                </div>
                                            </Listbox>
                                        </div>
                                        <div class="flex items-center w-1/2 rounded-md shadow-sm ml-2">
                                            <input type="text" v-model="order.delivery.phone.number" maxlength="12" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                    </div>
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Kraj<span class="text-red-500">*</span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="w-1/2">
                                            <Listbox as="div" v-model="deliveryChosenCountry" >
                                                <div class="relative">
                                                    <ListboxButton :key="deliveryChosenCountry" class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                        <span class="flex items-center">
                                                            <img :src="deliveryChosenCountry.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full border-gray-300 border-2" />
                                                            <span class="ml-3 block truncate">{{deliveryChosenCountry.translations.pol.common !== undefined ? deliveryChosenCountry.translations.pol.common :  deliveryChosenCountry.name.common}}</span>
                                                        </span>
                                                        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </ListboxButton>

                                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                        <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            <ListboxOption as="template" v-for="country in countries" :key="country.translations.pol.common " :value="country" v-slot="{ active, selected }">
                                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                                    <div class="flex w-full items-center">
                                                                        <img :src="country.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{country.translations.pol.common !== undefined ? country.translations.pol.common :  country.name.common}}</span>
                                                                    </div>

                                                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                </li>
                                                            </ListboxOption>
                                                        </ListboxOptions>
                                                    </transition>
                                                </div>
                                            </Listbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Adres<span class="text-red-500">*</span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" v-model="order.delivery.address" maxlength="50" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                        <p class="mt-2 text-xs text-red-500" v-if="errors.step1.delivery.address">Pole musi być wypełnione.</p>
                                    </div>
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Miasto<span class="text-red-500">*</span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input type="text" maxlength="50" v-model="order.delivery.city" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                        <p class="mt-2 text-xs text-red-500" v-if="errors.step1.delivery.city">Pole musi być wypełnione.</p>
                                    </div>
                                    
                                </div>

                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Kod pocztowy<span class="text-red-500">*</span> </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-1">
                                        <div class="w-1/2 flex rounded-md shadow-sm">
                                            <input type="text" v-model="order.delivery.postCode" maxlength="12" class="flex-1 block w-1/2 py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </div>
                                        <p v-if="deliveryChosenCountry.postalCode !== undefined" class="mt-2 text-xs text-gray-500">{{deliveryChosenCountry.postalCode.format !== undefined ? 'Sugerowany format: '+deliveryChosenCountry.postalCode.format : 'Brak'}}</p>
                                        <p class="mt-2 text-xs text-red-500" v-if="errors.step1.delivery.postCode">Pole musi być wypełnione.</p>
                                    </div>
                                </div>


                            </div>
                            <!-- <div v-if="editMode" class="mt-4 mb-2">
                                <div class="flex flex-col mb-2" >
                                    <p class="text-red-500 text-sm font-medium uppercase tracking-wide">Usuń zamówienie</p>
                                    <p class="text-xs">Przepisz ID zamówienia, aby usunąć je trwale usunąć.</p>
                                    <p class="select-none">{{order.id}}</p>
                                </div>
                                <div class="flex items-center">
                                    <input type="text" v-model="deleteKey" class="block w-2/6 mr-2 rounded-md border-red-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm" placeholder="Identyfikator zamówienia" />
                                    <button type="button" @click.prevent="deleteOrder" v-if="deleteKey === order.id" class="inline-flex items-center rounded-md border border-transparent bg-red-100 px-3 py-2 text-sm font-medium leading-4 text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń zamówienie</button>
                                </div>
                            </div> -->

                        </div>            
                    </div>
                </div>
                <div v-if="isFullyLoaded" class="pt-5">
                    <div class="sm:col-span-2" v-if="isDoneTrackerDoc != null && isDoneTrackerDoc[`${this.$route.query.id}`] !== undefined">
                        <!-- <div class="max-w-lg flex rounded-md shadow-sm">
                            <input v-model="variant.link.href" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                        </div> -->
                        <p class="text-xs text-gray-500">Zamówienie zostało już przetworzone przez inną osobę.</p>
                        <p class="mt-1 mb-2 text-xs text-gray-500 italic">Wróć do zamówień oczekujących i wybierz inne. </p>
                        <button ref="doneTracker" @click.prevent="sendToAwaitingOrders" type="button" class="mt-1 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-200 px-4 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-300 focus:ring-indigo-400 focus-visible:outline focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300">
                            <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Powrót
                        </button>
                    </div>
                    <div class="flex justify-end select-none">
                        <!-- <button type="button" @click.prevent="sendBack" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Back</button> -->
                        <button v-if="(isDoneTrackerDoc == null) || (isDoneTrackerDoc != null && isDoneTrackerDoc[`${this.$route.query.id}`] == null)" @click.prevent="validateStep1" :class="['transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500']">Następny krok</button>
                    </div>
                </div>
            </form>

            <form class="space-y-8 divide-y divide-gray-200 mt-6" ref="Step2" v-show="step === 2">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5 py-2" ref="Step1">
                    <div>
                        <div class="flex items-center justify-between">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Faktura</p>
                        </div>

                        <SwitchGroup as="div" class="flex items-center justify-between">
                            <span class="flex flex-grow flex-col">
                            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Włącz / Wyłącz</SwitchLabel>
                            </span>
                            <Switch @update:modelValue="clearInvoice" v-model="order.invoice.needed" :class="[order.invoice.needed ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                            <span aria-hidden="true" :class="[order.invoice.needed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                        </SwitchGroup>

                        <div v-if="order.invoice.needed" class="flex itemn-centere justify-end mt-6 mb-2">
                            <span @click.prevent="copyToInvoiceFromClient"  class="text-xs cursor-pointer text-blue-400 hover:text-blue-500 underline">Skopiuj z klienta</span>
                        </div>

                        <transition name="fade">
                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5" v-if="order.invoice.needed">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Imię Nazwisko </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="order.invoice.fullname" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Firma </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="order.invoice.company" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> NIP </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="16" v-model="order.invoice.taxId" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Kraj </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="w-1/2">
                                        <Listbox v-model="order.invoice.countryCode" as="div">
                                            <div class="relative">
                                                <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                    <span class="flex items-center">
                                                        <span class="ml-3 block truncate">{{order.invoice.countryCode.length > 0 ? order.invoice.countryCode : "Wybierz kraj"}}</span>
                                                    </span>
                                                    <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </span>
                                                </ListboxButton>

                                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                    <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        <ListboxOption as="template" v-for="country in countries" :key="country.translations.pol.common " :value="country.countryCode" v-slot="{ active, selected }">
                                                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                                <div class="flex w-full items-center">
                                                                    <img :src="country.flagURL" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{country.translations.pol.common !== undefined ? country.translations.pol.common :  country.name.common}}</span>
                                                                </div>

                                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            </li>
                                                        </ListboxOption>
                                                    </ListboxOptions>
                                                </transition>
                                            </div>
                                        </Listbox>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Adres</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="50" v-model="order.invoice.address" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Miasto </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="50" v-model="order.invoice.city" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Kod pocztowy</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-1">
                                    <div class="w-1/2 flex rounded-md shadow-sm">
                                        <input type="text" maxlength="12" v-model="order.invoice.postCode" class="flex-1 block w-1/2 py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>




                        </div>
                        </transition>  
                        <EmptyState class="mt-2" v-if="!order.invoice.needed"></EmptyState>

                        <div class="pt-5">
                            <div class="flex justify-end select-none">
                                <button type="button" @click.prevent="sendBack" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                                <button type="submit" @click.prevent="step = 3, progress = 50" :class="{'transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true}">Następny krok</button>
                            </div>
                        </div>
                    </div>
                            
                </div>
            </form>

            <form class="space-y-8 divide-y divide-gray-200 mt-6" ref="Step3" v-show="step === 3">
                
                <div class="space-y-8 sm:space-y-5">
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">POZYCJE</p>
                            <div>
                                <transition name="fade">
                                    <span class="text-xs mr-2 opacity-50 italic" v-if="EURtoPLN !== null && GBPtoPLN">Kursy NBP: 1EUR = {{EURtoPLN.toFixed(2)}} PLN, 1GBP = {{GBPtoPLN.toFixed(2)}} PLN </span>
                                </transition>
                                <button type="button" :class="[oldOrderStatusId !== 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 select-none" @click.prevent="oldOrderStatusId !== 1 ? '' : modalController.productFinder = true">Dodaj pozycję</button>
                            </div>
                        </div>
                        <div v-if="oldOrderStatusId !== 1" class="rounded-md bg-blue-50 p-4 mt-5">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between">
                                <p class="text-sm text-blue-700">Aktualny status zamówienia to: <span class="font-medium">"{{getOrderStatusName(oldOrderStatusId)}}"</span>. Zmień status zamówienia na <span class="font-medium">"Złożone"</span> w celu dodawania lub usuwania pozycji.</p>
                            </div>
                            </div>
                        </div>
                        <transition name="fade">
                        <EmptyState class="mt-2" v-if="Object.entries(order.positions).length === 0"></EmptyState>
                        </transition>
                    </div>
                    <div class="overflow-hidden rounded-md border border-gray-300 bg-white" v-if="Object.entries(order.positions).length > 0 && !quantityCheck.inProgress">
                        <ul role="list" class="divide-y divide-gray-300">
                            <li v-for="position in arrOfPositions()" :key="position.id" class="px-6 py-4">
                                <div class="flex items-center justify-between">
                                    <span class="text-gray-400 text-xs font-medium">Pozycja {{position.id}}</span>
                                    <span class="text-gray-400 text-xs font-medium">1 szt. {{position.volume !== null ? " - "+position.volume + "m³" : ""}}</span>
                                </div>
                                <div class="flex items-center justify-between mt-2">
                                    <router-link :to="`/dashboard/products/list/viewProduct?id=${position.product.id}`" target="_blank" class="hover:opacity-60">
                                        <div class="flex items-center">
                                            <img v-if="position.product.image.url.length> 0" :src="position.product.image.url" class="h-10 w-10 mr-2">
                                            <span class="font-medium">{{position.product.name}}</span>
                                        </div>
                                    </router-link>
                                    <div>
                                        <button :disabled="oldOrderStatusId !== 1" :class="oldOrderStatusId !== 1 ? 'bg-red-100 text-red-700 opacity-50 cursor-not-allowed' : 'bg-red-100 text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'" type="button" @click.prevent="deletePosition(position)" class="inline-flex items-center rounded border border-transparent  px-2.5 py-1.5 text-xs font-medium">Usuń</button>
                                    </div>
                                </div>
                                <div class="mt-2 mb-2 flex items-center select-none">
                                    <span v-for="atr in position.product.attributes.slice(0,1)" class="inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800">
                                        <svg class="mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                        <circle cx="4" cy="4" r="3" />
                                        </svg>
                                        {{atr.parent.name}}: {{atr.name}}
                                    </span>
                                    <router-link :to="`/dashboard/products/list/viewProduct?id=${position.product.id}`" target="_blank">                                    <span class="transition ease-in-out duration-300 text-xs px-2.5 py-0.5 text-gray-300 hover:text-blue-500 cursor-pointer" v-if="position.product.attributes.length-1 > 0" >+ {{position.product.attributes.length-1}} więcej</span></router-link>

                                    <span v-if="position.product.attributes.length === 0" class="text-xs text-gray-400 italic">Brak atrybutów</span>
                                </div>
                                <div class="mt-2 w-full">
                                    <div>
                                        <label for="name" class="block text-sm font-medium text-gray-700">Komentarz</label>
                                        <div class="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                                        <input type="text" v-model="position.infos.onOrderCreation" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" placeholder="Informacja wewnętrzna" />
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 flex justify-between">
                                    <div class="w-full flex items-center justify-between">
                                        <div class="flex w-full">
                                            <div class="w-1/6">
                                                <label for="price" class="block text-sm font-medium text-gray-700">Cena</label>
                                                <div class="relative mt-1 rounded-md shadow-sm">
                                                    <input type="number" min="0" :ref="`price_${position.id}`" v-model="position.product.pricePerItemTaxIncluded" @change="correctPositionValue(position.product)" name="price" id="price" class="block w-full rounded-md border-gray-300  pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="0.00" />
                                                    <div class="absolute inset-y-0 right-0 flex items-center select-none">
                                                        <label for="currency" class="sr-only">Currency</label>
                                                        <select id="currency" @change="translateToAnotherCurrency($event,position.product.currency,`price_${position.id}`,position.product)" name="currency" v-model="position.product.currency" class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                            <option>PLN</option>
                                                            <option>EUR</option>
                                                            <option>GBP</option>
                                                            <option>USD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ml-2 select-none">
                                                <label for="location" class="block text-sm font-medium text-gray-700">Stawka VAT</label>
                                                <select id="location" name="location" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" v-model="position.product.taxRate">
                                                    <option value="0">0%</option>
                                                    <option v-for="v in 30" :value="v">{{v}} %</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button v-if="decodedObj !== null && !hideAssignButton && this.$route.query.origin === 'otto'" type="button" @click.prevent="assignProductOrigin(position)" class="inline-flex whitespace-nowrap items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Przypisz produkt z {{this.$route.query.origin.toUpperCase()}}</button>
                                    </div>
                                    <div>
                                        <!-- <div class="ml-2 select-none">
                                            <label for="location" class="block text-sm font-medium text-gray-700">Stawka VAT</label>
                                            <select id="location" name="location" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" v-model="position.product.taxRate">
                                                <option value="0">0%</option>
                                                <option v-for="v in 30" :value="v">{{v}} %</option>
                                            </select>
                                        </div> -->
                                        <!-- <div class="ml-2 select-none w-36"> -->
                                            <!-- <label for="location" class="block text-sm font-medium text-gray-700">Stawka VAT</label>
                                            <select id="location" name="location" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" v-model="position.product.taxRate">
                                                <option value="0">0%</option>
                                                <option v-for="v in 30" :value="v">{{v}} %</option>
                                            </select> -->
                                            <!-- <button type="button" :class="['hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 select-none" >Dodaj pozycję</button> -->
                                        <!-- </div>   -->
                                    </div>
                                </div>
                                <div v-if="editMode && position.wasOnLoad" class="text-xs mt-2 mb-1 text-gray-600 select-none">
                                    <span class="flex items-center"><ExclamationCircleIcon class="h-5 w-5 mr-1"></ExclamationCircleIcon>Ta pozycja została juz zdjęta ze stanu magazynowego.</span>
                                </div>
                                <!-- <div class="relative flex items-start mt-2 text-xs">
                                    <div class="flex h-5 items-center">
                                        <input id="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" v-model="position.product.amountTakenFromStorage" />
                                        <label for="comments" class="font-medium text-gray-500 ml-2">Pobierz ze stanu</label>
                                    </div>
                                </div> -->
                            </li>
                        </ul>
                    </div>
                    <transition name="fade">
                        <div class="flex items-center justify-center w-full" v-if="quantityCheck.inProgress">
                            <div class="wrap flex items-center justify-center mt-4">
                                <div class="loading">
                                    <div class="bounceball"></div>
                                    <div class="text ml-4">{{quantityCheck.text}}</div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>

                <div class="pt-5">
                    <div class="flex justify-end select-none">
                        <!-- <button type="button" @click.prevent="elasticFix" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">elastic fix try</button> -->
                        <button type="button" @click.prevent="sendBack" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                        <button type="submit" @click.prevent="validateStep3" :class="{'transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true}">Następny krok</button>
                    </div>
                </div>
            </form>

            <form class="space-y-8 divide-y divide-gray-200 mt-6" ref="Step4" v-show="step === 4">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5" ref="Step1">
                    <div>
                        <div class="flex items-center justify-between">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Płatność</p>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div>
                                <div class="flex items-center justify-between">
                                    <h2 class="text-sm font-medium text-gray-900">Status</h2>
                                </div>
                                <RadioGroup v-model="order.payment.done" class="mt-2 select-none">
                                    <div class="grid grid-cols-3 gap-3 sm:grid-cols-6">
                                        <RadioGroupOption as="template" v-for="option in paymentValues.status" :key="option.name" :value="option.value" :disabled="!option.allowed" v-slot="{ active, checked }">
                                        <div :class="[option.allowed ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-yellow-500' : '', checked ? 'bg-yellow-600 border-transparent text-white hover:bg-yellow-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'border rounded-md py-2 px-2 flex items-center justify-center text-xs font-medium uppercase sm:flex-1']">
                                            <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
                                        </div>
                                        </RadioGroupOption>
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div>
                                <div class="flex items-center justify-between">
                                    <h2 class="text-sm font-medium text-gray-900">Typ</h2>
                                </div>
                                <RadioGroup @update:modelValue="clearPaymentMethod"  v-model="order.payment.cashOnDelivery" class="mt-2 select-none">
                                    <div class="grid grid-cols-3 gap-3 sm:grid-cols-6">
                                        <RadioGroupOption as="template" v-for="option in paymentValues.type" :key="option.name" :value="option.value" :disabled="!option.allowed" v-slot="{ active, checked }">
                                        <div :class="[option.allowed ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-yellow-500' : '', checked ? 'bg-yellow-600 border-transparent text-white hover:bg-yellow-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'border rounded-md py-2 px-2 flex items-center justify-center text-xs font-medium uppercase sm:flex-1']">
                                            <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
                                        </div>
                                        </RadioGroupOption>
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="w-2/6">
                                <label class="block text-sm font-medium text-gray-700">Sposób</label>
                                <select v-model="order.payment.method" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                    <option value="">Brak</option>
                                    <option value="Inny">Inny</option>
                                    <option v-for="name in order.payment.cashOnDelivery ? paymentValues.paymentNamesCOD : paymentValues.paymentNames" :value="name">{{name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="w-2/6">
                                <label for="email" class="block text-sm font-medium text-gray-700">Komentarz</label>
                                <div class="mt-1">
                                    <input type="text" maxlength="45" v-model="order.payment.comment" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Brak" />
                                </div>
                            </div>
                        </div>


                        <div class="pt-5">
                            <div class="flex justify-end select-none">
                                <button type="button" @click.prevent="sendBack" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                                <button type="submit" @click.prevent="step = 5, progress = 90" :class="{'transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true}">Następny krok</button>
                            </div>
                        </div>
                    </div>
                            
                </div>


            </form>

            <form class="space-y-8 divide-y divide-gray-200 mt-6" ref="Step5" v-show="step === 5">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5" ref="Step5">
                    <div>
                        <div class="flex items-center justify-between mt-4">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Status zamówienia</p>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <RadioGroup v-model="order.statusId">
                                <div class="-space-y-px rounded-md bg-white">
                                <RadioGroupOption as="template" v-for="(status, statusIndx) in orderStatuses" :key="status.name" :value="status.id" v-slot="{ checked, active }">
                                    <div :class="[statusIndx === 0 ? 'rounded-tl-md rounded-tr-md' : '', statusIndx === statusIndx.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                                    <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                                        <span class="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <span class="ml-3 flex flex-col">
                                        <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">{{ status.name }}</RadioGroupLabel>
                                        <RadioGroupDescription as="span" :class="[checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm']">{{ status.info }}</RadioGroupDescription>
                                    </span>
                                    </div>
                                </RadioGroupOption>
                                </div>
                            </RadioGroup>
                        </div>
                        

                        <div class="pt-5">
                            <div class="flex justify-end select-none">
                                <button type="button" @click.prevent="sendBack" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                                <button type="submit" @click.prevent="saveOrder" :class="{'transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true}">Zapisz zamówienie</button>
                            </div>
                        </div>
                    </div>
                            
                </div>


            </form>
            <ProductFinder v-if="modalController.productFinder" @addPosition="addPosition" :posCart="getActualProductsToFinder()" @closeModal="closeFinderModal"></ProductFinder>
        </div>
    </transition>
</template>

<script>
import CryptoJS from 'crypto-js';
import moment from 'moment';
import axios from 'axios';
import EmptyState from '@/components/EmptyState.vue';
import {db} from "@/firebase/gfbconf.js"
import ProductFinder from '@/components/FindProductModal.vue'
import LoadingSpinner from '@/components/LoadingSpinnerBlue.vue';
import { RadioGroup, RadioGroupLabel, RadioGroupOption, RadioGroupDescription } from '@headlessui/vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { HomeIcon, CheckIcon, ChevronDownIcon,ExclamationCircleIcon, InformationCircleIcon, RefreshIcon } from '@heroicons/vue/solid';
import { diff } from 'just-diff'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
export default {
    name: "AddOrder",
    data() {
        return {
            isDoneTrackingCall: null,
            decodedObj: null,
            deleteKey: "",
            editMode: false,
            originalProductsHidden: true,
            blOrder: null,
            orderStatuses: [],
            manualOrderId: "",
            paymentValues: {
                status: [{name: "Opłacony", value: true, allowed: true},{name: "Brak płatności", value: false, allowed: true}],
                type: [{name: "Pobranie", value: true, allowed: true},{name: "Płatność internetowa", value: false, allowed: true}],
                paymentNames: ["Amazon", "Przelew", "PayU", "Klarna", "Przelewy24","Cdiscount","CreditCard","Blik", "Ebay", "Stripe"],
                paymentNamesCOD: ["Płatność u kuriera", "Płatność u kierowcy", "Płatność w punkcie"]
            },
            quantityCheck: {
                inProgress: false,
                text: "Sprawdzanie dostępności stanów magazynowych..."
            },
            GBPtoPLN: null,
            EURtoPLN: null,
            modalController: {
                productFinder: false
            },
            errors: {
                step1: {
                    client: {
                        name: false,
                        phone: false
                    },
                    delivery: {
                        name: false,
                        address: false,  
                        city: false,
                        postCode: false          
                    }
                }
            },
            progress: 0,
            deliveryChosenCountry: null,
            order: {
                description: "",
                statusId: 1,
                client: {
                    name: "",
                    company: "",
                    email: "",
                    phone: {
                        prefix: "",
                        number: "",
                        full: ""
                    }
                },
                delivery: {
                    date: {
                        planned: null,
                        suggested: null,
                        confirmed: null
                    },
                    method: "",
                    price: 0,
                    currency: "",
                    packageNum: 0,
                    name: "",
                    phone: {
                        prefix: "",
                        number: "",
                        full: ""
                    },
                    company: "",
                    address: "",
                    city: "",
                    postCode: "",
                    country: {
                        code: "",
                        name: ""
                    }
                },
                positions: {},
                invoice: {
                    needed: false,
                    fullname: "",
                    company: "",
                    num: null,
                    ref: null,
                    taxId: "",
                    address: "",
                    city: "",
                    postCode: "",
                    countryCode: ""
                },
                payment: {
                    method: "",
                    cashOnDelivery: false,
                    done: false,
                    comment: ""
                },
                id: null,
                originName: "Manual"
            },
            step: 1,
            oldOrderStatusId: 1,
            countries: [],
            couriers: [],
            phonePrefixes: [], 
            loading: false,
            loadingOrder: false,
            oldPositions: [],
            initialOrder: null,
            isDoneTrackerDoc: null,
            dbListener: null,
            dbListenerOrder: null,
            isFullyLoaded: false,
            watchers: {
                statusWatcher: null,
                lastModificationWatcher: null
            },
            isChangedOrder: false,
            createdOttoVariantId: null,
            hideAssignButton: true,
        }
    },
    components: {
        LoadingSpinnerHub, RefreshIcon, ExclamationCircleIcon, RadioGroupDescription, RadioGroup, RadioGroupLabel, RadioGroupOption,Switch, SwitchDescription, SwitchGroup, SwitchLabel, ProductFinder, EmptyState, HomeIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, CheckIcon, ChevronDownIcon, LoadingSpinner, InformationCircleIcon
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
        if(this.dbListenerOrder !== null){
            this.dbListenerOrder();
        }
    },
    async created()
    {
        this.moment = moment;
        moment.locale('pl')
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.orders)
        {
            this.$router.push("/hub")
        }
        this.isFullyLoaded = false;
        await this.getCountries();
        await this.getCouriers();
        await this.getCurrencies();
        await this.getOrderStatuses();
        if(this.$route.query.origin !== undefined && this.$route.query.id !== undefined)
        {
            if(this.$route.query.origin !== 'baselinker')
            {
                this.getOrdersDone();
            }
            if(this.$route.query.origin === 'baselinker' && this.$route.query.id.length > 0)
            {
                await this.fillWithBaselinkerData(this.$route.query.id);
            }
            else if(this.$route.query.origin === 'otto' && this.$route.query.id.length > 0 && this.$route.query.data.length !== undefined && this.$route.query.data.length > 0)
            {
                this.decodeDataURL(this.$route.query.data);
                this.setDoneTrackingCall(this.$route.query.origin,this.$route.query.dateOrigin)
                await this.fillFieldsWithOttoData(this.decodedObj);   
            }
            else if(this.$route.query.origin === 'apilo' && this.$route.query.id.length > 0)
            {
                this.setDoneTrackingCall(this.$route.query.origin,this.$route.query.dateOrigin)
                await this.fillFieldsWithApiloData(this.$route.query.id);   
            }
            else if(this.$route.query.origin === 'bol' && this.$route.query.id.length > 0 && this.$route.query.data.length !== undefined && this.$route.query.data.length > 0)
            {
                this.decodeDataURL(this.$route.query.data);
                this.setDoneTrackingCall(this.$route.query.origin,this.$route.query.dateOrigin)
                await this.fillFieldsWithBolData(this.decodedObj);   
            }
            else if(this.$route.query.origin === 'wayfair' && this.$route.query.id.length > 0 && this.$route.query.data.length !== undefined && this.$route.query.data.length > 0)
            {
                this.decodeDataURL(this.$route.query.data);
                this.setDoneTrackingCall(this.$route.query.origin,this.$route.query.dateOrigin)
                await this.fillFieldsWithWayfairData(this.decodedObj);   
            }
            else if(this.$route.query.origin === 'hood' && this.$route.query.id.length > 0 && this.$route.query.data.length !== undefined && this.$route.query.data.length > 0)
            {
                this.decodeDataURL(this.$route.query.data);
                this.setDoneTrackingCall(this.$route.query.origin,this.$route.query.dateOrigin)
                await this.fillFieldsWithHoodData(this.decodedObj);   
            }
        }

        this.isFullyLoaded = true;
        if(this.$route.query.edit !== undefined)
        {
            this.editMode = true;
            if(this.$route.query.edit.length>0)
            {
                document.title = `Edytuj zamówienie ${this.$route.query.edit} - Jan-Art Sp. z o.o.`
                await this.getExistingOrder(this.$route.query.edit);
            }
        }
        
        this.$nextTick(() => {
            const doneTrackerElement = this.$refs.doneTracker;
            if (doneTrackerElement) {
                doneTrackerElement.scrollIntoView({ behavior: 'smooth' });
            }
        });
    },
    mounted()
    {

    },
    methods:
    {
        async assignProductOrigin(pos)
        {
            try {
                if(this.decodedObj != null && this.decodedObj.positions != null && Object.keys(this.decodedObj.positions).length === 1)
                {
                    const productRef = db.collection('Products').doc(pos.product.id);
                    const product = (await productRef.get()).data();
                    if(this.$route.query.origin === 'otto')
                    {
                        let url = this.decodedObj.positions[pos.id-1].externalSourceProductData.product.shopUrl;
                        let num = url.lastIndexOf('/');
                        let withoutVariant = url.slice(0, num);
                        let variantNum = url.lastIndexOf('=');
                        let variant_id = url.slice(variantNum+1, url.length);
                        let prod_num = withoutVariant.lastIndexOf('/');
                        let prod_id = withoutVariant.slice(prod_num+1);
                        if(product.integratorIds === undefined)
                        {
                            product.integratorIds = {};
                        };
                        if(product.integratorIds[this.$route.query.origin] === undefined)
                        {
                            product.integratorIds[this.$route.query.origin] = {};
                            await productRef.update({
                                [`integratorIds.${this.$route.query.origin}`]: prod_id
                            });
                        }
                        else if(product.integratorIds[this.$route.query.origin] !== prod_id)
                        {
                            await productRef.update({
                                [`integratorIds.${this.$route.query.origin}`]: prod_id
                            });
                        }
                        if(product.variants[pos.product.variantId].integratorIds === undefined)
                        {
                            await productRef.update({
                                [`variants.${pos.product.variantId}.integratorIds.${this.$route.query.origin}`]: variant_id
                            });
                        }
                        else if(product.variants[pos.product.variantId].integratorIds[this.$route.query.origin] === undefined)
                        {
                            await productRef.update({
                                [`variants.${pos.product.variantId}.integratorIds.${this.$route.query.origin}`]: variant_id
                            });
                        }
                        else if(product.variants[pos.product.variantId].integratorIds[this.$route.query.origin] !== variant_id)
                        {
                            await productRef.update({
                                [`variants.${pos.product.variantId}.integratorIds.${this.$route.query.origin}`]: variant_id
                            });
                        }
                    }
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: `Przypisano produkt z ${this.$route.query.origin.toUpperCase()}`,
                        success: true
                    }); 
                }
            } catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Błąd!",
                    subheader: `Nie udało się przypisać produktu z ${this.$route.query.origin.toUpperCase()}`,
                    success: false
                });
                this.hideAssignButton = true;
            }
        },
        async elasticFix()
        {
            try {
                let names = [];
                let models = [];

                names = this.order.names;
                models = this.order.models;
                
                let doc = {
                    id: this.order.id,
                    externalId: this.blOrder !== null && this.blOrder.meta.external.ids.externalOrderId !== undefined && this.blOrder.meta.external.ids.externalOrderId.length > 0 ? this.blOrder.meta.external.ids.externalOrderId : 
                    this.decodedObj !== null && this.$route.query.origin === 'bol' && this.decodedObj.meta.external.ids.bolOrderId !== undefined && this.decodedObj.meta.external.ids.bolOrderId.length > 0 ? this.decodedObj.meta.external.ids.bolOrderId : 
                    this.decodedObj !== null && this.$route.query.origin === 'wayfair' && this.decodedObj.meta.external.ids.wayfairOrderId !== undefined && this.decodedObj.meta.external.ids.wayfairOrderId.length > 0 ? this.decodedObj.meta.external.ids.wayfairOrderId : 
                    this.decodedObj !== null && this.$route.query.origin === 'hood' && this.decodedObj.meta.external.ids.hoodOrderId !== undefined && this.decodedObj.meta.external.ids.hoodOrderId.length > 0 ? this.decodedObj.meta.external.ids.hoodOrderId : "",
                    client: {
                        name: this.order.client.name,
                        company: this.order.client.company,
                        email: this.order.client.email,
                        phone: this.order.client.phone.full
                    },
                    delivery: {
                        name: this.order.delivery.name,
                        company: this.order.delivery.company,
                        phone: this.order.delivery.phone.full,
                        postCode: this.order.delivery.postCode
                    },
                    invoice: {
                        fullname: this.order.invoice.fullname,
                        company: this.order.invoice.company,
                        taxId: this.order.invoice.taxId
                    },
                    names: names,
                    models: models,
                };
                await axios.post(`${this.$store.state.apiLink}/elastic/add`, {
                    index: "jan-art-orders",
                    collection: "Orders",
                    firestoreId: this.order.id,
                    document: doc,
                })
            } catch (error)
            {
                console.log(error);    
            }
        },
        getOrdersDone()
        {
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }
            this.dbListener = db.collection(`AwaitingOrderDone_${this.$route.query.origin}`).doc(`${this.$route.query.dateOrigin}`)
            .onSnapshot(doc => {
                if(doc.exists)
                {
                    this.isDoneTrackerDoc = null;
                    this.isDoneTrackerDoc = doc.data();
                }
                else
                {
                    this.isDoneTrackerDoc = null;
                }
            })
        },
        sendToAwaitingOrders()
        {
            this.$router.push("/dashboard/awaiting");
        },
        async getChanges() {
            try {
                const allChanges = diff(this.initialOrder, this.order)
                //exclude 'ref' so we don't get any chnages that firebase does
                //exclude meta, it's not necessary
                //exclude positions, we'll take care of it manually
                const filteredChanges = allChanges.filter(el => (
                    !el.path.includes("ref") && 
                    !el.path.includes("meta") && 
                    !el.path.includes("positions")))

                let changes = []

                for(let change of filteredChanges) {
                    let oldValue =  this.initialOrder
                    let newValue = change.value
                    for(let pathPortion of change.path) {
                        oldValue = oldValue[pathPortion]
                    }
                    changes.push({changedObject: change.path.join("_"), oldValue, newValue})
                }

                //exclude rest of the unnecessary values
                changes = changes.filter(el => (
                    !el.changedObject.includes("delivery_country_code") && 
                    !el.changedObject.includes("delivery_currency") && 
                    !el.changedObject.includes("delivery_price") && 
                    !el.changedObject.includes("date_suggested_dateObj") && 
                    !el.changedObject.includes("date_suggested_dayNum") && 
                    !el.changedObject.includes("date_suggested_dayOfWeek") && 
                    !el.changedObject.includes("date_suggested_monthNum") && 
                    !el.changedObject.includes("date_suggested_unix") && 
                    !el.changedObject.includes("date_suggested_weekNum") && 
                    !el.changedObject.includes("date_suggested_year") && 
                    !el.changedObject.includes("phone_prefix")))
                
                //change labels
                const labels = (await db.collection("Labels").doc("Orders").get()).data()
                const changesWithLabels = []
                for(let change of changes) {
                    let { changedObject, oldValue, newValue } = change
                    const split = changedObject.split(/_(.*)/s)
                    const header = split[0]
                    const rest = split[1]

                    //values 'payment.cashOnDelivery' and 'payment.done' are stored as booleans so we convert booleans to text values
                    if(header === "payment" && rest === "cashOnDelivery" || rest === "done") {
                        if(rest === "cashOnDelivery") {
                            if(oldValue) oldValue = "Pobranie"
                            else oldValue = "Płatność internetowa"
                            if(newValue) newValue = "Pobranie"
                            else newValue = "Płatność internetowa"
                        } else if(rest === "done") {
                            if(oldValue) oldValue = "Opłacony"
                            else oldValue = "Brak płatności"
                            if(newValue) newValue = "Opłacony"
                            else newValue = "Brak płatności"
                        } 
                    }    
                    //changing status id to status name
                    if(header === "statusId") {
                        const orderStatuses = (await db.collection("OrderStatuses").get()).docs[0].data().list
                        oldValue = orderStatuses.find(el => el.id === oldValue).name
                        newValue = orderStatuses.find(el => el.id === newValue).name
                    }

                    if(oldValue && newValue)
                        changesWithLabels.push(`Zmieniono ${labels[header]}${rest ? '-' + labels[rest] : ''} z \"${oldValue}\" na \"${newValue}\"`)
                    else if(!oldValue)
                        changesWithLabels.push(`Zmieniono ${labels[header]}${rest ? '-' + labels[rest] : ''} na \"${newValue}\"`)
                    else 
                        changesWithLabels.push(`Zmieniono ${labels[header]}${rest ? '-' + labels[rest] : ''} z \"${oldValue}\" na brak`)
                }

                //take care of positions changes
                let initialVariantsIds = []
                const variantsIds = []
                const newVariantsIds = []
                Object.values(this.initialOrder.positions).forEach(position => {
                    initialVariantsIds.push(position.product.variantId)
                })
                Object.values(this.order.positions).forEach(position => {
                    variantsIds.push(position.product.variantId)
                })
                for(let variantId of variantsIds) {
                    const foundVariantId = initialVariantsIds.find(el => el === variantId)
                    if(foundVariantId) {
                        const index = initialVariantsIds.indexOf(foundVariantId)
                        initialVariantsIds.splice(index, 1)
                    } else {
                        newVariantsIds.push(variantId)
                    }
                }
                let addedVariants = {};
                let removedVariants = {};
                initialVariantsIds.forEach(function (x) { removedVariants[x] = (removedVariants[x] || 0) + 1; });
                newVariantsIds.forEach(function (x) { addedVariants[x] = (addedVariants[x] || 0) + 1; });
                removedVariants = Object.keys(removedVariants).map(key => [key, removedVariants[key]])
                addedVariants = Object.keys(addedVariants).map(key => [key, addedVariants[key]])

                for(let variant of removedVariants) {
                    const productId = variant[0].split("_p_")[1]
                    const variantName = (await db.collection("Products").doc(productId).get()).data().variants[variant[0]].name
                    changesWithLabels.push(`Usunięto pozycje ${variantName}${variant[1] > 1 ? ' x' + variant[1] : ''}`)
                }
                for(let variant of addedVariants) {
                    const productId = variant[0].split("_p_")[1]
                    const variantName = (await db.collection("Products").doc(productId).get()).data().variants[variant[0]].name
                    changesWithLabels.push(`Dodano pozycje ${variantName}${variant[1] > 1 ? ' x' + variant[1] : ''}`)
                }

                return changesWithLabels
            } catch (error) {
                console.log(error);
                return []
            }
        },
        async deleteOrderFromRoute(orderId){
            const allRoutesDoc = await db.collection("Routes").get()
            for (let i = 0; i < allRoutesDoc.docs.length; i++) {
                const routeDoc = allRoutesDoc.docs[i];
                const route = routeDoc.data()
                const newOrders = route.orders.filter(order => order.id !== orderId)
                await routeDoc.ref.update({orders: newOrders})
            }
        },
        setDoneTrackingCall(origin,date)
        {
            if(date === undefined) return;
            if(date === null) return;
            if(date.length === 0) return;

            const dbName = `AwaitingOrderDone_${origin}`;
            let doc = `${date}`;
            this.isDoneTrackingCall = db.collection(`${dbName}`).doc(`${doc}`);
        },
        getDateFromUNIX(unix)
        {
            if(unix === undefined) return "--"
            if(unix === null) return "--"
            if(unix === 0) return "Brak"
            let date = moment(unix*1000).format("DD.MM.YY HH:mm");
            return date;
        },
        decodeDataURL(str)
        {
            let bytes  = CryptoJS.AES.decrypt(str, 'prodData');
            let jsn = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.decodedObj = jsn;
        },
        setSuggestedDeliveryDate(event)
        {
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Popraw datę!",
                    subheader: `Wykryto, że data jest niepoprawna.`,
                    success: false
                });
                this.order.delivery.date.suggested = null;
            }
            else
            {
                this.order.delivery.date.suggested = {
                    asText: event.target.value,
                    unix: mDate.unix(),
                    dateObj: mDate.toDate(),
                    dayOfWeek: mDate.day(),
                    dayNum: mDate.date(),
                    monthNum: mDate.month() + 1,
                    year: mDate.year(),
                    weekNum: mDate.week()                  
                }
            }

        },
        async deleteOrder()
        {
            if(this.order === undefined) return;
            if(this.order.id !== this.deleteKey) return;
            if(this.order.elasticSearch !== undefined)
            {
                if(this.order.elasticSearch.docId.length>0)
                {
                    const res = await axios.post(`${this.$store.state.apiLink}/elastic/delete`, {
                        index: "jan-art-orders",
                        id: this.order.elasticSearch.docId
                    }).catch((error)=>
                    {
                        console.log(error);
                    })
                }
            }
            await this.deleteOrderFromRoute(this.order.id)
            await db.collection("Orders").doc(this.order.id.toString()).delete().catch((error) =>
            {
                console.log("Nie mozna usunac")
            });
            this.$store.commit('setNotification',{
                show: true,
                head: "Zamówienie zostało usunięte!",
                subheader: ``,
                success: true
            });
            
            this.$router.push("/dashboard/orders/list");
        },
        async refreshOrder()
        {
            try {
                this.step = 1;
                this.progress = 0;
                await this.getExistingOrder(this.order.id);
                this.isChangedOrder = false;
            } catch (error) {
                console.log(error);
            }
        },
        async getExistingOrder(num)
        {
            if(num === undefined) return;
            if(num.length === 0) return;
            const getOrder = await db.collection('Orders').doc(num.toString()).get();
            if(getOrder.exists)
            {
                if(this.dbListenerOrder !== null){
                    this.dbListenerOrder()
                    this.dbListenerOrder = null
                }
                this.dbListenerOrder = db.collection('Orders').doc(num.toString())
                .onSnapshot(doc => {
                    this.order = doc.data();
                    this.oldOrderStatusId = this.order.statusId
                    this.oldPositions = doc.data().positions
                    this.initialOrder = JSON.parse(JSON.stringify(this.order)) 
                    if(this.order.delivery.date.suggested !== undefined)
                    {
                        if(this.order.delivery.date.suggested !== null)
                        {
                            if(this.$refs['suggestedDeliveryDate'] !== undefined)
                            {
                                this.$refs['suggestedDeliveryDate'].value = this.order.delivery.date.suggested.asText
                            }
                        }
                    }
                    // Get courier
                    let foundCourier = false;
                    for(let i=0; i< this.couriers.length; i++)
                    {
                        let currentCourier = this.couriers[i];
                        if(currentCourier.name === this.order.delivery.method)
                        {
                            foundCourier = true;
                        }
                    }
                    for(let posId in this.order.positions)
                    {
                        let current = this.order.positions[posId];
                        current.wasOnLoad = true;
                    }

                    if(!foundCourier)
                    {
                        this.couriers.push({
                            cost: this.order.delivery.price,
                            currency: this.order.delivery.currency,
                            name: this.order.delivery.method
                        })
                    }
                    // Get delivery country
                    for(let i=0; i<this.countries.length; i++)
                    {
                        let currentCountry = this.countries[i];
                        if(currentCountry.countryCode === this.order.delivery.country.code)
                        {
                            this.deliveryChosenCountry = currentCountry;
                        }
                    };
                    this.watchers.lastModificationWatcher = this.$watch('order.meta.lastModificationDate', (newVal, oldVal) => {
                        if(oldVal.seconds !== newVal.seconds) {
                            this.isChangedOrder = true;
                        }
                    });
                })

            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się znaleźć zamówienia dla tego ID.",
                    subheader: `Coś poszło nie tak..`,
                    success: false
                });
            }
        },
        setActiveTeamTabName() {
            if(this.order.statusId) {
                // once you got your desired condition satisfied then unwatch by calling:
                this.statusWatcher();
            }
        },
        createPositionFromHoodOrder(variant,dbProduct,position)
        {
            for(let i=0; i<position.product.quantity;i++)
            {
                let currentAmount = Object.keys(this.order.positions).length;
                this.order.positions[currentAmount+1] = {
                    status: 1,
                    production: null,
                    packing: null,
                    delivery: null,
                    infos: {
                        onOrderCreation: position.product.name
                    },
                    volume: this.m3calc(variant.dimensions),
                    product: {
                        amountTakenFromStorage: !variant.selfProduction,
                        amountOfPackages: variant.amountOfPackages,
                        model: variant.model,
                        apiloId: variant.apiloId != null ? variant.apiloId : '',
                        baselinkerId: variant.baselinkerId != null ? variant.baselinkerId : '',
                        dimensions: variant.dimensions,
                        image: variant.image,
                        selfProduction: variant.selfProduction,
                        manufacturer: variant.manufacturer,
                        sku: variant.sku,
                        ean: variant.ean,
                        weight: variant.weight,
                        location: variant.storage.location,
                        quantity: 1,
                        taxRate: position.product.taxRate,
                        currency: position.product.currency,
                        pricePerItemTaxIncluded: position.product.pricePerItemTaxIncluded,
                        id: dbProduct.id,
                        ref: db.collection("Products").doc(dbProduct.id),
                        variantId: variant.id,
                        name: variant.name,
                        attributes: variant.attribs.list,
                        externalSourceProductData: position.externalSourceProductData
                    },
                    id: currentAmount+1,
                    meta: {
                        created: {
                            byUser: {
                                name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                                id: this.$store.state.userData.id,
                                ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                            },
                            asText: moment().format("YYYY-MM-DD").toString(),
                            unix: moment().unix(),
                            date: moment().toDate(),
                            dayOfWeek: moment().day(),
                            dayNum: moment().date(),
                            monthNum: moment().month() + 1,
                            year: moment().year(),
                            weekNum: moment().week()
                        },
                        external: position,
                    }
                }
            }
        },
        createPositionFromWayfairOrder(variant,dbProduct,position)
        {
            for(let i=0; i<position.product.quantity;i++)
            {
                let currentAmount = Object.keys(this.order.positions).length;
                this.order.positions[currentAmount+1] = {
                    status: 1,
                    production: null,
                    packing: null,
                    delivery: null,
                    infos: {
                        onOrderCreation: position.product.name
                    },
                    volume: this.m3calc(variant.dimensions),
                    product: {
                        amountTakenFromStorage: !variant.selfProduction,
                        amountOfPackages: variant.amountOfPackages,
                        model: variant.model,
                        apiloId: variant.apiloId != null ? variant.apiloId : '',
                        baselinkerId: variant.baselinkerId != null ? variant.baselinkerId : '',
                        dimensions: variant.dimensions,
                        image: variant.image,
                        selfProduction: variant.selfProduction,
                        manufacturer: variant.manufacturer,
                        sku: variant.sku,
                        ean: variant.ean,
                        weight: variant.weight,
                        location: variant.storage.location,
                        quantity: 1,
                        taxRate: 0,
                        currency: position.product.currency,
                        pricePerItemTaxIncluded: position.product.pricePerItemTaxIncluded,
                        id: dbProduct.id,
                        ref: db.collection("Products").doc(dbProduct.id),
                        variantId: variant.id,
                        name: variant.name,
                        attributes: variant.attribs.list,
                        externalSourceProductData: position.externalSourceProductData
                    },
                    id: currentAmount+1,
                    meta: {
                        created: {
                            byUser: {
                                name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                                id: this.$store.state.userData.id,
                                ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                            },
                            asText: moment().format("YYYY-MM-DD").toString(),
                            unix: moment().unix(),
                            date: moment().toDate(),
                            dayOfWeek: moment().day(),
                            dayNum: moment().date(),
                            monthNum: moment().month() + 1,
                            year: moment().year(),
                            weekNum: moment().week()
                        },
                        external: position,
                    }
                }
            }
        },
        createPositionFromBolOrder(variant,dbProduct,position)
        {
            for(let i=0; i<position.externalSourceProductData.quantity;i++)
            {
                let currentAmount = Object.keys(this.order.positions).length;
                this.order.positions[currentAmount+1] = {
                    status: 1,
                    production: null,
                    packing: null,
                    delivery: null,
                    infos: {
                        onOrderCreation: position.externalSourceProductData.product.title
                    },
                    volume: this.m3calc(variant.dimensions),
                    product: {
                        amountTakenFromStorage: !variant.selfProduction,
                        amountOfPackages: variant.amountOfPackages,
                        model: variant.model,
                        apiloId: variant.apiloId != null ? variant.apiloId : '',
                        baselinkerId: variant.baselinkerId != null ? variant.baselinkerId : '',
                        dimensions: variant.dimensions,
                        image: variant.image,
                        selfProduction: variant.selfProduction,
                        manufacturer: variant.manufacturer,
                        sku: variant.sku,
                        ean: variant.ean,
                        weight: variant.weight,
                        location: variant.storage.location,
                        quantity: 1,
                        taxRate: 0,
                        currency: position.product.currency,
                        pricePerItemTaxIncluded: position.externalSourceProductData.unitPrice,
                        id: dbProduct.id,
                        ref: db.collection("Products").doc(dbProduct.id),
                        variantId: variant.id,
                        name: variant.name,
                        attributes: variant.attribs.list,
                        externalSourceProductData: position.externalSourceProductData
                    },
                    id: currentAmount+1,
                    meta: {
                        created: {
                            byUser: {
                                name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                                id: this.$store.state.userData.id,
                                ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                            },
                            asText: moment().format("YYYY-MM-DD").toString(),
                            unix: moment().unix(),
                            date: moment().toDate(),
                            dayOfWeek: moment().day(),
                            dayNum: moment().date(),
                            monthNum: moment().month() + 1,
                            year: moment().year(),
                            weekNum: moment().week()
                        },
                        external: position,
                    }
                }
            }
        },
        createPositionFromBlOrder(variant,dbProduct,blPosition)
        {
            for(let i=0; i<blPosition.externalSourceProductData.quantity;i++)
            {
                let currentAmount = Object.keys(this.order.positions).length;
                this.order.positions[currentAmount+1] = {
                    status: 1,
                    production: null,
                    packing: null,
                    delivery: null,
                    infos: {
                        onOrderCreation: ""
                    },
                    volume: this.m3calc(variant.dimensions),
                    product: {
                        amountTakenFromStorage: !variant.selfProduction,
                        amountOfPackages: variant.amountOfPackages,
                        model: variant.model,
                        apiloId: variant.apiloId != null ? variant.apiloId : '',
                        baselinkerId: variant.baselinkerId != null ? variant.baselinkerId : '',
                        dimensions: variant.dimensions,
                        image: variant.image,
                        selfProduction: variant.selfProduction,
                        manufacturer: variant.manufacturer,
                        sku: variant.sku,
                        ean: variant.ean,
                        weight: variant.weight,
                        location: variant.storage.location,
                        quantity: 1,
                        taxRate: blPosition.externalSourceProductData.tax_rate,
                        currency: blPosition.product.currency,
                        pricePerItemTaxIncluded: blPosition.externalSourceProductData.price_brutto,
                        id: dbProduct.id,
                        ref: db.collection("Products").doc(dbProduct.id),
                        variantId: variant.id,
                        name: variant.name,
                        attributes: variant.attribs.list,
                        externalSourceProductData: blPosition.externalSourceProductData
                    },
                    id: currentAmount+1,
                    meta: {
                        created: {
                            byUser: {
                                name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                                id: this.$store.state.userData.id,
                                ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                            },
                            asText: moment().format("YYYY-MM-DD").toString(),
                            unix: moment().unix(),
                            date: moment().toDate(),
                            dayOfWeek: moment().day(),
                            dayNum: moment().date(),
                            monthNum: moment().month() + 1,
                            year: moment().year(),
                            weekNum: moment().week()
                        },
                        external: blPosition,
                    }
                }
            }
        },
        createPositionFromOttoOrder(variant,dbProduct,ottoPosition)
        {
            for(let i=0; i<ottoPosition.product.quantity;i++)
            {
                let currentAmount = Object.keys(this.order.positions).length;
                this.order.positions[currentAmount+1] = {
                    status: 1,
                    production: null,
                    packing: null,
                    delivery: null,
                    infos: {
                        onOrderCreation: ""
                    },
                    volume: this.m3calc(variant.dimensions),
                    product: {
                        amountTakenFromStorage: !variant.selfProduction,
                        amountOfPackages: variant.amountOfPackages,
                        model: variant.model,
                        apiloId: variant.apiloId != null ? variant.apiloId : '',
                        baselinkerId: variant.baselinkerId != null ? variant.baselinkerId : '',
                        dimensions: variant.dimensions,
                        image: variant.image,
                        selfProduction: variant.selfProduction,
                        manufacturer: variant.manufacturer,
                        sku: variant.sku,
                        ean: variant.ean,
                        weight: variant.weight,
                        location: variant.storage.location,
                        quantity: 1,
                        taxRate: ottoPosition.product.taxRate,
                        currency: ottoPosition.product.currency,
                        pricePerItemTaxIncluded: ottoPosition.product.pricePerItemTaxIncluded,
                        id: dbProduct.id,
                        ref: db.collection("Products").doc(dbProduct.id),
                        variantId: variant.id,
                        name: variant.name,
                        attributes: variant.attribs.list,
                        externalSourceProductData: ottoPosition.externalSourceProductData
                    },
                    id: currentAmount+1,
                    meta: {
                        created: {
                            byUser: {
                                name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                                id: this.$store.state.userData.id,
                                ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                            },
                            asText: moment().format("YYYY-MM-DD").toString(),
                            unix: moment().unix(),
                            date: moment().toDate(),
                            dayOfWeek: moment().day(),
                            dayNum: moment().date(),
                            monthNum: moment().month() + 1,
                            year: moment().year(),
                            weekNum: moment().week()
                        },
                        external: ottoPosition,
                    }
                }
            }
        },
        createPositionFromApiloOrder(variant,dbProduct,apiloPosition)
        {
            for(let i=0; i<apiloPosition.externalSourceProductData.quantity;i++)
            {
                let currentAmount = Object.keys(this.order.positions).length;
                this.order.positions[currentAmount+1] = {
                    status: 1,
                    production: null,
                    packing: null,
                    delivery: null,
                    infos: {
                        onOrderCreation: ""
                    },
                    volume: this.m3calc(variant.dimensions),
                    product: {
                        amountTakenFromStorage: !variant.selfProduction,
                        amountOfPackages: variant.amountOfPackages,
                        model: variant.model,
                        apiloId: variant.apiloId != null ? variant.apiloId : '',
                        baselinkerId: variant.baselinkerId != null ? variant.baselinkerId : '',
                        dimensions: variant.dimensions,
                        image: variant.image,
                        selfProduction: variant.selfProduction,
                        manufacturer: variant.manufacturer,
                        sku: variant.sku,
                        ean: variant.ean,
                        weight: variant.weight,
                        location: variant.storage.location,
                        quantity: 1,
                        taxRate: apiloPosition.product.taxRate,
                        currency: apiloPosition.product.currency,
                        pricePerItemTaxIncluded: apiloPosition.product.pricePerItemTaxIncluded,
                        id: dbProduct.id,
                        ref: db.collection("Products").doc(dbProduct.id),
                        variantId: variant.id,
                        name: variant.name,
                        attributes: variant.attribs.list,
                        externalSourceProductData: apiloPosition.externalSourceProductData
                    },
                    id: currentAmount+1,
                    meta: {
                        created: {
                            byUser: {
                                name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                                id: this.$store.state.userData.id,
                                ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                            },
                            asText: moment().format("YYYY-MM-DD").toString(),
                            unix: moment().unix(),
                            date: moment().toDate(),
                            dayOfWeek: moment().day(),
                            dayNum: moment().date(),
                            monthNum: moment().month() + 1,
                            year: moment().year(),
                            weekNum: moment().week()
                        },
                        external: apiloPosition,
                    }
                }
            }
        },
        async fillFieldsWithHoodData(awOrd)
        {
            this.loading = true;
            // Client
            this.order.client.name = awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : ""
            this.order.client.company = awOrd.delivery.company === "{}" ? awOrd.delivery.company !== "{}" ? awOrd.delivery.company : "" : awOrd.delivery.company
            this.order.client.email = awOrd.contact.email;
            this.order.originName = "Hood";
            this.order.origin = awOrd;

            if(awOrd.contact.phone.length > 0)
            {
                if(awOrd.contact.phone.length > 0)
                {
                    let foundPhone = false;
                    for(let i=0;i<this.phonePrefixes.length;i++)
                    {
                        let cPref = this.phonePrefixes[i];
                        if(awOrd.contact.phone.includes(cPref.prefix))
                        {
                            this.order.client.phone.prefix = cPref.prefix;
                            let numberToSplit = awOrd.contact.phone;
                            let splitted = numberToSplit.split(cPref.prefix);
                            if(splitted.length === 2)
                            {
                                this.order.client.phone.number = splitted[1];
                                this.order.client.phone.full = awOrd.contact.phone;
                                foundPhone = true;
                            }
                            else if(splitted.length === 1)
                            {
                                this.order.client.phone.number = splitted[0];
                                this.order.client.phone.full = awOrd.contact.phone;
                                foundPhone = true;
                            }
                            else
                            {
                                this.order.client.phone.number = awOrd.contact.phone;
                                this.order.client.phone.full = awOrd.contact.phone;
                                foundPhone = true;
                            }
                        }
                    }
                    if(!foundPhone)
                    {
                        for(let i=0;i<this.phonePrefixes.length;i++)
                        {
                            let cPref = this.phonePrefixes[i];
                            if(cPref.ref.countryCode === awOrd.delivery.countryCode)
                            {
                                this.order.client.phone.prefix = cPref.prefix;
                                this.order.client.phone.number = awOrd.contact.phone;
                                this.order.client.phone.full = awOrd.contact.phone;
                            }
                        }
                    }
                }
            }
            else
            {
                for(let i=0;i<this.phonePrefixes.length;i++)
                {
                    let cPref = this.phonePrefixes[i];
                    if(cPref.ref.countryCode === awOrd.delivery.countryCode)
                    {
                        this.order.client.phone.prefix = cPref.prefix;
                        this.order.client.phone.number = "000000000";
                        this.order.client.phone.full = "000000000";
                    }
                }                    
            }

            

            // Delivery
            this.order.delivery.name = awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : ""
            this.order.delivery.company = this.order.client.company;
            // this.order.delivery.company = awOrd.delivery.company.length > 0 ? awOrd.delivery.company : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : "";
            this.order.delivery.phone.prefix = this.order.client.phone.prefix;
            this.order.delivery.phone.number = this.order.client.phone.number;
            this.order.delivery.phone.full = this.order.client.phone.full;
            this.order.delivery.address = awOrd.delivery.address;
            this.order.delivery.city = awOrd.delivery.city;
            this.order.delivery.postCode = awOrd.delivery.postCode;
            this.order.delivery.currency = awOrd.currency;
            this.order.delivery.price = awOrd.delivery.price;

            let foundCourier = false;
            for(let i=0; i< this.couriers.length; i++)
            {
                let currentCourier = this.couriers[i];
                if(currentCourier.name === awOrd.delivery.method)
                {
                    foundCourier = true;
                    this.order.delivery.method = awOrd.delivery.method;
                }
            }

            if(!foundCourier)
            {
                this.couriers.push({
                    cost: awOrd.delivery.price === "" ? 0 : parseFloat(awOrd.delivery.price) ,
                    currency: awOrd.currency,
                    name: awOrd.delivery.method.length > 0 ? awOrd.delivery.method : "Dostawa (Automated)"
                })
                this.order.delivery.method = awOrd.delivery.method.length > 0 ? awOrd.delivery.method : "Delivery (Automated)";
            }

            for(let i=0; i<this.countries.length; i++)
            {
                let currentCountry = this.countries[i];
                if(currentCountry.countryCode === awOrd.delivery.countryCode)
                {
                    this.deliveryChosenCountry = currentCountry;
                }
            }

            this.order.invoice.needed = false;

            // Invoice
            // this.order.invoice.needed = awOrd.invoice.needed;
            // if(this.order.invoice.needed)
            // {
            //     this.order.invoice.fullname = awOrd.invoice.fullname;
            //     this.order.invoice.company = awOrd.invoice.company;
            //     this.order.invoice.taxId = awOrd.invoice.taxId;
            //     this.order.invoice.address = awOrd.invoice.address;
            //     this.order.invoice.postCode = awOrd.invoice.postCode;
            //     this.order.invoice.city = awOrd.invoice.city;
            //     if(awOrd.invoice.countryCode.length>0)
            //     {
            //         this.order.invoice.countryCode  = awOrd.invoice.countryCode;
            //     }
            // }

            // Positions
            for(let pId in awOrd.positions)
            {
                let currentPosition = awOrd.positions[pId];
                if(currentPosition.externalSourceProductData.item.itemid.toString().length>0)
                {
                    let res = await db.collection("Products").where("integratorIds.hood", "==", currentPosition.externalSourceProductData.item.itemid).get();
                    if(!res.empty)
                    {
                        res.docs.forEach(doc => {
                            let product = doc.data();
                            if(currentPosition.externalSourceProductData.item.itemnumber.length>0)
                            {
                                for(let vId in product.variants)
                                {
                                    let variant = product.variants[vId];
                                    if(variant.integratorIds != null)
                                    {
                                        if(variant.integratorIds.hood === currentPosition.externalSourceProductData.item.itemnumber)
                                        {
                                            this.createPositionFromHoodOrder(variant,product,currentPosition)
                                        }
                                    }
                                }                        
                            }
                        });
                    }
                }
            }

            // Payment
            this.order.payment.done = awOrd.payment.done > 0 ? true : false;
            if(this.order.payment.done)
            {
                this.order.payment.comment = `Wpłata ${awOrd.payment.done.toFixed(2)} ${awOrd.currency}`
            }
            for(let i=0; i<this.paymentValues.paymentNames.length;i++)
            {
                if(this.paymentValues.paymentNames[i] === awOrd.payment.method)
                {
                    this.order.payment.method = this.paymentValues.paymentNames[i];
                }
            }
            this.order.payment.cashOnDelivery = awOrd.payment.cashOnDelivery;
            this.loading = false;
        },
        async fillFieldsWithWayfairData(awOrd)
        {
            this.loading = true;
            // Client
            this.order.client.name = awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.delivery.company.length > 0 ? awOrd.delivery.company : ""
            this.order.client.company = awOrd.delivery.company.length > 0 ? awOrd.delivery.company : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : ""
            this.order.client.email = awOrd.contact.email;
            this.order.origin = awOrd;
            this.order.originName = "Wayfair";

            this.loading = false;

            if(awOrd.contact.phone.length > 0)
            {
                if(awOrd.contact.phone.length > 0)
                {
                    let foundPhone = false;
                    for(let i=0;i<this.phonePrefixes.length;i++)
                    {
                        let cPref = this.phonePrefixes[i];
                        if(awOrd.contact.phone.includes(cPref.prefix))
                        {
                            this.order.client.phone.prefix = cPref.prefix;
                            let numberToSplit = awOrd.contact.phone;
                            let splitted = numberToSplit.split(cPref.prefix);
                            if(splitted.length === 2)
                            {
                                this.order.client.phone.number = splitted[1];
                                this.order.client.phone.full = awOrd.contact.phone;
                                foundPhone = true;
                            }
                            else if(splitted.length === 1)
                            {
                                this.order.client.phone.number = splitted[0];
                                this.order.client.phone.full = awOrd.contact.phone;
                                foundPhone = true;
                            }
                            else
                            {
                                this.order.client.phone.number = awOrd.contact.phone;
                                this.order.client.phone.full = awOrd.contact.phone;
                                foundPhone = true;
                            }
                        }
                    }
                    if(!foundPhone)
                    {
                        for(let i=0;i<this.phonePrefixes.length;i++)
                        {
                            let cPref = this.phonePrefixes[i];
                            if(cPref.ref.countryCode === awOrd.delivery.countryCode)
                            {
                                this.order.client.phone.prefix = cPref.prefix;
                                this.order.client.phone.number = awOrd.contact.phone;
                                this.order.client.phone.full = awOrd.contact.phone;
                            }
                        }
                    }
                }
            }
            else
            {
                        for(let i=0;i<this.phonePrefixes.length;i++)
                        {
                            let cPref = this.phonePrefixes[i];
                            if(cPref.ref.countryCode === awOrd.delivery.countryCode)
                            {
                                this.order.client.phone.prefix = cPref.prefix;
                                this.order.client.phone.number = "000000000";
                                this.order.client.phone.full = "000000000";
                            }
                        }                    
            }

            // Delivery
            this.order.delivery.name = awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.delivery.company.length > 0 ? awOrd.delivery.company : ""
            this.order.delivery.company = awOrd.delivery.company.length > 0 ? awOrd.delivery.company : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : "";
            this.order.delivery.phone.prefix = this.order.client.phone.prefix;
            this.order.delivery.phone.number = this.order.client.phone.number;
            this.order.delivery.phone.full = this.order.client.phone.full;
            this.order.delivery.address = awOrd.delivery.address;
            this.order.delivery.city = awOrd.delivery.city;
            this.order.delivery.postCode = awOrd.delivery.postCode;
            this.order.delivery.currency = awOrd.currency;
            this.order.delivery.price = awOrd.delivery.price;

            let foundCourier = false;
            for(let i=0; i< this.couriers.length; i++)
            {
                let currentCourier = this.couriers[i];
                if(currentCourier.name === awOrd.delivery.method)
                {
                    foundCourier = true;
                    this.order.delivery.method = awOrd.delivery.method;
                }
            }

            if(!foundCourier)
            {
                this.couriers.push({
                    cost: awOrd.delivery.price === "" ? 0 : parseFloat(awOrd.delivery.price) ,
                    currency: awOrd.currency,
                    name: awOrd.delivery.method.length > 0 ? awOrd.delivery.method : "Dostawa (Automated)"
                })
                this.order.delivery.method = awOrd.delivery.method.length > 0 ? awOrd.delivery.method : "Delivery (Automated)";
            }

            for(let i=0; i<this.countries.length; i++)
            {
                let currentCountry = this.countries[i];
                if(currentCountry.countryCode === awOrd.delivery.countryCode)
                {
                    this.deliveryChosenCountry = currentCountry;
                }
            }

            // Invoice
            this.order.invoice.needed = awOrd.invoice.needed;
            if(this.order.invoice.needed)
            {
                this.order.invoice.fullname = awOrd.invoice.fullname;
                this.order.invoice.company = awOrd.invoice.company;
                this.order.invoice.taxId = awOrd.invoice.taxId;
                this.order.invoice.address = awOrd.invoice.address;
                this.order.invoice.postCode = awOrd.invoice.postCode;
                this.order.invoice.city = awOrd.invoice.city;
                if(awOrd.invoice.countryCode.length>0)
                {
                    this.order.invoice.countryCode  = awOrd.invoice.countryCode;
                }
            }

            // Positions
            for(let pId in awOrd.positions)
            {
                let currentPosition = awOrd.positions[pId];
                if(currentPosition.product.id.length>0)
                {
                    let prodInSystem = await db.collection("WayfairIntegrator").where("integratorId", "==", currentPosition.product.id.toString()).get();
                    if(!prodInSystem.empty)
                    {
                        const integratorData = prodInSystem.docs[0].data();
                        const productData = await integratorData.ref.get();
                        if(productData.exists)
                        {
                            let productCreds = productData.data();
                            let pickedVariant = productCreds.variants[integratorData.variantId];
                            if(pickedVariant !== undefined && pickedVariant !== null)
                            {
                                this.createPositionFromWayfairOrder(pickedVariant,productCreds,currentPosition);
                            }
                        }
                    }
                }

                this.order.payment.comment = `Zweryfikuj wpłatę (Zam.${awOrd.meta.external.ids.wayfairOrderId})`
            }
        },
        async fillFieldsWithBolData(awOrd)
        {
            this.loading = true;
            // Client
            this.order.client.name = awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.delivery.company.length > 0 ? awOrd.delivery.company : ""
            this.order.client.company = awOrd.delivery.company.length > 0 ? awOrd.delivery.company : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : ""
            this.order.client.email = awOrd.contact.email;
            this.order.origin = awOrd;
            this.order.originName = "Bol";

            if(awOrd.contact.phone !== undefined && awOrd.contact.phone.length > 0)
            {
                let foundPhone = false;
                for(let i=0;i<this.phonePrefixes.length;i++)
                {
                    let cPref = this.phonePrefixes[i];
                    if(awOrd.contact.phone.includes(cPref.prefix))
                    {
                        this.order.client.phone.prefix = cPref.prefix;
                        let numberToSplit = awOrd.contact.phone;
                        let splitted = numberToSplit.split(cPref.prefix);
                        if(splitted.length === 2)
                        {
                            this.order.client.phone.number = splitted[1];
                            this.order.client.phone.full = awOrd.contact.phone;
                            foundPhone = true;
                        }
                        else if(splitted.length === 1)
                        {
                            this.order.client.phone.number = splitted[0];
                            this.order.client.phone.full = awOrd.contact.phone;
                            foundPhone = true;
                        }
                        else
                        {
                            this.order.client.phone.number = awOrd.contact.phone;
                            this.order.client.phone.full = awOrd.contact.phone;
                            foundPhone = true;
                        }
                    }
                }
                if(!foundPhone)
                {
                    for(let i=0;i<this.phonePrefixes.length;i++)
                    {
                        let cPref = this.phonePrefixes[i];
                        if(cPref.ref.countryCode === awOrd.delivery.countryCode)
                        {
                            foundPhone = true;
                            this.order.client.phone.prefix = cPref.prefix;
                            this.order.client.phone.number = awOrd.contact.phone;
                            this.order.client.phone.full = awOrd.contact.phone;
                        }
                    }
                }
            }

            // Delivery
            this.order.delivery.name = awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.delivery.company.length > 0 ? awOrd.delivery.company : ""
            this.order.delivery.company = awOrd.delivery.company.length > 0 ? awOrd.delivery.company : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : "";
            this.order.delivery.phone.prefix = this.order.client.phone.prefix;
            this.order.delivery.phone.number = this.order.client.phone.number;
            this.order.delivery.phone.full = this.order.client.phone.full;
            this.order.delivery.address = awOrd.delivery.address;
            this.order.delivery.city = awOrd.delivery.city;
            this.order.delivery.postCode = awOrd.delivery.postCode;
            this.order.delivery.currency = awOrd.currency;
            this.order.delivery.price = awOrd.delivery.price;

            let foundCourier = false;
            for(let i=0; i< this.couriers.length; i++)
            {
                let currentCourier = this.couriers[i];
                if(currentCourier.name === awOrd.delivery.method)
                {
                    foundCourier = true;
                    this.order.delivery.method = awOrd.delivery.method;
                }
            }

            if(!foundCourier)
            {
                this.couriers.push({
                    cost: awOrd.delivery.price === "" ? 0 : parseFloat(awOrd.delivery.price) ,
                    currency: "EUR",
                    name: awOrd.delivery.method.length > 0 ? awOrd.delivery.method : "Dostawa (Automated)"
                })
                this.order.delivery.method = awOrd.delivery.method.length > 0 ? awOrd.delivery.method : "Delivery (Automated)";
            }

            for(let i=0; i<this.countries.length; i++)
            {
                let currentCountry = this.countries[i];
                if(currentCountry.countryCode === awOrd.delivery.countryCode)
                {
                    this.deliveryChosenCountry = currentCountry;
                }
            }

            // Invoice
            this.order.invoice.needed = awOrd.invoice.needed;
            if(this.order.invoice.needed)
            {
                this.order.invoice.fullname = awOrd.invoice.fullname;
                this.order.invoice.company = awOrd.invoice.company;
                this.order.invoice.taxId = awOrd.invoice.taxId;
                this.order.invoice.address = awOrd.invoice.address;
                this.order.invoice.postCode = awOrd.invoice.postCode;
                this.order.invoice.city = awOrd.invoice.city;
                if(awOrd.invoice.countryCode.length>0)
                {
                    this.order.invoice.countryCode  = awOrd.invoice.countryCode;
                }
            }

            // Positions
            for(let pId in awOrd.positions)
            {
                let currentPosition = awOrd.positions[pId];
                if(currentPosition.externalSourceProductData.offer.reference.length>0)
                {
                    let res = await db.collection('Products').where("integratorIds.bol", "==", currentPosition.externalSourceProductData.offer.reference.toString()).get();
                    if(!res.empty)
                    {
                        res.docs.forEach(doc => {
                            let product = doc.data();
                            if(currentPosition.externalSourceProductData.product.ean.length>0)
                            {
                                for(let vId in product.variants)
                                {
                                    let variant = product.variants[vId];
                                    if(variant.integratorIds != null)
                                    {
                                        if(variant.integratorIds.bol === currentPosition.externalSourceProductData.product.ean)
                                        {
                                            this.createPositionFromBolOrder(variant,product,currentPosition)
                                        }
                                    }
                                }                        
                            }
                        });
                    }
                }
            }

            this.order.payment.comment = `Zweryfikuj wpłatę (Zam.${awOrd.meta.external.ids.bolOrderId})`
            this.loading = false;
        },
        async fillFieldsWithBLData(blOrd)
        {
            this.loading = true;
            // Client
            this.order.client.name = blOrd.delivery.fullname.length > 0 ? blOrd.delivery.fullname : blOrd.delivery.company.length > 0 ? blOrd.delivery.company : ""
            this.order.client.company = blOrd.delivery.company.length > 0 ? blOrd.delivery.company : blOrd.invoice.company.length > 0 ? blOrd.invoice.company : ""
            this.order.client.email = blOrd.contact.email;
            this.order.origin = blOrd;
            this.order.originName = "Baselinker";

            if(blOrd.contact.phone.length > 0)
            {
                for(let i=0;i<this.phonePrefixes.length;i++)
                {
                    let cPref = this.phonePrefixes[i];
                    if(blOrd.contact.phone.includes(cPref.prefix))
                    {
                        this.order.client.phone.prefix = cPref.prefix;
                        let numberToSplit = blOrd.contact.phone;
                        let splitted = numberToSplit.split(cPref.prefix);
                        if(splitted.length === 2)
                        {
                            this.order.client.phone.number = splitted[1];
                            this.order.client.phone.full = blOrd.contact.phone;
                        }
                        else if(splitted.length === 1)
                        {
                            this.order.client.phone.number = splitted[0];
                            this.order.client.phone.full = blOrd.contact.phone;
                        }
                        else
                        {
                            this.order.client.phone.number = blOrd.contact.phone;
                            this.order.client.phone.full = blOrd.contact.phone;
                        }
                    }
                }
            }
            else
            {
                        for(let i=0;i<this.phonePrefixes.length;i++)
                        {
                            let cPref = this.phonePrefixes[i];
                            if(cPref.ref.countryCode === blOrd.delivery.countryCode)
                            {
                                this.order.client.phone.prefix = cPref.prefix;
                                this.order.client.phone.number = "000000000";
                                this.order.client.phone.full = "000000000";
                            }
                        }                    
            }

            // Delivery
            this.order.delivery.name = blOrd.delivery.fullname.length > 0 ? blOrd.delivery.fullname : blOrd.delivery.company.length > 0 ? blOrd.delivery.company : ""
            this.order.delivery.company = blOrd.delivery.company.length > 0 ? blOrd.delivery.company : blOrd.invoice.company.length > 0 ? blOrd.invoice.company : "";
            this.order.delivery.phone.prefix = this.order.client.phone.prefix;
            this.order.delivery.phone.number = this.order.client.phone.number;
            this.order.delivery.phone.full = this.order.client.phone.full;
            this.order.delivery.address = blOrd.delivery.address;
            this.order.delivery.city = blOrd.delivery.city;
            this.order.delivery.postCode = blOrd.delivery.postCode;
            this.order.delivery.currency = blOrd.currency;
            this.order.delivery.price = blOrd.delivery.price;

            let foundCourier = false;
            for(let i=0; i< this.couriers.length; i++)
            {
                let currentCourier = this.couriers[i];
                if(currentCourier.name === blOrd.delivery.method)
                {
                    foundCourier = true;
                    this.order.delivery.method = blOrd.delivery.method;
                }
            }

            if(!foundCourier)
            {
                this.couriers.push({
                    cost: blOrd.delivery.price,
                    currency: blOrd.currency,
                    name: blOrd.delivery.method
                })
                this.order.delivery.method = blOrd.delivery.method;
            }

            for(let i=0; i<this.countries.length; i++)
            {
                let currentCountry = this.countries[i];
                if(currentCountry.countryCode === blOrd.delivery.countryCode)
                {
                    this.deliveryChosenCountry = currentCountry;
                }
            }

            // Invoice
            this.order.invoice.needed = blOrd.invoice.needed;
            if(this.order.invoice.needed)
            {
                this.order.invoice.fullname = blOrd.invoice.fullname;
                this.order.invoice.company = blOrd.invoice.company;
                this.order.invoice.taxId = blOrd.invoice.taxId;
                this.order.invoice.address = blOrd.invoice.address;
                this.order.invoice.postCode = blOrd.invoice.postCode;
                this.order.invoice.city = blOrd.invoice.city;
                if(blOrd.invoice.countryCode.length>0)
                {
                    this.order.invoice.countryCode  = blOrd.invoice.countryCode;
                }
            }

            // Positions
            for(let pId in blOrd.positions)
            {
                let currentBlProduct = blOrd.positions[pId];
                if(currentBlProduct.externalSourceProductData.product_id.length>0)
                {
                    let res = await db.collection("Products").where("baselinkerId", "==", parseInt(currentBlProduct.externalSourceProductData.product_id)).get();
                    if(!res.empty)
                    {
                        if(res.docs[0] !== undefined)
                        {
                            let product = res.docs[0].data();
                            if(currentBlProduct.externalSourceProductData.variant_id.length>0)
                            {
                                for(let vId in product.variants)
                                {
                                    let variant = product.variants[vId];
                                    if(variant.baselinkerId.toString() === currentBlProduct.externalSourceProductData.variant_id)
                                    {
                                        this.createPositionFromBlOrder(variant,product,currentBlProduct)
                                    }
                                }                        
                            }

                        }
                    }
                }
            }

            // Payments
            this.order.payment.done = blOrd.payment.done > 0 ? true : false;
            if(this.order.payment.done)
            {
                this.order.payment.comment = `Wpłata ${blOrd.payment.done.toFixed(2)} ${blOrd.currency}`
            }
            for(let i=0; i<this.paymentValues.paymentNames.length;i++)
            {
                if(this.paymentValues.paymentNames[i] === blOrd.payment.method)
                {
                    this.order.payment.method = this.paymentValues.paymentNames[i];
                }
            }
            this.order.payment.cashOnDelivery = blOrd.payment.cashOnDelivery;


            this.loading = false;
            // this.order.delivery.country.code = blOrd.delivery.countryCode.length > 0 ? blOrd.delivery.countryCode : blOrd.invoice.countryCode.length > 0 ? blOrd.invoice.countryCode : "PL";

        },
        async fillFieldsWithOttoData(ottoOrd)
        {
            this.loading = true;
            // Client
            this.order.client.name = ottoOrd.delivery.fullname.length > 0 ? ottoOrd.delivery.fullname : ottoOrd.delivery.company.length > 0 ? ottoOrd.delivery.company : ""
            this.order.client.company = ottoOrd.delivery.companty != null ? ottoOrd.delivery.company.length > 0 ? ottoOrd.delivery.company : ottoOrd.invoice.company.length > 0 ? ottoOrd.invoice.company : "" : '';
            this.order.client.email = ottoOrd.contact.email;
            this.order.origin = ottoOrd;
            this.order.originName = "Otto";

            if(ottoOrd.contact.phone != null && ottoOrd.contact.phone.length > 0)
            {
                for(let i=0;i<this.phonePrefixes.length;i++)
                {
                    let cPref = this.phonePrefixes[i];
                    if(ottoOrd.contact.phone.includes(cPref.prefix))
                    {
                        this.order.client.phone.prefix = cPref.prefix;
                        let numberToSplit = ottoOrd.contact.phone;
                        let splitted = numberToSplit.split(cPref.prefix);
                        if(splitted.length === 2)
                        {
                            this.order.client.phone.number = splitted[1];
                            this.order.client.phone.full = ottoOrd.contact.phone;
                        }
                        else if(splitted.length === 1)
                        {
                            this.order.client.phone.number = splitted[0];
                            this.order.client.phone.full = ottoOrd.contact.phone;
                        }
                        else
                        {
                            this.order.client.phone.number = ottoOrd.contact.phone;
                            this.order.client.phone.full = ottoOrd.contact.phone;
                        }
                    }
                    else
                    {
                        if(cPref.ref.countryCode === ottoOrd.delivery.countryCode)
                        {
                            this.order.client.phone.prefix = cPref.prefix;
                            this.order.client.phone.number = ottoOrd.contact.phone;
                            this.order.client.phone.full = cPref.prefix+''+ottoOrd.contact.phone;
                        }
                    }
                }
            }
            else
            {
                for(let i=0;i<this.phonePrefixes.length;i++)
                {
                    let cPref = this.phonePrefixes[i];
                    if(cPref.ref.countryCode === ottoOrd.delivery.countryCode)
                    {
                        this.order.client.phone.prefix = cPref.prefix;
                        this.order.client.phone.number = "000000000";
                        this.order.client.phone.full = "000000000";
                    }
                }                    
            }
            
            // Delivery
            this.order.delivery.name = ottoOrd.delivery.fullname.length > 0 ? ottoOrd.delivery.fullname : ottoOrd.delivery.company.length > 0 ? ottoOrd.delivery.company : ""
            this.order.delivery.company = ottoOrd.delivery.company != null ? ottoOrd.delivery.company.length > 0 ? ottoOrd.delivery.company : ottoOrd.invoice.company.length > 0 ? ottoOrd.invoice.company : "" : '';
            this.order.delivery.phone.prefix = this.order.client.phone.prefix;
            this.order.delivery.phone.number = this.order.client.phone.number;
            this.order.delivery.phone.full = this.order.client.phone.full;
            this.order.delivery.address = ottoOrd.delivery.address;
            this.order.delivery.city = ottoOrd.delivery.city;
            this.order.delivery.postCode = ottoOrd.delivery.postCode;
            this.order.delivery.currency = ottoOrd.currency;
            this.order.delivery.price = ottoOrd.delivery.price;

            let foundCourier = false;
            for(let i=0; i< this.couriers.length; i++)
            {
                let currentCourier = this.couriers[i];
                if(currentCourier.name === ottoOrd.delivery.method)
                {
                    foundCourier = true;
                    this.order.delivery.method = ottoOrd.delivery.method;
                }
            }

            if(!foundCourier)
            {
                this.couriers.push({
                    cost: ottoOrd.delivery.price === "" ? 0 : parseFloat(ottoOrd.delivery.price) ,
                    currency: ottoOrd.currency,
                    name: ottoOrd.delivery.method.length > 0 ? ottoOrd.delivery.method : "Dostawa (Automatyczna)"
                })
                this.order.delivery.method = ottoOrd.delivery.method.length > 0 ? ottoOrd.delivery.method : "Dostawa (Automatyczna)";
            }

            for(let i=0; i<this.countries.length; i++)
            {
                let currentCountry = this.countries[i];
                if(currentCountry.countryCode === ottoOrd.delivery.countryCode)
                {
                    this.deliveryChosenCountry = currentCountry;
                }
            }

            // Invoice
            this.order.invoice.needed = ottoOrd.invoice.needed;
            if(this.order.invoice.needed)
            {
                this.order.invoice.fullname = ottoOrd.invoice.fullname;
                this.order.invoice.company = ottoOrd.invoice.company;
                this.order.invoice.taxId = ottoOrd.invoice.taxId;
                this.order.invoice.address = ottoOrd.invoice.address;
                this.order.invoice.postCode = ottoOrd.invoice.postCode;
                this.order.invoice.city = ottoOrd.invoice.city;
                if(ottoOrd.invoice.countryCode.length>0)
                {
                    this.order.invoice.countryCode  = ottoOrd.invoice.countryCode;
                }
            }
            
            // Positions
            for(let pId in ottoOrd.positions)
            {
                let currentPosition = ottoOrd.positions[pId];
                if(currentPosition.externalSourceProductData.product.shopUrl.toString().length>0)
                {
                    let url = currentPosition.externalSourceProductData.product.shopUrl;
                    let num = url.lastIndexOf('/');
                    let withoutVariant = url.slice(0, num);

                    let variantNum = url.lastIndexOf('=');
                    let ottoVariantId = url.slice(variantNum+1, url.length);
                    this.createdOttoVariantId = ottoVariantId;

                    let prod_num = withoutVariant.lastIndexOf('/');
                    let prod_id = withoutVariant.slice(prod_num+1);

                    let res = await db.collection("Products").where("integratorIds.otto", "==", prod_id).get();
                    if(!res.empty)
                    {
                        res.docs.forEach(doc => {
                            let product = doc.data();
                            if(currentPosition.externalSourceProductData.product.articleNumber.length>0)
                            {
                                for(let vId in product.variants)
                                {
                                    let variant = product.variants[vId];
                                    if(variant.integratorIds != null && variant.integratorIds.otto != null && variant.integratorIds.otto.length > 0)
                                    {
                                        if(variant.integratorIds.otto.length === ottoVariantId.length)
                                        {
                                            if(variant.integratorIds.otto === ottoVariantId)
                                            {
                                                this.createPositionFromOttoOrder(variant,product,currentPosition)
                                                break;
                                            };
                                        };
                                    }
                                }                        
                            }
                        });
                    }
                }
            }

            // Payments
            this.order.payment.done = parseFloat(ottoOrd.payment.done) > 0 ? true : false;
            if(this.order.payment.done)
            {
                this.order.payment.comment = `Wpłata ${parseFloat(ottoOrd.payment.done)} ${ottoOrd.currency}`
            }
            let foundPayment = false;
            for(let i=0; i<this.paymentValues.paymentNames.length;i++)
            {
                if(this.paymentValues.paymentNames[i] == ottoOrd.payment.method)
                {
                    foundPayment = true;
                    this.order.payment.method = this.paymentValues.paymentNames[i];
                    this.order.payment.cashOnDelivery = ottoOrd.payment.cashOnDelivery;
                }
            }
            if(!foundPayment)
            {
                // this.paymentValues.paymentNames.push(ottoOrd.payment.method);
                this.order.payment.method = 'Inny';
                this.order.payment.cashOnDelivery = false;
            }

            this.loading = false;
        },
        async fillFieldsWithApiloData(id)
        {
            try {
                this.loading = true;
                this.loadingOrder = true;
                
                const res = await axios.post(`${this.$store.state.herokuApiLink}/marketplace/apilo/${this.$store.state.herokuApiPath}/order/get`, {
                    id: id,
                }, {
                    headers: {
                        'x-api-jwt-token': this.$store.state.userData.jwt,
                        'x-api-key': process.env.VUE_APP_APIKEY,
                    },
                });
                
                if(!res.data.success)
                {
                    this.$router.push({ path: '/dashboard/awaiting'})
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Błąd pobierania zamówienia.",
                        subheader: `Odczekaj parę sekund i spróbuj ponownie..`,
                        success: false
                    });
                }
                else
                {
                    this.decodedObj = res.data.payload.order;
                    const aplOrd = res.data.payload.order;
               
                    // Client
                    this.order.client.name = aplOrd.delivery.fullname.length > 0 ? aplOrd.delivery.fullname : aplOrd.delivery.company.length > 0 ? aplOrd.delivery.company : ""
                    this.order.client.company = aplOrd.delivery.companty != null ? aplOrd.delivery.company.length > 0 ? aplOrd.delivery.company : aplOrd.invoice.company.length > 0 ? aplOrd.invoice.company : "" : '';
                    this.order.client.email = aplOrd.contact.email;
                    this.order.origin = aplOrd;
                    this.order.originName = "Apilo";
        
                    if(aplOrd.contact.phone != null && aplOrd.contact.phone.length > 0)
                    {
                        for(let i=0;i<this.phonePrefixes.length;i++)
                        {
                            let cPref = this.phonePrefixes[i];
                            if(aplOrd.contact.phone.includes(cPref.prefix))
                            {
                                this.order.client.phone.prefix = cPref.prefix;
                                let numberToSplit = aplOrd.contact.phone;
                                let splitted = numberToSplit.split(cPref.prefix);
                                if(splitted.length === 2)
                                {
                                    this.order.client.phone.number = splitted[1];
                                    this.order.client.phone.full = aplOrd.contact.phone;
                                }
                                else if(splitted.length === 1)
                                {
                                    this.order.client.phone.number = splitted[0];
                                    this.order.client.phone.full = aplOrd.contact.phone;
                                }
                                else
                                {
                                    this.order.client.phone.number = aplOrd.contact.phone;
                                    this.order.client.phone.full = aplOrd.contact.phone;
                                }
                            }
                            else
                            {
                                if(cPref.ref.countryCode === aplOrd.delivery.countryCode)
                                {
                                    this.order.client.phone.prefix = cPref.prefix;
                                    this.order.client.phone.number = aplOrd.contact.phone;
                                    this.order.client.phone.full = `${cPref.prefix} ${aplOrd.contact.phone}`;
                                }
                            }
                        }
                    }
                    else
                    {
                        for(let i=0;i<this.phonePrefixes.length;i++)
                        {
                            let cPref = this.phonePrefixes[i];
                            if(cPref.ref.countryCode === aplOrd.delivery.countryCode)
                            {
                                this.order.client.phone.prefix = cPref.prefix;
                                this.order.client.phone.number = "000000000";
                                this.order.client.phone.full = "000000000";
                            }
                        }                    
                    }
        
                    // Delivery
                    this.order.delivery.name = aplOrd.delivery.fullname.length > 0 ? aplOrd.delivery.fullname : aplOrd.delivery.company.length > 0 ? aplOrd.delivery.company : ""
                    this.order.delivery.company = aplOrd.delivery.company != null ? aplOrd.delivery.company.length > 0 ? aplOrd.delivery.company : aplOrd.invoice.company.length > 0 ? aplOrd.invoice.company : "" : '';
                    this.order.delivery.phone.prefix = this.order.client.phone.prefix;
                    this.order.delivery.phone.number = this.order.client.phone.number;
                    this.order.delivery.phone.full = this.order.client.phone.full;
                    this.order.delivery.address = aplOrd.delivery.address;
                    this.order.delivery.city = aplOrd.delivery.city;
                    this.order.delivery.postCode = aplOrd.delivery.postCode;
                    this.order.delivery.currency = aplOrd.currency;
                    this.order.delivery.price = aplOrd.delivery.price;
        
                    let foundCourier = false;
                    for(let i=0; i< this.couriers.length; i++)
                    {
                        let currentCourier = this.couriers[i];
                        if(currentCourier.name === aplOrd.delivery.method)
                        {
                            foundCourier = true;
                            this.order.delivery.method = aplOrd.delivery.method;
                        }
                    }
        
                    if(!foundCourier)
                    {
                        this.couriers.push({
                            cost: parseInt(aplOrd.delivery.price),
                            currency: aplOrd.currency,
                            name: aplOrd.delivery.method
                        })
                        this.order.delivery.method = aplOrd.delivery.method;
                    }
        
                    for(let i=0; i<this.countries.length; i++)
                    {
                        let currentCountry = this.countries[i];
                        if(currentCountry.countryCode === aplOrd.delivery.countryCode)
                        {
                            this.deliveryChosenCountry = currentCountry;
                        }
                    }
        
                    // Invoice
                    this.order.invoice.needed = aplOrd.invoice.needed;
                    if(this.order.invoice.needed)
                    {
                        this.order.invoice.fullname = aplOrd.invoice.fullname;
                        this.order.invoice.company = aplOrd.invoice.company;
                        this.order.invoice.taxId = aplOrd.invoice.taxId;
                        this.order.invoice.address = aplOrd.invoice.address;
                        this.order.invoice.postCode = aplOrd.invoice.postCode;
                        this.order.invoice.city = aplOrd.invoice.city;
                        if(aplOrd.invoice.countryCode.length>0)
                        {
                            this.order.invoice.countryCode  = aplOrd.invoice.countryCode;
                        }
                    }
        
                    // Positions
                    for(let pId in aplOrd.positions)
                    {
                        let currentApiloProduct = aplOrd.positions[pId];
                        if(currentApiloProduct.externalSourceProductData.originalCode.length>0)
                        {
                            let res = await db.collection("Products").where("integratorIds.apilo", "==", currentApiloProduct.externalSourceProductData.originalCode.toString()).get();
                            if(!res.empty)
                            {
                                res.docs.forEach(doc => {
                                    let product = doc.data();
                                    if(currentApiloProduct.product.id != null)
                                    {
                                        if(currentApiloProduct.product.id.length>0)
                                        {
                                            for(let vId in product.variants)
                                            {
                                                let variant = product.variants[vId];
                                                if(variant.integratorIds != null)
                                                {
                                                    if(variant.integratorIds.apilo === currentApiloProduct.externalSourceProductData.sku.toString())
                                                    {
                                                        this.createPositionFromApiloOrder(variant,product,currentApiloProduct)
                                                    }
                                                }
                                            }                        
                                        }
                                    }
                                });
                            }
                        }
                    }
        
                    // Payments
                    this.order.payment.done = parseFloat(aplOrd.payment.done) > 0 ? true : false;
                    if(this.order.payment.done)
                    {
                        this.order.payment.comment = `Wpłata ${parseFloat(aplOrd.payment.done)} ${aplOrd.currency}`
                    }
                    let foundPayment = false;
                    for(let i=0; i<this.paymentValues.paymentNames.length;i++)
                    {
                        if(this.paymentValues.paymentNames[i] == aplOrd.payment.method)
                        {
                            foundPayment = true;
                            this.order.payment.method = this.paymentValues.paymentNames[i];
                        }
                    }
                    if(!foundPayment)
                    {
                        this.paymentValues.paymentNames.push(aplOrd.payment.method);
                        this.order.payment.method = aplOrd.payment.method;
                    }
                    this.order.payment.cashOnDelivery = aplOrd.payment.cashOnDelivery;
        
                    this.loading = false;
                    this.loadingOrder = false;
                }
            } 
            catch (error) {
                console.log(error);    
            }
        },
        async fillWithBaselinkerData(id)
        {
            if(id === undefined) return;
            if(id.length === 0) return;
            try {
                let blOrd = null;
                const res = await axios.post(`${this.$store.state.apiLink}/getAwaitngOrdersFromBaselinker`, {whoAmI: this.$store.state.userData.id, specific: true, id: id})
                if(res.data.success === true)
                {
                    if(res.data.bufferOrders.length>0)
                    {
                        blOrd = res.data.bufferOrders[0];
                        this.blOrder = blOrd;
                        this.fillFieldsWithBLData(blOrd)
                    }
                    else
                    {
                        throw "Cannot GET BL Order"
                    }
                    
                }
                else
                {
                    throw "Cannot GET BL Order"
                }
            }
            catch(error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się znaleźć zamówienia dla tego ID w Baselinkerze.",
                    subheader: `Odczekaj parę sekund i spróbuj ponownie..`,
                    success: false
                });
                return;
            }
            

        },
        async generateOrderNum()
        {
            let yr = moment().year();
            let month = moment().month() + 1;
            let num = Date.now();
            let fullId = (yr.toString().substring(2,4))+month.toString()+num.toString();
            let res = await db.collection("Orders").doc(fullId).get();
            if(!res.exists)
            {
                return fullId;
            }
            else
            {
                return null;
            }
        },
        async lowerStock(positions)
        {
            for(let posId in positions)
            {
                let current = positions[posId].product;
                if(!this.editMode)
                {
                    await axios.post(`${this.$store.state.apiLink}/stock/quantity`,{
                        id: current.id,
                        variant: current.variantId,
                        quantity: current.quantity*(-1)
                    });
                }
                else
                {
                    if(positions[posId].wasOnLoad === undefined)
                    {
                        await axios.post(`${this.$store.state.apiLink}/stock/quantity`,{
                            id: current.id,
                            variant: current.variantId,
                            quantity: current.quantity*(-1)
                        });
                    }
                }
            }
        },
        async saveOrder()
        {
            try {

                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Dodawanie zamówienia..",
                    subheader: `Nie zamykaj tego okna.`,
                });

                if(this.$route.query.origin !== undefined && this.$route.query.id !== undefined)
                {
                    let funcitonalTracker = {};
                    const resultOfTrackerData = await db.collection(`AwaitingOrderDone_${this.$route.query.origin}`).get().catch((error)=>
                    {
                        this.isDoneTrackerDoc = null;
                    });

                    if(resultOfTrackerData.empty)
                    {
                        this.isDoneTrackerDoc = null;
                    }
                    else
                    {
                        resultOfTrackerData.docs.forEach(doc => {
                            let current = doc.data();
                            let everyDate = Object.entries(current);
                            everyDate.forEach(doc => {
                                funcitonalTracker[doc[0]] = doc[1]
                            });
                        });
                    }
                    if(funcitonalTracker[this.$route.query.id] !== undefined && funcitonalTracker[this.$route.query.id] === true)
                    {
                        this.isDoneTrackerDoc = funcitonalTracker;
                        this.$store.commit("resetGlobalLoader");
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "To zamówienie zostało już dodane.",
                            subheader: `Spróbuj ponownie z innym zamówieniem.`,
                            success: false
                        });
                        return;
                    }
                }
                let inUpdatingMode = this.editMode;
                if(!inUpdatingMode)
                {
                    let orderNum = null;
                    for(let i=0; i<5; i++)
                    {
                        if(orderNum === null)
                        {
                            orderNum =  await this.generateOrderNum();
                            if(orderNum !== null)
                            {
                                break;
                            }
                        }
                    }
                    if(orderNum === null)
                    {
                        this.$store.commit("resetGlobalLoader");
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie udało się znaleźć wolnego ID dla Twojego zamówienia.",
                            subheader: `Odczekaj parę sekund i spróbuj ponownie..`,
                            success: false
                        });  
                        return;
                    }
                    else
                    {
                        this.order.id = orderNum;
                        // Fill some data
                        this.order.client.phone.full = this.order.client.phone.prefix+this.order.client.phone.number;

                        let names = [], models = [], listOfManufacturers = [];
                        const product = Object.entries(this.order.positions)
                        for(let i=0; i<product.length; i++)
                        {
                            if(product[i][0])
                            {
                                if (typeof product[i][1].product.manufacturer === "string") {
                                    product[i][1].product.manufacturer.trim();
                                    if (!listOfManufacturers.includes(product[i][1].product.manufacturer)) {
                                        listOfManufacturers.push(product[i][1].product.manufacturer);
                                    }
                                } else {
                                    product[i][1].product.manufacturer.name.trim();
                                    if (!listOfManufacturers.includes(product[i][1].product.manufacturer.name)) {
                                        listOfManufacturers.push(product[i][1].product.manufacturer.name);
                                    }
                                }
                            }
                            names.push(product[i][1].product.name.trim());
                            models.push(product[i][1].product.model.trim())
                        };
                        this.order.manufacturers = listOfManufacturers;
                        this.order.names = names;
                        this.order.models = models;
                        
                        this.order.return = this.order.statusId === 403 ? true : false;
                        
                        const createOrder = await axios.post(`${this.$store.state.apiLink}/order/create`, {
                            order: this.order,
                            meta: this.$store.state.userData,
                            decodedObj: this.decodedObj,
                            query: this.$route.query,
                            blOrder: this.blOrder,
                            manualOrderId: this.manualOrderId
                        });
                        if(createOrder.data.success)
                        {
                            this.$store.commit("resetGlobalLoader");
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Dodano zamówienie.",
                                subheader: `ID: ${this.order.id}`,
                                success: true
                            });
                            if(this.$route.query.origin !== undefined && this.$route.query.id !== undefined)
                            {
                                this.$router.push("/dashboard/awaiting");
                            }
                            else
                            {
                                this.$router.push("/dashboard/orders/list");
                            }
                        }
                        else
                        {
                            throw 'Cannot add order';
                        }
                    }    
                }
                else
                {
                    //update
                    this.order.client.phone.full = this.order.client.phone.prefix+this.order.client.phone.number;;
                    for(let posId in this.order.positions)
                    {
                        let current = this.order.positions[posId];
                        if(current.wasOnLoad !== undefined)
                        {
                            delete current.wasOnLoad;
                        }
                    }
                    const changes = await this.getChanges()

                    let names = [],models = [], listOfManufacturers = [];
                    const product = Object.entries(this.order.positions)
                    for(let i=0; i<product.length; i++)
                    {
                        if(product[i][0])
                        {
                            if (typeof product[i][1].product.manufacturer === "string") {
                                product[i][1].product.manufacturer.trim();
                                if (!listOfManufacturers.includes(product[i][1].product.manufacturer)) {
                                    listOfManufacturers.push(product[i][1].product.manufacturer);
                                }
                            } else {
                                product[i][1].product.manufacturer.name.trim();
                                if (!listOfManufacturers.includes(product[i][1].product.manufacturer.name)) {
                                    listOfManufacturers.push(product[i][1].product.manufacturer.name);
                                }
                            }
                        }
                        names.push(product[i][1].product.name.trim());
                        models.push(product[i][1].product.model.trim())
                    };
                    this.order.manufacturers = listOfManufacturers;
                    this.order.names = names;
                    this.order.models = models;
                    
                    this.order.return = this.order.statusId === 403 ? true : false;
                    
                    // this.order.meta.lastModificationDate = moment().unix();

                    // let checkRouteForOrder = await db.collection("Routes").where("orderIds", "array-contains", this.order.id).get();
                    // if(!checkRouteForOrder.empty)
                    // {
                    //     this.$store.commit('setNotification',{
                    //         show: true,
                    //         head: 'Nie można edytować zamówienia.',
                    //         subheader: `Zamówienie jest już przypisane do trasy.`,
                    //         success: false
                    //     });
                    //     this.$store.commit("resetGlobalLoader");
                    //     return
                    // } 

                    if(this.oldOrderStatusId !== 1) {
                        const updateOrder = await axios.post(`${this.$store.state.apiLink}/edit/order`, {
                            order: this.order,
                            oldOrderStatusId: this.oldOrderStatusId
                        })
                        if(!updateOrder.data.success)
                        {
                            throw updateOrder.data.log
                        }
                    } else {
                        await db.collection("Orders").doc(this.order.id).set(this.order);
                    };
                    
                    await this.$store.dispatch('updateHistory', {
                        collectionName: "Orders",
                        docId: this.order.id,
                        message: "Edytowano zamówienie.",
                        details: changes
                    }) 

                    let document = {
                        id: this.order.id,
                        externalId: this.blOrder !== null && this.blOrder.meta.external.ids.externalOrderId !== undefined && this.blOrder.meta.external.ids.externalOrderId.length > 0 ? this.blOrder.meta.external.ids.externalOrderId : 
                        this.decodedObj !== null && this.$route.query.origin === 'bol' && this.decodedObj.meta.external.ids.bolOrderId !== undefined && this.decodedObj.meta.external.ids.bolOrderId.length > 0 ? this.decodedObj.meta.external.ids.bolOrderId : 
                        this.decodedObj !== null && this.$route.query.origin === 'wayfair' && this.decodedObj.meta.external.ids.wayfairOrderId !== undefined && this.decodedObj.meta.external.ids.wayfairOrderId.length > 0 ? this.decodedObj.meta.external.ids.wayfairOrderId : 
                        this.decodedObj !== null && this.$route.query.origin === 'hood' && this.decodedObj.meta.external.ids.hoodOrderId !== undefined && this.decodedObj.meta.external.ids.hoodOrderId.length > 0 ? this.decodedObj.meta.external.ids.hoodOrderId : "",
                        client: {
                            name: this.order.client.name,
                            company: this.order.client.company,
                            email: this.order.client.email,
                            phone: this.order.client.phone.full
                        },
                        delivery: {
                            name: this.order.delivery.name,
                            company: this.order.delivery.company,
                            phone: this.order.delivery.phone.full,
                            postCode: this.order.delivery.postCode
                        },
                        invoice: {
                            fullname: this.order.invoice.fullname,
                            company: this.order.invoice.company,
                            taxId: this.order.invoice.taxId
                        },
                        names: names,
                        models: models,
                    };

                    await axios.post(`${this.$store.state.apiLink}/elastic/update`, {
                            index: "jan-art-orders",
                            elasticId: this.order.elasticSearch.docId,
                            document
                    })

                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Uaktualniono zamówienie.",
                        subheader: `ID: ${this.order.id}`,
                        success: true
                    });
                    this.$router.push("/dashboard/orders/list");           
                }
            }
            catch(error)
            {
                console.log(error);
                this.$store.commit("resetGlobalLoader");
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Błąd podczas zapisywania zamówienia.",
                    subheader: `Coś poszło nie tak w trakcie połączenia..`,
                    success: false
                });
                this.$router.push("/dashboard/orders");
            }

        },
        async getOrderStatuses()
        {
            this.orderStatuses = [];
            let res = await db.collection("OrderStatuses").doc("StatusList").get();
            if(res.exists)
            {
                this.orderStatuses = res.data().list;
            }
        },
        getOrderStatusName(statusId) {
            let statusName = "Złożone"
            for(let i = 0; i < this.orderStatuses.length; i++) {
                let orderStatus = this.orderStatuses[i]
                if(orderStatus.id === statusId) {
                    statusName = orderStatus.name
                }
            }
            return statusName
        },
        clearPaymentMethod()
        {
            this.order.payment.method = "";
        },
        copyToInvoiceFromClient()
        {
            this.order.invoice.fullname = this.order.client.name;
            this.order.invoice.company = this.order.client.company;
            this.order.invoice.address = this.order.delivery.address;
            this.order.invoice.city = this.order.delivery.city;
            this.order.invoice.postCode = this.order.delivery.postCode;
            this.order.invoice.countryCode = this.deliveryChosenCountry.countryCode;
        },
        clearInvoice()
        {
            if(!this.order.invoice.needed)
            {
                this.order.invoice.fullname = "";
                this.order.invoice.company = "";
                this.order.invoice.taxId = "";
                this.order.invoice.address = "";
                this.order.invoice.city = "";
                this.order.invoice.postCode = "";
                this.order.invoice.countryCode = "";
            }
        },
        getActualProductsToFinder()
        {
            // Locking products in finder
            let arr = [];
            for(const id in this.order.positions)
            {
                let current = this.order.positions[id];
                if(this.editMode)
                {
                    if(current.wasOnLoad === undefined)
                    {
                        arr.push(current.product.variantId);
                    }
                }
                else
                {
                    arr.push(current.product.variantId)
                }
                
            }
            return arr;
        },
        correctPositionValue(ref)
        {
            if(typeof ref.pricePerItemTaxIncluded !== "number")
            {
                ref.pricePerItemTaxIncluded = 0;
            }
            if(ref.pricePerItemTaxIncluded < 0)
            {
                ref.pricePerItemTaxIncluded = 0;
            }
        },
        translateToAnotherCurrency(event,oldCurrency,refToAmountInput,posRef)
        {
            if(this.EURtoPLN === undefined) return;
            if(this.EURtoPLN === null) return;
            if(this.GBPtoPLN === undefined) return;
            if(this.GBPtoPLN === null) return;

            posRef.currency = event.target.value;
            let input = this.$refs[refToAmountInput][0];
            let newAmount = 0;

            // EURO
            if(oldCurrency === 'EUR' && posRef.currency === 'PLN')
            {
                newAmount  = input.value * this.EURtoPLN;
            }
            if(oldCurrency === 'PLN' && posRef.currency === 'EUR')
            {
                newAmount  = (input.value / this.EURtoPLN);
            }
            // British pount
            if(oldCurrency === 'GBP' && posRef.currency === 'PLN')
            {
                newAmount  = input.value * this.GBPtoPLN;
            }
            if(oldCurrency === 'PLN' && posRef.currency === 'GBP')
            {
                newAmount  = (input.value / this.GBPtoPLN);
            }

            newAmount = newAmount.toFixed(2);
            newAmount = parseFloat(newAmount);

            input.value = newAmount;
        },
        correctPositionsKeys()
        {
            let newPositions = {};
            let x = 0;
            for(const posKey in this.order.positions)
            {
                x = x+1;
                let current = this.order.positions[posKey];
                current.id = x;
                newPositions[x] = current;
            }
            this.order.positions = newPositions;
        },
        deletePosition(position)
        {
            if(this.oldOrderStatusId === 1) {
                const positions = this.arrOfPositions();
                for(let i=0;i<positions.length;i++)
                {
                    let current = positions[i];
                    if(current.id === position.id)
                    {
                        delete this.order.positions[position.id];
                    }
                }
                if(this.decodedObj != null)
                {
                    if(Object.keys(this.decodedObj.positions).length !== Object.keys(this.order.positions).length)
                    {
                        this.hideAssignButton = true;
                    }
                    else
                    {
                        this.hideAssignButton = false;
                    }
                }
                this.correctPositionsKeys();
            }
        },
        arrOfPositions()
        {
            let arr = [];
            for(let pos in this.order.positions)
            {
                arr.push(this.order.positions[pos])
            }
            return arr;
        },
        async getCurrencies()
        {
            const res = await axios.get("http://api.nbp.pl/api/exchangerates/rates/A/EUR/today/").catch((error)=>{
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "NBP: Brak bieżącego kursu dla euro!",
                    subheader: "Automatyczne przewalutowanie wyłączone.",
                    success: false
                });
            });
            if(res)
            {
                if(res.status === 200)
                {
                    if(res.data.rates === undefined) return;
                    if(res.data.rates.length === 0) return;
                    this.EURtoPLN = res.data.rates[0].mid;
                }
            }

            const res2 = await axios.get("http://api.nbp.pl/api/exchangerates/rates/A/GBP/today/").catch((error)=> 
                {
                    console.log(error)
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "NBP: Brak bieżącego kursu dla funta brytyjskiego!",
                        subheader: "Automatyczne przewalutowanie wyłączone.",
                        success: false
                    });
                }
            );
            if(res2)
            {
                if(res2.status === 200)
                {
                    if(res2.data.rates === undefined) return;
                    if(res2.data.rates.length === 0) return;
                    this.GBPtoPLN = res2.data.rates[0].mid;
                }
            }

        },
        m3calc(dimensions)
        {
            if(dimensions.height === 0 || dimensions.width === 0 || dimensions.length === 0)
            {
                return null
            }
            else
            {
                let volume = dimensions.width * dimensions.height * dimensions.length;
                volume = volume / 100000;
                volume = volume.toFixed(2);
                volume = parseFloat(volume);
                return volume;
            }
        },
        createPosition(product,variant)
        {
            let currentAmount = Object.keys(this.order.positions).length;
            this.order.positions[currentAmount+1] = {
                status: 1,
                production: null,
                packing: null,
                delivery: null,
                infos: {
                    onOrderCreation: ""
                },
                volume: this.m3calc(variant.dimensions),
                product: {
                    amountTakenFromStorage: !variant.selfProduction,
                    amountOfPackages: variant.amountOfPackages,
                    model: variant.model,
                    apiloId: variant.apiloId != null ? variant.apiloId : '',
                    baselinkerId: variant.baselinkerId != null ? variant.baselinkerId : '',
                    dimensions: variant.dimensions,
                    image: variant.image,
                    selfProduction: variant.selfProduction,
                    manufacturer: variant.manufacturer,
                    sku: variant.sku,
                    ean: variant.ean,
                    weight: variant.weight,
                    location: variant.storage.location,
                    quantity: 1,
                    taxRate: variant.pricesAndCosts.taxRate,
                    currency: variant.pricesAndCosts.currency,
                    pricePerItemTaxIncluded: variant.pricesAndCosts.sellPrice,
                    id: product.id,
                    ref: db.collection("Products").doc(product.id),
                    variantId: variant.id,
                    name: variant.name,
                    attributes: variant.attribs.list,
                    externalSourceProductData: null
                },
                id: currentAmount+1,
                meta: {
                    created: {
                        byUser: {
                            name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            id: this.$store.state.userData.id,
                            ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                        },
                        asText: moment().format("YYYY-MM-DD").toString(),
                        unix: moment().unix(),
                        date: moment().toDate(),
                        dayOfWeek: moment().day(),
                        dayNum: moment().date(),
                        monthNum: moment().month() + 1,
                        year: moment().year(),
                        weekNum: moment().week()
                    },
                    external: null,
                }
            }
            if(this.decodedObj != null)
            {
                if(Object.keys(this.decodedObj.positions).length === Object.keys(this.order.positions).length)
                {
                    this.hideAssignButton = false;
                }
                else
                {
                    this.hideAssignButton = true;
                }
            }
            this.$store.commit('setNotification',{
                show: true,
                head: "Dodano pozycję zamówienia.",
                subheader: `Pozycja została zapisana na liście.`,
                success: true
            });   
        },
        async addPosition(data)
        {
            let res = await db.collection("Products").doc(data.prodId).get();
            if(!res.empty)
            {
                let product = res.data();
                if(data.quantity > 0)
                {
                    for(let i=0; i < data.quantity ; i++)
                    {
                        this.createPosition(product,product.variants[data.variantId])
                    }
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wybrana ilość tego przedmiotu jest niedostępna!",
                        subheader: `Stan magazynowy: ${product.variants[data.variantId].quantity}, Dodawana ilość: ${data.quantity}`,
                        success: false
                    });                        
                }
            }
            
        },
        closeFinderModal()
        {
            this.modalController.productFinder = false;
        },
        sendBack()
        {
            this.step = this.step - 1;
            if(this.step < 0)
            {
                this.step = 1;
                this.progress = 0;
            }
            if(this.step === 1)
            {
                this.progress = 0;
            }
            if(this.step === 2)
            {
                this.progress = 30;
            }
            if(this.step === 3)
            {
                this.progress = 50;
            }
            if(this.step === 4)
            {
                this.progress = 70;
            }
            if(this.step === 5)
            {
                this.progress = 90;
            }
            
        },
        lengthTest(text)
        {
            if(text === undefined) return false;
            if(text === null) return false;
            if(text.length>0)
            {
                return true;
            }
            else
            {
                return false;
            }
        },
        setDeliveryPrice()
        {
            for(let i=0;i<this.couriers.length;i++)
            {
                if(this.couriers[i].name === this.order.delivery.method)
                {
                    this.order.delivery.price = parseFloat(this.couriers[i].cost);
                    this.order.delivery.currency = this.couriers[i].currency;
                }
            }
        },
        openGoogleMaps()
        {
            if(this.order.delivery.address.length === 0) return;
            if(this.order.delivery.city.length === 0) return;
            if(this.order.delivery.postCode.length === 0) return;
            window.open(`https://www.google.pl/maps/place/${encodeURIComponent(this.order.delivery.city)}+${encodeURIComponent(this.order.delivery.address)}+${encodeURIComponent(this.order.delivery.postCode)}`,'_blank')
        },
        async validateStep3()
        {
            if(Object.entries(this.order.positions).length === 0)
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Brak pozycji w zamówieniu!",
                    subheader: "Aby kontynuować, dodaj przynajmniej 1 pozycję!",
                    success: false
                });
                return;
            }
            this.quantityCheck.text = "Sprawdzanie dostępności stanów magazynowych...";
            this.quantityCheck.inProgress = true;

            // let arrOfVarIds = [];
            // let arrOfVarQtys = []

            // for(const posKey in this.order.positions)
            // {
            //     let currentPosition = this.order.positions[posKey];
            //     if(currentPosition.wasOnLoad === undefined)
            //     {
            //         if(!arrOfVarIds.includes(currentPosition.product.variantId))
            //         {
            //             arrOfVarIds.push(currentPosition.product.variantId);
            //             arrOfVarQtys.push({
            //                 variantId: currentPosition.product.variantId,
            //                 productId: currentPosition.product.id,
            //                 qty: 1
            //             })
            //         }
            //         else
            //         {
            //             for(let i=0; i<arrOfVarQtys.length; i++)
            //             {
            //                 if(arrOfVarQtys[i].variantId === currentPosition.product.variantId)
            //                 {
            //                     arrOfVarQtys[i].qty = arrOfVarQtys[i].qty+1;
            //                 }
            //             }
            //         }
            //     }
            // }

            // let allowed = [];
            // for(let i=0; i<arrOfVarQtys.length;i++)
            // {
            //     let testItem = arrOfVarQtys[i];
            //     const result = await db.collection("Products").doc(testItem.productId).get();
            //     if(result.exists)
            //     {
            //         let data = result.data();
            //         let variant = data.variants[testItem.variantId];
            //         if(variant.quantity >= testItem.qty)
            //         {
            //             allowed.push(testItem);
            //         }
            //     }
            // }
            // console.log(arrOfVarQtys.length)
            // if(allowed.length === arrOfVarQtys.length)
            // {
            //    

            // }
            // else
            // {
            //     this.$store.commit('setNotification',{
            //         show: true,
            //         head: "Brak stanów magazynowych.",
            //         subheader: "Niektóre przedmioty są aktualnie niedostępne. Sprawdź bądź uzupełnij stan magazynowy.",
            //         success: false
            //     });
            // }
                setTimeout(()=>
                {
                    this.quantityCheck.inProgress = false;
                    this.step = 4;
                    this.progress = 70;
                this.quantityCheck.inProgress = false;

                },1000)


            

        },
        validateStep1()
        {
            let amountOfErrors = 0;
            for(let error in this.errors.step1)
            {
                for(let key in this.errors.step1[error])
                this.errors.step1[error][key] = false;
            }

            
            let name = this.lengthTest(this.order.client.name);
            let deliveryName = this.lengthTest(this.order.delivery.name);
            let deliveryAddress = this.lengthTest(this.order.delivery.address);
            let deliveryCity = this.lengthTest(this.order.delivery.city);
            let deliveryPostCode = this.lengthTest(this.order.delivery.postCode);

            if(!name) {amountOfErrors = amountOfErrors+1; this.errors.step1.client.name = !name;}
            if(!deliveryName) {amountOfErrors = amountOfErrors+1; this.errors.step1.delivery.name = !deliveryName}
            if(!deliveryAddress) {amountOfErrors = amountOfErrors+1; this.errors.step1.delivery.address = !deliveryAddress}
            if(!deliveryCity) {amountOfErrors = amountOfErrors+1; this.errors.step1.delivery.city = !deliveryCity}
            if(!deliveryPostCode) {amountOfErrors = amountOfErrors+1; this.errors.step1.delivery.postCode = !deliveryPostCode}
        
            if(amountOfErrors === 0)
            {
                this.setDeliveryPrice();
                this.order.client.phone.full = `${this.order.client.phone.prefix}${this.order.client.phone.number}`
                this.order.delivery.phone.full = `${this.order.delivery.phone.prefix}${this.order.delivery.phone.number}`
                this.order.delivery.country.code = this.deliveryChosenCountry.countryCode;
                this.order.delivery.country.name = this.deliveryChosenCountry.name;

                this.step = 2;
                this.progress = 30;
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Formularz posiada błędy!",
                    subheader: "Popraw je, aby przejść dalej.",
                    success: false
                });
                if(this.$refs.Step1 !== undefined)
                {
                    this.$refs.Step1.scrollIntoView({behavior: "smooth"}); 
                }
            }
        
        },
        copyFromClientToDelivery()
        {
            this.order.delivery.name = this.order.client.name;
            this.order.delivery.company = this.order.client.company;
            this.order.delivery.phone.number = this.order.client.phone.number;
            this.order.delivery.phone.prefix = this.order.client.phone.prefix;
            this.setDeliveryByPhoneFromDelivery(this.order.delivery.phone.prefix);
        },
        setDeliveryByPhoneFromDelivery(prefix)
        {
            for(let i=0;i<this.countries.length;i++)
            {
                let country = this.countries[i];
                for(let y=0;y<country.phonePrefixes.length;y++)
                {
                    if(country.phonePrefixes[y] === prefix)
                    {
                        this.deliveryChosenCountry = country;
                        break;
                    }
                };
            }
        },
        setDeliveryByPhoneFromClient(prefix)
        {
            for(let i=0;i<this.countries.length;i++)
            {
                let country = this.countries[i];
                for(let y=0;y<country.phonePrefixes.length;y++)
                {
                    if(country.phonePrefixes[y] === prefix)
                    {
                        this.deliveryChosenCountry = country;
                        this.order.delivery.phone.prefix = prefix;
                        break;
                    }
                }
            }
        },
        async getCouriers()
        {
            this.loading = true;
            this.couriers = [];
            let res = await db.collection("DeliveryType").get();
            res.docs.forEach(doc => {
                this.couriers.push(doc.data())
            });
            this.order.delivery.method = this.couriers[0].name;
            this.loading = false;
        },
        async getCountries()
        {
            this.loading = true;
            this.countries = [];
            const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
            if(res.data.status === 'SUCCESS')
            {
                res.data.countries.forEach(country => {
                    this.countries.push(country);
                });
                
                this.countries.forEach(country => {
                    const prefix = country.phonePrefixes[0];
                    const name = country.translations.pol.common != null ? country.translations.pol.common : country.name;
                    
                    if(country.name === "Poland")
                    {
                        this.deliveryChosenCountry = country;
                    }
                    
                    // Check again to be sure
                    this.phonePrefixes.push({
                        prefix: prefix,
                        name: name,
                        ref: country
                    });

                });
            }
            this.loading = false;
        }
    },
    computed:
    {
    },
    watch:
    {
        'isDoneTrackerDoc': function(newVal,oldVal)
        {
            if(newVal !== null)
            {
                if(newVal[`${this.$route.query.id}`] !== undefined)
                {
                    this.step = 1;
                    this.$nextTick(() => {
                        const doneTrackerElement = this.$refs.doneTracker;
                        if(doneTrackerElement) {
                            doneTrackerElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    });
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

$width: 15px;
$height: 15px;
$bounce_height: 30px;



.text {
  color: #4f5aac;
  display: inline-block;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #4f5aac;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
</style>