<template>
  <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
      <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
          <li class="flex">
              <div class="flex items-center">
              <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                  <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                  <span class="sr-only">Home</span>
              </router-link>
              </div>
          </li>
          <li class="flex">
              <div class="flex items-center">
              <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
              </div>
          </li>
          <li class="flex">
              <div class="flex items-center">
              <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Skaner kodów QR</div>
              </div>
          </li>
      </ol>
  </nav>
  <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
      &larr; Cofnij
  </router-link>
  <div class="py-2">
    <div>
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8 select-none" aria-label="Tabs">
            <a @click.prevent="openTab(indx)" v-for="(tab, indx) in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">
              <component :is="tab.icon" :class="[tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
              <span>{{ tab.name }}</span>
            </a>
          </nav>
        </div>
      </div>
    </div>

    <div class="mx-auto max-w-3xl lg:max-w-7xl" v-if="this.tabs[0].current">
      <div class="flex">
        <div class="py-4">
          <h4 class="text-lg font-bold">Łączenie wariantów</h4>
          <p class="mt-1 mb-2">Panel łączący warianty umożliwia scalanie różnorodnych paczek wariantów w spójną całość.</p>
            <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <p class="text-sm text-yellow-700">
                    Połączenie paczek, wymieni brakujące nazwy, oraz zastąpieni obecne nazw paczek, przy użyciu informacji zawartych w pierwszej wybranej paczce.
                    <br/>
                    <span class="font-medium text-yellow-700 underline hover:text-yellow-600 select-none">Paczki bez nadanych nazw, również zostaną wymienione.</span>
                  </p>
                </div>
              </div>
            </div>

          <!-- <p class="mt-1 mb-2">Połączenie paczek, zastąpi nazwę wybranych paczek, na taką, która pierwsza została wybrana.</p> -->
        </div>
      </div>
      <LoadingSpinnerHub v-if="loading" />
      <h1 class="sr-only">Page title</h1>
      <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8" v-if="!loading">
        <!-- Left column -->
        <div class="grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <h2 class="sr-only" id="section-2-title">Section title</h2>
            <div class="overflow-hidden rounded-lg bg-white shadow">
              <div class="p-4">
                <!-- Your content -->
                <div class="overflow-y-scroll rounded-md border border-gray-300 bg-gray-50 max-h-112">
                  <ul role="list" class="space-y-3 m-1.5">
                    <li @click="selectProduct(item, $event)" v-for="item in products" :key="item.id" class="overflow-hidden shadow rounded-md bg-white hover:bg-gray-200 transition-all duration-300 cursor-pointer px-6 py-4 w-full flex justify-between">
                      <!-- Your content -->
                      <div class="flex min-w-0 gap-x-4 items-center">
                        <img class="h-8 w-8 flex-none bg-gray-50 mix-blend-multiply" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${item.name}`" alt="" />
                        <div class="min-w-0 flex-auto">
                          <p class="text-sm font-semibold leading-6 text-gray-900 w-44 truncate">{{item.name}}</p>
                          <p class="truncate text-xs leading-5 text-gray-500">
                            {{typeof item.manufacturer === 'object' ? item.manufacturer.name : item.manufacturer}}
                          </p>
                        </div>
                      </div>
                      <div class="items-center flex">
                        <input v-model="item.checked" type="checkbox" class="h-4 w-4 rounded border-gray-500 text-indigo-600 focus:ring-indigo-600" />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="flex justify-center mt-4">
                  <button @click.prevent="openConnectModal = true;" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Załaduj więcej
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!-- Right column -->
        <div class="grid grid-cols-1 gap-4 lg:col-span-2 overflow-hidden">
          <section aria-labelledby="section-1-title">
            <h2 class="sr-only" id="section-1-title">Section title</h2>
            <div class="overflow-hidden rounded-lg bg-white shadow">
              <div class="p-6">
                <!-- Your content -->
                <span class="uppercase text-gray-500 font-semibold text-sm">
                  {{product !== null ? `Produkt: ${product.name}, Producent: ${typeof product.manufacturer === 'object' ? product.manufacturer.name : product.manufacturer} ` : `BRAK WYBRANEGO PRODUKTU.`}}
                </span>
                <div class="overflow-y-scroll rounded-md border border-gray-300 bg-gray-50 mt-2 max-h-112" v-if="product !== null">
                  <ul role="list" class="divide-y divide-gray-300" v-for="(item, index) in product.variants" :key="index">
                    <li @click.prevent="item.parts !== undefined ? showItemParts(item, index) : ''" :class="['border-b border-gray-200 relative flex items-center space-x-3 px-6 py-4 hover:bg-gray-100 transition-all duration-300']">
                      <!-- Your content -->
                      <div class="flex-shrink-0">
                        <img class="h-10 w-10 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${item.name}`" alt="" />
                      </div>
                      <div class="min-w-0 flex-1">
                        <a href="#" class="focus:outline-none">
                          <span class="absolute inset-0" aria-hidden="true" />
                          <p class="text-sm font-medium text-gray-900">{{ item.name }}</p>
                          <p class="truncate text-sm text-gray-500">ID: {{ index }}</p>
                        </a>
                      </div>
                      <ChevronDownIcon v-if="item.parts !== undefined" :class="[item.checked ? 'transform rotate-180' : '', 'w-6 h-6 transition-all duration-300 text-gray-500']" />
                    <!-- <Transition name="fade2">
                    <CheckIcon v-if="item.checked" class="h-5 w-5 text-gray-600" />
                    </Transition> -->
                    </li>
                    <transition-group name="fade2">
                      <div v-if="item.checked">
                        <div v-for="(part, indx) in item.parts" :key="indx">
                          <ul @click="selectPartToConnect(item.id, part, indx)" class="bg-gray-200 divide-y divide-gray-200" role="list">
                            <li class="flex justify-between pl-6 pr-7 py-4">
                              <span class="uppercase text-gray-500 font-semibold">{{part.name}}</span>
                              <div class="items-center flex">
                                <input v-model="part.checked" type="checkbox" class="h-4 w-4 rounded border-gray-500 text-indigo-600 focus:ring-indigo-600" />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </transition-group>
                  </ul>
                </div>
                <EmptyState class="mt-2" v-if="product === null" />
              </div>
            </div>
          </section>
        </div>
      </div>
      <div v-if="!loading" class="flex justify-end mt-8">
        <button :disabled="Object.keys(items).length < 1" @click.prevent="connectVariants" type="button" :class="[Object.keys(items).length < 1 ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
          <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Połącz warianty
        </button>
      </div>
    </div>

    <div class="mt-8" v-if="this.tabs[1].current">
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden shadow border border-gray-300 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300" v-if="connectors.length > 0">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">ID Połączenia</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Połączone produkty</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Połączone warianty</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(connect, index) in connectors" :key="index">
                  <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-xs font-semibold text-gray-900">
                    {{connect.id}}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-900 font-semibold">
                    {{connect.product.name}}
                    <div class="flex items-center">
                      <span :class="['bg-blue-100 text-blue-800 mr-1 items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer truncate']" v-for="con in createConnectedProducts(connect).slice(0,1)" :key="con">{{con}}</span>
                      <span class="cursor-pointer" v-tooltip="`${createConnectedProducts(connect).slice(1).toString().replaceAll(',',' | ')}`" v-if="createConnectedProducts(connect).length - 1 > 0">+ {{createConnectedProducts(connect).length - 1}} więcej</span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-900 font-semibold">
                    <div class="flex items-center">
                      <span :class="['bg-blue-100 text-blue-800 mr-1 items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer truncate']" v-for="con in createConnectedVariants(connect).slice(0,1)" :key="con">{{con}}</span>
                      <span class="cursor-pointer" v-tooltip="`${createConnectedVariants(connect).slice(1).toString().replaceAll(',',' | ')}`" v-if="createConnectedVariants(connect).length - 1 > 0">+ {{createConnectedVariants(connect).length - 1}} więcej</span>
                    </div>
                  </td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <span @click="openDeleteModal(connect.id)" class="text-indigo-600 hover:text-indigo-900 select-none">
                      Zarządzaj
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <EmptyState v-if="connectors.length === 0" />
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <SelectProductModal v-if="openConnectModal" :products="products" :checkedProduct="product" @close="closeConnectModal" @accept="acceptConnectModal" :modalData="modalData" />
  <AlertModal v-if="deleteAsk" @accept="deleteConnectedProductVariants" :connector="test" :modalData="modalData" @closeModal="deleteAsk = false" />
</template>

<script>
import {db, firebase} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { QuestionMarkCircleIcon, HomeIcon, CheckIcon, CameraIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, EyeIcon, EyeOffIcon, QrcodeIcon, ExclamationIcon, XIcon, ExternalLinkIcon, ChevronDoubleRightIcon, RefreshIcon } from '@heroicons/vue/solid';
import { LogoutIcon, ViewGridAddIcon, ViewGridIcon } from '@heroicons/vue/outline';
import SelectProductModal from '../components/SelectProductModal.vue';
import EmptyState from '../components/EmptyState.vue';
import AlertModal from '../components/AlertModal.vue';

export default {
  components: {
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, AlertModal, RefreshIcon, EmptyState, SelectProductModal, QuestionMarkCircleIcon, LogoutIcon, ExternalLinkIcon, XIcon, ExclamationIcon, QrcodeIcon, LoadingSpinnerHub, EyeIcon, EyeOffIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, HomeIcon, CheckIcon, CameraIcon, ChevronDoubleRightIcon, ViewGridAddIcon, ViewGridIcon
  },
  data () {
    return {
      selected: '',
      test: '',
      loading: false,
      deleteAsk: false,
      modalData: {
        headerText: "Usuwanie połączenia",
        bodyText: "Czy na pewno chcesz usunąć połączenie? Po aktualizacji połączenia jego dane zostaną permamentnie usunięte.",
        acceptButtonText: "Usuń"
      },
      openConnectModal: false,
      product: null,
      productsToConnect: [],
      products: [],
      dbListener: null,
      query: null,
      queryLimit: 25,
      connectors: [],
      tabs: [
        { id: 0, name: 'Łączenie paczek', icon: ViewGridAddIcon, current: true },
        { id: 1, name: 'Lista połączeń', icon: ViewGridIcon, current: false },
      ],
      selectedTab: '',
      items: {},
      addedNum: 0
    }
  },
  async created() {
    for(let i=0; i<this.tabs.length; i++)
    {
      if(this.tabs[i].current)
      {
        this.selectedTab = this.tabs[i];
        break;
      }
    };
    moment.locale('pl')
    this.moment = moment;
    this.getAllProducts();
    // this.getConnectors();
  },
  methods: {
    openDeleteModal(val)
    {
      this.deleteAsk = true;
      this.test = val;
    },
    async deleteConnectedProductVariants() 
    {
      this.loading = true
      try {
          const connector = db.collection("ConnectedProductVariants").doc(this.test)
          connector.delete()
          //set a success notification
          this.$store.commit('setNotification', {
              show: true,
              head: "Połączenie usunięte.",
              subheader: `Połączenie zostało usunięty z listy.`,
              success: true
          });

          this.getConnectors();
          this.loading = false
      } catch (error) {
          //set a fail notification
          this.$store.commit('setNotification', {
              show: true,
              head: "Nie udało się usunąć Połączenia.",
              subheader: `Nie udało się usunąć połączenia z listy.`,
              success: false
          });

          console.log(error);
          this.loading = false
      }
    },
    openTab(indx)
    {
      for(let i=0;i<this.tabs.length; i++)
      {
        this.tabs[i].current = false
      }
      this.tabs[indx].current = true
      if(indx === 0)
      {
        this.getAllProducts();
      }
      else if(indx === 1)
      {
        this.getConnectors();
      }
    },
    selectPartToConnect(prod, part, index)
    { 
      let str = prod;
      let n = str.lastIndexOf('_');
      let product = str.substring(n+1)

      if(part.checked === undefined || !part.checked)
      {
        this.addedNum++;
        part.checked = true;
        if(this.items[product] !== undefined)
        {
          if(this.items[product][prod] !== undefined)
          {
            let found = false;
            for(let i=0;i<this.items[product][prod].length; i++)
            {
              if(this.items[product][prod][i].name == part.name)
              {
                found = true;
                break;
              }
            }
            if(!found)
            {
              this.items[product][prod].push({
                name: part.name,
                quantity: part.quantity,
                numOfPackage: index,
                num: this.addedNum
              });
              part.checked = true;
            };
          }
          else
          {
            this.items[product][prod] = [{
              name: part.name,
              quantity: part.quantity,
              numOfPackage: index,
              num: this.addedNum
            }]
          }
        }
        else
        {
          this.items[product] = {};
          this.items[product][prod] = [{
            name: part.name,
            quantity: part.quantity,
            numOfPackage: index,
            num: this.addedNum
          }]
          part.checked = true;
        }
      }
      else
      {
        for(let i=0; i<this.items[product][prod].length; i++)
        {
          if(this.items[product][prod][i].name === part.name)
          {
            this.items[product][prod].splice(i, 1);
            part.checked = false;
            this.addedNum--;
          }
        }
      }
      if(this.items[product][prod].length === 0)
      {
        delete this.items[product][prod];
      }
      if(this.items[product][prod] === undefined)
      {
        if(Object.keys(this.items[product]).length === 0)
        {
          delete this.items[product];
        }
      }
    },
    showItemParts(item, index)
    {
      if(item.checked)
      {
        item.checked = false;
      }
      else
      {
        item.checked = true;
      }
    },
    selectProduct(prod, event)
    {
      if(prod.checked)
      {
        prod.checked = false;
        this.product = null;
      }
      else
      {
        if(this.product !== null)
        {
          this.product.checked = false;
          this.product = null;
        }
        prod.checked = true;
        this.product = prod;
        let variants = Object.entries(this.product.variants);
        for(let i=0; i<variants.length; i++)
        {
          if(variants[i][1].parts === undefined)
          {
            for(let y=0; y<variants[i][1].amountOfPackages; y++)
            {
              if(variants[i][1].parts === undefined)
              {
                variants[i][1].parts = {};
                variants[i][1].parts[y+1] = {
                  name: '--- (BRAK UTWORZONEJ NAZWY)',
                  quantity: 0
                };
              }
            }
          }
          variants[i][1].checked = false;
        }
      }
    },
    acceptConnectModal(val)
    {
      if(val !== null)
      {
        this.product = val;
        const variants = Object.entries(this.product.variants);
        for(let i=0; i<variants.length; i++)
        {
          variants[i][1].checked = false;
        }
        for(let i=0; i<this.products.length; i++)
        {
          if(this.products[i].id === this.product.id)
          {
            this.products[i].checked = true;
          }
          else
          {
            this.products[i].checked = false;
          }
        }
      }
      this.openConnectModal = false;
    },
    closeConnectModal()
    {
      this.openConnectModal = false;
    },
    async connectVariants()
    {
      try {
        const productsToConnect = Object.entries(this.items);
        let updateProductIds = [];
        let updateVariantIds = [];

        for(let i=0; i<productsToConnect.length; i++)
        {
          let testEntry = Object.entries(productsToConnect[i][1]);
          for(let y=0; y<testEntry.length; y++)
          {
            let str = testEntry[y][0];
            let f = str.indexOf('_');
            let strV = str.substring(f+1)
            let fV = strV.indexOf('_');
            let variant = strV.slice(0, fV);
            updateVariantIds.push(variant)
          }
          updateProductIds.push(productsToConnect[i][0])
        }

        let uniqueProductIds = updateProductIds.filter((value, index, array) => array.indexOf(value) === index);

        let updateObject = {
          meta: {
            created: {
              asText: moment().format("YYYY-MM-DD").toString(),
              unix: moment().unix(),
              date: moment().toDate(),
              dayOfWeek: moment().day(),
              dayNum: moment().date(),
              monthNum: moment().month() + 1,
              year: moment().year(),
              weekNum: moment().week()
            },
          },
          id: null,
          productIds: uniqueProductIds,
          variantIds: updateVariantIds,
          connector: this.items
        }
        const checkIfExists = await db.collection('ConnectedProductVariants').where('variantIds', 'array-contains-any', updateVariantIds).get();

        if(!checkIfExists.empty)
        {
          this.$store.commit('setNotification', {
            show: true,
            head: "Nie udało się utworzyć połączenia.",
            subheader: `Nie udało się utworzyć połączenia między wariantami.`,
            success: false
          });
        }
        else
        {
          let connectId = db.collection('ConnectedProductVariants').doc()
          updateObject.id = connectId.id;
          await db.collection('ConnectedProductVariants').doc(connectId.id).set(updateObject)

          let partName = null;
          const connectors = Object.entries(updateObject.connector)

          for(let i=0; i<connectors.length; i++)
          {
            const connectedVariants = Object.entries(connectors[i][1]);
            for(let y=0; y<connectedVariants.length; y++)
            {
              for(let x=0; x<connectedVariants[y][1].length; x++)
              {
                if(connectedVariants[y][1][x].num === 1)
                {
                  partName = connectedVariants[y][1][x].name;
                  break;
                }
              }
            }
          }

          let lowestNumber = null;
          let highestNumber = null;
          for(let i=0; i<connectors.length; i++)
          {
            const connectedVariants = Object.entries(connectors[i][1]);
            for(let y=0; y<connectedVariants.length; y++)
            {
              for(let x=0; x<connectedVariants[y][1].length; x++)
              {
                if(connectedVariants[y][1][x].quantity < lowestNumber || lowestNumber === null)
                {
                  lowestNumber = connectedVariants[y][1][x].quantity;
                }
                if(connectedVariants[y][1][x].quantity > lowestNumber || highestNumber === null)
                {
                  highestNumber = connectedVariants[y][1][x].quantity;
                }
              }
            }
          }

          for(let i=0; i<connectors.length; i++)
          {
            const productRef = db.collection('Products').doc(connectors[i][0])
            const product = (await productRef.get()).data()
            const connectedVariant = Object.entries(connectors[i][1]);
            for(let y=0; y<connectedVariant.length; y++)
            {
              for(let x=0; x<connectedVariant[y][1].length; x++)
              {
                if(product.variants[connectedVariant[y][0]])
                {
                  if(product.variants[connectedVariant[y][0]].parts !== undefined)
                  {
                    if(product.variants[connectedVariant[y][0]].parts[connectedVariant[y][1][x].numOfPackage].name !== partName)
                    {
                      await productRef.update({
                        [`variants.${connectedVariant[y][0]}.parts.${connectedVariant[y][1][x].numOfPackage}.quantity`]: firebase.firestore.FieldValue.increment(highestNumber)
                      })
                      // break;
                    }
                    else if(connectedVariant[y][1][x].num !== 1)
                    {
                      await productRef.update({
                        [`variants.${connectedVariant[y][0]}.parts.${connectedVariant[y][1][x].numOfPackage}.quantity`]: firebase.firestore.FieldValue.increment(highestNumber)
                      });
                    }
                  }
                  else
                  {
                    await productRef.update({
                      [`variants.${connectedVariant[y][0]}.parts.${connectedVariant[y][1][x].numOfPackage}`]: {
                        name: partName,
                        quantity: highestNumber
                      },
                    });
                  }
                  break;
                }
              }
            }
          }

          this.$store.commit('setNotification', {
            show: true,
            head: "Pomyślnie utworzono połączenie.",
            subheader: `Pomyślnie udało się utworzyć połączenie między wariantami.`,
            success: true
          });
        }
      }
      catch (error) {
        console.log(error.message);
      }
    },
    clearData()
    {
      this.query = null;
      this.dbListener = null;
      this.queryLimit = 25;
      this.queryLimitReached = false;
    },
    createConnectedVariants(val)
    {
      const variants = val.variantIds
      let translatedListOfProducts = [];
      variants.forEach(element => {
        translatedListOfProducts.push(element);
      })
      return translatedListOfProducts
    },
    createConnectedProducts(val)
    {
      const products = val.product
      let translatedListOfProducts = [];
      products.forEach(element => {
        translatedListOfProducts.push(element.name);
      })
      return translatedListOfProducts
    },
    async getConnectors()
    {
      try {
        this.loading = true;
        this.queryLimit = 25;
        this.connectors = [];
        let getConnectors = await db.collection('ConnectedProductVariants').limit(this.queryLimit).get();
        if(getConnectors.docs.length<this.queryLimit)
        {
          this.queryLimitReached = true;
        }
        else
        {
          this.queryLimitReached = false;
        }
        let products = [];
        for(let i=0; i<getConnectors.docs.length; i++)
        {
          let current = getConnectors.docs[i].data();
          for(let y=0; y<current.productIds.length; y++)
          {
            let productData = await db.collection('Products').doc(current.productIds[y]).get();
            products.push(productData.data())
          }
          current.product = products;
          this.connectors.push(current)
        }
        this.loading = false;
      }
      catch (error) {
        console.log(error);
      }
    },
    continueListening()
    {
      this.queryLimit = this.queryLimit + 10;
      this.getAllProducts()
    },
    getAllProducts()
    {
      this.loading = true;
      this.query = null;
      this.queryLimit = 25;
      if(this.dbListener !== null){
        this.dbListener()
        this.dbListener = null
      }
      this.query = db.collection("Products")
      this.query = this.query

      this.dbListener = this.query.limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.products = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
          this.queryLimitReached = true;
        }
        else
        {
          this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
          let current = doc.data()
          current.checked = false;
          this.products.push(current);
        });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      });
    },
  },
  beforeUnmount() {
    if(this.dbListener !== null)
    {
      this.dbListener();
    }
  },
  watch: {
  },
}
</script>
<style lang="scss" scoped>
.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}
</style>