<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
              <div>
                <div class="mt-4 flex flex-col pl-2 sm:pl-0 sm:pr-6 -mx-4 mr-0 sm:-mx-6 sm:-mr-12">
                      <div class="inline-block py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                        <div class="mb-2">
                            <h2 class="text-lg font-medium -mb-1">Zarządzanie kolejnością</h2>
                            <span class="text-xs">Aby zarządzać kolejnością na wydruku przeciągnij zamówienie w wybrane miejsce.</span>
                        </div>

                          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                              <draggable v-if="this.showPrintOptions" v-model="this.printQueue" class="divide-y w-full divide-gray-300">
                                          <template v-slot:item="{item}">
                                              <!-- example -->
                                              <div class="divide-y divide-gray-200 bg-white">
                                              <tr  :class="{'cursor-pointer transition ease-in-out duration-300 flex justify-between': true}">
                                              <td class="whitespace-nowrap w-1/12 py-4 pr-3 pl-4 text-xs sm:pl-6">
                                                  <div class="flex items-center">
                                                      <img v-if="item.countryFlagURL.length>0" :src="item.countryFlagURL" v-tooltip="`${item.delivery.country.code}`" class="h-4 w-6 border border-gray-300">
                                                      <span v-if="item.countryFlagURL.length === 0 && item.delivery.countryCode.length === 0">--</span>
                                                  </div>
                                              </td>
                                              <td class="whitespace-nowrap w-1/12 py-4 pr-3 pl-4 text-xs sm:pl-6">
                                                <div class="flex items-center">
                                                  <span v-if="item.delivery.postCode.length>0">{{ item.delivery.postCode }}</span>
                                                  <span v-if="item.delivery.postCode.length === 0 && item.delivery.postCode.length === 0">--</span>
                                                </div>
                                              </td>
                                              <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-900 font-semibold">
                                                <div class="flex items-center">
                                                  <div class="inline-flex w-56 truncate items-center">
                                                    <span v-tooltip="`Zobacz komentarze wewnętrzne`" :class="[item.statusId === 400 && createCommentList(item)[0].length > 0 ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800', 'mr-1 items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer truncate']" v-for="prod in createProductList(item).slice(0,1)" :key="prod">{{prod}}</span>
                                                    <span class="cursor-pointer" v-tooltip="`${createProductList(item).slice(1).toString().replaceAll(',',' | ')}`" v-if="createProductList(item).length - 1 > 0">+ {{createProductList(item).length - 1}} więcej</span>
                                                  </div>
                                                </div>
                                              </td>
                                              <td class="whitespace-nowrap w-2/12 py-4 pr-3 text-xs">
                                                <span class="mr-1 inline-flex items-center rounded-full py-0.5 text-xs font-medium" v-for="manu in getManufacturer(item).slice(0,1)" :key="manu">{{typeof manu === 'object' ? manu.name : manu}}</span>
                                                <template v-for="manu in getManufacturer(item).slice(1)" :key="manu">  
                                                  <span v-tooltip="`${manu}`" v-if="getManufacturer(item).length - 1 > 0">+ {{getManufacturer(item).length - 1}} więcej</span>
                                                </template>
                                              </td>
                                              <td class="whitespace-nowrap w-1/12 py-4 pr-3 text-xs mr-4">
                                                  <span v-if="item.delivery.date.suggested !== null">{{item.delivery.date.suggested.asText}}</span>
                                                  <span v-if="item.delivery.date.suggested === null" class="opacity-60">Nie wskazano</span>
                                              </td>
                                              </tr> 
                                              </div>
                                              <!-- or your own template -->
                                          </template>
                                      </draggable>
                            
                          </div>
                      </div>
              </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button @click.prevent="emitClose()" type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">Zapisz i wyjdź</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Draggable from "vue3-draggable";
// import { ExclamationIcon } from '@heroicons/vue/outline'
export default {
  data(){
      return {
          showPrintOptions: false,
          printQueue: []
      }
    },
  components: {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    Draggable
  },
  props: ['pickedToPrint'],
  created(){
    this.listenForPickedToPrint()
  },
  methods: {
    createProductList(order)
    {
        const products = Object.entries(order.positions)
        let translatedListOfProducts = [];
        products.forEach(element => {
            if(element[0])
            {
                translatedListOfProducts.push(element[1].product.name);
            }
        })
        return translatedListOfProducts
    },
    createCommentList(order)
    {
      const products = Object.entries(order.positions)
      let translatedListOfComments = [];
      products.forEach(element => {
        if(element[0])
        {
          translatedListOfComments.push(element[1].infos.onOrderCreation);
        }
      })

      return translatedListOfComments
    },
    // getManufacturer(order)
    // {
    //     const manufacturer = Object.entries(order.positions)
    //     let translatedListOfManufacturer = [];
    //     manufacturer.forEach(element => {
    //         if(element[0])
    //         {
    //             translatedListOfManufacturer.push(element[1].product.manufacturer);
    //         }
    //     })

    //     return translatedListOfManufacturer
    // },
    getManufacturer(order)
    {
      const manufacturer = Object.entries(order.positions)
      let translatedListOfManufacturer = [];
      manufacturer.forEach(element => {
        if(element[0])
        {
          if (typeof element[1].product.manufacturer === "string") {
            element[1].product.manufacturer.trim();
            if (!translatedListOfManufacturer.includes(element[1].product.manufacturer)) {
              translatedListOfManufacturer.push(element[1].product.manufacturer);
            }
          } else {
            element[1].product.manufacturer.name.trim();
            if (!translatedListOfManufacturer.includes(element[1].product.manufacturer.name)) {
              translatedListOfManufacturer.push(element[1].product.manufacturer.name);
            }
          }
        }
      });
      return translatedListOfManufacturer
    },
    listenForPickedToPrint(){
      if(this.pickedToPrint === undefined){
        setTimeout(() => {
          this.listenForPickedToPrint()
        }, 1000);
      }else{
        this.showPrintOptions = true
        // console.log(this.pickedToPrint)
        this.pickedToPrint.forEach(base => {
          let stringified = JSON.stringify(base)
          let readyToPush = JSON.parse(stringified)
          this.printQueue.push(readyToPush)
        });
      }
    },
    emitClose()
    {
        this.$emit("closeModal", this.printQueue);
    },
    emitAccept()
    {
        this.$emit("accept");
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    }
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
