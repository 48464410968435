<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">Zarządzaj pojazdem</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Zarządzaj pojazdem {{vehicle.brand}} {{vehicle.model}}.</p>
                    </div>
                  </div>

                  <div class="px-4 sm:px-6 lg:px-6 mt-5">
                    <div>
                      <label for="brand" class="block text-xs font-semibold text-gray-500 uppercase select-none">Marka pojazdu</label>
                      <div class="mt-1">
                        <input v-model="newVehicle.brand" type="text" name="brand" id="brand" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. OPEL" />
                      </div>
                      <div v-if="errors.brand" class="text-red-500 text-xs uppercase font-semibold mt-1">Marka pojazdu jest wymagana</div>
                    </div>
                    <div class="mt-3">
                      <label for="model" class="block text-xs font-semibold text-gray-500 uppercase select-none">Model pojazdu</label>
                      <div class="mt-1">
                        <input v-model="newVehicle.model" type="text" name="model" id="model" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. VIVARO" />
                      </div>
                      <div v-if="errors.model" class="text-red-500 text-xs uppercase font-semibold mt-1">Model pojazdu jest wymagany</div>
                    </div>
                    <div class="mt-3">
                      <label for="plate" class="block text-xs font-semibold text-gray-500 uppercase select-none">Tablica rejestracyjna</label>
                      <div class="mt-1">
                        <input v-model="newVehicle.plate" type="text" name="plate" id="plate" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. VIVARO" />
                      </div>
                      <span v-if="errors.model" class="text-red-500 text-xs uppercase font-semibold mt-1">Tablica rejestracyjna jest wymagana</span>
                    </div>
                  </div>

                  <div class="py-6">
                    <div class="relative">
                      <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                      </div>
                    </div>
                  </div>

                  <div class="px-4 sm:px-6 lg:px-6">
                    <div>
                      <label for="length" class="block text-xs font-semibold text-gray-500 uppercase select-none">Długość</label>
                      <div class="mt-1">
                        <div class="max-w-lg relative rounded-md shadow-sm">
                          <input type="number" @change="checkLength()" name="length" v-model="newVehicle.payload.dimensions.length" id="length" class="flex-1 block w-full py-2 pl-3 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                          </div>
                        </div>
                        <span v-if="errors.payload.dimensions.length" class="text-red-500 text-xs uppercase font-semibold mt-1">Długość jest wymagana</span>
                      </div>
                    </div>
                    <div class="mt-3">
                      <label for="height" class="block text-xs font-semibold text-gray-500 uppercase select-none">Wysokość</label>
                      <div class="mt-1">
                        <div class="max-w-lg relative rounded-md shadow-sm">
                          <input type="number" @change="checkHeight()" name="height" v-model="newVehicle.payload.dimensions.height" id="height" class="flex-1 block w-full py-2 pl-3 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                          </div>
                        </div>
                        <span v-if="errors.payload.dimensions.height" class="text-red-500 text-xs uppercase font-semibold mt-1">Wysokość jest wymagana</span>
                      </div>
                    </div>
                    <div class="mt-3">
                      <label for="width" class="block text-xs font-semibold text-gray-500 uppercase select-none">Szerokość</label>
                      <div class="mt-1">
                        <div class="max-w-lg relative rounded-md shadow-sm">
                          <input type="number" @change="checkWidth()" name="width" v-model="newVehicle.payload.dimensions.width" id="width" class="flex-1 block w-full py-2 pl-3 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                          </div>
                        </div>
                        <span v-if="errors.payload.dimensions.width" class="text-red-500 text-xs uppercase font-semibold mt-1">Szerokość jest wymagana</span>
                      </div>
                    </div>
                    <div class="mt-3">
                      <label class="block text-xs font-semibold text-gray-500 uppercase select-none">Pojemność strefy załadunku</label>
                      <div class="mt-1">
                        <div class="max-w-lg relative rounded-md shadow-sm">
                          <input disabled type="number" v-model="newVehicle.payload.capacity" id="dimension" class="opacity-40 pointer-events-none flex-1 block w-full py-2 pl-3 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency"> m³ </span>
                          </div>
                        </div>
                      </div>
                      <p v-if="!errors.payload.dimensions.width" class="mt-1 text-xxs text-gray-500 select-none">Pojemność wylicza się automatycznie, podczas zmiany wymiarów paczek.</p>
                      <span v-if="errors.payload.dimensions.width" class="text-red-500 text-xs uppercase font-semibold mt-1">Wymiary są wymagane do uzupełnienia</span>
                    </div>
                  </div>

                  <div class="py-6">
                    <div class="relative">
                      <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                      </div>
                    </div>
                  </div>

                  <div class="px-4 sm:px-6 lg:px-6 pb-6">
                    <div>
                      <label for="brand" class="block text-xs font-semibold text-gray-500 uppercase select-none">Status pojazdu</label>
                      <div class="flex reverse items-center mt-2">
                        <Switch v-model="newVehicle.active" :class="[newVehicle.active ? 'bg-green-600 focus:ring-green-600' : 'bg-red-500 focus:ring-red-600', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2']">
                          <span class="sr-only">Use setting</span>
                          <span :class="[newVehicle.active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                            <span :class="[newVehicle.active ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                              <svg class="h-3 w-3 text-red-400" fill="none" viewBox="0 0 12 12">
                                <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </span>
                            <span :class="[newVehicle.active ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                              <svg class="h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                              </svg>
                            </span>
                          </span>
                        </Switch>
                        <span class="text-xs uppercase text-gray-500 ml-2 items-center">{{newVehicle.active ? 'Aktywny' : 'Nieaktywny'}}</span>
                      </div>
                    </div>
                    <div class="my-3 font-medium text-red-500">Usuń pojazd</div>
                    <div class="flex items-center mt-1 mb-4">
                      <input v-model="deleteUserOnSave" id="delete-vehicle-checkbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500">
                      <label for="delete-vehicle-checkbox" class="text-xs ml-2 select-none text-">Chcę usunąć ten pojazd - wiem co robię.</label>
                    </div>
                  <!-- </div> -->
                  </div>

                  <!-- USUWANIE POJAZDU // WYŁACZONE Z POWODU WYWRACANIA SIE TABEL TRAS - brak zabezpieczeń -->
                  <!-- <div class="px-4 sm:px-6 mt-4"> -->

                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <div v-if="!saving">
                    <span class="text-xs text-gray-500" v-if="loading">Zapisywanie..</span>
                    <button @click.prevent="emitClose" v-if="!loading" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                    <button @click.prevent="validateUpdate" v-if="!deleteUserOnSave && !loading" class="ml-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Aktualizuj</button>
                    <button type="button" @click.prevent="deleteVehicle" v-if="deleteUserOnSave && !loading" class="ml-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń pojazd</button>
                  </div>    
                  <div v-else>
                    <p>Zapisywanie ...</p>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>

import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import EmptyState from './EmptyState.vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch } from '@headlessui/vue';
import { QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/vue/solid';
export default {
    name: "VehiclesModal",
    components: {
      Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, EmptyState, Switch, QuestionMarkCircleIcon, XCircleIcon
    },
    props: ['vehicle', 'saving'],
    data () {
      return {
        queryLimit:4,
        queryLimitReached:false,
        deleteUserOnSave: false,
        loading: false,
        open: false,
        newVehicle: {
          active: '',
          id: '',
          plate: '',
          payload: {
            dimensions: {
              length: '',
              width: '',
              height: ''
            },
            capacity: ''
          },
          brand: '',
          model: ''
        },
        errors: {
          brand: false,
          model: false,
          plate: false,
          payload: {
            dimensions: {
              length: false,
              width: false,
              height: false
            },
            capacity: false,
          }
        }
      }
    },
    methods: {
      checkHeight()
      {
        if(this.newVehicle.payload.dimensions.height.length === 0)
        {
            this.newVehicle.payload.dimensions.height = 0;
        }
        if(this.newVehicle.payload.dimensions.height < 0)
        {
            this.newVehicle.payload.dimensions.height = 0;
        }
        else
        {
            this.newVehicle.payload.dimensions.height = parseFloat(this.newVehicle.payload.dimensions.height);
        }
        this.newVehicle.payload.dimensions.height = parseFloat(this.newVehicle.payload.dimensions.height.toFixed(2));
        this.calculatePayloadCapacity();
      },
      checkWidth()
      {
        if(this.newVehicle.payload.dimensions.width.length === 0)
        {
            this.newVehicle.payload.dimensions.width = 0;
        }
        if(this.newVehicle.payload.dimensions.width < 0)
        {
            this.newVehicle.payload.dimensions.width = 0;
        }
        else
        {
            this.newVehicle.payload.dimensions.width = parseFloat(this.newVehicle.payload.dimensions.width);
        }
        this.newVehicle.payload.dimensions.width = parseFloat(this.newVehicle.payload.dimensions.width.toFixed(2));
        this.calculatePayloadCapacity();
      },
      checkLength()
      {
        if(this.newVehicle.payload.dimensions.length.length === 0)
        {
            this.newVehicle.payload.dimensions.length = 0;
        }
        if(this.newVehicle.payload.dimensions.length < 0)
        {
            this.newVehicle.payload.dimensions.length = 0;
        }
        else
        {
            this.newVehicle.payload.dimensions.length = parseFloat(this.newVehicle.payload.dimensions.length);
        }
        this.newVehicle.payload.dimensions.length = parseFloat(this.newVehicle.payload.dimensions.length.toFixed(2));
        this.calculatePayloadCapacity();
      },
      checkWeight()
      {
        if(this.newVehicle.payload.weight.amount.length === 0)
        {
          this.newVehicle.payload.weight.amount = 0;
        }
        if(this.newVehicle.payload.weight.amount < 0)
        {
          this.newVehicle.payload.weight.amount = 0;
        }
        else
        {
          this.newVehicle.payload.weight.amount = parseFloat(this.newVehicle.payload.weight.amount);
        }
        this.newVehicle.payload.weight.amount = parseFloat(this.newVehicle.payload.weight.amount.toFixed(2));
      },
      calculatePayloadCapacity()
      {
        let payloadCapacity = (this.newVehicle.payload.dimensions.height * this.newVehicle.payload.dimensions.width * this.newVehicle.payload.dimensions.length) / 1000000;
        this.newVehicle.payload.capacity = parseFloat(payloadCapacity.toFixed(2));
      },
      resetErrors()
      {
        this.errors.brand = false
        this.errors.model = false
        this.errors.payload = {
          dimensions: {
            length: false,
            width: false,
            height: false
          },
          capacity: false,
        }
        this.errors.plate = false
      },
      async validateUpdate()
      {
        const errors = this.vehicleValidator();
        if(errors > 0) return;
        await this.updateVehicle();
      },
      vehicleValidator()
      {
        let errorsAmount = 0;
        this.resetErrors();

        if(this.newVehicle.brand.length === 0)
        {
          this.errors.brand = true
          errorsAmount++;
        }
        if(this.newVehicle.model.length === 0)
        {
          this.errors.model = true
          errorsAmount++;
        }
        if(this.newVehicle.plate.length === 0)
        {
          this.errors.plate = true
          errorsAmount++;
        }
        if(parseInt(this.newVehicle.payload.dimensions.length) < 0)
        {
          this.errors.payload.dimensions.length = true;
          errorsAmount++;
        }
        if(parseInt(this.newVehicle.payload.dimensions.height) < 0)
        {
          this.errors.payload.dimensions.height = true;
          errorsAmount++;
        }
        if(parseInt(this.newVehicle.payload.dimensions.width) < 0)
        {
          this.errors.payload.dimensions.width = true;
          errorsAmount++;
        }
        if(parseInt(this.newVehicle.payload.capacity) < 0)
        {
          this.errors.payload.capacity = true;
          errorsAmount++;
        }

        return errorsAmount;
      },
      createVehicleUpdateObj()
      {
        this.newVehicle.id = this.vehicle.id;
        this.newVehicle.active = this.vehicle.active;
        this.newVehicle.plate = this.vehicle.attribs.licensePlate.value;
        this.newVehicle.payload.dimensions = this.vehicle.attribs.dimensions != null ? this.vehicle.attribs.dimensions : {length: 0, width: 0, height: 0};
        this.newVehicle.payload.capacity = this.vehicle.attribs.payloadCapacity.value;
        this.newVehicle.brand = this.vehicle.brand
        this.newVehicle.model = this.vehicle.model
        this.newVehicle.elasticSearch = this.vehicle.elasticSearch
      },
      async deleteVehicle()
      {
        if(this.vehicle.id === undefined || this.vehicle.id === null) return;
        if(this.vehicle.id.length === 0) return;
        this.loading = true;

        try {
          let now = moment();
          for(let i=0; i<this.vehicle.bookings.length; i++)
          {
            let bookingDate = moment.unix(this.vehicle.bookings[i].end.seconds);
            if(now.isBefore(bookingDate)) throw 'Pojazd jest zarezerwowany w tym terminie. Nie można go usunąć.';
          } 
          const res = await axios.post(`${this.$store.state.apiLink}/deleteVehicle`, {
            id: this.vehicle.id,
          });
          if(res.data.success)
          {
            this.emitClose();
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona sukcesem!",
              subheader: "Pojazd został usunięty.",
              success: true
            });
            this.loading = false;                
          }
          else
          {
            throw res.data.log;
          }       
        }
        catch(error)
        {
          this.emitClose();
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error,
            success: false
          });
          this.loading = false;              
        }
      },
      prepareElasticDocument(vehicle)
      {
        let document = {
          brands: [vehicle.brand],
          models: [vehicle.model],
          licensePlates: [vehicle.plate],
          id: vehicle.id,
        }

        return document;
      },
      async updateVehicle()
      {
        try {
          this.calculatePayloadCapacity();
          if(this.vehicle.id === undefined || this.vehicle.id === null) return;
          if(this.vehicle.id.length === 0) return;
          let newVehicle = this.newVehicle;

          const vehicleRef = await db.collection("Vehicles").doc(this.vehicle.id);
          //updating vehicle
          await vehicleRef.update({
            "active": newVehicle.active,
            "brand": newVehicle.brand,
            "model": newVehicle.model,
            "attribs.licensePlate.value": newVehicle.plate,
            "attribs.payloadCapacity.value": newVehicle.payload.capacity,
            "attribs.dimensions": newVehicle.payload.dimensions,
          })
          //updating elastic search
          if(newVehicle.elasticSearch.docId.length>0)
          {
            let document = this.prepareElasticDocument(newVehicle);
            await axios.post(`${this.$store.state.apiLink}/elastic/update`, {
              index: "jan-art-vehicles",
              elasticId:  newVehicle.elasticSearch.docId,
              document: document
            })
          };
          this.emitClose();
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona sukcesem!",
            subheader: 'Pojazd został zaktualizowany.',
            success: true
          });
        }
        catch (error) {
          console.log(error);
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error.message,
            success: false
          });
        }
      },
      callNotificationOnError(translatedCode)
      {
        this.emitClose();
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona niepowodzeniem!",
          subheader: translatedCode,
          success: false
        });
      },
      emitClose()
      {
        this.$emit("close");
      },
      emitAccept()
      {
        this.$emit("accept", data);
        this.$emit("emitClose");
      },
      close()
      {
        setTimeout(() => {
          this.emitClose();
          this.open = false
        }, 1500);
      }
    },
    created()
    {
      this.createVehicleUpdateObj()
    },
    watch: {
      'vehicle.attribs.dimensions.height': function (val) {
          this.checkHeight()
      },
      'vehicle.attribs.dimensions.width': function (val) {
          this.checkWidth()
      },
      'vehicle.attribs.dimensions.length': function (val) {
          this.checkLength()
      },
      'vehicle.attribs.weight.amount': function (val) {
          this.checkWeight()
      },
    },
};
</script>