<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Skaner kodów QR</div>
                </div>
            </li>
        </ol>
    </nav>
    <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
        &larr; Cofnij
    </router-link>

    <div class="py-2">
      <!-- camera error alert. -->
      <div v-if="error" class="border-l-4 border-red-400 bg-red-50 p-4 mb-4">
        <div class="flex">
          <div class="flex items-center">
            <ExclamationIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <p class="text-sm text-red-700">
              ERROR:
              {{ ' ' }}
              <a href="#" class="font-medium text-red-700 underline hover:text-red-600">{{errorLog}}</a>
            </p>
          </div>
        </div>
      </div>

      <!-- camera -->
      <div class="flex flex-col p-2 qr-container">
        <div :class="{'pt-4': error}">
          <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
            <qrcode-stream @detect="onDecode" @init="onInit">
              <div v-if="!error">
              <button v-if="!notification.success" @click="fullscreen = !fullscreen" :class="[fullscreen ? 'rotate-180' : '','filter fullscreen-button bg-white rounded-lg hover:text-gray-400 transition-all duration-300 transform -scale-x-100']">
                <ExternalLinkIcon class="w-6 h-6 bg-transparent" />
              </button>
              </div>
              <Transition name="fade">
              <div v-if="fullscreen && notification.show" :class="[notification.success ? 'bg-green-50' : 'bg-red-50', 'm-4 rounded-md p-4']">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <CheckCircleIcon :class="[notification.success ? 'text-green-400' : 'text-red-400', 'h-5 w-5']" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <h3 :class="[notification.success ? 'text-green-800' : 'text-red-800', 'text-sm font-medium']">{{ notification.head }}</h3>
                    <div :class="[notification.success ? ' text-green-700' : ' text-red-700', 'mt-1 text-sm']">
                      <p>{{ notification.subheader }}</p>
                    </div>
                  </div>
                  <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                      <button @click.prevent="notification.show = false;" type="button" :class="[notification.success ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50' : 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50', 'inline-flex rounded-md  p-1.5  focus:outline-none focus:ring-2focus:ring-offset-2']">
                        <span class="sr-only">Dismiss</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Transition>

              <Transition name="fade">
                <div class="relative z-50" v-if="segmentLoaded">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-50 w-screen shadow-lg">
                    <div class="pb-6 flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                        <div class="w-112 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                          <div :class="[Object.keys(this.products).length > 3 ? ' h-112 pr-3.5 overflow-y-scroll' : '', 'bg-white']" v-if="Object.keys(this.products).length > 0">
                            <div class="mx-auto max-w-7xl overflow-hidden">
                            <h2 class="sr-only">Products</h2>
                              <div class="-mx-px grid grid-cols-1">
                                <div v-for="(product, idx) in Object.keys(this.products)" :key="idx" class="bg-gray-100 group border-t relative border-b border-r border-gray-200 p-4 sm:p-6 rounded-lg shadow-md mb-2">
                                  <div class="px-2">
                                    <div class="flex items-center gap-1 select-none">
                                      <img class="w-10 h-10 bg-transparent mix-blend-multiply" :src="`${this.products[product].product.image.url.length > 0 ? this.products[product].product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`${this.products[product].product.name}`}`}`" alt="">
                                      <label class="uppercase block text-xs font-bold text-gray-600">{{this.products[product].product.name}}</label>
                                    </div>
                                    <div class="flex justify-between select-none uppercase text-xs font-bold mt-3">
                                    <span class="">Paczka</span>
                                    <span class="">Ilość</span>
                                    </div>
                                    <div class="flex flex-col-reverse py-1" v-for="(prts, indx) in this.products[product].parts" :key="indx">
                                      <div class="mt-1 select-none" v-for="(prt, index) in prts" :key="index">
                                        <div v-if="prt.name !== undefined" class="text-indigo-600 font-bold truncate text-sm">{{prt.name.length > 30 ? prt.name.slice(0, 35)+'...' : prt.name}}</div>
                                        <div class="flex justify-between text-sm" v-if="prt.part !== undefined && prt.part !== null">
                                          <div>{{prt.part}}</div>
                                          <div>{{prt.quantity}}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <LoadingSpinnerHub v-if="startLoader" />
                            <div v-if="!startLoader && Object.keys(this.products).length === 0" type="button" class="select-none relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                              <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-300" />
                              <span class="mt-2 block text-sm font-medium text-gray-400">W tym segmencie nie znajdują się żadne produkty...</span>
                            </div>
                          <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                            <button :disabled="isScanned" @click.prevent="segmentLoaded = false; this.products = {}"  type="button" :class="[isScanned ? 'opacity-20 pointer-events-none' : '','transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                              <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                              Anuluj
                            </button>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </Transition>

              <Transition name="fade">
                <div class="relative z-50" v-if="warehouseLoaded && !segmentLoading && step === 1">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-50 w-screen overflow-y-auto shadow-lg">
                    <div class="flex pb-6 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div :class="['w-112 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
                          <div>
                            <div v-if="!segmentLoading">
                              <h2 class="text-sm font-medium text-gray-500 select-none">Wybierz dokładny segment:</h2>
                                <ul role="list" :class="[segments.length > 5 ? 'h-96 overflow-y-scroll' : '', 'mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4']">
                                  <li v-for="(sgmnt, index) in segments" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                                    <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                                      <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${sgmnt.name}&scale=50`">
                                    </div>
                                    <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                      <div class="flex-1 truncate px-4 py-2 text-sm">
                                        <div class="flex flex-col">
                                          <span class="font-medium text-gray-900 hover:text-gray-600">{{sgmnt.name}}</span>
                                          <span class="font-medium text-gray-400 text-xs">Produktów w segmencie: {{Object.keys(sgmnt.products).length}}</span>
                                        </div>
                                      </div>
                                      <div class="flex-shrink-0 pr-4">
                                      <Transition name="fade">
                                        <div class="flex h-6 items-center">
                                          <input v-if="!sgmnt.blocked" v-model="sgmnt.selected" @click="selectSegmentLocation($event, sgmnt)" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                      </Transition>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                                  <button :disabled="isScanned" @click.prevent="changeStep(2)" type="button" :class="[isScanned ? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                    <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Przejdź dalej
                                  </button>
                               </div>
                            </div>
                            <LoadingSpinnerHub v-if="segmentLoading" />
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </Transition>

              <Transition name="fade">
                <div class="relative z-50" v-if="!loading && step === 2">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-50 w-screen shadow-lg">
                    <div class="pb-6 flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                        <div class="w-112 z-50 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                          <div :class="[Object.keys(this.products).length > 3 ? 'h-112 pr-3.5 overflow-y-scroll' : '', 'bg-white']">
                            <div class="mx-auto max-w-7xl overflow-hidden">
                            <h2 class="sr-only">Products</h2>
                              <div class="-mx-px grid grid-cols-1" >
                                <div v-for="(product, idx) in Object.keys(this.products)" :key="idx" class="bg-gray-100 group border-t relative border-b border-r border-gray-200 p-4 sm:p-6 rounded-lg shadow-md mb-2">
                                  <div class="px-2">
                                    <div class="flex items-center gap-1 select-none">
                                      <img class="w-10 h-10 bg-transparent mix-blend-multiply" :src="`${this.products[product].product.image.url}`" alt="">
                                      <label class="uppercase block text-xs font-bold text-gray-600">{{this.products[product].product.name}}</label>
                                    </div>
                                    <div class="py-1" v-for="(prts, indx) in this.products[product].parts" :key="indx">
                                      <div class="mt-1 select-none" v-for="(prt, index) in prts.reverse()" :key="index">
                                        <span v-if="prt.name !== undefined" class="text-indigo-600 font-bold truncate text-sm">{{prt.name.length > 30 ? prt.name.slice(0, 35)+'...' : prt.name}}</span>
                                        <div v-if="index === 1" class="flex justify-between select-none uppercase text-xs font-bold mb-2">
                                          <span class="">Paczka</span>
                                          <span class="">Ilość</span>
                                        </div>
                                        <div class="flex justify-between text-sm" v-if="prt.part !== undefined && prt.part !== null">
                                          <span>{{prt.part}}</span>
                                          <span>{{prt.quantity}}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <EmptyState v-if="Object.keys(this.products).length === 0" />
                              </div>
                            </div>
                          </div>

                          <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                            <button @click.prevent="windowReload()" type="button" :class="[isScanned ? 'opacity-20 pointer-events-none' : '','transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                              <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                              Anuluj
                            </button>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </Transition>

            </qrcode-stream>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import { QrcodeStream } from 'vue3-qrcode-reader'
import moment from 'moment';
import axios from 'axios';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { QuestionMarkCircleIcon, HomeIcon, CheckIcon, CameraIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, EyeIcon, EyeOffIcon, QrcodeIcon, ExclamationIcon, XIcon, ExternalLinkIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import { LogoutIcon, CubeTransparentIcon } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue';

export default {
  components: {
    QuestionMarkCircleIcon, LogoutIcon, ExternalLinkIcon, XIcon, ExclamationIcon, QrcodeIcon, LoadingSpinnerHub, EyeIcon, EyeOffIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, Listbox, CubeTransparentIcon, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, QrcodeStream, HomeIcon, CheckIcon, CameraIcon, ChevronDoubleRightIcon, EmptyState
  },
  data () {
    return {
      open: true,
      moment: moment,
      error: false,
      errorLog: '',
      loading: true,
      modalData: {
        accept: false,
        found: false,
        headerText: '',
        bodyText: '',
        order: null,
        num: null,
      },
      fullscreen: true,
      notification: {
        show: false,
        head: "",
        subheader: "",
        success: false,
      },
      resultDecode: null,
      isScanned: false,
      step: 1,
      storage: [],
      singleSelect: false,
      selected: {
        segment: null,
        warehouse: null,
        part: '',
        num: '',
        quantity: 1
      },
      segments: [],
      segmentLoading: false,
      products: {},
      segmentLoaded: false,
      warehouseLoaded: false,
      startLoader: false,
    }
  },
  methods: {
    selectSegmentLocation(event, val)
    {
      val.selected = event.target.checked;
      this.selected.segment = val;
      if(val.selected)
      {
        for(let i=0; i<this.segments.length; i++)
        {
          if(!this.segments[i].selected)
          {
            this.segments[i].blocked = true;
          }
        }
      }
      else
      {
        for(let i=0; i<this.segments.length; i++)
        {
          this.segments[i].blocked = false;
        }
      }
    },
    clearData()
    {
      this.segments = [];
      this.products = {};
    },
    async changeStep(val)
    {
      switch(val)
      {
        case 1:
          this.step = 1;
          break;
        case 2:
          this.step = 2;
          await this.getSegmentProducts()
      }
    },
    async getSegmentProducts()
    {
      try
      {
        this.loading = true;
        const res = await axios.post(`${this.$store.state.apiLink}/storage/location/get`, {
          location: {
            warehouse: {
              id: this.selected.warehouse.id,
            },
            segment: {
              id: this.selected.segment.id
            },
          }
        })
        if(!res.data.success)
        {
          this.loading = false;
          this.products = {};
        }
        else
        {
          this.products = res.data.formatedProducts;
          this.loading = false;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async onDecode(result)
    {
      try
      {
        let decodedResult = await result;
        if(decodedResult.content.length === 0) throw 'ERROR: The length of the result is equal to 0;';
        this.segments = [];
        this.segmentLoaded = false;
        this.warehouseLoaded = false;
        let warehouseId = '';
        let segmentId = '';
        let bracketNumber = null;
        this.resultDecode = decodedResult.content;

        let str = this.resultDecode;

        if(str.match(/{|}|\[|\]/g).length === 0) throw 'ERROR: Wrong QR Code'

        bracketNumber = str.match(/{|}|\[|\]/g).length;

        if(bracketNumber === 2)
        {
          this.segmentLoading = true;
          this.warehouseLoaded = true;
          let warehouseFirstIndex = str.indexOf('[');
          if(warehouseFirstIndex !== -1)
          {
            let warehouseSecondindex = str.indexOf(']');
            warehouseId = str.slice(warehouseFirstIndex+1, warehouseSecondindex) 
          }
          const warehouse = await db.collection('Storage').doc(warehouseId).collection('Segments').get()
          this.selected.warehouse = {
            id: parseInt(warehouseId)
          }
          warehouse.docs.forEach(doc => {
            this.segments.push(doc.data())
          });
          this.segmentLoading = false;
        }

        if(bracketNumber === 4)
        {
          this.products = {};
          this.startLoader = true;
          this.segmentLoaded = true;

          let warehouseFirstIndex = str.indexOf('[');
          if(warehouseFirstIndex !== -1)
          {
            let warehouseSecondindex = str.indexOf(']');
            warehouseId = str.slice(warehouseFirstIndex+1, warehouseSecondindex) 
          }
          let segmentFirstIndex = str.lastIndexOf('[');
          if(segmentFirstIndex !== -1)
          {
            let segmentSecondIndex = str.lastIndexOf(']');
            segmentId = str.slice(segmentFirstIndex+1, segmentSecondIndex) 
          }
          const res = await axios.post(`${this.$store.state.apiLink}/storage/location/get`, {
            location: {
              warehouse: {
                id: warehouseId,
              },
              segment: {
                id: segmentId
              },
            }
          })
          if(res.data.success)
          {
            this.startLoader = false;
            this.products = res.data.formatedProducts;
          }
          else
          {
            this.startLoader = false;
            this.products = {};
          }
        }
      }
      catch (error)
      {
        console.log(error);
      }
    },
    onFullscreenChange(event) {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement !== null
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen()
      }
    },
    requestFullscreen() {
      const elem = this.$refs.wrapper

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    async onInit (promise)
    {
      try
      {
        await promise
      }
      catch(err)
      {
        this.error = true;
        if(err.name === 'NotAllowedError')
        {
          this.errorLog = "Musisz przyznać uprawnienia dostępu do kamery."
        }
        else if (err.name === 'NotFoundError')
        {
          this.errorLog = "Brak kamery w tym urządzeniu."
        }
        else if (err.name === 'NotSupportedError')
        {
          this.errorLog = "Wymagany bezpieczny kontekst (HTTPS, localhost)."
        }
        else if (err.name === 'NotReadableError')
        {
          this.errorLog = "Czy kamera jest już w użyciu?"
        }
        else if (err.name === 'OverconstrainedError')
        {
          this.errorLog = "Zainstalowane kamery są nieodpowiednie."
        }
        else if (err.name === 'StreamApiNotSupportedError')
        {
          this.errorLog = "Stream API nie jest obsługiwany w tej przeglądarce."
        }
        else if (err.name === 'InsecureContextError')
        {
          this.errorLog = 'Dostęp do kamery jest dozwolony tylko w bezpiecznym kontekście. Należy używać protokołu HTTPS.';
        }
        else
        {
          this.errorLog = `(${err.name})`;
        }
      }
    },
  },
  watch: {
    fullscreen(enterFullscreen) {
      if (enterFullscreen) {
        this.requestFullscreen()
      } else {
        this.exitFullscreen()
      }
    },
    error(changed) {
      if(changed)
      {
        this.fullscreen = false;
      }
      else
      {
        this.fullscreen = true;
      }
    }
  },
  created()
  {
    moment.locale('pl')
  }
}
</script>
<style scoped>
.qr-container {
  background:
    linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fullscreen-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}
.fullscreen-button img {
  width: 2rem;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

</style>