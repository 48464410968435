<template>
    <div>
        <nav class="flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="ml-4 text-xs font-medium text-gray-400 cursor-default select-none" aria-current="page">Użytkownicy systemu</div>
                    </div>
                </li>
            </ol>
        </nav>
        <ModalManageUser :show="showManageModal" :managedUsr="pickedUsr" @closeClientModal="closeClientModal" @pickedWasDeleted="pickedWasDeleted" v-if="pickedUsr !== null"></ModalManageUser>
        <ModalNewUser :show="showAddModal" @closeClientModal="showAddModal = false"></ModalNewUser>
        <div class="min-w-0 flex-1 xl:col-span-6">
            <div class="flex items-center justify-between pt-2">
                <div class="w-1/3 flex">
                    <label for="search" class="sr-only">Szukaj</label>
                    <div class="relative">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                    </div>
                    <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['ml-2 inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">Szukaj</button>
                </div>
                <div>
                    <button type="button" @click.prevent="searcher.found.length > 0 ? showAddModal = false : showAddModal = true" :class="{'inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm': true, 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': this.searcher.found.length === 0, 'opacity-50 pointer-events-none select-none focus outline-none': this.searcher.found.length > 0}">Dodaj użytkownika</button>
                </div>
            </div>
            <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700">
                    Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                    <button @click.prevent="resetSearch"  type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>           
            </div>
        </div>
        <div class="mt-4 flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden shadow border border-gray-200 rounded-lg" v-if="!queryLoading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Imię Nazwisko</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">E-mail</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Stanowisko</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Uprawnienia</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                </th>
                                </tr>
                            </thead>

                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="user in searcher.found.length > 0 ? searcher.found : userList" :key="user.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                        <div class="inline-flex items-center text-xs w-24">
                                            <span v-tooltip="`ID: ${user.id}`" class="truncate">{{user.id}}</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                         <router-link target="_blank" class="cursor-pointer transition-all duration-300 hover:text-blue-500" :to="'/dashboard/user?id=' + user.id">{{user.name}} {{user.surname}}</router-link> <span class="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800 ml-2" v-if="user.permissions.admin">ADMIN</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                        <a :href="`mailto:${user.contact.email}`" alt="Napisz e-mail" class="truncate cursor-pointer transition-all duration-300 hover:text-blue-500 underline">{{user.contact.email}}</a>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                        <div>
                                            <a :href="`tel:${user.contact.phone}`" alt="Zadzwoń" class="cursor-pointer transition-all duration-300 hover:text-blue-500 underline">{{user.contact.phone}}</a>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 ">
                                        <div >
                                            {{user.position.length === 0 ? '---' : user.position}}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                        <span class="mr-1 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800" v-for="perm in createPermissionsList(user).slice(0,2)">{{perm}}</span>
                                        <span v-if="createPermissionsList(user).length - 2 > 0">+ {{createPermissionsList(user).length - 2}} więcej</span>
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                        <button type="button" @click.prevent="showManageModal = true, pickedUsr = user" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zarządzaj</button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        
                    </div>
                    <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false && searcher.found.length === 0">
                        <button type="button" @click.prevent="loadMoreUsers" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Załaduj więcej..</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { HomeIcon } from '@heroicons/vue/solid'
    import axios from 'axios';
    import ModalNewUser from '@/components/ModalNewUser.vue';
    import ModalManageUser from '@/components/ModalManageUser.vue';
    import {db} from "@/firebase/gfbconf.js"
    import { SearchIcon } from '@heroicons/vue/outline'
    export default {
        name: "ManageUsers",
        data()
        {
            return {
                searcher: {
                    found: [],
                    locked: false,
                    phrase: "",
                    lastCall: null
                },
                showManageModal: false,
                showAddModal: false,
                pickedUsr: null,
                queryLoading: false,
                queryLimitReached: false,
                dbListener: null,
                lastLoaded: null,
                userList: [],
                queryLimit: 25
            }
        },
        components: {SearchIcon,ModalNewUser,ModalManageUser,HomeIcon},
        async created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.users)
            {
                this.$router.push("/hub")
            }
            this.dbListener = db.collection("UsersData").orderBy("meta.created.unix",'desc').limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                this.userList = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                this.userList = []
                querySnapshot.forEach((doc) => {
                    this.userList.push(doc.data())
                    this.lastLoaded = doc.data().id;
                });
                this.queryLoading = false;
            });
        },
        methods:
        {
            pickedWasDeleted()
            {
                // In case when user is in searchmode
                const lookForId = this.pickedUsr.id;
                if(this.searcher.found.length>0)
                {
                    this.searcher.found.forEach((element,index) => {
                        if(element.id === lookForId)
                        {
                            this.searcher.found.splice(index,1);
                        }
                    });
                    if(this.searcher.found.length === 0)
                    {
                        this.resetSearch();                      
                    }
                }
                else
                {
                    return;
                }
            },
            resetSearch()
            {
                this.userList = [];
                this.searcher.found = [];
                this.searcher.lastCall = null;
                this.searcher.phrase = "";
                this.dbListener = db.collection("UsersData").orderBy("meta.created.unix",'desc').limit(this.queryLimit)
                .onSnapshot((querySnapshot) => {
                    this.queryLoading = true;
                    this.userList = [];
                    if(querySnapshot.docs.length<this.queryLimit)
                    {
                        this.queryLimitReached = true;
                    }
                    else
                    {
                        this.queryLimitReached = false;
                    }
                    this.userList = []
                    querySnapshot.forEach((doc) => {
                        this.userList.push(doc.data())
                        this.lastLoaded = doc.data().id;
                    });
                    this.queryLoading = false;
                });
            },
            async search()
            {
                if(this.searcher.phrase.length > 0)
                {
                    this.searcher.locked = true;
                    const res = await axios.post(`${this.$store.state.apiLink}/search`,{collection: "UsersData" ,phrase: this.searcher.phrase, index: 'jan-art-users', searchFields: ["fullname","phone","email","firebaseId"]})
                    if(res.data.success)
                    {
                        this.searcher.found = res.data.found;
                        if(res.data.found.length > 0)
                        {
                            this.searcher.lastCall = res.data.data;
                            this.dbListener();
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Wyszukiwanie zakończone!",
                                subheader: `Znaleziono ${res.data.found.length} użytkowników.`,
                                success: true
                            });

                        }else{
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Nie znaleziono wyników!",
                                subheader: `Spróbuj wyszukać inną frazę.`,
                                success: false
                            });
                        }

                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone niepowodzeniem!",
                            subheader: `Coś poszło nie tak...`,
                            success: false
                        });
                    }
                    setTimeout(()=>
                    {
                        // this.searcher.phrase = "";
                        this.searcher.locked = false;
                    }, 3000)
                }
                else
                {
                    return;
                }
            },
            createPermissionsList(usr)
            {
                const permissions = Object.entries(usr.permissions);
                let translatedListOfPermissions = [];
                permissions.forEach(element => {
                    if(element[1] === true)
                    {
                        translatedListOfPermissions.push(element[0].toUpperCase());
                    }
                })

                return translatedListOfPermissions
            },
            closeClientModal()
            {
                this.showManageModal = false;

                // Allow transition to end
                setTimeout(()=>
                {
                    this.pickedUsr = null
                },300)
            },
            loadMoreUsers()
            {
                this.dbListener();
                this.continueListening();  
            },
            continueListening()
            {
                this.queryLimit = this.queryLimit + 10;
                this.dbListener = db.collection("UsersData").orderBy("meta.created.unix",'desc').limit(this.queryLimit)
                .onSnapshot((querySnapshot) => {
                    this.queryLoading = true;
                    if(querySnapshot.docs.length<this.queryLimit)
                    {
                        this.queryLimitReached = true;
                    }
                    else
                    {
                        this.queryLimitReached = false;
                    }
                    this.userList = []
                    querySnapshot.forEach((doc) => {
                        this.userList.push(doc.data());
                        this.lastLoaded = doc.data().id;
                    });
                    this.queryLoading = false;
                });
            }
        },
        beforeUnmount() {
            if(this.dbListener !== null)
            {
                this.dbListener();
            }  
        }
    }
</script>
<style lang="scss" scoped>

</style>