<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-96 sm:w-full sm:max-w-lg sm:p-6">
            <div class="sm:flex sm:items-center pt-2 pb-4 select-none">
              <div :class="[iconColor !== undefined ? `bg-${iconColor}-100` : 'bg-blue-100']" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon :class="[iconColor !== undefined ? `text-${iconColor}-600` : 'text-blue-600']" class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div class="text-center items-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> {{modalData.headerText}} </DialogTitle>
              </div>
            </div>
            <div class="sm:flex justify-center items-center">
              <div class="mt-3 sm:mt-0">
                <VueDatePicker placeholder="Wybierz datę wyjazdu..." inline auto-apply @open="this.changeDatePicker = true" @closed="this.changeDatePicker = false" locale="pl" :enable-time-picker="false" v-model="this.date" range :min-date="new Date()" />
                <!-- <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> {{modalData.headerText}} </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">{{modalData.bodyText}}</p>
                </div> -->
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button :disabled="!validation" @click="emitAccept(this.date)" type="submit" :class="{'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm cursor-default text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 cursor-pointer': validation  }">{{modalData.acceptButtonText}}</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    VueDatePicker
  },
  data()
  {
    return {
      open: true,
      date: null,
    }
  },
  props: ['modalData', 'buttonColor', 'iconColor'],
  methods: {
    emitClose()
    {
        this.$emit("closeModal");
    },
    emitAccept(value)
    {
        this.$emit("accept", value);
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    validate()
    {
      if(!this.date || !this.date[0] || !this.date[1]) return false
      return true
    },
  },
  computed: {
    validation()
    {
      return this.validate()
    }
  }
}
</script>
