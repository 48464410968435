<template>
  <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-for="route in routes" :key="route.id">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                <span class="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">{{ findStatus(route.statusId)?.name }}</span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span class="font-semibold" v-tooltip="`Tablica rejestracyjna: ${route.vehicle.attribs.licensePlate.value} - Przestrzeń transportowa: ${route.vehicle.attribs.payloadCapacity.value} m3`">{{route.vehicle.brand}} {{route.vehicle.model}}</span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span class="text-xs">{{ moment.unix(route.date.start.seconds || route.date.start._seconds).format("LL") }}</span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span class="text-xs">{{ moment.unix(route.date.end.seconds || route.date.end._seconds).format("LL") }}</span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div class="flex items-center ml-5 lg:ml-6">
                    <span @click="ordersModal.orders = route.orders, ordersModal.route = route, ordersModal.open = true" class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-400">{{route.orders.length}}
                    </span>
                </div>
            </td>
            <td v-tooltip="`Drukuj`">
            <div @click="printLabel(route)" class="h-full cursor-pointer flex justify-center items-center">
                <PrinterIcon class="transition ease-in-out duration-300 h-5 w-5 text-gray-400 hover:text-gray-600 flex-shrink-0" aria-hidden="true"></PrinterIcon>
             </div>
             </td>
            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <button v-if="$route.path !== '/dashboard/routes/print'" @click="modal.route = route, modal.open = true" class="transition ease-in-out duration-300 -ml-px relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                    Zarządzaj
                    <span class="sr-only">, a</span>
                </button>
            </td>
            
        </tr>
        </tbody>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  components:{GoogleMap, Marker},
    data(){
        return{
            coordinates:{
                lat:0,
                lng:0
            }
        }
    },
    created(){
        this.$getLocation({})
        .then(coordinates => {
          this.coordinates = coordinates;
        }).catch(error => alert(error))
    }
}
</script>

