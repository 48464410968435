<template>
  <Combobox as="div" v-model="selectedItem">
    
    <div class="relative mt-1">
      <ComboboxInput class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(item) => item?.SelectInputDisplayName" />
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions v-if="filtered.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <ComboboxOption v-for="item in filtered" :key="item.id" :value="item" :display-value="item.SelectInputDisplayName" as="template" v-slot="{ active, selected }">
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ item.SelectInputDisplayName }}
            </span>

            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script>
 import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/outline'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'

export default {
  components: {
  CheckIcon, 
  ChevronDownIcon,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
  },
  props: ['data', 'preselectedItem'],
  data() {
    return {
      query: '',
      selectedItem: null
    }
  },
  computed: {
    filtered() {
        if(this.query === '') {
          return this.data
        } else {
          return this.data.filter((item) => {
            return item.SelectInputDisplayName.toLowerCase().includes(this.query.toLowerCase())
          })
        }
    }
  },
  methods: {
    selectItem(item){
      this.$emit('selectedItem', item)
    }
  },
  watch: {
    selectedItem(value){
      this.selectItem(value)
    }
  },
  created(){
    if(this.preselectedItem){
      this.selectedItem = this.preselectedItem
    }
  }
}

</script>