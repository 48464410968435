<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <!-- <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span> -->
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div :class="[results.length === 0 ? 'max-w-2xl w-screen' : '', 'flex flex-col align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle h-screen9/10 sm:p-6']">
                    <!-- <div v-if="variant.attribs.list.length > 0">{{variant.attribs.list}}</div> -->
                    <div>
                        <div class="flex justify-between items-center sticky mb-2">
                            <label htmlFor="search" className="block text-sm font-medium text-gray-700">
                                Wyszukaj
                            </label>
                            <XIcon @click.prevent="closeModal" class="h-5 w-5 text-gray-400 hover:text-gray-500 cursor-pointer select-none"></XIcon>
                        </div>
                        <div>
                            <div className="relative mt-1 flex items-center">
                                <input
                                @keydown.enter="search"
                                type="text"
                                v-model="phrase"
                                placeholder="Nazwa produktu"
                                className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                    <kbd @click.prevent="search" className="select-none cursor-pointer inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400 transition-all duration-300 hover:text-blue-500 hover:border-blue-300">
                                        Szukaj
                                    </kbd>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full h-full overflow-auto pt-2">
                        <EmptyState v-if="results.length === 0 && !loading" class="flex flex-col justify-center items-center h-full"></EmptyState>
                        <ul role="list" class="divide-y divide-gray-200" v-if="!loading">
                            <li v-for="product in results" :key="product.id" class="flex flex-col py-4 select-none">
                                <div class="flex">
                                    <img class="h-10 w-10 rounded-full" :src="product.image.url.length > 0 ? product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}`" />
                                    <div class="ml-3">
                                        <router-link :to="`/dashboard/products/list/viewProduct?id=${product.id}`" target="_blank" class="text-sm font-medium text-gray-900 hover:text-gray-600">{{product.name}}</router-link>
                                        <p v-if="typeof product.manufacturer === 'string'" class="text-sm text-gray-500">{{product.manufacturer.length > 0 ? product.manufacturer : "Brak producenta"}}</p>
                                        <p v-else class="text-sm text-gray-500">{{product.manufacturer.name.length > 0 ? product.manufacturer.name : "Brak producenta"}}</p>
                                    </div>
                                </div>
                                <div class="text-xs select-none font-medium ml-3 mt-2" v-if="variantArray(product.variants).length>0">Warianty</div>
                                <div class="text-xs select-none italic ml-3 mt-2 text-gray-500" v-if="variantArray(product.variants).length === 0">Brak wariantów</div>
                                <div v-for="variant in variantArray(product.variants)" class="ml-6 flex items-center mt-2">
                                    <!-- {{variant.image !== undefined && variant.image !== null ?  variant.image.url.length > 0 ? variant.image.url : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Product${product.id}`)}.svg` : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Product${product.id}`)}.svg`}} -->
                                    <img class="h-5 w-5 rounded-full" :src="variant.image !== undefined && variant.image !== null ?  variant.image.url.length > 0 ? variant.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}` : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}`" />
                                    <div class="ml-3 text-xs font-medium text-gray-900 select-none flex items-center justify-between w-full ">
                                        <div>
                                            {{variant.name}} <span class="text-gray-500">({{variant.quantity}} szt.)</span>
                                        </div>
                                        
                                        <div  class="flex justify-end w-40 items-center">
                                            <!-- @change="fixQuantityInput(`q_${variant.id}`,variant.quantity)" -->
                                            <input type="number" :ref="`q_${variant.id}`" min='1' value="1" class="w-1/2 rounded-full border-gray-300 text-center  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xs" placeholder="Ilość" />
                                            <button @click.prevent="addPosition(product,variant.id, $refs[`q_${variant.id}`][0].value)" type="button" class=" ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Dodaj</button>
                                        </div> 
                                    </div>                               
                                </div>
                            </li>
                        </ul>
                        <LoadingSpinner v-if="loading"></LoadingSpinner>
                    </div>
                </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinnerBlue.vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon,XIcon } from '@heroicons/vue/outline'
import EmptyState from '../components/EmptyState.vue';
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
export default {
    data()
        {
        return {
            loading: false,
            phrase: "",
            results: []
        }
    },
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationIcon,
        PlusSmIconOutline,
        EmptyState,
        XIcon,
        LoadingSpinner
    },
    props: ['posCart'],
    methods: {
         
        addPosition(product,variantId,qty)
        {
;
            this.$emit("addPosition", {prodId: product.id, variantId: variantId, quantity: qty})
        },
        closeModal()
        {
            this.open = false;
            this.phrase = "";
            this.results = [];
            this.$emit("closeModal");
        },
        variantArray(variants)
        {
            let arr = [];
            for(let vrnt in variants)
            {
                let variant = variants[vrnt];
                arr.push(variant);
            }
            return arr
        },
        // fixQuantityInput(id,quantity)
        // {
        //     if(this.$refs[id] === undefined) return;
        //     this.$refs[id][0].value = parseInt(this.$refs[id][0].value)
        //     let value = parseInt(this.$refs[id][0].value);
        //     if(value > quantity)
        //     {
        //         this.$refs[id][0].value = 1;
        //     }
        //     else if(value <= 0)
        //     {
        //         this.$refs[id][0].value = 1;
        //     }
        // },
        correctQuantitiesWithPositionsCart()
        {
            // if(this.posCart === undefined) return;
            // if(this.posCart.length === 0) return;

            // function isTheSame(elId,varId)
            // {
            //     return elId === varId
            // }

            // for(let i=0; i<this.results.length;i++)
            // {
            //     let prod = this.results[i];
            //     for(const id in prod.variants)
            //     {
                    
            //         let variant = prod.variants[id];
            //         let amountToCorrect = this.posCart.filter(el => isTheSame(el,variant.id));
            //         variant.quantity = variant.quantity - amountToCorrect.length
            //     }
            // }
        },
        async search()
        {
            if(this.phrase.length < 3) return;
            this.loading = true;
            const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                index: "jan-art-products",
                phrase: this.phrase,
                collection: "Products",
                searchFields: ['names','models','skus','eans','id','categories','manufacturers','variantsIds','baselinkerIds']
            })
            if(res.data.success)
            {
                this.results = res.data.found;
                if(res.data.found.length>0)
                {
                    this.correctQuantitiesWithPositionsCart();
                }
            }
            this.loading = false;
        }
    },
    computed:
    {
    },
    mounted() {
        
    },
    setup() {
        const open = ref(true)
        return {
        open,
        }
    },
}
</script>
<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>