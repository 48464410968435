<template>
   <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/drivers/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł kierowcy</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Skaner kodów QR</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/drivers/routes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>
        <div>

          <!-- <button @click.prevent="changePackage()" type="button" :class="['transition-all duration-300 mt-3 mr-1 inline-flex justify-center items-center gap-x-2 rounded-md bg-gray-400 bg-opacity-80 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600']">
            <ArrowCircleLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Wybierz inne zamówienie
          </button> -->


          <!-- <div class="flex items-center pt-5 justify-center gap-1 font-bold select-none text-gray-500 hover:text-gray-400 transition-all duration-300" v-if="cameraLock && cameraLoaded">
            <div>
              <QrcodeIcon class="w-8 h-8" />
            </div>
            <div>
              <h4 class="text-sm font-semibold">Identyfikator zamówienia</h4>
              <p class="-mt-2 text-md">{{this.$route.params.id}}</p>
            </div>
          </div> -->


          <!-- <p class="error">{{ error }}</p>
          <p class="decode-result"><b>{{ result }}</b></p>
          <div :class="[cameraLoaded ? 'pt-2' : 'pt-6', 'pr-1']">
            <div id="qr-container" class="flex justify-center p-2" v-if="cameraLock">
              <qrcode-stream :class="[cameraLoaded ? '' : 'hidden']" @decode="onDecode" @init="onInit" />
              <LoadingSpinner v-if="!cameraLoaded" />
            </div>
          </div> -->

          <!-- <div class="flex justify-end font-bold mt-2 pr-1 select-none text-gray-500 hover:text-gray-400 transition-all duration-300" v-if="!loading && scannedPackages !== null">
            <span class="uppercase font-bold">Paczka {{ scannedPackages }}</span>
          </div> -->

        <!-- <Transition name="fade">
          <div v-if="!loading" :class="[cameraLoaded ? 'pt-3' : 'pt-4' ,'grid grid-cols-1 gap-4 sm:grid-cols-2']">
            <div v-for="(pack, index) in packages" :key="index" :class="[pack.scanned ? 'focus-within:ring-green-500' : 'focus-within:ring-red-500', 'relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2  focus-within:ring-offset-2 hover:border-gray-400']">
              <div class="min-w-0 flex-1">
                <a href="#" class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true" />
                  <div class="select-none flex justify-between">
                    <p class="text-sm font-medium text-gray-900">Paczka {{index+1}}</p>
                    <p class="text-sm font-medium text-gray-400">{{ pack.id }}</p>
                  </div>
                  <span :class="[pack.scanned ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700', 'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium']">{{pack.scanned ? 'Zeskanowana' : 'Niezeskanowana'}}</span>
                </a>
              </div>
            </div>
          </div>
        </Transition> -->

        <!-- camera -->
        <!-- <input placeholder="test" @change="test()" v-model="testPackageId" /> -->
        <div class="flex flex-col p-2 qr-container">
          <div :class="{'pt-4': error}">
            <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
              <qrcode-stream @detect="onDecode" @init="onInit">
                <div v-if="!error">
                  <button @click="fullscreen = !fullscreen" :class="[fullscreen ? 'rotate-180' : '', 'filter absolute top-0 right-0 m-6 bg-white rounded-lg hover:text-gray-400 transition-all duration-300 transform -scale-x-100']">
                    <ExternalLinkIcon class="w-7 h-7 bg-transparent" />
                  </button>
                </div>
                <div v-if="fullscreen">
                  <div class="m-4 flex items-center text-white select-none">
                    <QrcodeIcon class="w-10 h-10" />
                    <div class="pl-2 text ">
                      <span class="font-semibold">Identyfikator zamówienia</span>
                      <p class="-mt-2">{{this.$route.query.id}}</p>
                    </div>
                  </div>
                  <div v-if="!loading && scannedPackages !== null" :class="['absolute bottom-0 left-0 m-6 select-none text-white transition-all duration-300']">
                    <span class="uppercase font-semibold">Paczka {{ scannedPackages }}</span>
                  </div>
                  <div :class="['absolute bottom-0 right-0 m-4 select-none text-white transition-all duration-300']">
                    <!-- <span class="uppercase font-semibold">Paczka {{ scannedPackages }}</span> -->
                      <div class="cursor-default" v-for="(pack, index) in packages" :key="index" :class="[pack.scanned ? 'focus-within:ring-green-500' : 'focus-within:ring-red-500']">
                        <div class="min-w-0 flex-1 my-1 p-1 rounded-lg">
                          <a href="#" class="focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true" />
                            <div class="select-none flex justify-between">
                              <p class="text-sm font-semibold text-gray-200 uppercase">Paczka {{index+1}}</p>
                              <!-- <p class="text-sm font-medium text-gray-400">{{ pack.id }}</p> -->
                            </div>
                            <span :class="[pack.scanned ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700', 'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium']">
                              {{pack.scanned ? 'Zeskanowana' : 'Niezeskanowana'}}
                            </span>
                          </a>
                        </div>
                      </div>
                  </div>
                </div>
              </qrcode-stream>
            </div>
          </div>
        </div>

      </div>
    </div>

<askForScan v-if="scanAsk" @accept="scan()" :modalData="modalData" @closeModal="scanAsk = false" />
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import { QrcodeStream } from 'vue3-qrcode-reader';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { HomeIcon, CheckIcon, CameraIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, EyeIcon, EyeOffIcon, QrcodeIcon, ExternalLinkIcon } from '@heroicons/vue/solid';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import askForScan from '@/components/ScanAlertModal.vue';
import LoadingSpinner from '../components/LoadingSpinnerHub.vue';
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
    ExternalLinkIcon, QrcodeIcon, LoadingSpinner, askForScan, EyeIcon, EyeOffIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, QrcodeStream, HomeIcon, CheckIcon,CameraIcon
  },
  data () {
    return {
      moment: moment,
      scanAsk: false,
      result: '',
      error: '',
      positions: [],
      cameraLoaded: false,
      orderPackages:'', 
      routes: [],
      route: [],
      order: null,
      fullscreen: true,
      cameraLock: true,
      selected: '',
      toggleInformation: {},
      loading: false,
      modalData: {
        headerText: "Skanowanie zamówienia",
        bodyText: "Czy na pewno chcesz zeskanować to zamówienie?",
        acceptButtonText: "Skanuj"
      },
      packageId: '',
      routeId: '',
      packages: [],
      scannedPackages: null,
      testPackageId: ''
    }
  },
  methods: {
    test() {
      this.onDecode(this.testPackageId, true);
    },
    changePackage()
    {
      this.$router.push('/dashboard/drivers/routes')
      this.$store.state.route = this.$route.query.id
    },
    routeSelector(value)
    {
      this.routeId = value;
    },
    scan()
    {
      this.cameraLock = true;
    },
    withoutPackage(index, id)
    {
      this.scanAsk = !this.scanAsk;
      this.packageId = id;
    },
    async getPackages(val)
    {
      this.loading = true;
      this.packages = [];
      let scanned = 0
      let total = 0

      let orderId = val.split(":")[0];
      let position = ""
      if(orderId.includes("_")) {
        position = orderId.split("_")[0];
        orderId = orderId.split("_")[1];
      }
      if(this.$store.state.isDriverOnline)
      {
        const res = await db.collection('Orders').doc(orderId).get()
        let orderPackages = res.data();
        orderPackages.packages.forEach(item => {
          if(item.scanned)
          {
              scanned++
          }
          total++
  
          total === scanned ? item.isScan = true : item.isScan = false
          this.scannedPackages = `${scanned}/${total}`
          
          this.packages.push(item)
        });
  
        if(scanned === total)
        {
          this.changePackage()
        }
      }
      else
      {
        let orderPackages = this.order.packages;
        orderPackages.forEach(item => {
          if(item.scanned)
          {
              scanned++
          }
          total++
  
          total === scanned ? item.isScan = true : item.isScan = false
          this.scannedPackages = `${scanned}/${total}`
          
          this.packages.push(item)
        });
  
        if(scanned === total)
        {
          this.changePackage()
        }
      }
      this.loading = false;
    },
    async onDecode(res, fromScanner)
    {
      try {
        this.packages = [];
        let decodedResult = null

        if(fromScanner) {
          // this.packageId = value
          decodedResult = {content: res}
        } else {
          decodedResult = await res;
        }
        if(decodedResult.content.length === 0) throw 'ERROR: The length of the result is equal to 0;';

        let result = decodedResult.content;
        let orderId = result.split(":")[0];

        let position = ""
        if(orderId.includes("_")) {
          position = orderId.split("_")[0];
          orderId = orderId.split("_")[1];
        }

        let firebasePayload = {};

        if(this.$store.state.isDriverOnline)
        {
          //when online
          const orderDoc = db.collection("Orders").doc(orderId)
          const order = (await orderDoc.get()).data()

          if(!orderId.includes(":") && orderId === this.$route.query.id)
          {
            let scanned = false
            for(let i = 0; i < order.packages.length; i++) {
              let preparedResult = decodedResult.content;
              let productPackage = order.packages[i]
              if(!productPackage.id.includes('_'))
              {
                preparedResult = preparedResult.split("_")[1];
              }
              if(productPackage.id === preparedResult && !productPackage.scanned) {
                productPackage.scanned = true
                scanned = true
                break
              }
            }

            if(scanned) {
              firebasePayload = {
                "packages": order.packages
              }
              await orderDoc.update(firebasePayload)

              this.$store.commit('setNotification',{
                show: true,
                head: "Paczka pomyślnie zeskanowana.",
                subheader: ``,
                success: true
              });
            } else {
              this.$store.commit('setNotification',{
                show: true,
                head: "Błędny kod QR dla wybranego zamówienia lub paczka została zeskanowana.",
                subheader: ``,
                success: false
              });
            }

            this.cameraLock = true;
            this.$store.state.route = this.$route.query.id
            this.getPackages(result)
          }
          else
          {
            this.$store.commit('setNotification',{
              show: true,
              head: "Błędny kod QR dla wybranego zamówienia.",
              subheader: `Wybrany numer zamówienia: ${this.$route.query.id}.`,
              success: false
            });
  
            this.cameraLock = false;
            this.$router.push('/dashboard/drivers/routes')
            this.$store.state.route = this.$route.query.id
          }
        }
        else
        {
          for(let i = 0; i < order.packages.length; i++) {
            let productPackage = order.packages[i]
            if(productPackage.id === result && !productPackage.scanned) {
              productPackage.scanned = true
              break
            }
          }

          firebasePayload = {
            "packages": this.order.packages
          }

          // when offline
          if(!orderId.includes(":") && orderId === this.$route.query.id)
          {
            this.$store.commit("addToOfflineCallsQueue",
            {
              done: false,
              callClient: "firebase",
              call: {
                firebase: {
                  ref: {
                    collection: "Orders",
                    doc: this.order.id 
                  },
                  action: "update", // set or update
                  payload: {...firebasePayload} // body
                },
                axios: null,
              },
              routeId: this.$route.query.id,  // id lub null
              orderId: this.order.id, // id lub null
              originalFunction: {
                name: "onDecode", // nazwa oryginalnej funkcji
                payload: this.order // oryginalne argumenty wykonywanej funkcji 
              }
            })

            this.$store.commit('setNotification',{
              show: true,
              head: "Paczka pomyślnie zeskanowana.",
              subheader: ``,
              success: true
            });
            this.cameraLock = true;
            this.$store.state.route = this.$route.query.id
            this.$store.state.callForScan.taken = true;
            this.getPackages(result)
          }
          else
          {
            this.$store.commit('setNotification',{
              show: true,
              head: "Błędny kod QR dla wybranego zamówienia.",
              subheader: `Wybrany numer zamówienia: ${this.$route.query.id}.`,
              success: false
            });
            this.cameraLock = false;
            this.$router.push('/dashboard/drivers/routes')
            this.$store.state.route = this.$route.query.id
          }
        }
      }
      catch (error)
      {
        console.log(error);  
      }
    },
    async onInit (promise)
    {
      try {
        this.cameraLoaded = false
        await promise
        this.cameraLoaded = true
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    onFullscreenChange(event) {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement !== null
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen()
      }
    },
    requestFullscreen() {
      const elem = this.$refs.wrapper

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
  },
  watch: {
    fullscreen(enterFullscreen) {
      if (enterFullscreen) {
        this.requestFullscreen()
      } else {
        this.exitFullscreen()
      }
    },
    error(changed) {
      if(changed)
      {
        this.fullscreen = false;
      }
      else
      {
        this.fullscreen = true;
      }
    }
  },
  computed: {
    orderId() {
      return this.$route.query.id
    }
  },
  created()
  {
    moment.locale('pl')
    if(!this.$store.state.isDriverOnline)
    {
      for(let i=0; i<this.$store.state.offlineModeState.dataSnapshot.orders.length; i++)
      {
        let order = this.$store.state.offlineModeState.dataSnapshot.orders[i];
        if(this.$store.state.callForScan.order.id === order.id)
        {
          this.order = order;
          break;
        }
      };
    }
  }
}
</script>
<style scoped>

.text {
  color: #fff;
  display: inline-block;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 1);
}

#qr-container {
  background:
    linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fullscreen {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fullscreen-button{
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  margin: 1rem;
}

.fullscreen-button img {
  width: 2rem;
}
</style>