<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Moduł produkty</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 mb-4 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
    &larr; Wybierz inny moduł
    </router-link>

    <LoadingSpinnerHub v-if="loading" />
    <div v-auto-animate v-if="!loading">
        <div class="flex flex-col sm:flex-row sm:justify-between">
            <div class="bg-white shadow-md sm:w-fit px-6 py-4 h-full rounded-md">
                <div class="sm:w-112">
                    <div class="border-b border-gray-200 pb-4">
                        <div class="flex items-center justify-between">
                            <h1 id="message-heading" class="select-none text-base font-semibold leading-6 text-gray-900">Podgląd trasy</h1>
                            <div class="select-none">
                                <img v-if="route.country !== undefined && route.country.flagUrl.length > 0" :src="route.country.flagUrl" v-tooltip="`${route.country.displayName}`" class="h-5 w-7 mr-1 border border-gray-300">
                                <span v-if="route.country === undefined" v-tooltip="`Brak wybranego kraju dla trasy.`">---</span>
                            </div>
                        </div>
                    </div>
                    <div class="border-b border-gray-200 pb-4">
                        <div class="pt-4 flex flex-col gap-y-1">
                            <label class="select-none uppercase text-sm text-gray-500 font-semibold">informacje o trasie</label>
                            <div class="flex justify-between">
                                <span class="uppercase text-xs">Nazwa trasy</span>
                                <span class="inline-flex uppercase text-xs font-semibold break-all text-indigo-600">{{route.title !== undefined && route.title.length > 0  ? route.title : (route.vehicle.brand + ' ' + route.vehicle.model + ' - ' + route.vehicle.attribs.licensePlate.value)}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="uppercase text-xs">Data wyjazdu</span>
                                <span class="inline-flex uppercase text-xs font-semibold break-all">{{moment.unix(route.date.start.seconds || route.date.start._seconds).format("LL")}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="uppercase text-xs">Data powrotu</span>
                                <span class="inline-flex uppercase text-xs font-semibold break-all">{{moment.unix(route.date.end.seconds || route.date.end._seconds).format("LL")}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="border-b border-gray-200 pb-4">
                        <div class="pt-4 flex flex-col gap-y-1">
                            <label class="select-none uppercase text-sm text-gray-500 font-semibold">Informacje o pojeździe</label>
                            <div class="flex justify-between">
                                <span class="uppercase text-xs">Pojazd</span>
                                <span class="inline-flex uppercase text-xs font-semibold break-all text-indigo-600">{{route.vehicle.brand + ' ' + route.vehicle.model}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="uppercase text-xs">Tablica rejestracyjna</span>
                                <span class="inline-flex uppercase text-xs font-semibold break-all">{{route.vehicle.attribs.licensePlate.value}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="uppercase text-xs">Pojemność transportowa</span>
                                <span class="inline-flex uppercase text-xs font-semibold break-all">{{route.vehicle.attribs.payloadCapacity.value}} m3</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-md sm:w-fit px-6 py-4 mt-4 sm:mt-0 sm:flex rounded-md">
                <div class="sm:w-96">
                    <label class="select-none uppercase text-sm text-gray-500 font-semibold">Informacje o kierowcach</label>
                    <div v-for="(driver, index) in route.drivers" :key="index" :class="[index != route.drivers.length - 1 ? 'border-b' : 'bor', 'flex flex-col gap-y-1 border-gray-200 py-2']">
                        <div class="flex justify-between">
                            <span class="select-none uppercase text-xs">Imię i nazwisko</span>
                            <span class="inline-flex uppercase text-xs font-semibold break-all text-indigo-600">{{driver.name + ' ' + driver.surname}}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="select-none uppercase text-xs">Numer telefonu</span>
                            <a :href="`tel:${driver.contact.phone}`" class="transition-all duration-300 hover:text-indigo-500 inline-flex uppercase text-xs font-semibold break-all">{{driver.contact.phone}}</a>
                        </div>
                        <div class="flex justify-between">
                            <span class="select-none uppercase text-xs">Adres e-mail</span>
                            <a :href="`mailto:${driver.contact.email}`" class="transition-all duration-300 inline-flex uppercase text-xs font-semibold break-all hover:text-indigo-500">{{driver.contact.email}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-end mt-4 mb-2">
            <button v-auto-animate @click="SMSChatOpen = !SMSChatOpen, !SMSChatOpen ? getRoute() : ''" type="button" class="w-56 justify-center whitespace-nowrap transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <MailIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                <span v-if="!SMSChatOpen">Pokaż wiadomości SMS</span>
                <span v-if="SMSChatOpen">Schowaj wiadomości SMS</span>
            </button>
        </div>
        <div v-if="!SMSChatOpen" class="flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden shadow border border-gray-200 rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">Dostawa</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Zamówienie</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Producent</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Dane klienta</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Komentarz</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase inline-flex w-32">
                                    <span class="truncate" v-tooltip="`Planowana data dostawy`">Planowana data dostawy</span>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">
                                    <span class="sr-only">externalOrderId</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(ord, index) in route.orders" :key="index">
                                <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-sm font-medium text-gray-900">
                                    <div class="inline-flex w-32 gap-2 items-center">
                                        <div v-tooltip="ord.order.delivery.date.dateConfirmed !== undefined && ord.order.delivery.date.dateConfirmed ? 'Potwierdzono dostawę' : ord.order.delivery.date.dateConfirmationSent !== undefined && ord.order.delivery.date.dateConfirmationSent ? 'Wysłano wiadomość z potwiedzeniem' : 'Nie wysłano wiadomości z potwierdzeniem'" :class="[ord.order.delivery.date.dateConfirmed !== undefined && ord.order.delivery.date.dateConfirmed ? 'bg-green-50 text-green-500' : ord.order.delivery.date.dateConfirmationSent !== undefined && ord.order.delivery.date.dateConfirmationSent ? 'bg-blue-50 text-blue-500' : 'bg-red-50 text-red-500', 'rounded-md p-1']">
                                            <MailIcon v-if="ord.order.delivery.date.dateConfirmed === undefined || !ord.order.delivery.date.dateConfirmed" class="h-4 w-4" />
                                            <TruckIcon v-if="ord.order.delivery.date.dateConfirmed !== undefined && ord.order.delivery.date.dateConfirmed" class="h-4 w-4" />
                                        </div>
                                        <span v-tooltip="`${ord.order.delivery.postCode + ' ' + ord.order.delivery.city}`" v-if="ord.order.delivery.postCode !== undefined && ord.order.delivery.city !== undefined && ord.order.delivery.city !== null && ord.order.delivery.postCode !== null" class="truncate">{{ord.order.delivery.postCode + ' ' + ord.order.delivery.city}}</span>
                                        <span v-if="ord.order.delivery.postCode === undefined && ord.order.delivery.city == undefined" class="truncate">---</span>
                                    </div>
                                </td>   
                                <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-900">
                                    <div class="w-40 text-gray-900 inline-flex" v-tooltip="`${prod}`" v-for="prod in createProductNameList(ord.order).slice(0,1)" :key="prod.id">
                                        <span class="truncate text-sm font-medium">{{prod}}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-900">
                                    <div class="inline-flex w-32" v-for="(manu, index) in createProductManufacturerList(ord.order).slice(0,1)" :key="index">
                                        <span class="truncate focus-within:text-sm font-medium text-gray-900">{{typeof manu === 'string' ? manu : manu.name}}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-900">
                                    <div class="inline-flex w-28">
                                        <span v-tooltip="`Dane osobowe: ${ord.order.client.name.length > 0 ? ord.order.client.name : ''} | Adres dostawy: ${ord.order.delivery.address !== undefined && ord.order.delivery.address !== null ? ord.order.delivery.address : '---'} | Miasto dostawy: ${ord.order.delivery.city !== undefined && ord.order.delivery.city !== null ? ord.order.delivery.city : '---'} | Kod pocztowy: ${ord.order.delivery.postCode !== undefined && ord.order.delivery.postCode !== null ? ord.order.delivery.postCode : '---'}`" class="truncate items-center gap-x-1.5 rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">{{ ord.order.client.name !== undefined && ord.order.client.name !== null ? ord.order.client.name : '' }}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-900">
                                    <div class="inline-flex w-32" v-for="(comment, index) in createCommentList(ord.order).slice(0,1)" :key="index">
                                        <span class="truncate rounded-lg focus-within:ring-2 focus-within:ring-indigo-600 items-center gap-x-1.5 bg-red-100 px-2 py-1 text-xs font-medium text-red-700" v-tooltip="`${comment}`">{{comment.length > 0 ? comment : '---'}}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-900">
                                    <span class="inline-flex items-center rounded-md text-xs font-semibold break-all">{{ ord.order.delivery.date.planned !== undefined && ord.order.delivery.date.planned !== null ? moment.unix(ord.order.delivery.date.planned.unix).format("DD.MM.YYYY") : 'Brak ustalonej daty' }}</span>
                                </td>
                                <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-900">
                                    <div v-tooltip="`${ord.order.origin !== undefined ? ord.order.origin.meta !== undefined ? ord.order.origin.meta.external !== undefined ? ord.order.origin.meta.external.ids !== undefined ? ord.order.origin.meta.external.ids.externalOrderId !== undefined && ord.order.origin.meta.external.ids.externalOrderId.length > 0 ? ord.order.origin.meta.external.ids.externalOrderId : ord.order.origin.meta.external.ids.bolOrderId !== undefined && ord.order.origin.meta.external.ids.bolOrderId.length > 0 ? ord.order.origin.meta.external.ids.bolOrderId : ord.order.origin.meta.external.ids.hoodOrderId !== undefined && ord.order.origin.meta.external.ids.hoodOrderId.length > 0 ? ord.order.origin.meta.external.ids.hoodOrderId : '--' : '--' : '--' : '--' : '--'} ${ord.order.origin !== undefined ? ord.order.origin.meta !== undefined ? ord.order.origin.meta.external !== undefined ? ord.order.origin.meta.external.source !== undefined ? ord.order.origin.meta.external.source.name !== undefined && ord.order.origin.meta.external.source.name.length > 0 ? '(' + ord.order.origin.meta.external.source.name + ')' : '(' + ord.order.originName + ')' : '(' + ord.order.originName + ')' : '(' + ord.order.originName + ')' : '(' + ord.order.originName + ')' : '(' + ord.order.originName + ')'}`" class="inline-flex w-32">
                                        <span class="truncate items-center rounded-md text-xs font-semibold break-all">{{ `${ord.order.origin !== undefined ? ord.order.origin.meta !== undefined ? ord.order.origin.meta.external !== undefined ? ord.order.origin.meta.external.ids !== undefined ? ord.order.origin.meta.external.ids.externalOrderId !== undefined && ord.order.origin.meta.external.ids.externalOrderId.length > 0 ? ord.order.origin.meta.external.ids.externalOrderId : ord.order.origin.meta.external.ids.bolOrderId !== undefined && ord.order.origin.meta.external.ids.bolOrderId.length > 0 ? ord.order.origin.meta.external.ids.bolOrderId : ord.order.origin.meta.external.ids.hoodOrderId !== undefined && ord.order.origin.meta.external.ids.hoodOrderId.length > 0 ? ord.order.origin.meta.external.ids.hoodOrderId : "--" : "--" : "--" : "--" : "--"} ${ord.order.origin !== undefined ? ord.order.origin.meta !== undefined ? ord.order.origin.meta.external !== undefined ? ord.order.origin.meta.external.source !== undefined ? ord.order.origin.meta.external.source.name !== undefined && ord.order.origin.meta.external.source.name.length > 0 ? "(" + ord.order.origin.meta.external.source.name + ")" : "(" + ord.order.originName + ")" : "(" + ord.order.originName + ")" : "(" + ord.order.originName + ")" : "(" + ord.order.originName + ")" : "(" + ord.order.originName + ")"}` }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        <SMSChat v-if="SMSChatOpen" :ordersProp="route.orders" :routeId="route.id"></SMSChat>
    </div>

</template>

<script>
import axios from 'axios';
import {db} from "@/firebase/gfbconf.js"
import { ViewListIcon, DocumentAddIcon, CollectionIcon, PuzzleIcon, ViewGridIcon, TruckIcon, PencilIcon, MailIcon } from '@heroicons/vue/outline'
import { HomeIcon, PhoneIcon, UserIcon } from '@heroicons/vue/solid'
import moment from 'moment';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import SMSChat from '../components/SMSChat.vue'
export default {
    name: "routeLookup",
    data() {
        return {
            route: null,
            loading: false,
            changeDatePicker: false,
            SMSChatOpen: false
        }
    },
    components: {
        PencilIcon, LoadingSpinnerHub, VueDatePicker, TruckIcon, PhoneIcon, UserIcon, MailIcon, ViewListIcon, DocumentAddIcon, CollectionIcon, PuzzleIcon, HomeIcon, ViewGridIcon, SMSChat
    },
    async created()
    {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes)
        {
            this.$router.push("/hub")
        }
        this.getRoute()
        moment.locale('pl')
        this.moment = moment;
    },
    methods:
    {
        async getRoute()
        {
            this.loading = true;
            const res = await axios.post(`${this.$store.state.apiLink}/route/get-single-route`, {
                routeId: this.$route.query.edit,
            })
            if(!res.data.success)
            {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: res.data.log,
                  success: false
                });
            }
            else
            {
                this.route = res.data.route;
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona pomyślnie!",
                    subheader: `Pobrano trasę: ${this.route.title !== undefined || this.route.title.length > 0 ? this.route.title : this.route.id}`,
                    success: true
                });
            }
            this.loading = false;
        },
        createCommentList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfComments.push(element[1].infos.onOrderCreation);
                }
            })

            return translatedListOfComments
        },
        createProductNameList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfProducts = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfProducts.push(element[1].product.name);
                }
            })

            return translatedListOfProducts
        },
        createProductManufacturerList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfProducts = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfProducts.push(element[1].product.manufacturer);
                }
            })

            return translatedListOfProducts
        },
    },
    computed:
    {

    },
    beforeUnmount() {
    }
}
</script>
<style lang="scss" scoped>

</style>