<template>
    <div v-if="loaded">
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/products" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł produkty</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/products/attributes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Atrybuty produktów</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Modyfikacja grupy {{attrib.name}}</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/products/attributes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <div>
            <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Zarządzaj grupą {{attrib.name}}</h2>
        </div>
        <div class="rounded-md bg-red-100 p-4 mt-2">
            <div class="flex items-center">
                <div class="flex-shrink-0">
                    <InformationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
                <div class="flex flex-col md:flex-row md:justify-between w-full md:items-center">
                    <div class="ml-3 flex flex-1 items-center md:flex md:justify-between">
                        <p class="text-sm text-red-600">Usunięcie grupy nie wpłynie na ich obecność w utworzonych wcześniej produktach</p>
                        <p class="mt-3 text-sm md:mt-0 md:ml-6">
                        </p>
                    </div>
                    <div class="mt-2 ml-3 md:mt-0 md:ml-0">
                        <button type="button" @click.prevent="deleteAskGroup = true" class="transition ease-in-out duration-300 justify-center w-full md:w-auto inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-900 bg-red-300 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Usuń grupę atrybutów</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <div class="mt-1 flex w-full sm:4/5 md:w-3/5 lg:w-2/5 rounded-md">
                <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> Nowa wartość </span>
                <input type="text" v-model="newAttribName" name="name" id="name" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300" placeholder="Podaj wartość" />
                <button type="button" @click.prevent="updateAttributeGroup"  :class="{'ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500': true, 'opacity-40': newAttribName.length === 0 }">Dodaj</button>
            </div>         
        </div>
        <!-- <div class="mt-6 flex">
            <div class="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Value name</label>
                <input type="text" v-model="newAttribName" name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="New value" />
            </div>
            <button type="button" @click.prevent="updateAttributeGroup" class="ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Add value</button>
        </div> -->
        <div :class="['flex justify-between items-center', attrib.list.length > 0 ? 'mt-2' : 'mt-4 mb-4']">
            <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Dostępne wartości:</h2>
            <div v-show="attrib.list.length > 0" class="flex items-center py-1.5">
                    <Switch v-model="enabledEdit" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="sr-only">Use setting</span>
                        <span aria-hidden="true" class="pointer-events-none absolute w-full h-full rounded-md" />
                        <span aria-hidden="true" :class="[enabledEdit ? 'bg-blue-600' : 'bg-gray-200', 'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200']" />
                        <span aria-hidden="true" :class="[enabledEdit ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200']" />
                    </Switch>
                    <div @click="enabledEdit = !enabledEdit" class="text-xs cursor-pointer text-gray-400 ml-2"> Edycja </div>
            </div>
        </div>
        <div v-if="attrib.list.length !== 0">
            <div v-show="attrib.list.length !== 0" class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                <div v-for="value in attrib.list" :key="value.id" class="relative flex justify-between items-center py-4">
                    <div class="flex items-center justify-between w-full space-x-4">
                            <div class="flex">
                                <div class="flex-shrink-0 mr-4">
                                    <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm">{{attrib.name.substring(0,3).toUpperCase()}}</div>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        {{ value.name }}
                                    </p>
                                    <p class="text-sm text-gray-500 truncate">
                                        ID: {{ value.id }}
                                    </p>
                                </div>
                            </div>
                            <div v-show="enabledEdit">
                                <button @click="prepareForDelete(value)" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200"> Usuń </button>
                            </div>
                        </div>
                    <!-- <div class="ml-3 flex justify-end items-center h-5" v-if="enabledEdit">
                        <input :id="`category-${category.id}`" :key="rerender" @change="selectOption(category.id,category.name)" :name="`category-${category.id}`" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded cursor-pointer" />
                    </div> -->
                    <!-- <div v-if="enabledEdit">
                        <button @click.prevent="deleteOption(category.id,category.name)" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200"> Usuń </button>                    </div> -->
                </div>
            </div>
        </div>
        <emptyState v-if="attrib.list.length === 0"></emptyState>
        <askForDelete v-if="deleteAsk" :modalData="modalDataValuesText" @accept="deleteValueFromDatabase" @closeModal="deleteAsk = false" ></askForDelete>
        <askForDelete v-if="deleteAskGroup" :modalData="modalDataGroupText" @accept="deleteGroupFromDatabase" @closeModal="deleteAskGroup = false" ></askForDelete>
        
    </div>
</template>

<script>
    //import axios from 'axios';
    import moment from 'moment';
    import emptyState from '@/components/EmptyState.vue';
    import { HomeIcon } from '@heroicons/vue/solid';
    import { InformationCircleIcon} from '@heroicons/vue/outline';
    import askForDelete from '@/components/AlertModal.vue';
    import {db, firebase} from "@/firebase/gfbconf.js"
    import { Switch } from '@headlessui/vue'
    export default {
        name: "Modify Attributes",
        components: {InformationCircleIcon, askForDelete, emptyState,HomeIcon, Switch},
        data()
        {
            return {
                enabledEdit: false,
                valueToDelete: null,
                deleteAskGroup: false,
                deleteAsk: false,
                modalDataValuesText: {
                  headerText: "Usuwanie wartości atrybutu",
                  bodyText: "Czy na pewno chcesz usunąć wybraną wartość atrybutu? Dane zostaną permamentnie usunięte.",
                  acceptButtonText: "Usuń"
                },
                modalDataGroupText: {
                  headerText: "Usuwanie grupy atrybutów",
                  bodyText: "Czy na pewno chcesz usunąć wybraną grupę atrybutów? Dane zostaną permamentnie usunięte.",
                  acceptButtonText: "Usuń"
                },
                newAttribName: '',
                attrib: null,
                loaded: false,
            }
        },
        created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
            {
                this.$router.push("/hub")
            }
            this.getAttribGroup();
        },
        methods:
        {
            async deleteGroupFromDatabase()
            {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Usuwanie grupy atrybutów..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                try{
                    for(let i=0; i<this.attrib.list.length;i++)
                    {
                        let currentValue = this.attrib.list[i];
                        await currentValue.ref.delete();
                    }
                    await db.collection("GroupsOfAttributes").doc(this.$route.query.attrib).delete();
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Usunięto grupę atrybutów.",
                        subheader: `Usuwanie grupy atrybutów zakończone sukcesem.`,
                        success: true
                    });
                    this.$router.push('/dashboard/products/attributes')
                }
                catch(error)
                {
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Coś poszło nie tak..",
                        subheader: `Błąd powstał podczas usuwania grupy atrybutów.`,
                        success: false
                    }); 
                    this.$router.push('/dashboard/products/attributes');
                }

            },
            async deleteValueFromDatabase()
            {
                if(this.valueToDelete !== null)
                {
                    try{
                        await this.valueToDelete.ref.delete();
                        await db.collection("GroupsOfAttributes").doc(this.$route.query.attrib).update({
                            list: firebase.firestore.FieldValue.arrayRemove(this.valueToDelete)
                        })
                        this.valueToDelete = null;
                        this.getAttribGroup();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wartość grupy atrybutów usunięta!",
                            subheader: `Wartość została usunięta, nie wpływa to na posiadane atrybuty w stworzonych wcześniej produktach.`,
                            success: true
                        });  
                    }
                    catch(error)
                    {
                        console.log(error);
                    }

                }

            },
            prepareForDelete(value)
            {
                this.valueToDelete = value;
                this.deleteAsk = true;
            },
            async calculateId()
            {
                const newId = Math.floor(Math.random() * 10000)+1;
                const freeId = await db.collection("Attributes").doc(newId.toString()).get();
                return {
                    id: newId,
                    exists: freeId.exists
                }
            },
            async getAttribGroup()
            {
                this.loaded = false;
                const result = await db.collection("GroupsOfAttributes").doc(this.$route.query.attrib).get();
                this.attrib = result.data();
                this.loaded = true;
            },
            async updateAttributeGroup()
            {
                if(this.newAttribName.length === 0)
                {
                    return 0;
                }
                let freeId = null;
                for(let i=0; i < 999; i++)
                {
                    const resultOfGettingId = await this.calculateId();
                    if(!resultOfGettingId.exists)
                    {
                        freeId = resultOfGettingId;
                        break;
                    }
                }
                if(freeId !== null)
                {
                    let newAttribute = {
                        name: this.newAttribName,
                        id: parseInt(freeId.id),
                        type: "product",
                        creationDate: moment().toDate(),
                        createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`, 
                        childOf: this.attrib.name,
                        groupOfAttributes: {
                            ref: db.collection("GroupsOfAttributes").doc(this.$route.query.attrib),
                            id: this.$route.query.attrib
                        }
                    };
                    await db.collection("Attributes").doc(freeId.id.toString()).set(newAttribute);
                    await newAttribute.groupOfAttributes.ref.update({
                        'list': firebase.firestore.FieldValue.arrayUnion(
                            {
                                name: newAttribute.name,
                                id: newAttribute.id,
                                ref: db.collection("Attributes").doc(freeId.id.toString())
                            }
                        )
                    })
                    this.getAttribGroup();
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Nowa wartość atrybutu została utworzona!",
                        subheader: `Teraz możesz wykorzystać tę wartość podczas tworzenia produktu.`,
                        success: true
                    });
                    this.newAttribName = "";
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wartość nie została utworzona!",
                        subheader: `Nie można odnaleźć wolnego ID.`,
                        success: false
                    });
                }

                
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>