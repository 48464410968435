<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="overflow-y-auto max-h-screen9/10 relative inline-block align-bottom bg-gray-50 rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
          <div class="sticky bg-gray-50 z-50 top-0 w-full py-4">
            <!-- SEARCHER -->
            <div class="min-w-0 flex-1 xl:col-span-6 mx-6">
              <div class="flex items-center">
                <div class="w-2/3 flex">
                <label for="search" class="sr-only">Szukaj</label>
                <div class="relative">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                </div>
                <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                </div>
              </div>
              <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                  Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                  <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                  </button>
                </span>           
              </div>
            </div>
          </div>
          <div>
          <ul role="list" class="space-y-3 mb-2 mx-6 mt-2">
            <li @click="selectProduct(item, $event)" v-for="item in searcher.found.length > 0 ? searcher.found : preparedProducts" :key="item.id" class="overflow-hidden shadow rounded-md bg-white hover:bg-gray-200 transition-all duration-300 cursor-pointer px-6 py-4 w-full flex justify-between">
              <!-- Your content -->
              <div class="flex min-w-0 gap-x-4">
                <img class="h-12 w-12 flex-none bg-gray-50 mix-blend-multiply" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${item.name}`" alt="" />
                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-6 text-gray-900">{{item.name}}</p>
                  <p class="truncate text-xs leading-5 text-gray-500">{{typeof item.manufacturer === 'string' ? item.manufacturer : item.manufacturer.name}}</p>
                </div>
              </div>
              <div class="items-center flex">
                <input v-model="item.checked" type="checkbox" class="h-4 w-4 rounded border-gray-500 text-indigo-600 focus:ring-indigo-600" />
              </div>
            </li>
          </ul>
            <div class="flex justify-center pt-4 pb-4">
              <InfiniteLoading v-tooltip="`W przypadku zatrzymania się ładowania, proszę nacisnąć kręcące się kółko.`" v-if="!queryLimitReached" @infinite="loadMoreProducts()" />
            </div>
          </div>
          <div class="sticky bg-gray-50 z-50 bottom-0 w-full px-4 py-4">
            <div class="flex gap-2 justify-between px-2">
              <button @click="close()" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <ArrowCircleLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Anuluj
              </button>
              <button @click="emitAccept()" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Przejdź dalej
              </button>
            </div>
          </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { db, firebase } from "@/firebase/gfbconf.js";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckCircleIcon, ArrowCircleLeftIcon, SearchIcon } from '@heroicons/vue/outline'
import axios from 'axios';
export default {
  data() {
    return {
      open: true,
      queryLimit: 25,
      queryLimitReached: false,
      lockCheck: false,
      product: null,
      dbListener: null,
      query: null,
      preparedProducts: [],
      preparedProduct: null,
      searcher: {
        found: [],
        locked: false,
        phrase: "",
        lastCall: null
      },
    }
  },
  components: {
    SearchIcon, InfiniteLoading, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, CheckCircleIcon, ArrowCircleLeftIcon
  },
  props: ['checkedProduct', 'products', 'modalData', 'buttonColor', 'iconColor', 'buttonBlocked', 'closeModalAfterAccept'],
  created() {
    this.product = JSON.parse(JSON.stringify(this.checkedProduct))
    for(let i = 0; i < this.products.length; i++) 
    {
      let preparedProduct = JSON.parse(JSON.stringify(this.products[i]))
      this.preparedProducts.push(preparedProduct)
    };
    this.getProducts();
  },
  methods: {
    resetSearch()
    {
      this.preparedProducts = [];
      this.searcher.found = [];
      this.searcher.lastCall = null;
      this.searcher.phrase = "";
      this.dbListener = db.collection("Products").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.queryLoading = true;
        this.preparedProducts = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
            this.queryLimitReached = true;
        }
        else
        {
            this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
            this.preparedProducts.push(doc.data())
            this.lastLoaded = doc.data().meta.createdDate;
            
        });
        this.queryLoading = false;
      });
    },
    async search()
    {
      if(this.searcher.phrase.length > 0)
      {
          this.searcher.locked = true;
          const res = await axios.post(`${this.$store.state.apiLink}/search`, {
              index: "jan-art-products",
              collection: "Products",
              phrase: this.searcher.phrase,
              searchFields: ['names','models','skus','eans','id','categories','manufacturers','variantsIds','baselinkerIds']

          })
          if(res.data.success)
          {
              this.searcher.found = res.data.found;
              if(res.data.found.length > 0)
              {
                  this.searcher.lastCall = res.data.data;
                  // this.dbListener();
                  this.$store.commit('setNotification',{
                      show: true,
                      head: "Wyszukiwanie zakończone!",
                      subheader: `Znaleziono ${res.data.found.length} produktów.`,
                      success: true
                  });

              }else{
                  this.$store.commit('setNotification',{
                      show: true,
                      head: "Nie znaleziono wyników!",
                      subheader: `Spróbuj wyszukać inną frazę.`,
                      success: false
                  });
              }

          }
          else
          {
              this.$store.commit('setNotification',{
                  show: true,
                  head: "Wyszukiwanie zakończone niepowodzeniem!",
                  subheader: `Coś poszło nie tak...`,
                  success: false
              });
          }
          setTimeout(()=>
          {
              // this.searcher.phrase = "";
              this.searcher.locked = false;
          }, 3000)
      }
      else
      {
          return;
      }
    },
    selectProduct(prod, event)
    {
      if(!prod.checked)
      {
        for(let i=0; i<this.preparedProducts.length; i++)
        {
          this.preparedProducts[i].checked = false;
        }
        prod.checked = true;
        this.product = prod;
      }
      else
      {
        prod.checked = false;
      }
    },
    continueListening()
    {
      this.queryLimit = this.queryLimit + 25;
      this.dbListener = this.query.limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.preparedProducts = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
            this.queryLimitReached = true;
        }
        else
        {
            this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
            let current = doc.data()
            this.preparedProducts.push(current);
        });
      });
    },
    loadMoreProducts()
    {
      this.dbListener();
      this.continueListening();      
    },
    getProducts()
    {
      this.query = null;
      this.queryLimit = 25;
      if(this.dbListener !== null){
        this.dbListener()
        this.dbListener = null
      }
      this.query = db.collection("Products")
      this.query = this.query

      this.dbListener = this.query.limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.preparedProducts = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
          this.queryLimitReached = true;
        }
        else
        {
          this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
          let current = doc.data()
          current.checked = false;
          this.preparedProducts.push(current);
        });
      });
      this.loading = false;
    },
    emitClose()
    {
      this.$emit("close");
    },
    emitAccept()
    {
      this.$emit("accept", this.product);
      this.resetSearch();
      // if(this.closeModalAfterAccept) {
      //   this.$emit("closeModal");
      // }
    },
    close()
    {
      this.emitClose();
      this.open = false
    }
  },
}
</script>

<style scoped>

</style>