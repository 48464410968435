<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Dodaj użytkownika</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Stwórz nowe konto systemowe.</p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Dane logowania</div>
                        <div class="select-none flex flex-col">
                            <label for="email" class="block text-sm font-medium text-gray-700">Email*</label>
                            <div class="mt-1">
                                <input type="email" maxlength="35"  name="email" v-model="newUser.contact.email" id="email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. ty@gmail.com" />
                            </div>
                            <span class="text-xs select-none text-gray-400 mt-1">Brak możliwości późniejszej zmiany.</span>
                            <span class="text-xs select-none text-red-500" v-if="errors.email"> Sprawdź poprawność pola.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Hasło*</label>
                            <div class="mt-1">
                                <input type="password" v-model="password" maxlength="20" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Hasło" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.password"> Pole musi zawierać minimum 6 znaków.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Informacje</div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Imię*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Jan" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Nazwisko*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.surname" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Kowalski" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.surname"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Telefon komórkowy*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="12" v-model="newUser.contact.phone" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Numer telefonu z kierunkowym" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.phone"> Poprawny format to +XXXXXXXXXXX lub XXXXXXXXX.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Stanowisko*</label>
                            <div class="mt-1">
                                <input type="text" v-model="newUser.position" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Sprzedawca" />
                            </div>
                            <span class="text-xs select-none text-red-500" maxlength="20" v-if="errors.position"> Pole nie może być puste.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 my-4">
                      <div class="mb-3 font-medium">Moduły</div>
                        <div class="flex items-center">
                          <input type="checkbox" v-model="newUser.permissions.users" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Użytkownicy</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.products" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Produkty</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.orders" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Zamówienia</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.prints" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Centrum wydruków</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.routes" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Trasy</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.storage" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Magazyn</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.storageEdit" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Zarządzanie magazynami / segmentami</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.driver" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Kierowca</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="newUser.permissions.manufacturers" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Producenci</span>
                        </div>
                    </div>

                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                   <span class="text-xs text-gray-500" v-if="loading">Zapisywanie..</span>
                  <button @click.prevent="emitClose" v-if="!loading" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                  <button ref="validateButton" @click.prevent="validateUser" v-if="!loading" class="ml-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Dodaj</button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import moment from 'moment';
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { QuestionMarkCircleIcon } from '@heroicons/vue/solid';
    import axios from 'axios';
    import {db} from "@/firebase/gfbconf.js";
    export default {
        name: "ClientsModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            QuestionMarkCircleIcon,
        },
        data: function () {
            return {
              loading: false,
              errors: {
                email: false,
                password: false,
                name: false,
                surname: false,
                phone: false,
                position: false
              },
              open: false,
              password: "",
              newUser: {
                  active: true,
                  name: "",
                  surname: "",
                  position: "",
                  contact: {
                      email: "",
                      phone: ""
                  },
                  id: null,
                  permissions: {
                      admin: false,
                      driver: true,
                      orders: true,
                      products: true,
                      users: true,
                      storageEdit: false,
                      storageDelete: false,
                      storage: true,
                      prints: true,
                      routes: true,
                      manufacturers: true,
                  },
                  routeSchedules: []
              }
            }
        },
        methods: {
          callNotificationOnError(translatedCode)
          {
            this.emitClose();
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: translatedCode,
              success: false
            });
          },
          checkAutheError(code)
          {
            switch (code) {
              case 'auth/email-already-in-use':
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                break;
              case 'auth/email-already-exists':
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                break;
              case 'auth/invalid-email':
                this.callNotificationOnError('E-mail jest niepoprawny.');
                break;
              case 'auth/operation-not-allowed':
                this.callNotificationOnError('Operacja zakazana przez system autoryzacji.');
                break;
              case 'auth/weak-password':
                this.callNotificationOnError('Hasło jest zbyt słabe. Sugerowane hasło powinno być dłuższe niż 6 znaków oraz zawierać znaki specjalne.');
                break;
              default:
                console.log(code);
                break;
            }   
          },
          resetErrors()
          {
            // Reset all errors
            this.errors.email = false;
            this.errors.password = false;
            this.errors.name =  false;
            this.errors.surname = false;
            this.errors.phone = false;
            this.errors.position = false;
          },
          async addUser()
          {
            this.loading = true;
            try{
              this.newUser.contact.email = this.newUser.contact.email.toLowerCase();
              const checkForUsrInDb = await db.collection("UsersData").where("contact.email", "==", this.newUser.contact.email).get();
              if(checkForUsrInDb.empty)
              {
                const result = await axios.post(`${this.$store.state.apiLink}/newUser`, {user: this.newUser, password: this.password, appVersion: process.env.VUE_APP_VERSION});
                if(result.data.success)
                {
                  this.emitClose();
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona sukcesem!",
                    subheader: "Użytkownik został utworzony.",
                    success: true
                  });
                  this.loading = false;
                }
                else
                {
                  this.checkAutheError(result.data.log);
                  this.loading = false;
                }
              }
              else
              {
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                this.loading = false;
              }
              // const usr = await auth.createUserWithEmailAndPassword(this.newUser.contact.email, this.password);
              
            }
            catch(error)
            {
              console.log(error);
              this.loading = false;
            }
            
          },
          userValidator()
          {
            let errorsAmount = 0;
            this.resetErrors();
            if(this.newUser.contact.email === 0)
            {
              errorsAmount++;
              this.errors.email = true;
            }
            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.newUser.contact.email.toLowerCase()) === false)
            {
              errorsAmount++;
              this.errors.email = true;
            }
            if(this.password.length < 6)
            {
              errorsAmount++;
              this.errors.password = true;
            }
            if(this.newUser.name.length === 0)
            {
              errorsAmount++;
              this.errors.name = true;
            }
            if(this.newUser.surname.length === 0)
            {
              errorsAmount++;
              this.errors.surname = true;
            }
            if(/^\+?[1-9][0-9]{7,14}$/.test(this.newUser.contact.phone) === false)
            {
              errorsAmount++;
              this.errors.phone = true;
            }
            if(this.newUser.position.length === 0)
            {
              errorsAmount++;
              this.errors.position = true;
            }

            return errorsAmount;

          },
          async validateUser()
          {
            const errors = this.userValidator();
            if(errors > 0) return;
            this.addUser();
          },
          emitClose()
          {
            this.resetErrors();
            this.$emit("closeClientModal");
            this.clearData();
          },
          clearData()
          {
              this.password = "";
              this.newUser = {
                    active: true,
                    name: "",
                    surname: "",
                    position: "",
                    contact: {
                        email: "",
                        phone: ""
                    },
                    id: null,
                    permissions: {
                        admin: false,
                        orders: true,
                        products: true,
                        users: true,
                        prints: true,
                        routes: true,
                        driver: true,
                        storage: true,
                    },
                    routeSchedules: []
                }            
          }
          // Create new client
        },
        computed:{

        }
    };
</script>