<template>
<div>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
                <router-link to="/dashboard" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500" aria-current="page">
            <li class="flex">
                <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Moduł produkty</div>
                </div>
            </li>
                </router-link>
        </ol>
    </nav>
</div>
<div>
    <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 mb-4 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Wybierz inny moduł</router-link>
    <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <ViewListIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/manufacturers/list" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Lista producentów 
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Zarządzaj listą dostępnych producentów.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <FolderAddIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link disabled to="/dashboard/manufacturers/add" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Dodaj producentów
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj producentów do listy.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
    </div>
</div>
</template>

<script>
import { HomeIcon } from '@heroicons/vue/solid'
import { ViewListIcon, DocumentAddIcon,ShoppingCartIcon, FolderAddIcon,CameraIcon,ClipboardCheckIcon  } from '@heroicons/vue/outline'

export default {
    components: {
        HomeIcon,
        ViewListIcon,
        DocumentAddIcon,
        ShoppingCartIcon,
        FolderAddIcon,CameraIcon,ClipboardCheckIcon 
    },
    created() {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
            {
                this.$router.push("/hub")
            }
    }

 }
</script>