<template>
<div>
  <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
    <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
    <li class="flex">
      <div class="flex items-center">
      <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
        <span class="sr-only">Home</span>
      </router-link>
      </div>
    </li>
    <li class="flex">
      <div class="flex items-center">
      <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
      </svg>
        <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazynu</router-link>
      </div>
    </li>
    <li class="flex">
      <div class="flex items-center">
      <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
      </svg>
      <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Zarządzanie zdarzeniami, logami</div>
      </div>
    </li>
    </ol>
  </nav>
  
  <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 mb-4 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
      &larr; Wybierz inny moduł
  </router-link>

  <div class="pb-6">
    <div class="sm:hidden">
      <Listbox as="div" v-model="selected">
        <ListboxLabel class="block text-sm font-semibold uppercase text-gray-600">Wybierz opcję</ListboxLabel>
        <div class="relative mt-2">
          <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span class="block truncate">{{ selected.length === 0 ? 'Wybierz opcję do sprawdzenia...' : selected.name }}</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption as="template" v-for="(tab, index) in tabs" :key="index" :value="tab" v-slot="{ active, selected }">
                <li @click="openTab(index)" :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ tab.name }}</span>
                  <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <a v-for="(tab, index) in tabs" @click="openTab(index)" :key="index" :class="[tab.current ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700', 'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium select-none cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
  </div>

  <!-- FILTER -->
  <div class="min-w-0 flex-1 xl:col-span-6">
    <div :class="['flex flex-col gap-2 lg:gap-0 lg:flex-row', showStatusFilters ? 'lg:justify-between' : 'justify-end']">
      <Transition name="fade">
      <div v-if="showStatusFilters" class="items-center flex flex-col lg:flex-row justify-between gap-4 select-none">
        <div class="w-full lg:w-fit flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
          <div class="flex items-center">
            <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true}">
              <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data utworzenia</label>
              <input type="date" @change="pickDateOfCreate($event)" v-model="pickedDateOfCreate" ref="pickedCreatedDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
            </div>
            <Transition name="fade2">
            <div v-if="pickedDateOfCreate !== null" class="ml-2 mr-2">
              <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
            </div>
            </Transition>
          </div>
        </div>
        <div class="w-full lg:w-fit flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                  <option value="creationAsc">Utworzono: Od najstarszych</option>
                  <option value="creationDesc">Utworzono: Od najnowszych</option>
                  <!-- <option v-for="status in statuses" :value="status.id" :key="status.id">{{status.name}}</option> -->
                </select>
            </div>
            <button type="button" @click="this.pickStatus()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
        </div>
      </div>
      </Transition>
      <div @click="showFilters()" class="flex flex-col items-center">
        <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
        <span class="text-xs mt-1 font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
      </div>
    </div>
  </div>

  <div v-if="this.tabs[0].current">
    <Transition name="fade">
      <ul role="list" class="divide-y divide-gray-100" v-if="logList.length > 0">
        <li v-for="(log, index) in logList" :key="index" class="flex items-center justify-between gap-x-6 py-5">
          <div class="min-w-0">
            <div class="flex items-start gap-x-3">
              <p class="text-sm font-semibold leading-6 text-gray-900">{{ log.info }}</p>
              <p :class="{'select-none uppercase inline-flex items-center rounded-md px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-opacity-30': true, 'ring-green-600 text-green-700 bg-green-50': log.part.status === 1, 'ring-red-600 text-red-700 bg-red-50': log.part.status === 2, 'ring-yellow-600 text-yellow-700 bg-yellow-50': log.part.status === 3}">
                {{ getStatusById(log.part.status).name }}
              </p>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p class="whitespace-nowrap">
                <span>{{ moment.unix(log.meta.created.unix).format('MMMM Do YYYY, HH:mm:ss') }}</span>
              </p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate uppercase">Dodane przez <span class="font-semibold normal-case">{{ log.meta.created.createdBy.user }}</span></p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate uppercase">Faktura <span class="font-semibold normal-case">{{ typeof log.invoice === 'string' ? log.invoice.length === 0 ? '---' : log.invoice : '---' }}</span></p>
            </div>
          </div>
        </li>
      </ul>
    </Transition>
  </div>
  <div v-if="this.tabs[1].current">
    <Transition name="fade">
      <ul role="list" class="divide-y divide-gray-100" v-if="logList.length > 0">
        <li v-for="(log, index) in logList" :key="index" class="flex items-center justify-between gap-x-6 py-5">
          <div class="min-w-0">
            <div class="flex items-start gap-x-3">
              <p class="text-sm font-semibold leading-6 text-gray-900">{{ log.info }}</p>
              <p :class="{'select-none uppercase inline-flex items-center rounded-md px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-opacity-30': true, 'ring-green-600 text-green-700 bg-green-50': log.part.status === 1, 'ring-red-600 text-red-700 bg-red-50': log.part.status === 2, 'ring-yellow-600 text-yellow-700 bg-yellow-50': log.part.status === 3}">
                {{ getStatusById(log.part.status).name }}
              </p>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p class="whitespace-nowrap">
                <span>{{ moment.unix(log.meta.created.unix).format('MMMM Do YYYY, HH:mm:ss') }}</span>
              </p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate uppercase">Wydano przez <span class="font-semibold normal-case">{{ log.meta.created.createdBy.user }}</span></p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate uppercase">Wariant <span class="font-semibold normal-case">{{ log.product.variant.name }}</span></p>
            </div>
          </div>
        </li>
      </ul>
    </Transition>
  </div>
  <div v-if="this.tabs[2].current">
    <Transition name="fade">
      <ul role="list" class="divide-y divide-gray-100" v-if="logList.length > 0">
        <li v-for="(log, index) in logList" :key="index" class="flex items-center justify-between gap-x-6 py-5">
          <div class="min-w-0">
            <div class="flex items-start gap-x-3">
              <p class="text-sm font-semibold leading-6 text-gray-900">{{ log.info }}</p>
              <p :class="{'select-none uppercase inline-flex items-center rounded-md px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-opacity-30': true, 'ring-purple-600 text-purple-700 bg-purple-50': log.status === 4}">
                {{ getStatusById(log.status).name }}
              </p>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p class="whitespace-nowrap">
                <span>{{ moment.unix(log.meta.created.unix).format('MMMM Do YYYY, HH:mm:ss') }}</span>
              </p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate uppercase">Dodane przez <span class="font-semibold normal-case">{{ log.meta.created.createdBy.user }}</span></p>
            </div>
          </div>
        </li>
      </ul>
    </Transition>
  </div>
  
  <div v-if="this.tabs[3] !== undefined ? this.tabs[3].current : false">
    <Transition name="fade">
      <ul role="list" class="divide-y divide-gray-100" v-if="logList.length > 0">
        <li v-for="(log, index) in logList" :key="index" class="flex items-center justify-between gap-x-6 py-5">
          <div class="min-w-0">
            <div class="flex items-start gap-x-3">
              <p class="text-sm font-semibold leading-6 text-gray-900">{{ log.info }}</p>
              <p :class="{'select-none uppercase inline-flex items-center rounded-md px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-opacity-30 ring-red-600 text-red-700 bg-red-50': true,}">
                {{ getStatusById(log.part.status).name }}
              </p>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p class="whitespace-nowrap">
                <span>{{ moment.unix(log.meta.created.unix).format('MMMM Do YYYY, HH:mm:ss') }}</span>
              </p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate uppercase">Usunięto przez <span class="font-semibold normal-case">{{ log.meta.created.createdBy.user }}</span></p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate uppercase">Wariant <span class="font-semibold normal-case">{{ log.product.variant.name }}</span></p>
            </div>
          </div>
        </li>
      </ul>
    </Transition>
  </div>

  <Transition name="fade">
    <EmptyState class="mt-6" v-if="logList.length === 0" />
  </Transition>

  <div class="flex items-center justify-center mt-4" v-if="!queryLimitReached && logList.length > 0">
    <button type="button" @click.prevent="loadMoreBacklogs" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
  </div> 

</div>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import moment from 'moment';
import axios from 'axios';
import { HomeIcon } from '@heroicons/vue/solid';
import { DocumentDownloadIcon, DocumentAddIcon, QrcodeIcon, ViewGridAddIcon, ViewGridIcon, ViewListIcon, CollectionIcon, ShoppingCartIcon, TruckIcon, ArchiveIcon, PuzzleIcon, CubeIcon, DotsVerticalIcon, CheckIcon, ChevronDownIcon, FilterIcon } from '@heroicons/vue/outline';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import EmptyState from '../components/EmptyState.vue';

export default {
  components: {
    CheckIcon, FilterIcon, ChevronDownIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, EmptyState, Menu, MenuButton, MenuItem, MenuItems, DocumentDownloadIcon, DocumentAddIcon, QrcodeIcon, ViewGridAddIcon, ViewGridIcon, HomeIcon, ArchiveIcon, ViewListIcon, CollectionIcon, TruckIcon, ShoppingCartIcon, PuzzleIcon, CubeIcon, DotsVerticalIcon
  },
  data() {
    return {
      tabs: [
        { name: 'Przyjęcie produtków', query: 'received', current: false, hidden: false },
        { name: 'Wydanie produktów', query: 'released', current: false, hidden: false },
        { name: 'Przetworzenie zamówień', query: 'processed', current: false, hidden: false },
        { name: 'Usunięte produkty', query: 'deleted', current: false, hidden: false },
      ],
      selected: '',
      statuses: [],
      logList: [],
      dbListener: null,
      query: null,
      queryLimit: 25,
      queryLimitReached: false,
      showStatusFilters: false,
      pickedDateOfCreate: null,
      pickedDateOfCreateUnix: null,
      sortName: "creationDesc",
      sortType: {
        order: "desc",
        type: "meta.created.unix"
      }
    }
  },
  async created() {
    this.moment = moment;
    moment.locale('pl');
    if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storage)
    {
      this.$router.push("/hub");
    };
    if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storageDelete)
    {
      this.tabs[3].hidden = true;
      this.tabs = this.tabs.filter((tab, index) => index !== 3 || !tab.hidden);
    };
    this.openTab(0)
    await this.getStatuses();
  },
  beforeUnmount() {
    if(this.dbListener !== null)
    {
      this.dbListener();
    }
  },
  methods: {
    showFilters()
    {
      this.showStatusFilters = !this.showStatusFilters
      if(!this.showStatusFilters){
        this.pickedDateOfCreate = null;
        this.pickedDateOfCreateUnix = null
        this.sortName = "creationDesc",
        this.sortType = {
            order: "desc",
            type: "meta.created.unix"
        }
        this.pickStatus()
      }
    },
    pickStatus(){
      this.dbListener()
      this.getAllBacklogs()
    },
    changeSort()
    {
      if(this.sortName === "creationAsc")
      {
        this.sortType = {
          order: "asc",
          type: "meta.created.unix"
        }
      }
      if(this.sortName === "creationDesc")
      {
        this.sortType = {
          order: "desc",
          type: "meta.created.unix"
        }
      }
    // this.pickStatus()
    },
    pickDateOfCreate(event)
    {
      let mDate = moment(event.target.value)
      if(!mDate.isValid())
      {
        this.$store.commit('setNotification',{
            show: true,
            head: "Popraw datę!",
            subheader: `Wykryto, że data jest niepoprawna.`,
            success: false
        });
        this.pickedDateOfCreate = null;
        this.pickedDateOfCreateUnix = null
      }
      else
      {
        this.pickedDateOfCreateUnix = mDate.unix()
        // this.pickStatus()
      }
    },
    resetDateOfCreate()
    {
      this.pickedDateOfCreate = null;
      this.pickedDateOfCreateUnix = null
      this.pickStatus()
    },
    openTab(indx){
      for (let i = 0; i < this.tabs.length; i++)
      {
        this.tabs[i].current = false;
      }
      if(this.$route.query.type === this.tabs[indx].query)
      {
        this.selected = this.tabs[indx];
      }
      this.tabs[indx].current = true;
      if(indx === 0)
      {
        this.$router.push({path: this.$route.fullPath, query: {'type': 'received'} });
        this.$route.query.type = 'received';
      }
      else if(indx === 1)
      {
        this.$router.push({path: this.$route.fullPath, query: {'type': 'released'} });
        this.$route.query.type = 'released';
      }
      else if(indx === 2)
      {
        this.$router.push({path: this.$route.fullPath, query: {'type': 'processed'} });
        this.$route.query.type = 'processed';
      }
      else if(indx === 3)
      {
        this.$router.push({path: this.$route.fullPath, query: {'type': 'deleted'} });
        this.$route.query.type = 'deleted';
      }
      this.getAllBacklogs();
    },
    getStatusById(id)
    {
      let currentStatus = {
        name: "Brak",
        info: "Brak",
        id: null
      }
      this.statuses.forEach((status) => {
        if(status.id === id)
        {
          currentStatus = {
            name: status.name,
            info: status.info,
            id: status.id
          }
        }
      })
      return currentStatus
    },
    async getStatuses(){
      let res = await db.collection("BacklogStatuses").doc("StatusList").get()
      this.statuses = res.data().list
    },
    getAllBacklogs()
    {
      this.logList = [];
      this.query = null;
      this.queryLimit = 25;
      if(this.dbListener !== null){
        this.dbListener()
        this.dbListener = null
      }
      this.query = db.collection("StorageBacklogs")

      if(this.$route.query.type === "received")
      {
        this.query = this.query.where("part.status", "in", [1,3])
      }
      else if(this.$route.query.type === "released")
      {
        this.query = this.query.where("part.status", "in", [2])
      }
      else if(this.$route.query.type === "processed")
      {
        this.query = this.query.where("status", "in", [4])
      }
      else if(this.$route.query.type === "deleted")
      {
        this.query = this.query.where("part.status", "in", [5])
      };
      
      if(this.pickedDateOfCreateUnix != null){
        this.pickedDateOfCreateUnix = parseInt(this.pickedDateOfCreateUnix)
        let startOfDay = this.pickedDateOfCreateUnix
        let endOfDay = this.pickedDateOfCreateUnix + 86399
        this.query = this.query.where('meta.created.unix', ">=", startOfDay).where('meta.created.unix', "<=", endOfDay)
      };
      
      if(this.pickedDateOfCreateUnix !== null){
        this.query = this.query.orderBy(`meta.created.unix`, `${this.sortType.order}`)
      };
      
      if(this.pickedDateOfCreateUnix === null){
        this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)
      };

      this.dbListener = this.query.limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.logList = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
          this.queryLimitReached = true;
        }
        else
        {
          this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
          let current = doc.data()
          this.logList.push(current);
        });
      });
      this.loading = false;
    },
    continueListening()
    {
      this.queryLimit = this.queryLimit + 25;
      this.dbListener = this.query.limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.logList = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
          this.queryLimitReached = true;
        }
        else
        {
          this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
          let current = doc.data()
          this.logList.push(current);
        });
      });
    },
    loadMoreBacklogs()
    {
      this.dbListener();
      this.continueListening();      
    },
  },
 }
</script>


<style lang="scss" scoped>

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

</style>