<template>
  <TransitionRoot as="template" :show="open && managedUsr !== undefined && managedUsr !== null">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Zarządzaj użytkownikiem</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Zmień dane użytkownika {{`${managedUsr.name} ${managedUsr.surname}`}}. </p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between" v-if="!managedUsr.permissions.admin">
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Dane logowania </div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">E-mail <span></span></label>
                            <div class="mt-1 text-gray-500">
                              <span>{{managedUsr.contact.email}}</span>
                            </div>
                        </div>
                        <div class="select-none mt-2">
                            <label for="email" class="block text-sm font-medium text-gray-700">Hasło<span></span></label>
                            <div class="mt-1 text-gray-500">
                              <button type="button" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click.prevent="resetPwd">Zresetuj hasło</button>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Informacje</div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Imię*</label>
                            <div class="mt-1">
                                <input type="text" v-model="userUpdate.name" maxlength="20"  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Jan" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Nazwisko*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="userUpdate.surname" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Kowalski" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.surname"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Telefon komórkowy*</label>
                            <div class="mt-1">
                                <input v-model="userUpdate.phone" maxlength="12" type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Numer telefonu z kierunkowym" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.phone"> Poprawny format to +XXXXXXXXXXX.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Stanowisko*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="userUpdate.position" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Sprzedawca" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.position"> Pole nie może być puste.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Moduły</div>
                        <div class="flex items-center">
                          <input type="checkbox" v-model="userUpdate.permissions.users" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Użytkownicy</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.products" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Produkty</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.orders" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Zamówienia</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.prints" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Centrum wydruków</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.routes" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Trasy</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.storage" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Magazyn</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.storageEdit" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Zarządzanie magazynami / segmentami</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.storageDelete" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Usuwanie produktu z magazynu</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.driver" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Kierowca</span>
                        </div>
                        <div class="flex items-center mt-1">
                          <input type="checkbox" v-model="userUpdate.permissions.manufacturers" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          <span class="text-xs ml-2 select-none">Producenci</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium text-red-500">Usuń użytkownika</div>
                        <div class="flex items-center mt-1 mb-4">
                          <input v-model="deleteUserOnSave" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500">
                          <span class="text-xs ml-2 select-none text-">Chcę usunąć to konto - wiem co robię.</span>
                        </div>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between" v-if="managedUsr.permissions.admin">
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">
                          <span class="text-gray-500 font-normal text-xs">To konto jest administratorem. Nie możesz zarządzać tym kontem.</span>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <button @click.prevent="emitClose" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" v-if="!loading">Zamknij</button>
                  <button v-if="!managedUsr.permissions.admin && !deleteUserOnSave && !loading" @click.prevent="validateUserDataBeforeUpdate" class="ml-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Aktualizuj</button>
                  <button type="button" @click.prevent="deleteUser" v-if="!managedUsr.permissions.admin && deleteUserOnSave && !loading" class="ml-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń konto</button>
                  <span class="text-xs text-gray-500" v-if="loading">Zapisywanie..</span>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { QuestionMarkCircleIcon } from '@heroicons/vue/solid';
    import axios from 'axios';
    export default {
        name: "ClientsModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            QuestionMarkCircleIcon,
        },
        props: ['managedUsr'],
        data: function () {
            return {
              loading: false,
              deleteUserOnSave: false,
              open: false,
              userUpdate: {
                name: "",
                surname: "",
                phone: "",
                position: "",
                permissions: {
                  admin: false,
                  orders: true,
                  products: true,
                  users: true,
                  storage: true,
                  storageEdit: false,
                  storageDelete: false,
                  driver: true,
                  prints: true,
                  routes: true,
                  manufacturers: true,
                },
              },
              errors: {
                name: false,
                surname: false,
                phone: false,
                position: false
              }
                // If you change something bellow, edit it also in NewComplaint.vue when creating new client
            }
        },
        created()
        {
          this.createUserUpdateObj()
        },
        methods: {
          async resetPwd()
          {
            const res = await axios.post(`${this.$store.state.apiLink}/resetPasswordViaLink`, {
              email: this.managedUsr.contact.email
            })
            if(res.data.success)
            {
              window.open(res.data.link, '_blank').focus();
            }
          },
          createUserUpdateObj()
          {
            this.userUpdate.name = this.managedUsr.name.toString();
            this.userUpdate.surname = this.managedUsr.surname.toString();
            this.userUpdate.phone = this.managedUsr.contact.phone.toString();
            this.userUpdate.position = this.managedUsr.position.toString();
            this.userUpdate.permissions.orders = this.managedUsr.permissions.orders;
            this.userUpdate.permissions.products = this.managedUsr.permissions.products;
            this.userUpdate.permissions.users = this.managedUsr.permissions.users;
            this.userUpdate.permissions.prints = this.managedUsr.permissions.prints;
            this.userUpdate.permissions.routes = this.managedUsr.permissions.routes;
            this.userUpdate.permissions.driver = this.managedUsr.permissions.driver;
            this.userUpdate.permissions.storage = this.managedUsr.permissions.storage;
            this.userUpdate.permissions.storageEdit = this.managedUsr.permissions.storageEdit;
            this.userUpdate.permissions.storageDelete = this.managedUsr.permissions.storageDelete;
            this.userUpdate.permissions.manufacturers = this.managedUsr.permissions.manufacturers;
          },
          async deleteUser()
          {
            if(this.managedUsr.id === undefined || this.managedUsr.id === null) return;
            if(this.managedUsr.id.length === 0) return;
            this.loading = true;
            try{
              const res = await axios.post(`${this.$store.state.apiLink}/deleteUser`, {
                id: this.managedUsr.id,
                elasticId: this.managedUsr.elasticSearch.docId,
              });
              if(res.data.success)
              {
                this.$emit('pickedWasDeleted');
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona sukcesem!",
                  subheader: "Użytkownik został usunięty.",
                  success: true
                });
                this.loading = false;                
              }
              else
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Użytkownik nie został usunięty.",
                  success: false
                });
                this.loading = false; 
              }       
            }
            catch(error)
            {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Użytkownik nie został usunięty.",
                  success: false
                });
                this.loading = false;
            }
          },
          async updateUser()
          {
            if(this.managedUsr.id === undefined || this.managedUsr.id === null) return;
            if(this.managedUsr.id.length === 0) return;
            this.loading = true;
            try{
              const res = await axios.post(`${this.$store.state.apiLink}/updateUser`, {
                loggedUser: this.$store.state.userData,
                update: this.userUpdate,
                id: this.managedUsr.id,
                elasticId: this.managedUsr.elasticSearch.docId
              })
              if(res.data.success)
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona sukcesem!",
                  subheader: "Użytkownik został zmodyfikowany.",
                  success: true
                });
                this.loading = false;
              }
              else
              {
                this.emitClose();
                this.loading = false;
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Coś poszło nie tak..",
                  success: false
                });
              }
            }
            catch(error)
            {
              console.log(error);
              this.emitClose();
              this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: "Coś poszło nie tak..",
                success: false
              });
              this.loading = false;
            }
          },
          clearErrors()
          {
            this.errors.name = false;
            this.errors.surname = false;
            this.errors.phone = false;
            this.errors.position = false;
          },
          userValidator()
          {
            let errorsAmount = 0;
            this.clearErrors();
            if(this.userUpdate.name.length === 0)
            {
              errorsAmount++;
              this.errors.name = true;
            }
            if(this.userUpdate.surname.length === 0)
            {
              errorsAmount++;
              this.errors.surname = true;
            }
            if(/^\+?[1-9][0-9]{7,14}$/.test(this.userUpdate.phone) === false)
            {
              errorsAmount++;
              this.errors.phone = true;
            }
            if(this.userUpdate.position.length === 0)
            {
              errorsAmount++;
              this.errors.position = true;
            }

            return errorsAmount;

          },
          validateUserDataBeforeUpdate()
          {
            this.clearErrors();
            if(this.userValidator() > 0) return;
            this.updateUser();    
          },
          emitClose()
          {
            this.clearErrors();
            this.$emit("closeClientModal");
          },
          // Create new client
        },
        computed:{

        }
    };
</script>