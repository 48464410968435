<template>
   <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu tras</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Kalendarz transportowy</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/routes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <div v-if="!loading" class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
            <div class="lg:flex lg:h-full lg:flex-col">
            <header class="flex items-center justify-between border-b border-gray-200 py-4">
            <div class="flex flex-row items-center gap-5 w-full justify-between sm:justify-end  ">
                <!-- Change month -->
                <div class="flex items-stretch rounded-md shadow-sm">
                    <button @click="changeMonth('subtract')" type="button" class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Previous month</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button type="button" class="w-20 border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative">{{currentMonth.monthName}}</button>
                    <button @click="changeMonth('add')" type="button" class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Next month</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>

                <!-- Change year -->
                <div class="flex items-stretch rounded-md shadow-sm">
                    <button @click="changeYear('subtract')" type="button" class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Previous month</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button type="button" class="w-14 border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative">{{currentMonth.year}}</button>
                    <button @click="changeYear('add')" type="button" class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Next month</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
            </header>
            <div class="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div class="bg-white py-2">Pon</div>
                    <div class="bg-white py-2">Wt</div>
                    <div class="bg-white py-2">Śr</div>
                    <div class="bg-white py-2">Czw</div>
                    <div class="bg-white py-2">Pt</div>
                    <div class="bg-white py-2">Sob</div>
                    <div class="bg-white py-2">Nie</div>
                </div>
                <div class="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    <!-- above large screen width -->
                    <div class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 gap-px border-b border-gray-200">
                        <button @click="selectedDay === day ? selectedDay = null : selectedDay = day" v-for="day in days" :key="day.date" class="flex flex-col items-between gap-0.5 hover:bg-gray-50" :class="[day.monthName !== currentMonth.monthName ? 'bg-gray-100' : 'bg-white' , 'relative py-2 px-3', selectedDay && (selectedDay.fullDate === day.fullDate) ? 'bg-blue-50 hover:bg-blue-50' : undefined]">
                            <time :datetime="day.fullDate" :class="day.fullDate === currentDay ? 'flex h-6 w-6 items-center justify-center rounded-full bg-purple-600 font-bold text-white' : undefined" class="font-semibold">{{day.day}}</time>
                            <div class="flex flex-col justify-end h-full items-start">
                                <div><span v-if="day.amountOfDepartures > 0" :class="[day.monthName === currentMonth.monthName ? 'bg-yellow-50' : '']" class="text-gray-500 px-2 rounded-lg mt-3">Odjazdy: <span class="font-semibold text-yellow-500">{{ day.amountOfDepartures }}</span></span></div>
                                <div><span v-if="day.amountOfOngoing > 0" :class="[day.monthName === currentMonth.monthName ? 'bg-indigo-50' : '']" class="text-gray-500 px-2 rounded-lg mt-3">Trwające trasy: <span class="font-semibold text-indigo-500">{{ day.amountOfOngoing}}</span></span></div>
                                <div><span v-if="day.amountOfArrivals > 0" :class="[day.monthName === currentMonth.monthName ? 'bg-green-50' : '']" class="text-gray-500 px-2 rounded-lg mt-3">Przyjazdy: <span class="font-semibold text-green-500">{{ day.amountOfArrivals}}</span></span></div>
                            </div>
                        </button>
                    </div>

                    <!-- below large screen width -->
                    <div class="isolate grid w-full grid-cols-7 grid-rows-5 gap-px border-b border-gray-200 lg:hidden">
                        <button @click="selectedDay === day ? selectedDay = null : selectedDay = day" v-for="day in days" :key="day.date" type="button" :class="[day.monthName !== currentMonth.monthName ? 'bg-gray-100' : 'bg-white', selectedDay && (selectedDay.fullDate === day.fullDate) ? 'bg-blue-50 hover:bg-blue-50' : undefined ,'flex h-14 flex-col py-2 px-3 hover:bg-gray-50 focus:z-10']">
                            <time :datetime="day.date" :class="[ day.fullDate === currentDay ? ' text-purple-500' : undefined ]" >{{day.day}}</time>
                            <span class="sr-only">{{ day.events.length }} events</span>
                            <div>
                                <span v-if="day.events.length > 0" class="rounded-full px-2 bg-indigo-400">{{day.events.length}}</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Selected day details -->
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 -translate-y-5" enter-to-class="transform opacity-100 translate-y-0" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 translate-y-0" leave-to-class="transform opacity-0 -translate-y-0">
                <div v-if="selectedDay" class="p-6 bg-white">
                    <div class="flex items-center">
                        <div class="flex-auto">
                            <h1 class="text-base leading-6 text-gray-700">Szczegóły na dzień: <span class="font-semibold">{{ selectedDay.day }} {{ selectedDay.monthName }} {{selectedDay.year}} </span></h1>
                            <p class="mt-2 text-md text-gray-700">Liczba tras: <span class="font-semibold">{{ selectedDay.events.length }}</span></p>
                        </div>
                        <button @click="selectedDay = null " type="button" class="inline-flex items-center rounded-full p-2 text-gray-400 hover:text-gray-500">
                            <XIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                    <TableRoutesList v-if="selectedDay.events.length && !loading" :routes="selectedDay.events"></TableRoutesList>
                    <EmptyState class="mt-4" v-else></EmptyState>
                </div>
                </transition>
            </div>
        </div>
        </div>
        <LoadingSpinnerHub v-if="loading"/>
    </div>
</template>

<script>
import moment from 'moment'
import {
    HomeIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    XIcon,
    DotsHorizontalIcon,
} from '@heroicons/vue/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {db} from "@/firebase/gfbconf.js";
import TableRoutesList from '../components/TableRoutesList.vue'
import EmptyState from '../components/EmptyState.vue'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';


 export default {
    components: {
        XIcon,
        HomeIcon,
        ChevronDownIcon,
        ChevronLeftIcon,
        ChevronRightIcon,
        ClockIcon,
        DotsHorizontalIcon,
        Menu, MenuButton, MenuItem, MenuItems,
        TableRoutesList,
        EmptyState,
        LoadingSpinnerHub
    },
    data() {
        return {
            loading: false,
            orders: [],
            allDates: [],
            uniqueDates: [],
            selectedDay: '',
            currentDay: '',
            days: [],
            currentMonth: {
                month: 0,
                year: 0,
                monthName: ""
            },
            routes: [],
            lastLoaded: null,
            dbListener: null,
            modal: {
                open: false,
                route: null,
            },
            secModal:{
                open:false,
                product:null,
            },
            vehicles: {},
        }
    },
    beforeUnmount() {
            if(this.dbListener !== null){
                this.dbListener();
            }
        },
     methods: {
        async getVehicles(){
            let vehicleData = await db.collection('Vehicles').get()
            for (let i = 0; i < vehicleData.docs.length; i++) {
                const vehicle = vehicleData.docs[i];
                const vehicleToPush = vehicle.data()
                this.vehicles[vehicleToPush.id] = vehicleToPush
            }
        },
        getRoutesForDay(){
            for(let day of this.days){
                const routes = []
                let amountOfDepartures = 0
                let amountOfOngoing = 0
                let amountOfArrivals = 0
                for(let route of this.routes){
                    const compare = moment(day)
                    const start = moment.unix(route.date.start.seconds)
                    const end = moment.unix(route.date.end.seconds)
                    const result = compare.isBetween(start, end, 'days', '[]')

                    if(result) {
                        routes.push(route)
                        let isOngoing = true
                        if(start.isSame(compare, 'day')) {
                            amountOfDepartures++
                            isOngoing = false
                        }
                        if(end.isSame(compare, 'day')) {
                            amountOfArrivals++
                            isOngoing = false
                        }
                        if(isOngoing) {
                            amountOfOngoing++
                        }
                    } 
                }
                day.events = routes
                day.amountOfDepartures = amountOfDepartures
                day.amountOfOngoing= amountOfOngoing
                day.amountOfArrivals= amountOfArrivals
            }        
        },
        setCurrentMonth()
        {
            this.currentMonth.year = moment().year();
            this.currentMonth.month = moment().month();
            this.currentMonth.monthName = moment().format('MMMM');
            this.currentDay = moment().format('YYYY-MM-DD');
            this.getDaysOfMonth();
            let currentDayDate
            for(let i = 0; i < this.days.length; i++) {
                if(this.days[i].fullDate === this.currentDay) {
                currentDayDate = this.days[i]
                }
            }
            this.setDay(currentDayDate)
        },
        getDaysOfMonth()
        {
            this.days = [];
            let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
            let amountOfDaysInMonth = moment(current,"DD-M-YYYY").daysInMonth();

            for(let i=1; i<amountOfDaysInMonth; i++)
            {
                let momentObj = moment(`${i}-${this.currentMonth.month+1}-${this.currentMonth.year}`,"D-M-YYYY")
                let day = {
                    fullDate: momentObj.format("YYYY-MM-DD"), 
                    dayOfWeek: momentObj.day(),
                    year: momentObj.year(),
                    month: momentObj.month(),
                    day: momentObj.date(),
                    monthName: momentObj.format('MMMM'),
                    unix: momentObj.unix(),
                    weekNum: momentObj.week(),
                    events: [],
                    titleDate: momentObj.format("Do MMMM YYYY")
                }
                this.days.push(day);
            }
            this.addRestOfDaysToCallendar();
        },
        addRestOfDaysToCallendar()
        {
            let amountFromStart  = this.days[0].dayOfWeek-7*(-1);
            let amountFromEnd = this.days[this.days.length-1].dayOfWeek;
            let currentDayFromStart = moment(this.days[0].fullDate,"YYYY-MM-DD");
            let currentDayFromEnd = moment(this.days[this.days.length-1].fullDate,"YYYY-MM-DD");
            
            for(let i=0; i<amountFromStart-1; i++)
            {
                let currentDay = currentDayFromStart.subtract(1, 'days')
                this.days.unshift({
                    fullDate: currentDay.format("YYYY-MM-DD"), 
                    dayOfWeek: currentDay.day(),
                    year: currentDay.year(),
                    month: currentDay.month(),
                    day: currentDay.date(),
                    monthName: currentDay.format('MMMM'),
                    unix: currentDay.unix(),
                    weekNum: currentDay.week(),
                    events: [],
                    titleDate: currentDay.format("Do MMMM YYYY")
                })
            }
            
            for(let i=0; i<=6-amountFromEnd; i++)
            {
                let currentDay = currentDayFromEnd.add(1, 'days')
                this.days.push({
                    fullDate: currentDay.format("YYYY-MM-DD"), 
                    dayOfWeek: currentDay.day(),
                    year: currentDay.year(),
                    month: currentDay.month(),
                    day: currentDay.date(),
                    monthName: currentDay.format('MMMM'),
                    unix: currentDay.unix(),
                    weekNum: currentDay.week(),
                    events: [],
                    titleDate: currentDay.format("Do MMMM YYYY")
                })
            }

            if(this.days.length/7 >= 6)
            {
                for(let i=0; i<7;i++)
                {
                    this.days.shift();
                }
            }
        },
        getTime(unix) {
            return moment.unix(unix).format('h:mm:ss A')
        },
        async setDay(day)
        {
            if(day !== this.selected)
            {
                this.selected = day;
                // this.getEvents(day)
            }
        },
        changeMonth(type)
        {
            let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
            if(type === 'add')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").add(1, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").add(1, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").add(1, 'months').format('MMMM');
            }
            if(type === 'subtract')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").subtract(1, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").subtract(1, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").subtract(1, 'months').format('MMMM');
            }
            this.getDaysOfMonth();
            this.getRoutesForDay()
        },
        changeYear(type)
        { 
            let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
            if(type === 'add')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").add(12, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").add(12, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").add(12, 'months').format('MMMM');
            }
            if(type === 'subtract')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").subtract(12, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").subtract(12, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").subtract(12, 'months').format('MMMM');
            }
            this.getDaysOfMonth();
            this.getRoutesForDay()
        }
    },
    async created()
    {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes)
        {
            this.$router.push("/hub")
        } 
        await this.getVehicles()
        //get routes from database
        this.loading = true
        this.dbListener = db.collection("Routes").orderBy("date.start", "desc")
        .onSnapshot(async (querySnapshot) => {
            this.routes = [];

            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }

            for (let i = 0; i < querySnapshot.docs.length; i++) {
                const data = querySnapshot.docs[i].data()
                data.vehicle = this.vehicles[data.vehicle.id]
                this.routes.push(data)
            }
            this.getRoutesForDay()
            this.loading = false
        });

        moment.locale('pl')
        this.setCurrentMonth()
        
    }
 }

</script>