<template>
<div class="h-full overflow-hidden">
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu tras</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Tworzenie trasy</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/routes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

    <div>
        <h4 class="sr-only">Progres</h4>
        <p class="text-gray-500 text-xs font-medium uppercase tracking-wide">Progres dodawania trasy</p>
        <div class="mt-2" aria-hidden="true">
        <div class="bg-gray-200 rounded-full overflow-hidden">
            <div class="h-2 transition-all duration-1000 bg-gradient-to-r from-blue-400 via-indigo-400 to-blue-600 rounded-full" :style="`width: ${progress}%`" />
        </div>
        <div class="hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-1">
            <div class="text-blue-500">Podstawowe informacje</div>
            <div class="text-center text-blue-500"></div>
            <div class="text-center text-blue-500">Zamówienia</div>
        </div>
        </div>
    </div>

    <form class="space-y-8 divide-y divide-gray-200 mt-6" v-show="step === 1">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5" v-if="dateAsk === false">
            <div>
                <div>
                    <p class="select-none text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                </div>
            </div>

            <div class="min-h-full">
                <main class="mt-4 pb-3 pt-8">
                <div class="mx-auto max-w-7xl">
                    <h1 class="sr-only">Page title</h1>
                    <!-- Main 3 column grid -->
                    <div :class="['grid items-start gap-4 lg:gap-8 lg:grid-cols-3 grid-cols-1']">
                    <!-- Left column -->
                    <div class="grid grid-cols-1 gap-4 lg:col-span-1">
                        <div>
                            <section aria-labelledby="section-1-title">
                            <h2 class="sr-only" id="section-1-title">Date</h2>
                            <div class="rounded-lg bg-white shadow relative">
                                <div class="p-6">
                                <label for="name" class="absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-gray-900 select-none">Wybór daty wyjazdu</label>
                                    <VueDatePicker placeholder="Wybierz datę wyjazdu..." @open="this.dateAsk = true" @closed="this.changeDatePicker = false" auto-apply locale="pl" v-model="this.date" range :min-date="new Date()" />
                                </div>
                            </div>
                            </section>
                        </div>
                        <div>
                            <section aria-labelledby="section-1-title">
                            <h2 class="sr-only" id="section-1-title">Date</h2>
                            <div class="rounded-lg bg-white shadow relative">
                                <div class="p-6">
                                <label for="name" class="absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-gray-900 select-none">Wybór kraju</label>
                                    <Listbox as="div" v-model="selectedCountry">
                                        <div class="relative mt-1">
                                        <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <div class="flex gap-1 items-center">
                                            <div v-if="selectedCountry !== undefined && selectedCountry !== null">
                                                <img v-if="selectedCountry.flagUrl.length>0" :src="selectedCountry.flagUrl" v-tooltip="`${selectedCountry.code}`" class="h-4 w-6 mr-1 border border-gray-300">
                                                <span v-if="selectedCountry.flagUrl.length === 0">--</span>
                                            </div>
                                            <span class="block truncate">{{selectedCountry?.displayName || 'Wybierz kraj...'}}</span>
                                            </div>
                                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            <ListboxOption as="template" v-for="(country, index) in countries" :key="index" :value="country" v-slot="{ active, selected }">
                                                <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pr-8 pl-4']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{country.displayName}} </span>
                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-1 flex items-center px-1.5']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                <span v-if="!selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-1 flex items-center px-1.5']">
                                                    <div class="flex items-center">
                                                    <img v-if="country.flagUrl.length>0" :src="country.flagUrl" v-tooltip="`${country.code}`" class="h-4 w-6 mr-1 border border-gray-300">
                                                    <span v-if="country.flagUrl.length === 0">--</span>
                                                    </div>
                                                </span>
                                                </li>
                                            </ListboxOption>
                                            </ListboxOptions>
                                        </transition>
                                        </div>
                                    </Listbox>  
                                </div>
                            </div>
                            </section>
                        </div>
                    </div>

                    <!-- Right column -->
                    <div :class="['grid grid-cols-1 gap-4 transition-all duration-300', validation ? 'opacity-100' : 'opacity-50 select-none pointer-events-none']">
                        <section aria-labelledby="section-2-title">
                        <h2 class="sr-only" id="section-2-title">Drivers</h2>
                        <div class="rounded-lg bg-white shadow relative">
                            <div class="p-6">
                                <label for="driverPicker" class="absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-gray-900">Wybór kierowcy</label>
                                <div class="w-full text-sm flex flex-col items-center">
                                    <div class="inline-block relative w-full">
                                    <div class="flex flex-col items-center relative">
                                        <div @click.stop="drivers.input.showList = !drivers.input.showList" :class="[selectedDrivers.length ? 'py-2 pl-3' : 'py-2 pl-3', 'flex items-center border text-gray-700 border-gray-300 w-full bg-white rounded-md select-none cursor-pointer']">
                                            <ul v-if="selectedDrivers.length" class="flex flex-auto flex-wrap">
                                                <li v-for="driver in selectedDrivers" :key="driver.id">
                                                    <div class="flex justify-center items-center m-0.5 font-medium px-1 py-0.5 rounded bg-gray-100 border">
                                                        <div class="text-xs font-normal leading-none max-w-full flex-initial">
                                                            {{ driver.name + ' ' + driver.surname}}
                                                        </div>
                                                        <div class="flex flex-auto flex-row-reverse cursor-pointer">
                                                            <XIcon @click.stop="driver.selected = false" class="text-gray-400 hover:text-red-500 w-4 h-4"/>
                                                        </div>
                                                    </div>
                                                </li> 
                                            </ul>
                                            <div v-else class="w-full">
                                                <span :class="['bg-red-500', 'bg-green-400 inline-block h-2 w-2 flex-shrink-0 rounded-full']" />
                                                <span class="ml-3">Wybierz kierowców...</span>
                                            </div>
                                            <div class="text-gray-300 flex items-center pr-2">
                                                <ChevronDownIcon class="w-5 h-5 text-gray-400"/>
                                            </div>
                                        </div>
                                        <transition leave-active-class="transition all duration-300" leave-from-class="transform opacity-100" leave-to-class="transform opacity-0">
                                            <div v-if="drivers.input.showList" class="w-full mt-1">
                                            <div class="shadow bg-white z-40 max-h-select overflow-y-scroll max-h-36">
                                                <ul v-if="drivers.all.length" class="flex flex-col w-full overflow-y-auto h-auto rounded-md">
                                                <li v-for="driver in drivers.all" :key="driver.id" class="overflow-auto"> 
                                                    <button :disabled="!driver.available" @click.prevent="driver.selected = !driver.selected" class="w-full hover:bg-indigo-600 hover:text-white"  :class="[driver.available ? 'cursor-pointer' :  'cursor-default bg-gray-100']">
                                                    <div class="w-full border-gray-100">
                                                        <div class="flex w-full items-center p-2 pl-3 border-transparent border-l-2 relative">
                                                        <div class="w-full items-center flex justify-between">
                                                            <div class="flex flex-row items-center h-5">
                                                                <span :class="[driver.available ? 'bg-green-400' : 'bg-red-500', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                                                                <span class="ml-3 leading-6">{{ driver.name }} {{driver.surname}}</span>
                                                            </div>
                                                            <CheckIcon v-if="driver.selected" class="text-indigo-400 w-5 h-5"/>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </button>
                                                </li>
                                                </ul>
                                                <div v-else>
                                                    <EmptyState/>
                                                </div>
                                            </div>
                                            </div>
                                        </transition>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                    </div>
                    <div :class="['grid grid-cols-1 gap-4 transition-all duration-300', validation1 ? 'opacity-100' : 'opacity-50 select-none pointer-events-none']">
                        <section aria-labelledby="section-2-title">
                        <h2 class="sr-only" id="section-2-title">Vehicles</h2>
                        <div class="rounded-lg bg-white shadow relative">
                            <label for="driverPicker" class="absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-gray-900">Wybór pojazdu</label>
                            <div class="p-6">
                            <Listbox as="div" v-model="selectedVehicles" @open="vehicleOpen = true" v-if="vehicles.length > 0 && !load">
                            <div class="relative">
                                <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span class="flex items-center">
                                    <span :aria-label="selected.available ? 'Online' : 'Offline'" :class="[!selected.available ? 'bg-green-400' : 'bg-red-500', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" />
                                    <span class="ml-3 block truncate">{{ selectedVehicles === null ? 'Wybierz pojazd...'  :  fullSelectedVehicle }}</span>
                                </span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                                </ListboxButton>

                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="transition-all duration-200 min-h-full h-48 relative z-20 mt-1 w-full overflow-y-scroll rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption :class="[!vehicle.available || !vehicle.active ? 'bg-gray-100 cursor-default' : 'cursor-pointer']" as="template" v-for="vehicle in vehicles" :key="vehicle.id" :disabled="!vehicle.available || !vehicle.active" :value="vehicle" v-slot="{ active, selected }">
                                    <li v-tooltip="`${vehicle.available ? 'Dostępny' : 'Niedostępny'}`" :class="[selected ? 'text-white' : ' text-white', active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative select-none py-2 pl-3 pr-2']">
                                        <div class="flex justify-between items-center">
                                        <div class="flex flex-row items-center">
                                            <span :class="[vehicle.available ? 'bg-green-400' : 'bg-red-500', vehicle.active ? '' : 'bg-yellow-500', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                                            {{ vehicle.brand + ' ' +  vehicle.model }}
                                            <span class="font-medium">{{ vehicle.attribs.licensePlate.value }}</span>
                                            <span class="sr-only"> is {{ !vehicle.available ? 'online' : 'offline' }}</span>
                                            </span>
                                        </div>
                                        <div class="flex flex-row items-center">
                                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                                            {{ vehicle.attribs.payloadCapacity.value }} m3
                                            </span>
                                        </div>
                                        </div>
                                    </li>
                                    </ListboxOption>
                                </ListboxOptions>
                                </transition>
                            </div>
                            </Listbox>
                            
                            <div v-if="vehicles.length === 0" class="select-none">
                                <div class="rounded-md bg-yellow-50 p-4">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    </div>
                                    <div class="ml-3">
                                        <h3 class="text-sm font-medium text-yellow-800">Brak dostępnych pojazdów.</h3>
                                        <div class="text-sm text-yellow-700">
                                            <router-link class="text-md font-medium text-yellow-700 underline hover:text-yellow-600" to="/dashboard/vehicles/add">Dodaj pojazd</router-link> 
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
                    </div>
                    </div>
                </div>
                </main>
            </div>
        </div>

        <div class="pt-5" v-if="!dateAsk">
            <div class="flex flex-col sm:flex-row justify-end">
                <button type="button" :disabled="!stepValidation" :class="[!stepValidation ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-600 text-base font-medium hover:bg-blue-700 focus:ring-blue-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm transition-all duration-300' ]" @click="changeStep(2)">Następny krok</button>
            </div>
        </div>
    </form>

    <form class="space-y-8 divide-y divide-gray-200 mt-6" v-if="step === 2">
        <div class="space-y-8 sm:space-y-5">

            <!-- About Page -->
            <div class="flex justify-between items-center border-b border-gray-200 pb-6">
                <span class="text-sm uppercase text-gray-600 font-semibold">Wybór zamówień</span>
                <div class="text-xs text-gray-600 font-semibold">
                    <span class="uppercase">Pozostała pojemność: </span>
                    <span :class="[fullVolume > selectedVehicles.attribs.payloadCapacity.value ? 'text-red-500' : 'text-gray-500']">{{currency(this.fullVolume)}}</span>
                    z
                    <span class="text-indigo-600">{{selectedVehicles.attribs.payloadCapacity.value}}</span>
                    m3
                    <!-- <span class="text-xs text-gray-500 font-semibold lowercase"><span :class="[fullVolume > selectedVehicles.attribs.payloadCapacity.value  ? 'text-red-500' : 'text-gray-500']">{{currency(this.fullVolume)}}</span> z {{selectedVehicles.attribs.payloadCapacity.value}} m3</span> -->
                </div>
            </div>


            <!-- <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5"> -->
            <div class="flex justify-between">
                <div class="flex items-end gap-2">
                    <div v-if="showStatusFilters === true">
                        <Listbox v-if="groups.length > 0" as="div" v-model="selectedGroups">
                        <div class="relative mt-1">
                            <ListboxButton class="relative w-56 cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-transparent px-1 text-xs font-medium text-gray-900 rounded-t-md">Kod pocztowy</label>
                                <div class="flex gap-2">
                                    <img v-if="selectedGroups !== null" :src="selectedGroups.country.flagUrl " class="h-3 w-5 border border-gray-400 mt-1">
                                    <span class="block truncate">{{ selectedGroups === null ? 'Wybierz kod pocztowy...' :  selectedGroups.name}}</span>
                                </div>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ListboxOption as="template" v-for="group in groups" :key="group.id" :value="group" v-slot="{ active, selected }">
                                <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-4', selected ? 'pr-9' : '']">
                                    <div class="flex gap-2">
                                        <img :src="group.country.flagUrl " class="h-3 w-5 border border-gray-400 mt-1">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                            {{ group.name }}
                                        </span>
                                    </div>
                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-3']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                </li>
                                </ListboxOption>
                            </ListboxOptions>
                            </transition>
                        </div>
                        </Listbox>
                        <div v-else>
                        <div class="rounded-md bg-yellow-50 p-4">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-yellow-800">Brak dostępnych grup kodów.</h3>
                                <div class="text-sm text-yellow-700">
                                <router-link class="text-md font-medium text-yellow-700 underline hover:text-yellow-600" to="/dashboard/postcode/add">Dodaj grupę</router-link> 
                                </div>
                            </div>
                            </div>
                        </div> 
                        </div>
                    </div>
                    <Transition name="fade"> 
                    <div v-if="showStatusFilters === true" class="mr-4 ml-3 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                        <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-transparent px-1 text-xs font-medium text-gray-900 rounded-t-md">Opcje sortowania</label>
                            <select v-model="pickedSort" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                <option value="all">Brak</option>
                                <option v-for="sort in sortList" :value="sort.type" :key="sort.id">{{sort.name}}</option>
                            </select>
                        </div>
                        <div v-if="this.pickedSort === 'country' && (!this.selectedGroups || this.selectedGroups.country.code === 'Brak')" class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-transparent px-1 text-xs font-medium text-gray-900 rounded-t-md">Wybierz kraj</label>
                            <select v-model="pickedCountry" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                <option v-for="country in countries" :value="country.code" :key="country.id">{{country.displayName}}</option>
                            </select>
                        </div>
                        <div v-if="(this.pickedSort === 'suggested' || this.pickedSort === 'code')" class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-transparent px-1 text-xs font-medium text-gray-900 rounded-t-md">Posortuj wyniki</label>
                            <select v-model="pickedOption" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                <!-- <option v-if="false" value="all">Wszystkie</option> -->
                                <option v-for="option in optionList" :value="option.type" :key="option.id">{{option.name}}</option>
                            </select>
                        </div>
                          <button type="button" @click.prevent="getAllOrders()" class="rounded-md bg-indigo-100 hover:bg-indigo-200 px-3.5 py-2.5 text-xs font-semibold text-indigo-600 shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zastosuj</button>
                        </div>
                    </div>
                    </Transition>
                    <!-- <div @click="showFilters()" class="cursor-pointer flex justify-center items-center flex-col select-none">
                    <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                    <span class="text-xs font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                    </div> -->
                </div>
                <div class="flex gap-3 items-center">
                    <div @click="showFilters()" class="mt-1 cursor-pointer flex justify-center items-center flex-col select-none">
                        <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                        <span class="text-xs font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                    </div>
                    <div class="flex" v-if="showStatusFilters === false">
                        <button @click="checkOrdersByDeliveryDate()" type="button" :class="{'transition ease-in-out duration-300 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2': true, 'bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600': !ordersByDeliveryDate, 'bg-red-600 text-white hover:bg-red-500 focus-visible:outline-red-600': ordersByDeliveryDate}">
                            <span v-if="!ordersByDeliveryDate">Pokaż sugerowane do doręczenia {{createSuggestedDate(this.date)}}</span>
                            <span v-if="ordersByDeliveryDate">Pokaż wszystkie</span>
                        </button>
                    </div>
                </div>
            </div>


            <div class="overflow-hidden flex flex-col pt-2" v-if="orders.length > 0 && !load">
                <div class="-my-2 -mx-4 overflow-auto sm:-mx-6 lg:-mx-8 px-6 lg:px-8">
                    <div :class="['overflow-x-hidden inline-block min-w-full align-middle px-1 py-1 pb-4']">
                        <div class="shadow ring-1 ring-black ring-opacity-5 rounded-lg h-112 overflow-y-scroll">
                            <table class=" min-w-full divide-y divide-gray-300 overflow-hidden">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">Kraj</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Kod pocztowy</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Nazwa mebla</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Producent</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Status</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Sugerowana dostawa</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">
                                            <span class="sr-only">Data wpłynięcia zamówienia</span></th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">
                                            <span class="sr-only">Dodatkowe informacje</span></th>
                                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span class="sr-only">Dodawanie</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white" v-for="(order, index) in ordersByDeliveryDate ? newCheckedOrders : orders" :key="index">
                                    <tr>
                                        <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-sm font-medium text-gray-900">
                                            <div class="flex items-center">
                                                <img v-if="order.countryFlagURL.length>0" :src="order.countryFlagURL" v-tooltip="`${order.delivery.country.code}`" class="h-4 w-6 border border-gray-300">
                                                <span v-if="order.countryFlagURL.length === 0 && order.delivery.countryCode.length === 0">--</span>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-900 font-semibold">
                                            <div class="inline-flex w-16">
                                                <span v-if="order.delivery.postCode.length>0">{{ order.delivery.postCode }}</span>
                                                <span v-if="order.delivery.postCode.length === 0 && order.delivery.postCode.length === 0">--</span>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                            <div class="flex items-center">
                                                <div class="inline-flex w-56 truncate items-center">
                                                    <span @click.prevent="openCommentsModal(order)" v-tooltip="`Zobacz komentarze wewnętrzne`" :class="[order.statusId === 400 && createCommentList(order)[0].length > 0 ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800', 'mr-1 items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer truncate']" v-for="prod in createProductList(order).slice(0,1)" :key="prod">
                                                        {{prod}}
                                                    </span>
                                                    <span @click.prevent="openCommentsModal(order)" class="text-xs font-semibold select-none cursor-pointer" v-tooltip="`${createProductList(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="createProductList(order).length - 1 > 0">+ {{createProductList(order).length - 1}} więcej</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                            <div class="inline-flex w-32">
                                                <span class="truncate mr-1 items-center rounded-full py-0.5 text-xs font-medium" v-for="manu in getManufacturer(order).slice(0,1)" :key="manu.id">{{typeof manu === 'object' ? manu.name : manu}}</span>
                                                <span v-tooltip="`${getManufacturer(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="getManufacturer(order).length - 1 > 0">+ {{getManufacturer(order).length - 1}} więcej</span>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-xs select-none">
                                            <div class="inline-flex w-32">
                                                <span v-tooltip="`Status: ${getStatusById(order.statusId).name} - Informacje: ${getStatusById(order.statusId).info}`" :class="{'cursor-pointer inline-flex w-full justify-center items-center rounded-md px-2 py-1 text-xs font-medium': true, 'bg-yellow-400 text-white': order.statusId === 1, 'bg-yellow-600 text-yellow-100': order.statusId === 2, 'bg-blue-500 text-blue-100': order.statusId === 3, 'bg-purple-400 text-purple-100': order.statusId === 4, 'bg-pink-500 text-pink-100': order.statusId === 5, 'bg-green-500 text-green-100': order.statusId === 6, 'bg-purple-500 text-purple-100': order.statusId === 7, 'bg-pink-800 text-pink-100': order.statusId === 400, 'bg-orange-400 text-orange-100': order.statusId === 401, 'bg-teal-500 text-teal-100': order.statusId === 402, 'bg-gray-400 text-gray-100': order.statusId === 403, 'bg-gray-500 text-gray-100': order.statusId === 404, 'bg-red-600 text-red-100': order.statusId === 300, 'bg-gray-300 text-gray-100': order.statusId === 8}">
                                                    {{order.statusId === 2 ? 'Kompletowanie' : getStatusById(order.statusId).name}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                                            <span class="font-semibold" v-if="order.delivery.date.suggested !== null">{{moment.unix(order.delivery.date.suggested.unix).format('LL')}}</span>
                                            <span class="font-semibold uppercase opacity-40 select-none" v-if="order.delivery.date.suggested === null">Nie wskazano</span>
                                        </td>
                                        <td class="whitespace-nowrap py-4 text-sm text-gray-500 cursor-pointer">
                                            <div v-tooltip="`Data wpłynięcia zamówienia: ${order.origin !== undefined ? moment.unix(order.origin.meta.external.dates.add_TimestampUNIX).format('LLL') : moment.unix(order.meta.created.unix).format('LLL')}`" class="h-full cursor-pointer flex justify-center items-center">
                                                <AnnotationIcon class="transition ease-in-out duration-300 h-5 w-5 text-gray-400 hover:text-gray-600 flex-shrink-0" aria-hidden="true" />
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer">
                                            <div>
                                                <EyeIcon @click="openAdditionalInformation(index)" v-tooltip="`Wyświetl informacje o zamówieniu.`" class="h-6 w-6" v-if="!this.toggleInformation[index].open" />
                                                <EyeOffIcon @click="openAdditionalInformation(index)" v-tooltip="`Schowaj informacje o zamówieniu.`" class="h-6 w-6" v-if="this.toggleInformation[index].open" />
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap py-4 text-sm text-gray-500 relative">
                                            <input v-tooltip="`Wybierz zamówienie`" :checked="isOrderChecked(order)" @change="checkOrder($event, order, index), calculateVolume($event, order)" name="order" type="checkbox" class="mb-0.5 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </td>
                                    </tr>
                                    <td colspan="12" v-if="this.toggleInformation[index].open" class="toggled bg-gray-100 border-t border-gray-200 px-6">
                                        <div class="py-5">
                                        <div class="flex justify-between">
                                            <div class=" gap-1 flex flex-col">
                                            <span class="font-medium mr-1 text-xs text-gray-500">Informacje o zamówieniu:</span>
                                                <div class="text-xs flex flex-col" v-for="(position, index) in createPositionsList(order)" :key="index">
                                                    <!-- <span class="text-gray-500">ID produktu: <span class="font-bold">{{ position.item.product.externalSourceProductData.item.itemid }}</span></span>                                                 -->
                                                    <span class="text-gray-500">Nazwa produktu: <span class="font-bold">{{ position.item.product.name === undefined ? '?' : position.item.product.name }}</span></span>                                                
                                                    <span class="text-gray-500">Ilość: <span class="font-bold mr-2">{{ position.item.product.quantity === undefined ? '?' : position.item.product.quantity }}</span>
                                                    <span class="text-gray-500">Ilość paczek: <span class="font-bold">{{ position.item.product.amountOfPackages === undefined ? '?' : position.item.product.amountOfPackages}}</span></span></span>                                                
                                                    <span class="text-gray-500">Cena za sztukę (podatek wliczony w cenę): <span class="font-bold">{{position.item.product.pricePerItemTaxIncluded === undefined ? '?' : position.item.product.pricePerItemTaxIncluded}} {{position.item.product.currency === undefined ? '?' : position.item.product.currency}}</span></span>                                                
                                                </div>
                                            </div>
                                            <div class="flex self-end">
                                                <span class="text-gray-500 text-xs">Data wpłynięcia do systemu: <span class="font-bold">{{ moment.unix(order.meta.created.date.seconds).format('LLL') }}</span></span>                                                
                                            </div>
                                        </div>
                                        </div>
                                    </td>
                                    <td v-if="index == orders.length - 1 && !queryLimitReached" colspan="12">
                                        <div class="flex justify-center pt-4 pb-4">
                                            <InfiniteLoading v-tooltip="`W przypadku zatrzymania się ładowania, proszę nacisnąć kręcące się kółko.`" @click.prevent="loadMoreOrders" v-if="!load && !queryLimitReached" @infinite="loadMoreOrders()" />
                                        </div>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Transition name="fade">
                <LoadingSpinnerHub v-if="load" :header="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader" />

                <!-- <div class="flex items-center justify-center mt-4" v-if="!load">
                    <button type="button" @click.prevent="loadMoreOrders" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
                </div>     -->
            </Transition>
            <EmptyState class="ml-2 mt-3 mb-3" v-if="orders.length === 0 && !load" />
        </div>

        <div class="pt-5">
        <div class="flex flex-col sm:flex-row justify-end">
            <button type="button" @click.prevent="changeStep(1)" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
            <button @click="changeStep(3)" :disabled="checkedOrders.length === 0 || fullVolume > selectedVehicles.attribs.payloadCapacity.value" type="button" :class="[checkedOrders.length === 0 || fullVolume > selectedVehicles.attribs.payloadCapacity.value ? 'bg-blue-300' : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500', 'transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2' ]">Następny krok</button>
        </div>
        </div>
    </form>

    <form :class="[singleLoad ? 'mt-0' : 'mt-6', 'space-y-8 divide-y divide-gray-200']" v-if="step === 3">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <p class="select-none text-gray-500 text-sm font-medium uppercase tracking-wide">Dodatkowe opcje</p>
            </div>
            <div class="min-h-full">
                <transition name="fade">
                    <LoadingSpinnerHub v-if="singleLoad" :header="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></LoadingSpinnerHub>
                </transition>
                <Transition name="fade">
                    <main class="mt-0 pb-3 pt-8" v-if="!singleLoad">
                        <div class="mx-auto max-w-7xl pr-3 sm:pr-0">
                            <h1 class="sr-only">Page title</h1>
                            <!-- Main 3 column grid -->
                            <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                                <!-- Left column -->
                                <div class="grid grid-cols-1 gap-4">
                                <!-- <div class="grid grid-cols-1 gap-4 lg:col-span-2"> -->
                                    <section aria-labelledby="section-1-title">
                                    <h2 class="sr-only" id="section-1-title">Section title</h2>
                                    <div class="flex justify-between select-none">
                                        <span class="text-sm leading-6 text-gray-500" id="email-optional">Opcjonalnie</span>
                                    </div>
                                    <div class="overflow-hidden rounded-lg bg-white shadow">
                                        <div class="p-6 ">
                                            <div class="mt-2 relative">
                                                <label for="title" class="select-none absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Dodaj tytuł trasy</label>
                                                <input v-model="title" type="text" name="title" id="title" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: Trasa" />
                                            </div>
                                        </div>
                                        <div class="px-6 pb-6 pt-0">
                                            <div class="mt-2 relative">
                                                <label for="comment" class="select-none absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Dodaj komentarz do trasy</label>
                                                <textarea v-model="description" rows="4" name="comment" id="comment" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: Komentarz" />
                                            </div>
                                        </div>
                                    </div>
                                    </section>
                                </div>

                                <!-- Right column -->
                                <!-- <div class="grid grid-cols-1 gap-4"> -->
                                <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                                    <section aria-labelledby="section-2-title">
                                    <h2 class="sr-only" id="section-2-title">Section title</h2>
                                    <!-- <div class="flex select-none">
                                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Lista zamówień</label>
                                    </div> -->
                                    <div class="flex justify-between">
                                        <label for="email" class="select-none block text-sm font-medium leading-6 text-gray-900">Lista zamówień</label>
                                        <span class="text-xs leading-6 text-gray-500" id="email-optional">{{this.route.id}}</span>
                                    </div>
                                    <div class="overflow-hidden rounded-lg bg-white shadow">
                                        <div class="pt-6 pr-6 pl-6 pb-2">

                                            <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 gap-x-6 xl:gap-x-8 gap-y-6 mb-6" >
                                            <li class="overflow-hidden rounded-xl border border-gray-200" v-for="(order, index) in singleOrders" :key="index">
                                                <div class="flex justify-between items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 px-6 py-4">
                                                <div class="text-sm font-medium leading-6 text-gray-900 select-none" v-for="prod in createProductList(order).slice(0,1)" :key="prod.id">{{prod}}</div>
                                                </div>
                                                <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                                <div class="flex justify-between gap-x-4 py-3">
                                                    <dt class="text-gray-500 select-none">Dane osobowe:</dt>
                                                    <dd class="text-gray-700">
                                                    <span>{{ order.client.name }}</span>
                                                    </dd>
                                                </div>
                                                <div class="flex justify-between gap-x-4 py-3">
                                                    <dt class="text-gray-500 select-none">Adres dostawy:</dt>
                                                    <dd class="flex items-start gap-x-2">
                                                    <div class="font-medium text-gray-900">{{ order.delivery.address }}</div>
                                                    </dd>
                                                </div>
                                                <div class="flex justify-between gap-x-4 py-3">
                                                    <dt class="text-gray-500 select-none">Miasto dostawy:</dt>
                                                    <dd class="flex items-start gap-x-2">
                                                    <div class="font-medium text-gray-900">{{ order.delivery.city }}</div>
                                                    </dd>
                                                </div>
                                                <div class="flex justify-between gap-x-4 py-3">
                                                    <dt class="text-gray-500 select-none">Planowana data dostawy:</dt>
                                                    <dd class="flex items-start gap-x-2">
                                                    <div class="font-medium text-gray-900">{{ order.delivery.date.planned !== undefined ? order.delivery.date.planned !== null ? moment.unix(order.delivery.date.planned.unix).format("DD.MM.YYYY") : 'Brak ustalonej daty' : 'Brak ustalonej daty' }} </div>
                                                    </dd>
                                                </div>
                                                </dl>
                                            </li>
                                            </ul>

                                        <div class="relative">
                                            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div class="w-full border-t border-gray-300" />
                                            </div>
                                        </div>

                                        <div class="flex pt-6 pb-3 flex-col justify-center items-center">
                                            <div>
                                                <div class="flex justify-between select-none">
                                                    <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Sortowanie trasy</label>
                                                    <!-- <span class="text-sm leading-6 text-gray-500" id="email-optional">Opcjonalnie</span> -->
                                                </div>
                                                <div class="flex gap-5">
                                                    <span class="isolate inline-flex rounded-md shadow-sm">
                                                        <button @click="get()" :disabled="!ordersDownloaded" type="button" :class="[!ordersDownloaded ? 'pointer-events-none opacity-20' : '', 'relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 trasition-all duration-300']">
                                                            <DocumentDownloadIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />                    
                                                            Eksportuj
                                                        </button>
                                                    </span>
                                                    <!-- <span class="isolate inline-flex rounded-md shadow-sm">
                                                        <input type="file" @change="handleFileUpload($event)" accept=".csv" class="cursor-pointer w-64 relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 trasition-all duration-300" />
                                                    </span> -->
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                                </div>
                            </div>
                        </div>
                    </main>
                </Transition>
            </div>
        </div>
        <div class="pt-5">
            <div class="flex flex-col sm:flex-row justify-end">
                <!-- <button v-if="false" type="button" @click.prevent="changeStep(2)" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button> -->
                <button v-if="!singleLoad" type="submit" @click.prevent="this.content !== undefined ? sortOrders() : confirmRoute() " :class="{'transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true}">Zakończ dodawnie</button>
            </div>
        </div>
    </form>
    <CommentsModal v-if="commentsModal.toggle" :order="commentsModal.data" @close="closeCommentsModal"></CommentsModal>
    <askForDate v-if="dateAsk" :modalData="modalData" @accept="pickDate" @closeModal="dateAsk = false" />

</div>
</template>

<script>
import currency from 'currency.js';
import moment from 'moment';
import axios from 'axios';
import { db } from "@/firebase/gfbconf.js";
import Papa from 'papaparse';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { HomeIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/vue/solid'
import { MenuIcon, BellIcon, AnnotationIcon, DocumentDownloadIcon, ExclamationIcon, ChevronLeftIcon, ChevronRightIcon, EyeIcon, EyeOffIcon, FilterIcon } from '@heroicons/vue/outline'

import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import AttribListModal from '@/components/AttribListModal.vue';
import EmptyState from '../components/EmptyState.vue';
import askForDate from '@/components/dateModal.vue';
import CommentsModal from "@/components/CommentsInOrder.vue";
export default {
    name: "ProductAdd",
    data() {
        return {
            route: {
                id: ''
            },
            selectedCountry: null,
            countries: [
                {
                    displayName: 'Polska',
                    name: 'Poland',
                    code: 'PL',
                    flagUrl: 'https://flagcdn.com/w320/pl.png',
                },
                {
                    displayName: 'Niemcy',
                    name: 'Germany',
                    code: 'DE',
                    flagUrl: 'https://flagcdn.com/w320/de.png',
                },
                {
                    displayName: 'Francja',
                    name: 'France',
                    code: 'FR',
                    flagUrl: 'https://flagcdn.com/w320/fr.png',
                },
                {
                    displayName: 'Luksemburg',
                    name: 'Luxembourg',
                    code: 'LU',
                    flagUrl: 'https://flagcdn.com/w320/lu.png',
                },
                {
                    displayName: 'Włochy',
                    name: 'Italy',
                    code: 'IT',
                    flagUrl: 'https://flagcdn.com/w320/it.png',
                },
                {
                    displayName: 'Holandia',
                    name: 'Netherlands',
                    code: 'NL',
                    flagUrl: 'https://flagcdn.com/w320/nl.png',
                },
                {
                    displayName: 'Belgia',
                    name: 'Belgium',
                    code: 'BE',
                    flagUrl: 'https://flagcdn.com/w320/be.png',
                },
                {
                    displayName: 'Austria',
                    name: 'Austria',
                    code: 'AT',
                    flagUrl: 'https://flagcdn.com/w320/at.png',
                },
            ],
            title: '',
            description: '',
            singleRoute: null,
            singleOrders: [],
            singleLoad: false,
            dateAsk: true,
            modalData: {
                headerText: "Wybierz datę wyjazdu kierowcy",
                bodyText: "Czy na pewno chcesz usunąć wybraną wartość atrybutu? Dane zostaną permamentnie usunięte.",
                acceptButtonText: "Wybierz datę"
            },
            load: false,
            ordersByDeliveryDate: false,
            vehicleOpen: false,
            query: null,
            pickedOption: "desc",
            pickedCountry: "PL",
            pickedSort: "all",
            showStatusFilters: false,
            countriesData: [],
            optionList: [
                { id: 1, name: 'Malejąco', type: 'desc'},
                { id: 2, name: 'Rosnąco', code: 'asc'},
            ],
            statuses:[],
            orders: [],
            vehicles:[],
            queryLimit: 25,
            lastLoaded: null,
            queryLimitReached: false,
            selectedVehicles: null,
            selectedGroups: null,
            groups:[],
            dbListener: null,
            dbListenerVehicles: null,
            view: false,
            date: null,
            open: true,
            totalAmount: '',
            queryLimitReached: false,
            ordersDownloaded: false,
            changeDatePicker: false,
            checkedOrders: [],
            newCheckedOrders:[],
            toggleInformation: {},
            fullVolume: 0,
            checkboxRefs: [],
            drivers: {
                all: [],
                input: {
                    showList: false,
                    query: ""
                }
            },
            selectedDriver: {
                user: "",
                name: ""
            },
            postcodesList: [], 
            progress: 0,
            step: 1,
            commentsModal: {
                toggle: false,
                data: null
            },
            selected: {},
        }
    },
    components: {
        CommentsModal, AnnotationIcon, Dialog, MenuIcon, DialogOverlay, DialogTitle, ExclamationIcon, ChevronLeftIcon, ChevronRightIcon, EyeIcon, EyeOffIcon, FilterIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, AttribListModal, EmptyState, HomeIcon, MenuIcon, BellIcon, XIcon, Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot, VueDatePicker, CheckIcon, ChevronDownIcon, ChevronUpIcon, askForDate, LoadingSpinnerHub, DocumentDownloadIcon, InfiniteLoading
    },
    async created()
    {
        moment.locale('pl')
        this.moment = moment;
        this.currency = currency;
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes)
        {
            this.$router.push("/hub")
        }
        else
        {
            await this.getStatuses()
            await this.getCountries()
            await this.getPostcodeGroups()
        }
    },
    beforeUnmount() {
        if(this.dbListenerVehicles !== null){
            this.dbListenerVehicles();
        }
        if(this.dbListener !== null){
            this.dbListener();
        }
    },
    methods:
    {
        openCommentsModal(order){
            this.commentsModal.toggle = true
            this.commentsModal.data = order
        },
        openHistoryModal(order){
            this.historyModal.toggle = true
            this.historyModal.data = order
        },
        closeCommentsModal(){
            this.commentsModal.toggle = false
            this.commentsModal.data = null
        },
        closeHistoryModal(){
            this.historyModal.toggle = false
            this.historyModal.data = null
        },
        async confirmRoute()
        {
            try
            {
                if(this.description.length > 0 && this.title.length > 0)
                {
                    await db.collection('Routes').doc(this.route.id).update({
                        description: this.description,
                        title: this.title,
                    })
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: `Dodatkowe rzeczy zostały pomyślnie ustawione.`,
                        success: true
                    });
                }
                else if(this.description.length > 0)
                {
                    await db.collection('Routes').doc(this.route.id).update({
                        description: this.description,
                    })
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Opis trasy pomyślnie ustawiony.",
                        subheader: ``,
                        success: true
                    });
                }
                else if(this.title.length > 0)
                {
                    await db.collection('Routes').doc(this.route.id).update({
                        title: this.title,
                    })
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Tytuł trasy pomyślnie ustawiony.",
                        subheader: ``,
                        success: true
                    });
                }
                else
                {
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Trasa pomyślnie utworzona.",
                        subheader: ``,
                        success: true
                    });
                }
                
                this.$router.push(`/dashboard/routes/list`)
            }
            catch (error)
            {
                console.log(error);    
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił nieoczekiwany błąd!",
                    subheader: ``,
                    success: false
                });
            }
        },
        async sortOrders()
        {
            try
            {
                let order = [];
                let correctFile = false;
                this.$store.commit('setNotification', {
                    show: true,
                    head: "Sortowanie trasy..",
                    subheader: `Nie zamykaj tego okna.`,
                    success: true
                });

                if(this.content !== null && this.content !== undefined)
                {
                    for(let i=0; i<this.content.data.length; i++)
                    {
                        if(this.content.data[i].comment !== undefined && this.content.data[i].stationnumberer !== undefined && this.content.data[i].city !== undefined)
                        {
                            correctFile = true;
                        }
                        else
                        {
                            correctFile = false;
                        }
                    }

                    if(correctFile)
                    {
                        if(this.content.data.length-2 === this.singleOrders.length)
                        {
                            for(let i=0; i<this.content.data.length; i++)
                            {
                                for(let y=0; y<this.singleOrders.length; y++)
                                { 
                                    if(this.content.data[i].city !== 'Baranów Mroczeń' || this.content.data[i].city !== 'Baranów')
                                    {
                                        if(this.content.data[i].comment === this.singleOrders[y].id)
                                        {
                                            order.push({
                                                index: parseFloat(this.content.data[i].stationnumberer),
                                                ref: db.collection('Orders').doc(this.singleOrders[y].id)
                                            })
                                        }
                                    }
                                }
                            }
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Akcja zakończona niepowodzeniem!",
                                subheader: `Ilośc punktów w trasie i CSV nie są jednakowe.`,
                                success: false
                            });
                        }

                        if(order.length === this.singleOrders.length)
                        {
                            await db.collection('Routes').doc(this.route.id).update({
                                orders: order
                            })
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Akcja zakończona sukcesem!",
                                subheader: "Trasa została posortowana.",
                                success: true
                            });
                            this.$router.push(`/dashboard/routes/list`)
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Akcja zakończona niepowodzeniem!",
                                subheader: "Trasa nie została posortowana.",
                                success: false
                            });
                        }
                    }
                    else
                    {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona niepowodzeniem!",
                        subheader: `Sprawdź poprawność importowanego pliku CSV.`,
                        success: false
                    });
                    }
                }
                else
                {
                    await db.collection('Routes').doc(this.route.id).update({
                        description: this.description
                    })
                    this.$router.push(`/dashboard/routes/list`)
                }
            }
            catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił nieoczekiwany błąd!",
                    subheader: ``,
                    success: false
                });
            }

        },
        handleFileUpload(event)
        {
            if(event.target.files[0] !== undefined)
            {
                this.file = event.target.files[0];
                this.parseFile();
            }
            else
            {
                this.content = null;
            }
        },
        parseFile()
        {
            Papa.parse(this.file, {
                header: true,
                skipEmptyLines: true,
                delimiter: ";",
                complete: function( results ){
                    this.content = results;
                    this.parsed = true;
                }.bind(this)
            });
        },
        get()
        {

            const stopOffPoint = {
            code: 'PL',
            postcode: '63-604',
            place: 'Baranów',
            address: 'ulica Cło',
            costs: '0',
            item6: '',
            item7: '',
            item8: '',
            item9: '',
            item10: '',
            item11: '',
            item12: '',
            item13: '',
            item14: '',
            item15: '0',
            item16: '',
            item17: '',
            item18: 'town',
            item19: '',
            item20: '',
            item21: '',
            item22: '',
            item23: '',
            item24: '',
            item25: '',
            item26: '',
            item27: '',
            item28: 'UNCHANGED',
            item29: '',
            item30: '',
            }

            const ordersLayout = [
                stopOffPoint
            ];

            let order = {
                code: '',
                postcode: '',
                place: '',
                address: '',
                costs: '0',
                item6: '',
                item7: '',
                item8: '',
                item9: '',
                item10: '',
                item11: '',
                item12: '',
                item13: '',
                item14: '',
                item15: '0',
                item16: '',
                item17: '',
                item18: 'town',
                item19: '',
                item20: '',
                item21: '',
                item22: '',
                item23: '',
                item24: '',
                item25: '',
                item26: '',
                item27: '',
                item28: 'UNCHANGED',
                item29: '',
                item30: '',
            };
            
            for(let i=0; i<this.singleOrders.length; i++)
            {
                order = {
                    code: this.singleOrders[i].delivery.country.code,
                    postcode: this.singleOrders[i].delivery.postCode,
                    place: this.singleOrders[i].delivery.city,
                    address: this.singleOrders[i].delivery.address,
                    costs: '0',
                    item6: '',
                    item7: '',
                    item8: '',
                    item9: '',
                    item10: '',
                    item11: '',
                    item12: this.singleOrders[i].id,
                    item13: '',
                    item14: '',
                    item15: '0',
                    item16: '',
                    item17: '',
                    item18: 'town',
                    item19: '',
                    item20: '',
                    item21: '',
                    item22: '',
                    item23: '',
                    item24: '',
                    item25: '',
                    item26: '',
                    item27: '',
                    item28: 'UNCHANGED',
                    item29: '',
                    item30: '',
                };
                ordersLayout.push(order)
            }
            ordersLayout.push(stopOffPoint)
            const csvdata = this.csvmaker(ordersLayout);
            this.download(csvdata);
        },
        csvmaker(data)
        {
            let csvRows = [];
            for(let i=0;i<data.length; i++)
            {
                const values = Object.values(data[i]).join(';');
                csvRows.push(values)
            }
            return csvRows.join('\n')
        },
        download(data)
        {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let counter = 0;
            while (counter < 8)
            {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
            counter += 1;
            }
            const blob = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.setAttribute('href', url)
            a.setAttribute('download', `PTV-${result}.csv`);
            a.click()
        },
        calculateProducts(order)
        {
            let products = 0;
            Object.values(order.positions).forEach(position => {
                products += position.product.amountOfPackages * position.product.quantity
            }); 
            return products
        },
        pickDate(value)
        {
            this.date = value;
            this.getDrivers()
            this.getVehicles()
        },
        filenameGenerator(length)
        {
            let result           = `prod_`;
            let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for ( let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
                charactersLength));
            }
            return result;
        },
        async changeStep(val)
        {
            switch(val)
            {
                case 1: 
                    this.progress = 0;
                    this.step = 1;
                    break;
                case 2:
                    if(this.stepValidation === true)
                    {   
                        this.drivers.input.showList = false;
                        this.step = 2;
                        this.progress = 50;
                        this.clearData();
                        await this.getAllOrders();
                    }
                    break;
                case 3:
                    this.singleLoad = true;
                    this.step = 3;
                    this.progress = 75;
                    this.addDateToDb();
                    break;
                case 4:
                    this.step = 4;
                    this.progress = 100;
                    break;
            }
        },
        async setDeliveryDate()
        {
            try
            {
                for(let orderId of this.checkedOrders) 
                {
                    const orderDoc = db.collection("Orders").doc(orderId)
                    const order = (await orderDoc.get()).data()
                    const routeStart = moment(this.date[0])
                    const routeEnd = moment(this.date[1])
                    let plannedDate
                    
                    //if suggested date doesn't exist or it doesn't land between start and end of the route
                    if(!order.delivery.date.suggested || !moment.unix(order.delivery.date.suggested.unix).isBetween(routeStart, routeEnd, "[]"))
                    {
                        plannedDate = {
                            asText: moment(this.date[0]).format("YYYY-MM-DD"),
                            unix: moment(this.date[0]).unix(),
                            dateObj: moment(this.date[0]).toDate(),
                            dayOfWeek: moment(this.date[0]).day(),
                            dayNum: moment(this.date[0]).date(),
                            monthNum: moment(this.date[0]).month() + 1,
                            year: moment(this.date[0]).year(),
                            weekNum: moment(this.date[0]).week()                  
                        }
                    } else {  
                        plannedDate = order.delivery.date.suggested
                    }
                    await orderDoc.update({"delivery.date.planned": plannedDate})
                }
            }
            catch (error)
            {
                console.log(error);    
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił nieoczekiwany błąd!",
                    subheader: ``,
                    success: false
                });
            }
        },
        resetFilters()
        {
            this.pickedOption = "desc",
            this.pickedSort = "all",
            this.pickedCountry = 'all';
        },
        showFilters()
        {
            this.showStatusFilters = !this.showStatusFilters;
            if(!this.showStatusFilters)
            {
                this.selectedGroups = null;
                this.selectOrders('Brak')
                this.resetFilters()
                this.getAllOrders()
            }
        },
        createSuggestedDate(date)
        {
            let start = null
            let end = null
            if(moment(date[0]).isBefore(date[1]))
            {
                start = moment(date[0]).format("DD.MM.YYYY")
                end = moment(date[1]).format("DD.MM.YYYY")
            }
            else
            {
                start = moment(date[1]).format("DD.MM.YYYY") 
                end = moment(date[0]).format("DD.MM.YYYY")
            }
            let text = start + ' - ' + end;

            return text
        },
        checkOrdersByDeliveryDate()
        {
            this.newCheckedOrders = [];
            if(!this.ordersByDeliveryDate)
            {
                this.ordersByDeliveryDate = true
                this.orders.forEach(order => {
                    if(order.delivery.date.suggested !== null)
                    {
                        const compare = moment.unix(order.delivery.date.suggested.unix)
                        const start = this.date[0]
                        const end = this.date[1]
                        if(compare.isBetween(start, end, 'days', '[]'))
                        {
                            this.newCheckedOrders.push(order)
                        }
                    }
                }) 
            }
            else
            {
                this.ordersByDeliveryDate = false
            }
        },
        createPositionsList(ord)
        {
            const positions = Object.entries(ord.positions).map((arr) => ({
            item: arr[1]
            }));
            return positions
        },
        openAdditionalInformation(indx)
        {
            if(this.toggleInformation[indx].open)
            {
                this.toggleInformation[indx].open = false
            }
            else
            {
            for (let i = 0; i < Object.keys(this.toggleInformation).length; i++) {
                this.toggleInformation[i].open = false
            }
            this.toggleInformation[indx].open = true
            }
        },
        calculateVolume(e, order)
        {
            if(e.target.checked)
            {
                Object.values(order.positions).forEach(position => {
                this.fullVolume = currency(this.fullVolume).add(position.volume);
                // this.fullVolume += Math.round(position.volume * 100) / 100;
                });
            }
            if(!e.target.checked)
            {
                Object.values(order.positions).forEach(position => {
                this.fullVolume = currency(this.fullVolume).subtract(position.volume);
                // this.fullVolume -= Math.round(position.volume * 100) / 100;
                });
            }
        },
        checkOrder(e, order, index)
        {
            if(e.target.checked)
            {
                order.index = index
                this.checkedOrders.push(order.id)
            }
            if(!e.target.checked)
            {
                for(let i=0; i<this.checkedOrders.length; i++)
                {
                    if(this.checkedOrders[i] === order.id)
                    {
                        this.checkedOrders.splice(i, 1)
                    }
                }
            }
        },
        isOrderChecked(order) {
            return !!this.checkedOrders.find(el => el  === order.id)
        },
        async getStatuses()
        {
            try
            {
                let res = await db.collection("OrderStatuses").doc("StatusList").get()
                let resData = res.data()
                this.statuses = resData.list                    
            }
            catch (error)
            {
                console.log(error);    
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił nieoczekiwany błąd!",
                    subheader: ``,
                    success: false
                });
            }
        },
        getStatusById(id)
        {
            let currentStatus = {
                name: "Brak",
                info: "Brak",
                id: null
            }
            this.statuses.forEach((status) => {
                if(status.id === id){
                    currentStatus = {
                        name: status.name,
                        info: status.info,
                        id: status.id
                    }
                }
            })
            return currentStatus
        },
        totalPrice(positions, currency, deliveryPrice)
        {
            let totalPrice = 0;
            for (const pos in positions) {
                totalPrice = totalPrice + (positions[pos].product.quantity * positions[pos].product.pricePerItemTaxIncluded)
            }
            if(deliveryPrice > 0)
            {
                totalPrice = totalPrice + deliveryPrice;
            }
            return `${totalPrice} ${currency}`
        },
        orderPlacedDateFromUNIX(unix)
        {
            if(unix === undefined) return "--"
            if(unix === null) return "--"
            if(unix === 0) return "Brak"
            let date = moment(unix * 1000).format("DD.MM.YY HH:mm");
            return date;
        },
        amountOfItems(positions)
        {
            let posAm = 0;
            let items = 0;
            for (const pos in positions)
            {
                posAm = posAm +1
                items = items + positions[pos].product.quantity;
            }

            return `${posAm} poz. (${items} przedmiotów)`
        },
        amountOfItemsForLabel(positions)
        {
            let posAm = 0;
            let items = 0;
            for (const pos in positions)
            {
                posAm = posAm +1
                items = items + positions[pos].product.quantity;
            }

            return `${posAm}`
        },
        async getCountries()
        {
            try
            {
                this.countriesData = [];
                const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
                if(res.data.status === 'SUCCESS')
                {
                    res.data.countries.forEach(country => {
                        this.countriesData.push({
                            code: country.countryCode, flagURL: country.flagURL
                        });
                    });
                }
            }
            catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił nieoczekiwany błąd!",
                    subheader: ``,
                    success: false
                });
            }
        },
        async getAllOrders()
        {
            // this.resetSearch()
            this.load = true;
            this.hideOptions = false,
            this.query = null;
            this.queryLimit = 25;
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }
            this.query = db.collection("Orders")
            this.query = this.query.where("statusId", "in", [3, 400, 401, 403])

            switch(this.pickedSort)
            {
                case 'all':
                    this.query = this.query
                    break;
                case 'country':
                    this.query = this.query.where("delivery.country.code", "==", this.pickedCountry)
                    break;
                case 'suggested':
                    this.query = this.query.orderBy("delivery.date.suggested", this.pickedOption)
                    break;
                case 'code':
                    this.query = this.query.orderBy("delivery.postCode", this.pickedOption)
                    break;
                default:
                    this.query = this.query      
                    break;
            }

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.orders = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let current = doc.data()
                    current.countryFlagURL = "";

                    if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                    {
                        current.delivery.country.code = current.invoice.countryCode;
                    }
                    
                    for(let y=0; y< this.countriesData.length; y++)
                    {
                        if(this.countriesData[y].code === current.delivery.country.code)
                        {
                            current.countryFlagURL = this.countriesData[y].flagURL;
                        }
                    }

                    this.orders.push(current);
                    for(let t=0; t<this.orders.length; t++)
                    {
                        this.toggleInformation[t] = {
                            open: false
                        }
                    }

                    this.lastLoaded = current.meta.created.date;
                });
                if(this.selectedGroups)
                { 
                    this.orders = this.selectOrders(this.selectedGroups.country.code, this.selectedGroups.postcodes)
                }
            });
            this.load = false;
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 25;
            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.orders = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let current = doc.data()
                    current.countryFlagURL = "";
                    if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                    {
                        current.delivery.country.code = current.invoice.countryCode;
                    }
                    
                    for(let y=0; y< this.countriesData.length; y++)
                    {
                        if(this.countriesData[y].code === current.delivery.country.code)
                        {
                            current.countryFlagURL = this.countriesData[y].flagURL;
                        }
                    }
                    this.orders.push(current);
                    for(let t=0; t<this.orders.length; t++)
                    {
                        this.toggleInformation[t] = {
                            open: false
                        }
                    }
                    if(this.selectedGroups)
                    { 
                        this.orders = this.selectOrders(this.selectedGroups.country.code, this.selectedGroups.postcodes)
                    }
                    this.lastLoaded = current.meta.created.date;
                });
            });
        },
        loadMoreOrders()
        {
            this.dbListener();
            this.continueListening();      
        },
        selectOrders(country, codes)
        {
            if(country === "Brak")
            {
                return this.orders
            }
            const countryOrders = this.orders.filter(order => order.delivery.country.code === country);
            const groupOrders = []

            switch (country)
            {
                case 'PL':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'DE':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'FR':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'IT':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'NL':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'BE':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'AT':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'LU':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                default:
                    console.log(`Country ${country} not supported`);
                    break;
            }

            return groupOrders
        },
        getManufacturer(order)
        {
            const manufacturer = Object.entries(order.positions)
            let translatedListOfManufacturer = [];
            manufacturer.forEach(element => {
                if(element[0])
                {
                    if(typeof element[1].product.manufacturer === "string")
                    {
                        if(!translatedListOfManufacturer.includes(element[1].product.manufacturer))
                        {
                            translatedListOfManufacturer.push(element[1].product.manufacturer);
                        }
                    }
                    else
                    {
                        if(!translatedListOfManufacturer.includes(element[1].product.manufacturername))
                        {
                            translatedListOfManufacturer.push(element[1].product.manufacturer.name);
                        }
                    }
                }
            })
            return translatedListOfManufacturer
        },
        createCommentList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfComments.push(element[1].infos.onOrderCreation);
                }
            })

            return translatedListOfComments
        },
        createProductList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfProducts = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfProducts.push(element[1].product.name);
                }
            })

            return translatedListOfProducts
        },
        async getVehicles()
        {
            try
            {
                this.load = true
                this.dbListenerVehicles = db.collection("Vehicles").orderBy("meta.createdDate", "desc")
                .onSnapshot((querySnapshot) => {
                this.vehicles = [];

                querySnapshot.forEach((doc) => {
                    const data = doc.data()

                    const routeStart = moment(this.date[0]).unix();
                    const routeEnd = moment(this.date[1]).unix();

                    if(data.active === false)
                    {
                        data.available = false
                    }
                    else
                    {
                        data.available = true
                    }

                    data.bookings.forEach(booking => {
                    
                    const bookingStart = booking.start.seconds
                    const bookingEnd = booking.end.seconds

                    if(bookingEnd < routeStart)
                    {
                        //Booking ends before route starts
                        return
                    }
                    if(bookingStart > routeEnd) {
                        //Booking start after route ends
                        return
                    }
                        data.available = false
                    })
                    this.vehicles.push(data);
                });
                    this.vehicles.sort((a, b) => {
                        if(a.available && !b.available){
                        return -1
                        }
                        if(!a.available && b.available){
                        return 1
                        }
                        return 0
                    })
                });
                this.load = false;
            }
            catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił nieoczekiwany błąd!",
                    subheader: ``,
                    success: false
                });
            }
        },
        async getDrivers()
        {
            try
            {
                const routeStart = moment(this.date[0]).unix()
                const routeEnd = moment(this.date[1]).unix()

                this.drivers.all = [];
                const driversDoc = await db.collection("UsersData").where("permissions.driver", "==", true).orderBy("name", "asc").get()

                driversDoc.forEach(driverDoc => {
                const driver = driverDoc.data()
                driver.available = true

                driver.routeSchedules.forEach(schedule => {
                    const bookingStart = schedule.start.seconds;
                    const bookingEnd = schedule.end.seconds;

                    if(bookingEnd < routeStart) {
                    //Booking ends before route starts so it's irrelevant
                    return
                    }
                    if(bookingStart > routeEnd) {
                    //Booking starts after route ends so it's irrelevant
                    return
                    }

                    driver.available = false
                })

                this.drivers.all.push(driver)
                })
            }
            catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił nieoczekiwany błąd!",
                    subheader: ``,
                    success: false
                });
            }
        },
        async getPostcodeGroups()
        {
            this.groups = [];
            this.postcodesList = [];
            const res = await db.collection('PostcodeGroups').orderBy("meta.createdDate", "desc").get()

            res.docs.forEach(doc => {
                this.groups.push(doc.data())
                this.postcodesList.push(...doc.data().postcodes)
            });
        },
        validate()
        {
            if(!this.date || !this.date[0] || !this.date[1]) return false
            return true
        },
        validate1()
        {
            if(!this.date || !this.date[0] || !this.date[1] || this.selectedDrivers.length === 0) return false
            return true
        },
        validateEnd()
        {
            if(!this.date || !this.date[0] || !this.date[1] || this.selectedDrivers.length === 0) return false
            return true
        },
        validateStep()
        {
            if(!this.date || !this.date[0] || !this.date[1] || this.selectedDrivers.length === 0 || this.selectedVehicles === null || this.selectedCountry === null) return false
            return true
        },
        async addDateToDb()
        {
            this.$store.commit('setNotification',{
                show: true,
                head: "Tworzenie trasy..",
                subheader: `Nie zamykaj tego okna.`,
                success: true
            }); 
            try
            {
                if(this.checkedOrders.length > 0 && this.selectedVehicles.id.length > 0 && this.selectedDrivers.length > 0)
                {
                    let uniqueOrders = this.checkedOrders.filter((element, index) => {
                        return this.checkedOrders.indexOf(element) === index;
                    });

                    const res = await axios.post(`${this.$store.state.apiLink}/route/create`, {
                        vehicle: this.selectedVehicles,
                        orders: uniqueOrders,
                        drivers: this.selectedDrivers,
                        country: this.selectedCountry,
                        createdBy: this.$store.state.userData,
                        description: this.description, 
                        title: this.title,
                        date: this.date,
                        appVersion: process.env.VUE_APP_VERSION
                    })
                    if(res.data.success)
                    {
                        this.getSingleRoute(res.data.route);
                        for(let i=0; i<uniqueOrders.length; i++)
                        {
                            await this.$store.dispatch('updateHistory', {
                            collectionName: "Orders",
                            docId: uniqueOrders[i],
                            message: "Dodano zamówienie do trasy.",
                            routeId: res.data.route,
                            details: [res.data.route]
                            })
                        }
                    }
                    else
                    {
                        this.changeStep(1);
                        this.clearData();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wystąpił nieoczekiwany błąd!",
                            subheader: `${res.data.log}`,
                            success: false
                        });                    
                    }
                }
                else
                {
                    throw error
                }
            }
            catch(error)
            {
                console.log(error);
            }
        },
        async getSingleRoute(value)
        {
            this.route.id = value;
            let result = await db.collection('Routes').doc(value).get()
            this.singleRoute = result.data();

            for(let i=0; i<this.singleRoute.orders.length; i++)
            {
                this.singleOrders.push((await this.singleRoute.orders[i].ref.get()).data())
            }
            this.ordersDownloaded = true;

            this.singleLoad = false;
        },
        clearData()
        {
            this.fullVolume = 0;
            this.checkedOrders = [];
            this.toggleInformation = {};
            this.title = '';
            this.description = '';
        },
        prepareElasticDocument(route)
        {
            let document = {
                // names: [route.group.name],
                // countries: [route.group.country.name, route.group.country.SelectInputDisplayName],
                brands: [route.vehicle.brand],
                models: [route.vehicle.model],
                licensePlates: [route.vehicle.attribs.licensePlate.value],
                id: route.id,
            }

            return document;
        },
    },
    computed: {
        sortList()
        {
            if(!this.selectedGroups || this.selectedGroups.country.code === 'Brak') {
                return [
                { id: 1, name: 'Kraj dostawy', type: 'country'},
                { id: 1, name: 'Kod pocztowy', type: 'code'},
                { id: 1, name: 'Sugerowana dostawa', type: 'suggested'},
                ]
            } else {
                return [
                { id: 1, name: 'Kod pocztowy', type: 'code'},
                { id: 1, name: 'Sugerowana dostawa', type: 'suggested'},
                ]
            }
        },
        selectedDrivers()
        {
            const selectedDrivers = []
            this.drivers.all.forEach(driver => {
                if(driver.selected) selectedDrivers.push(driver)
            })
            return selectedDrivers
        },
        fullSelectedVehicle()
        {
            return `${this.selectedVehicles.brand} ${this.selectedVehicles.model} [${this.selectedVehicles.attribs.licensePlate.value}]`
        },
        validation()
        {
            return this.validate();
        },
        validation1()
        {
            return this.validate1();
        },
        stepValidation()
        {
            return this.validateStep();
        }
    },
    watch: {
        selectedVehicles(value, oldValue)
        {
            if(!value.available)
            {
                this.selectedVehicles = oldValue
            }
        },
        async selectedGroups()
        {
            this.resetFilters()
            await this.getAllOrders()
            if(this.selectedGroups === null)
            {
                this.orders = this.selectOrders('Brak')
            }
            else
            {
                this.orders = this.selectOrders(this.selectedGroups.country.code, this.selectedGroups.postcodes)
            }
        }
    }
}
</script>
<style scoped>
.smooth-enter-active,
.smooth-leave-active {
  overflow: hidden;
  transition: max-height .4s;
}

.smooth-enter-from,
.smooth-leave-to {
  max-height: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.75s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>