<template>
  <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
    <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
      <li class="flex">
        <div class="flex items-center">
        <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
          <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
          <span class="sr-only">Home</span>
        </router-link>
        </div>
      </li>
      <li class="flex">
        <div class="flex items-center">
        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
        <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
        </div>
      </li>
      <li class="flex">
        <div class="flex items-center">
        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
        <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Skaner kodów QR</div>
        </div>
      </li>
    </ol>
  </nav>
  <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
    &larr; Cofnij
  </router-link>

  <div :class="[!fullscreen ? 'flex flex-col justify-between gap-4 mt-10' : '', 'flex justify-center']">
    <!-- camera error alert. -->
    <div v-if="error" class="border-l-4 border-red-400 bg-red-50 p-4 mb-4 select-none">
      <div class="flex">
        <div class="flex items-center">
          <ExclamationIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <p class="text-sm text-red-700">
            ERROR:
            {{ ' ' }}
            <span class="font-medium text-red-700 underline hover:text-red-600">{{errorLog.log}}</span>
          </p>
        </div>
      </div>
    </div>

    <div v-if="!fullscreen" class="flex flex-col justify-center items-center gap-4 mt-8">
      <span class="uppercase text-gray-500 font-bold text-2xl select-none text-center">Wybierz tryb do skanowania</span>
      <div class="flex gap-10">
        <div @click="fullscreen = false" class="flex flex-col items-center group text-gray-400">
          <QrcodeIcon :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
          <span :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">
            Skaner
          </span>
        </div>
        <div @click="error ? '' : fullscreen = true" :class="[error ? 'opacity-30 pointer-events-none' : '', 'flex flex-col items-center group text-gray-400']">
          <CameraIcon :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
          <span :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">
            Kamera
          </span>
        </div>
      </div>
      <p class="text-xs italic text-gray-600 select-none">Skaner działa tylko z urządzeniami z podczerwienią.</p>
      <div @click="clearData('scannerClear')" :class="[scannerDecode.length > 0 ? 'select-none rounded-md bg-indigo-200 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 items-center text-center flex justify-center my-2' : '', 'transition-all duration-300']">
        <span v-if="scannerDecode.length > 0" class="absolute font-semibold text-lg select-none">INNA PACZKA</span>
        <input v-if="fullscreen != null ? !fullscreen : false" @focus="focus = true" @blur="focus = false" type="text" v-model="scannerDecode" ref="scanner" name="scanner" id="scanner" style="caret-color: transparent;" class="cursor-pointer select-none opacity-0 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
      </div>

      <!-- 1st step -->
      <Transition name="fade">
        <div class="relative w-full flex justify-center" v-if="step === 1">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>
          <LoadingSpinnerHub v-if="loading" />
          <div v-if="modalData.accept && !loading" :class="[modalData.found ? 'px-4 pb-4 pt-5' : 'py-6', 'min-w-full sm:min-w-0 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
            <!-- found -->
            <div v-if="modalData.found">
              <div class="mt-3 text-center sm:mt-5 select-none">
                <div v-if="!isUnique" class="pb-4">
                  <div class="rounded-md bg-red-50 p-3 flex justify-center flex-col">
                    <div class="flex justify-center">
                      <div class="flex-shrink-0">
                        <ExclamationIcon class="h-7 w-7 text-red-400 mr-2" aria-hidden="true" />
                      </div>
                      <h3 class="font-semibold text-red-800">WYKRYTO NIEAKTUALNĄ ETYKIETĘ</h3>
                    </div>
                    <p class="font-semibold uppercase text-sm">Zablokowano możliwość przeniesienia paczki</p>
                  </div>
                </div>
                <div :class="[!isUnique ? 'opacity-50' : '']">
                  <h3 class="text-base font-bold leading-6 text-gray-900">{{modalData.bodyText}}</h3>
                  <div v-if="modalData.headerText.length > 0" class="mt-2">
                    <p class="text-sm text-gray-500">Paczka: 
                      <span class="font-bold ">{{modalData.headerText}}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div :class="[!isUnique ? 'opacity-50' : '', 'mx-auto flex items-center justify-center']">
                <img v-if="modalData.found" class="h-40" :src="modalData.image.url" alt="">
              </div>
            </div>
            <!-- !found -->
            <div v-if="!modalData.found" class="px-4">
              <div class="rounded-md bg-red-50 p-3 flex justify-center flex-col">
                <div class="flex justify-center">
                  <div class="flex-shrink-0">
                    <ExclamationIcon class="h-7 w-7 text-red-400 mr-2" aria-hidden="true" />
                  </div>
                  <h3 class="font-semibold text-red-800">{{modalData.bodyText}}</h3>
                </div>
                <div class="flex items-center justify-center">
                  <p class="font-semibold uppercase text-sm break-words">{{modalData.headerText}}</p>
                </div>
              </div>
              <!-- <div class="text-center select-none">
                <h3 class="text-lg font-semibold leading-6 text-gray-900">
                  {{modalData.bodyText}}
                </h3>
                <div v-if="modalData.headerText.length > 0" class="mt-2">
                  <span class="font-semibold text-gray-500">{{modalData.headerText}}</span>
                </div>
              </div> -->
              <div class="mx-auto flex items-center justify-center">
                <component :is="modalData.image" v-if="!modalData.found" class="h-40 text-indigo-500" />
              </div>
            </div>
            <div class="mt-6" v-if="modalData.found">
              <button :disabled="!isUnique" type="button" @click="changeStep(2)" :class="[!isUnique ? 'opacity-50 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Przejdź dalej
              </button>
            </div>
          </div>
        </div>
      </Transition>
      <!-- 2nd step -->
      <Transition name="fade">
        <div class="relative w-full flex justify-center" v-if="modalData.accept && step === 2">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>
          <div :class="['px-4 pb-4 pt-5 min-w-full sm:min-w-0 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
            <h2 class="text-sm font-semibold text-gray-900 select-none">Skąd przenieść paczkę?</h2>
            <div class="mt-3 flex flex-col overflow-y-scroll max-h-48 pr-3">
              <div v-for="(strg, index) in storage" :key="index" :class="[index !== 0 ? 'mt-3' : '', 'flex flex-col gap-2']">
                <span class="font-semibold text-gray-600 uppercase select-none">{{strg.name}}</span>
                <template v-for="(seg, index) in segment.to != null ? segmentsToRelocate.filter(seg => seg.id !== segment.to.id) : segmentsToRelocate" :key="index">
                  <div @click="selectSegment('from', seg, index)" v-if="strg.id === seg.warehouse.id" class="flex">
                    <div :class="[index % 2 ? 'bg-red-500' : 'bg-indigo-500', 'select-none flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">{{ seg.name.length > 2 ? seg.name.slice(0, 3) : seg.name }}</div>
                    <div :class="['transition-all duration-300 flex flex-1 items-center truncate rounded-r-md border-b border-r border-t border-gray-200', seg.selected ? 'bg-gray-100' : 'bg-white']">
                      <div class="flex justify-between w-full truncate px-4 py-3 text-sm items-center">
                        <div class="flex flex-col">
                          <span class="font-medium text-gray-900 hover:text-gray-600 select-none">{{ seg.name }}</span>
                          <p class="text-gray-300 select-none">ID: {{ seg.id }}</p>
                        </div>
                        <Transition name="fade2">
                        <div class="flex h-6 items-center" v-if="seg.selected">
                          <input v-model="seg.selected" id="segment-1" aria-describedby="segment-1" name="segment-1" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                        </div>
                        </Transition>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="rounded-md bg-yellow-50 border-2 border-yellow-100 py-4 px-3 h-16 flex items-center select-none pointer-events-none">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <ExclamationIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div class="ml-3 flex items-center">
                          <h3 class="text-xs font-semibold text-yellow-800 uppercase">Paczka z tym unikalnym ID nie została znaleziona.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="mt-6">
              <button :disabled="this.segment.from == null" type="button" @click="changeStep(3)" :class="[this.segment.from == null ? 'opacity-30 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Przejdź dalej
              </button>
            </div>
          </div>
        </div>
      </Transition>
      <!-- 3rd step -->
      <Transition name="fade">
        <div class="relative w-full flex justify-center" v-if="modalData.accept && step === 3">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>
          <div :class="['px-4 pb-4 pt-5 min-w-full sm:min-w-0 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
            <h2 class="text-sm font-semibold text-gray-900 select-none">Gdzie umieścić paczkę?</h2>
              <div class="my-3">
                <label for="email" class="block text-xs font-medium leading-6 text-gray-600 uppercase">Wpisz nazwę segmentu, aby go wyszukać</label>
                <input v-model="search" type="text" name="segmentFrom" id="segmentFrom" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: 9B" />
                <div class="relative  mt-3">
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300" />
                  </div>
                  <div class="relative flex justify-center">
                    <span class="bg-white px-2 text-gray-500">
                      <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            <div class="flex flex-col overflow-y-scroll max-h-48 pr-3">
              <div v-for="(strg, index) in storage" :key="index" :class="[index !== 0 ? 'mt-3' : '', 'flex flex-col gap-2']">
                <span class="font-semibold text-gray-600 uppercase select-none">{{strg.name}}</span>
                <template v-for="(seg, indx) in search.length > 0 ? searchSegment : segments.filter(seg => seg.id !== segment.from.id)" :key="indx">
                  <div @click="selectSegment('to', seg, indx)" v-if="(strg.id === seg.warehouse.id) && seg != null" class="flex">
                    <div :class="[indx % 2 ? 'bg-red-500' : 'bg-indigo-500', 'select-none flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">{{ seg.name.length > 2 ? seg.name.slice(0, 3) : seg.name }}</div>
                    <div :class="['transition-all duration-300 flex flex-1 items-center truncate rounded-r-md border-b border-r border-t border-gray-200', seg.selected ? 'bg-gray-100' : 'bg-white']">
                      <div class="flex justify-between w-full truncate px-4 py-3 text-sm items-center">
                        <div class="flex flex-col">
                          <span class="font-medium text-gray-900 hover:text-gray-600 select-none">{{ seg.name }}</span>
                          <p class="text-gray-300 select-none">ID: {{ seg.id }}</p>
                        </div>
                        <Transition name="fade2">
                        <div class="flex h-6 items-center" v-if="seg.selected">
                          <input v-model="seg.selected" id="segment-1" aria-describedby="segment-1" name="segment-1" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                        </div>
                        </Transition>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-if="strg.segments === 0">
                  <div class="rounded-md bg-yellow-50 border-2 border-yellow-100 py-4 px-3 h-16 flex items-center select-none pointer-events-none">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <ExclamationIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3 flex items-center">
                        <h3 v-if="search.length === 0" class="text-xs font-semibold text-yellow-800 uppercase">BRAK UTWORZONYCH SEGMENTÓW W MAGAZYNIE.</h3>
                        <h3 v-if="search.length > 0" class="text-xs font-semibold text-yellow-800 uppercase">BRAK WYSZUKIWANEGO SEGMENTU</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 flex gap-6">
              <button v-if="!automaticallySelected" :disabled="automaticallySelected" type="button" @click="changeStep(2)" :class="[automaticallySelected ? 'opacity-50 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-red-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 select-none']">
                <ArrowCircleLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Poprzedni krok
              </button>
              <button v-if="automaticallySelected" :disabled="!automaticallySelected" type="button" @click="clearData('scanner')" :class="['flex justify-center w-full items-center gap-x-2 rounded-md bg-red-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 select-none']">
                <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Anuluj
              </button>
              <button :disabled="this.segment.to == null" type="button" @click="changeStep(4)" :class="[this.segment.to == null ? 'opacity-30 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 select-none']">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Przejdź dalej
              </button>
            </div>
          </div>
        </div>
      </Transition>
      <!-- 4th step -->
      <Transition name="fade">
        <div class="relative w-full flex justify-center" v-if="modalData.accept && step === 4">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>
          <div :class="['px-4 pb-4 pt-5 min-w-full sm:min-w-0 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
            <h2 class="text-sm font-semibold text-gray-500 select-none">Potwierdzenie przenoszenia paczki.</h2>
            <div class="flex gap-2 justify-center items-center py-8">
              <span class="text-2xl font-semibold text-gray-600">{{segment.from.name}}</span>
              <ArrowNarrowRightIcon class="w-8 h-8" />
              <span class="text-2xl font-semibold text-gray-600">{{segment.to.name}}</span>
            </div>
            <div class="flex gap-6">
              <button type="button" @click="changeStep(3)" :class="['flex justify-center w-full items-center gap-x-2 rounded-md bg-red-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                <ArrowCircleLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Poprzedni krok
              </button>
              <button :disabled="lockButton && this.segment.from == null && this.segment.to == null" type="button" @click="acceptRelocation()" :class="[this.segment.from == null && this.segment.to == null ? 'opacity-30 pointer-events-none' : '', lockButton ? 'opacity-30 pointer-events-none select-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Akceptuj
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>

    <!-- camera -->
    <div v-if="fullscreen">
      <div class="flex flex-col p-2">
        <div :class="{'pt-4': error}">
          <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
            <qrcode-stream v-if="fullscreen" @detect="onDecode" @init="onInit">

              <!-- <div v-if="acceptedPackage" class="bg-white select-none py-1 px-2 rounded-lg filter fullscreen-button-check relative flex items-start">
                <div class="flex h-6 items-center">
                  <input v-model="haveQRCode" id="qr" aria-describedby="qr-code" name="qr" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 transition-all duration-300" />
                </div>
                <div class="ml-3 text-sm leading-6">
                  <label for="qr" class="text-gray-900">
                    Mam <span class="font-semibold">QR magazynu</span> lub <span class="font-semibold"> segmentu</span>
                  </label>
                </div>
              </div> -->

              <div v-if="!error">
                <button v-if="!notification.success" @click="fullscreen = false" :class="[fullscreen ? 'rotate-180' : '','filter fullscreen-button bg-white rounded-lg hover:text-gray-400 transition-all duration-300 transform -scale-x-100']">
                  <ExternalLinkIcon class="w-6 h-6 bg-transparent" />
                </button>
              </div>

              <!-- custom notification -->
              <Transition name="fade2">
              <div v-if="fullscreen && notification.show" :class="{ fullscreen: fullscreen }" class="shadow-lg py-10">
                <div class="flex min-h-full items-end p-4">
                  <div :class="[notification.success ? 'bg-green-50' : 'bg-red-50', 'rounded-md p-4 w-full']">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <CheckCircleIcon :class="[notification.success ? 'text-green-400' : 'text-red-400', 'h-5 w-5']" aria-hidden="true" />
                      </div>
                      <div class="ml-3">
                        <h3 :class="[notification.success ? 'text-green-800' : 'text-red-800', 'text-sm font-medium']">{{ notification.head }}</h3>
                        <div :class="[notification.success ? ' text-green-700' : ' text-red-700', 'mt-1 text-sm']">
                          <p>{{ notification.subheader }}</p>
                        </div>
                      </div>
                      <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                          <button @click.prevent="notification.show = false;" type="button" :class="[notification.success ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50' : 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50', 'inline-flex rounded-md  p-1.5  focus:outline-none focus:ring-2focus:ring-offset-2']">
                            <span class="sr-only">Dismiss</span>
                            <XIcon class="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Transition>
              
              <!-- 1st step -->
              <Transition name="fade">
                <div class="absolute bottom-0 w-full flex justify-center pb-4 px-4" v-if="step === 1">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>
                  <div class="min-w-full sm:min-w-0 transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 py-20" v-if="loading">
                    <LoadingSpinnerHub  />
                  </div>
                  <div v-if="modalData.accept && !loading" :class="[modalData.found ? 'px-4 pb-4 pt-5' : 'py-12', 'min-w-full sm:min-w-0 transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
                    <!-- found -->
                    <div v-if="modalData.found">
                      <div class="mt-3 text-center sm:mt-5 select-none">
                        <div v-if="!isUnique" class="pb-4">
                          <div class="rounded-md bg-red-50 p-3 flex justify-center flex-col">
                            <div class="flex justify-center">
                              <div class="flex-shrink-0">
                                <ExclamationIcon class="h-7 w-7 text-red-400 mr-2" aria-hidden="true" />
                              </div>
                              <h3 class="font-semibold text-red-800">WYKRYTO NIEAKTUALNĄ ETYKIETĘ</h3>
                            </div>
                            <p class="font-semibold uppercase text-sm">Zablokowano możliwość przeniesienia paczki</p>
                          </div>
                        </div>
                        <div :class="[!isUnique ? 'opacity-50' : '']">
                          <h3 class="text-base font-bold leading-6 text-gray-900">{{modalData.bodyText}}</h3>
                          <div v-if="modalData.headerText.length > 0" class="mt-2">
                            <p class="text-sm text-gray-500">Paczka: 
                              <span class="font-bold ">{{modalData.headerText}}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div :class="[!isUnique ? 'opacity-50' : '', 'mx-auto flex items-center justify-center']">
                        <img v-if="modalData.found" class="h-40" :src="modalData.image.url" alt="">
                      </div>
                    </div>
                    <!-- !found -->
                    <div v-if="!modalData.found">
                      <div class="mt-3 text-center sm:mt-5 select-none">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">
                          {{modalData.bodyText}}
                        </h3>
                        <div v-if="modalData.headerText.length > 0" class="mt-2">
                          <p class="text-sm text-gray-500">
                            <span class="font-bold">{{modalData.headerText}}</span>
                          </p>
                        </div>
                      </div>
                      <div class="mx-auto flex items-center justify-center">
                        <component :is="modalData.image" v-if="!modalData.found" class="h-40 text-indigo-500" />
                      </div>
                    </div>
                    <div class="mt-6" v-if="modalData.found">
                      <button :disabled="!isUnique" type="button" @click="changeStep(2)" :class="[!isUnique ? 'opacity-50 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Przejdź dalej
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>
              
              <!-- 2nd step -->
              <Transition name="fade">
                <div class="absolute bottom-0 w-full flex justify-center pb-4 px-4" v-if="modalData.accept && step === 2">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>
                  <div :class="['px-4 pb-4 pt-5 min-w-full sm:min-w-0 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
                    <h2 class="text-sm font-semibold text-gray-500 select-none">Skąd przenieść paczkę?</h2>
                    <div class="mt-3 flex flex-col overflow-y-scroll max-h-48 pr-3">
                      <div v-for="(strg, index) in storage" :key="index" :class="[index !== 0 ? 'mt-3' : '', 'flex flex-col gap-2']">
                        <span class="font-semibold text-gray-600 uppercase select-none">{{strg.name}}</span>
                        <template v-for="(seg, index) in segment.to != null ? segmentsToRelocate.filter(seg => seg.id !== segment.to.id) : segmentsToRelocate" :key="index">
                          <div @click="selectSegment('from', seg, index)" v-if="strg.id === seg.warehouse.id" class="flex">
                            <div :class="[index % 2 ? 'bg-red-500' : 'bg-indigo-500', 'select-none flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">{{ seg.name.length > 2 ? seg.name.slice(0, 3) : seg.name }}</div>
                            <div :class="['transition-all duration-300 flex flex-1 items-center truncate rounded-r-md border-b border-r border-t border-gray-200', seg.selected ? 'bg-gray-100' : 'bg-white']">
                              <div class="flex justify-between w-full truncate px-4 py-3 text-sm items-center">
                                <div class="flex flex-col">
                                  <span class="font-medium text-gray-900 hover:text-gray-600 select-none">{{ seg.name }}</span>
                                  <p class="text-gray-300 select-none">ID: {{ seg.id }}</p>
                                </div>
                                <Transition name="fade2">
                                <div class="flex h-6 items-center" v-if="seg.selected">
                                  <input v-model="seg.selected" id="segment-1" aria-describedby="segment-1" name="segment-1" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                </div>
                                </Transition>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="rounded-md bg-yellow-50 border-2 border-yellow-100 py-4 px-3 h-16 flex items-center select-none pointer-events-none">
                              <div class="flex">
                                <div class="flex-shrink-0">
                                  <ExclamationIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div class="ml-3 flex items-center">
                                  <h3 class="text-xs font-semibold text-yellow-800 uppercase">Paczka z tym unikalnym ID nie została znaleziona.</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="mt-6">
                      <button :disabled="this.segment.from == null" type="button" @click="changeStep(3)" :class="[this.segment.from == null ? 'opacity-30 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Przejdź dalej
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>
              
              <!-- 3rd step -->
              <Transition name="fade">
                <div class="absolute bottom-0 w-full flex justify-center pb-4 px-4" v-if="modalData.accept && step === 3">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>
                  <div :class="['px-4 pb-4 pt-5 min-w-full sm:min-w-0 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 border-2 border-gray-300']">
                    <h2 class="text-sm font-semibold text-gray-500 select-none">Gdzie umieścić paczkę?</h2>
                    <div class="my-3">
                      <label for="email" class="block text-xs font-medium leading-6 text-gray-600 uppercase">Wpisz nazwę segmentu, aby go wyszukać</label>
                      <input v-model="search" type="text" name="segmentFrom" id="segmentFrom" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: 9B" />
                      <div class="relative  mt-3">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                          <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-center">
                          <span class="bg-white px-2 text-gray-500">
                            <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 flex flex-col overflow-y-scroll max-h-48 pr-3">
                      <div v-for="(strg, index) in storage" :key="index" :class="[index !== 0 ? 'mt-3' : '', 'flex flex-col gap-2']">
                        <span class="font-semibold text-gray-600 uppercase select-none">{{strg.name}}</span>
                        <template v-for="(seg, indx) in search.length > 0 ? searchSegment : segments.filter(seg => seg.id !== segment.from.id)" :key="indx">
                          <div @click="selectSegment('to', seg, indx)" v-if="(strg.id === seg.warehouse.id) && seg != null" class="flex">
                            <div :class="[indx % 2 ? 'bg-red-500' : 'bg-indigo-500', 'select-none flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">{{ seg.name.length > 2 ? seg.name.slice(0, 3) : seg.name }}</div>
                            <div :class="['transition-all duration-300 flex flex-1 items-center truncate rounded-r-md border-b border-r border-t border-gray-200', seg.selected ? 'bg-gray-100' : 'bg-white']">
                              <div class="flex justify-between w-full truncate px-4 py-3 text-sm items-center">
                                <div class="flex flex-col">
                                  <span class="font-medium text-gray-900 hover:text-gray-600 select-none">{{ seg.name }}</span>
                                  <p class="text-gray-300 select-none">ID: {{ seg.id }}</p>
                                </div>
                                <Transition name="fade2">
                                <div class="flex h-6 items-center" v-if="seg.selected">
                                  <input v-model="seg.selected" id="segment-1" aria-describedby="segment-1" name="segment-1" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                </div>
                                </Transition>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div v-if="strg.segments === 0">
                          <div class="rounded-md bg-yellow-50 border-2 border-yellow-100 py-4 px-3 h-16 flex items-center select-none pointer-events-none">
                            <div class="flex">
                              <div class="flex-shrink-0">
                                <ExclamationIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" />
                              </div>
                              <div class="ml-3 flex items-center">
                                <h3 class="text-xs font-semibold text-yellow-800 uppercase">BRAK UTWORZONYCH SEGMENTÓW W MAGAZYNIE.</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-6 flex gap-6">
                      <button v-if="!automaticallySelected" :disabled="automaticallySelected" type="button" @click="changeStep(2)" :class="[automaticallySelected ? 'opacity-50 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-red-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 select-none']">
                        <ArrowCircleLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Poprzedni krok
                      </button>
                      <button v-if="automaticallySelected" :disabled="!automaticallySelected" type="button" @click="clearData('camera')" :class="['flex justify-center w-full items-center gap-x-2 rounded-md bg-red-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 select-none']">
                        <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Anuluj
                      </button>
                      <button :disabled="this.segment.to == null" type="button" @click="changeStep(4)" :class="[this.segment.to == null ? 'opacity-30 pointer-events-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Przejdź dalej
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>
              
              <!-- 4th step -->
              <Transition name="fade">
                <div class="absolute bottom-0 w-full flex justify-center pb-4 px-4" v-if="modalData.accept && step === 4">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>
                  <div :class="['px-4 pb-4 pt-5 min-w-full sm:min-w-0 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6']">
                    <h2 class="text-sm font-semibold text-gray-500 select-none">Potwierdzenie przenoszenia paczki.</h2>
                    <div class="flex gap-2 justify-center items-center py-8">
                      <span class="text-2xl font-semibold text-gray-600">{{segment.from.name}}</span>
                      <ArrowNarrowRightIcon class="w-8 h-8" />
                      <span class="text-2xl font-semibold text-gray-600">{{segment.to.name}}</span>
                    </div>
                    <div class="flex gap-6">
                      <button type="button" @click="changeStep(3)" :class="['flex justify-center w-full items-center gap-x-2 rounded-md bg-red-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                        <ArrowCircleLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Poprzedni krok
                      </button>
                      <button :disabled="lockButton && this.segment.from == null && this.segment.to == null" type="button" @click="acceptRelocation()" :class="[this.segment.from == null && this.segment.to == null ? 'opacity-30 pointer-events-none' : '', lockButton ? 'opacity-30 pointer-events-none select-none' : '', 'flex justify-center w-full items-center gap-x-2 rounded-md bg-indigo-600 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Akceptuj
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>

            </qrcode-stream>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { db } from "@/firebase/gfbconf.js";
import { QrcodeStream } from 'vue3-qrcode-reader'
import moment from 'moment';
import axios from 'axios';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { XCircleIcon, ArrowNarrowRightIcon, QuestionMarkCircleIcon, HomeIcon, CheckIcon, CameraIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, EyeIcon, EyeOffIcon, QrcodeIcon, ExclamationIcon, XIcon, ExternalLinkIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import { LogoutIcon } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue';

export default {
  components: {
    XCircleIcon, EmptyState, ArrowNarrowRightIcon, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, QuestionMarkCircleIcon, LogoutIcon, ExternalLinkIcon, XIcon, ExclamationIcon, QrcodeIcon, LoadingSpinnerHub, EyeIcon, EyeOffIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, QrcodeStream, HomeIcon, CheckIcon, CameraIcon, ChevronDoubleRightIcon
  },
  data () {
    return {
      lockButton: false,
      focus: false,
      watchers: {
        error: null,
      },
      search: '',
      haveQRCode: false,
      open: true,
      moment: moment,
      error: false,
      errorLog: {
        name: '',
        log: ''
      },
      loading: false,
      modalData: {
        accept: false,
        found: false,
        headerText: '',
        bodyText: '',
        product: null,
        num: null,
      },
      scannerDecode: '',
      fullscreen: null,
      notification: {
        show: false,
        head: "",
        subheader: "",
        success: false,
      },
      resultDecode: null,
      isScanned: false,
      step: 1,
      singleSelect: false,
      selected: { 
        segment: null,
        warehouse: null,
        part: '',
        invoice: '',
        dimensions: {
          unit: 'cm',
          height: 0,
          width: 0,
          length: 0,
        },
        weight: {
          unit: 'kg',
          amount: 0
        },
        num: '',
        quantity: 1
      },
      storage: [],
      segments: [],
      segmentsToRelocate: [],
      segment: {
        from: null,
        to: null,
        product: null
      },
      segmentLoading: false,
      isSelectedWarehouse: true,
      isSelectedSegment: true,
      acceptedPackage: false,
      scanned: {
        warehouse: null,
        segment: null,
        package: '',
      },
      isUnique: false,
      automaticallySelected: false
    }
  },
  methods: {
    selectSegment(location, seg, indx)
    {
      if(location === 'from')
      {
        let filteredSegments = this.segment.to != null ? this.segmentsToRelocate.filter(seg => seg.id !== this.segment.to.id) : this.segmentsToRelocate;
        if(filteredSegments[indx].selected)
        {
          filteredSegments[indx].selected = false
          this.segment.from = null;
        }
        else
        {
          for (let i = 0; i < filteredSegments.length; i++) {
            filteredSegments[i].selected = false
          }
          filteredSegments[indx].selected = true
          this.segment.from = filteredSegments[indx];
        }
      }
      else if(location === 'to')
      {
        let filteredSegments = this.search.length > 0 ? this.searchSegment : this.segment.from != null ? this.segments.filter(seg => seg.id !== this.segment.from.id) : this.segments;
        if(filteredSegments[indx].selected)
        {
          filteredSegments[indx].selected = false
          this.segment.to = null;
        }
        else
        {
          for (let i = 0; i < filteredSegments.length; i++) {
            filteredSegments[i].selected = false
          }
          filteredSegments[indx].selected = true
          this.segment.to = filteredSegments[indx];
        }
      }
    },
    async changeStep(val)
    {
      switch(val)
      {
        case 1:
          this.step = 1;
          break;
        case 2:
          this.step = 2;
          if(this.segmentsToRelocate.length === 1)
          {
            this.automaticallySelected = true;
            this.segment.from = this.segmentsToRelocate[0];
            this.changeStep(3)
          }
          break;
        case 3:
          if(this.segment.from == null)
          {
            this.changeStep(2)
            return;
          }
          this.step = 3;
          break;
        case 4:
          this.step = 4;
          break;
      }
    },
    clearData(type)
    {
      if(type === 'scannerClear')
      {
        if(this.$refs.scanner != null)
        {
          this.scannerDecode = '';
          this.$refs.scanner.readOnly = false;
          if(this.$refs.scanner != null)
          {
            this.$refs.scanner.focus();
          }
        };
        this.modalData = {
          accept: false,
          found: false,
          headerText: '',
          bodyText: '',
          product: null,
          num: null,
        };
      }
      if(type === 'scanner')
      {
        if(this.$refs.scanner != null)
        {
          // this.scannerDecode = '';
          this.$refs.scanner.readOnly = false;
          if(this.$refs.scanner != null)
          {
            this.$refs.scanner.focus();
          }
        };
        this.modalData = {
          accept: false,
          found: false,
          headerText: '',
          bodyText: '',
          product: null,
          num: null,
        };
      }
      if(type === 'camera')
      {
        this.scannerDecode = '';
        this.error = false;
        this.notification = {
          show: false,
          head: "",
          subheader: "",
          success: false,
        };
        this.modalData = {
          accept: false,
          found: false,
          headerText: '',
          bodyText: '',
          product: null,
          num: null,
        };
      }
      this.isUnique = false;
      this.step = 1;
      this.segments = [];
      this.storage = [];
      this.segmentsToRelocate = [];
      this.segment = {
        from: null,
        to: null,
        product: null
      };
      this.lockButton = false;
      this.search = '';
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    //camera decode
    async onDecode(val)
    {
      try {
        let decodedResult = await val;
        if(decodedResult.content.length === 0) throw 'ERROR: The length of the result is equal to 0;';
        
        this.loading = true;
        this.clearData('camera');
        
        let str = decodedResult.content;
        let result = null;
        let numOfPackage  = null;
      
        let n = str.lastIndexOf('-');
        if(n !== -1)
        {
          this.isUnique = true;
          result = str.slice(0, n);
          let p = result.lastIndexOf('_');
          if(p === -1) throw 'ERROR;'
          numOfPackage = result.substring(p+1);
        }
        else
        {
          this.isUnique = false;
          let p = str.lastIndexOf('_');
          if(p === -1) throw 'ERROR;'
          numOfPackage = str.substring(p+1);
        };
        
        const res = await axios.post(`${this.$store.state.apiLink}/scan/checkIfCorrect`, {
          id: str,
          type: 'relocation'
        });
        if(!res.data.success)
        {
          this.loading = false;
          this.modalData = {
            accept: true,
            found: false,
            bodyText: 'WYSTĄPIŁ PROBLEM!',
            headerText: res.data.log,
            image: QuestionMarkCircleIcon,
            product: null,
            num: null
          };
          // throw 'ERROR: No data found.';
        }
        else
        {
          this.loading = false;
          this.segment.product = {
            id: res.data.product,
            variantId: res.data.variant.id,
            num: parseInt(res.data.variant.num),
            label: str,
          };
          this.modalData = {
            accept: true,
            found: true,
            bodyText: res.data.variant.name,
            headerText: `${res.data.variant.num + '/' + res.data.variant.amountOfPackages}`,
            image: res.data.variant.image,
            product: res.data.product,
            num: parseInt(res.data.variant.num),
          };
          if(res.data.storage.length > 0)
          {
            this.storage = res.data.storage;
          }
          if(res.data.segmentsToRelocate.length > 0)
          {
            this.segmentsToRelocate = res.data.segmentsToRelocate;
          }
          if(res.data.segments.length > 0)
          {
            this.segments = res.data.segments;
          }
        };
      }
      catch (error) {
        console.log(error);  
      }
    },
    //scanner decode
    async onDecodeScanner(val)
    {
      try {
        this.loading = true;
        this.clearData('scanner');
        
        let str = val;
        let result = null;
        let numOfPackage  = null;
      
        let n = str.lastIndexOf('-');
        if(n !== -1)
        {
          this.isUnique = true;
          result = str.slice(0, n);
          let p = result.lastIndexOf('_');
          if(p === -1) throw 'ERROR;'
          numOfPackage = result.substring(p+1);
        }
        else
        {
          this.isUnique = false;
          let p = str.lastIndexOf('_');
          if(p === -1) throw 'ERROR;'
          numOfPackage = str.substring(p+1);
        };
        
        const res = await axios.post(`${this.$store.state.apiLink}/scan/checkIfCorrect`, {
          id: val,
          type: 'relocation'
        });
        if(!res.data.success)
        {
          this.loading = false;
          this.modalData = {
            accept: true,
            found: false,
            bodyText: 'WYSTĄPIŁ PROBLEM!',
            headerText: res.data.log,
            image: QuestionMarkCircleIcon,
            product: null,
            num: null
          };
          // throw 'ERROR: No data found.';
        }
        else
        {
          this.loading = false;
          this.segment.product = {
            id: res.data.product,
            variantId: res.data.variant.id,
            num: parseInt(res.data.variant.num),
            label: val,
          };
          this.modalData = {
            accept: true,
            found: true,
            bodyText: res.data.variant.name,
            headerText: `${res.data.variant.num + '/' + res.data.variant.amountOfPackages}`,
            image: res.data.variant.image,
            product: res.data.product,
            num: parseInt(res.data.variant.num),
          };
          if(res.data.storage.length > 0)
          {
            this.storage = res.data.storage;
          }
          if(res.data.segmentsToRelocate.length > 0)
          {
            this.segmentsToRelocate = res.data.segmentsToRelocate;
          }
          if(res.data.segments.length > 0)
          {
            this.segments = res.data.segments;
          }
        };
      }
      catch (error) {
        console.log(error);
        this.clearData('scanner');
        this.$store.commit('setNotification', {
          show: true,
          head: "Akcja zakończona niepowodzeniem!",
          subheader: 'Nie znaleziono paczki o podanym numerze.',
          success: false
        });
      }
    },
    async acceptRelocation()
    {
      try {
        this.lockButton = true;
        const res = await axios.post(`${this.$store.state.apiLink}/scan/relocate`, {
          from: this.segment.from,
          to: this.segment.to,
          product: this.segment.product
        });
        if(!res.data.success)
        {
          throw res.data.log;
        }
        else
        {
          if(!this.fullscreen)
          {
            this.clearData('scanner');
            this.$store.commit('setNotification', {
              show: true,
              head: 'Akcja zakończona sukcesem!',
              subheader: res.data.log,
              success: true
            }); 
          }
          else
          {
            this.clearData('scanner');
            this.notification = {
              show: true,
              head: 'Akcja zakończona sukcesem!',
              subheader: res.data.log,
              success: true
            }
          }
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Fsuccess.mp3?alt=media&token=d15ba5f1-9521-4d55-b905-3c38499c3b27');
          audio.play();
        }
        if(this.fullscreen)
        {
          setTimeout(() => {
            this.clearData('camera')
          }, 3500);
        }
        else
        {
          this.clearData('scanner')
          this.$refs.scanner.focus();
        }
      } catch (error)
      {
        console.log(error);
        if(!this.fullscreen)
        {
          this.$store.commit('setNotification', {
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error,
            success: false
          }); 
          this.clearData('scanner')
          this.$refs.scanner.focus();
        }
        else
        {
          this.modalData.accept = false;
          this.notification = {
            show: true,
            head: 'Akcja zakończona niepowodzeniem!',
            subheader: error,
            success: false
          }
          setTimeout(() => {
            this.clearData('camera')
          }, 3500);
        }
        let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Ferror.mp3?alt=media&token=a1fabac7-7082-412f-9b64-25d54c0efe04');
        audio.play();
      }
    },
    onFullscreenChange(event) {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement != null;
    },
    exitFullscreen() {
      if (!document.fullscreenElement) {
        return;
      }

      if (!document.hidden) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    requestFullscreen() {
      const elem = this.$refs.wrapper
      this.watchers.error = this.$watch('error', (newVal, oldVal) => {
        if(newVal) {
          this.error = true;
          this.fullscreen = false;
        }
      });
      while(elem != null)
      {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
        elem = elem.parentElement;
      };
    },
    async onInit(promise)
    {
      try {
        let res = await promise
      } catch(err)
      {
        this.error = true;
        if(err.name === 'NotAllowedError')
        {
          this.errorLog = {
            name: 'NotAllowedError',
            log: "Musisz przyznać uprawnienia dostępu do kamery.",
          }
        }
        else if (err.name === 'NotFoundError')
        {
          this.errorLog = {
            name: 'NotFoundError',
            log: "Brak kamery w tym urządzeniu."
          }
        }
        else if (err.name === 'NotSupportedError')
        {
          this.errorLog = {
            name: 'NotSupportedError',
            log: "Wymagany bezpieczny kontekst (HTTPS, localhost)."
          }
        }
        else if (err.name === 'NotReadableError')
        {
          this.errorLog = {
            name: 'NotReadableError',
            log: "Kamera jest zajęta."
          }
        }
        else if (err.name === 'OverconstrainedError')
        {
          this.errorLog = {
            name: 'OverconstrainedError',
            log: "Brak kamery spełniającej wymagania."
          }
        }
        else if (err.name === 'StreamApiNotSupportedError')
        {
          this.errorLog = {
            name: 'StreamApiNotSupportedError',
            log: "Stream API nie jest obsługiwany w tej przeglądarce."
          }
        }
        else if (err.name === 'InsecureContextError')
        {
          this.errorLog = {
            name: 'InsecureContextError',
            log: "Dostęp do kamery jest dozwolony tylko w bezpiecznym kontekście. Należy używać protokołu HTTPS."
          }
        }
        else
        {
          this.errorLog = {
            name: err.name,
            log: err.name,
          }
        }
      }
    },
  },
  watch: {
    focus(isFocused)
    {
      if(!this.modalData.accept)
      {
        if(!isFocused)
        {
          if(this.$refs.scanner != null)
          {
            this.$refs.scanner.focus();
          }
        }
      }
    },
    scannerDecode(newVal)
    {
      if(newVal)
      {
        let input = this.$refs.scanner;
        if(newVal.length === 0)
        {
          input.readOnly = false;
        }
        else if(newVal.length > 1)
        {
          input.readOnly = true;
          this.onDecodeScanner(newVal);
        }
      }
    },
    fullscreen(enterFullscreen) {
      if(enterFullscreen)
      {
        this.requestFullscreen()
      }
      else
      {
        this.exitFullscreen();
        this.$nextTick(() => {
          if(this.$refs.scanner != null)
          {
            this.scannerDecode = '';
            this.$refs.scanner.focus();
          }
        })
      }
    },
  },
  computed: {
    searchSegment() {
      if(this.segment.to != null)
      {
        for (let i = 0; i < this.segments.length; i++) {
          if (this.segments[i].id !== this.segment.to.id) {
            this.segments[i].selected = false;
          }
        };
      };
      return this.segments.filter(seg => 
        seg.name.toLowerCase().includes(this.search.toLowerCase()) &&
        seg.id !== this.segment.from.id
      );
    },
  },
  created()
  {
    moment.locale('pl')
  }
}
</script>
<style scoped>
.qr-container {
  background:
    linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

.fullscreen {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fullscreen-button{
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  margin: 1rem;
}

.fullscreen-notification {
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  margin: 1rem;
}

.fullscreen-button-check {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  margin: 1rem;
}

.fullscreen-button img {
  width: 2rem;
}
.fullscreen-z {
  z-index: 1000;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


</style>