<template>
    <router-link @touchstart.prevent to="/dashboard/drivers/routes" class="no-select block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>
    <div @touchstart.prevent v-if="!isScreenHorizontal" class="no-select absolute z-50 top-0 bottom-0 right-0 left-0 bg-gray-800">
        <p @touchstart.prevent class="text-white text-center text-2xl pt-4 no-select">Obróć telefon aby zebrać podpis</p>
    </div>
    <div @touchstart.prevent tabindex="-1" :class="{'hidden': !isScreenHorizontal}" class="flex flex-col justify-center items-center no-select ">
        <canvas @touchstart.prevent tabindex="-1" class="absolute z-20 right-0 bottom-0 top-0 left-0 bg-white border-2 no-select " ref="signature"></canvas>
        <div @touchstart.prevent :class="isScreenHorizontal ? 'top-0' : 'transform rotate-90 bottom-1/2 left-40' " class=" absolute z-30 flex w-full justify-between p-4 no-select">
            <div @touchstart.prevent class="flex gap-x-4 no-select">
                <button @click="clearSignature" @touchstart.prevent="clearSignature" tabindex="-1" type="button" class="no-select rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Wyczyść
                </button>
                <button @click="returnToRoutes" @touchstart.prevent="returnToRoutes" tabindex="-1" class="no-select font-semibold text-blue-700">Cofnij</button>
            </div>
            <button tabindex="-1" @click="saveSignature" @touchstart.prevent="saveSignature" type="button" class="px-3 py-1 no-select bg-indigo-600 hover:bg-indigo-500 shadow-sm rounded-full text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <span class="no-select " @touchstart.prevent v-if="!loading">Zapisz podpis</span> 
                <div v-if="loading" @touchstart.prevent class="lds-ring no-select"><div></div><div></div><div></div><div></div></div>
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { HomeIcon } from '@heroicons/vue/solid'
import { ArrowsExpandIcon } from '@heroicons/vue/outline'
import SignaturePad from 'signature_pad'
import {db} from "@/firebase/gfbconf.js";
let signaturePad

export default {
    components: {
        HomeIcon, ArrowsExpandIcon
    },
    data() {
        return {
            order: null,
            loading: false,
            signatureData: null,
            signaturePad: null,
            defaultWidth: null,
            defaultHeight: null,
            isScreenHorizontal: false,
            size: [window.width,window.height]
        }
    },
    methods: {
        returnToRoutes() {
            this.$store.state.route = this.$route.params.id
            this.$router.push("/dashboard/drivers/routes")
        },
        expandPad() {
            if(window.innerHeight > window.innerWidth) {
                this.isScreenHorizontal = false
            } else {
                this.isScreenHorizontal = true
            }
            this.signatureData = signaturePad.toData()
            this.defaultWidth = this.$refs.signature.width
            this.defaultHeight = this.$refs.signature.height
            const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            this.$refs.signature.width = width
            this.$refs.signature.height = height
            signaturePad.fromData(this.signatureData, {clear: true})
        },
        clearSignature() {
            signaturePad.clear()
        },
        async saveSignature() {
            this.loading = true
            try {
                if(signaturePad.isEmpty()){
                    this.loading = false
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Nie udało się zapisać podpisu.",
                        subheader: `Podpis nie może być pusty.`,
                        success: false
                    }); 
                    return
                }
                const data = signaturePad.toDataURL()

                if(this.$store.state.isDriverOnline)
                {
                    const signature = {
                        id: null,
                        orderId: this.order.id,
                        img: {
                            base64: data
                        }
                    }
    
                    let date = null;
                    date = {
                        asText: moment().format("YYYY-MM-DD"),
                        unix: moment().unix(),
                        dateObj: moment().toDate(),
                        dayOfWeek: moment().day(),
                        dayNum: moment().date(),
                        monthNum: moment().month() + 1,
                        year: moment().year(),
                        weekNum: moment().week() 
                    }
    
                    const signatureDoc = db.collection("Signatures").doc()
                    signature.id = signatureDoc.id
                    await signatureDoc.set(signature)
    
                    const orderDoc = db.collection("Orders").doc(this.order.id)
                    await orderDoc.update({
                        "statusId": 6,
                        "delivery.date.confirmed": date
                    })
    
                    await this.$store.dispatch('updateHistory', {
                        collectionName: "Orders",
                        docId: this.order.id,
                        message: "Zamówienie zostało dostarczone.",
                        details: []
                    })
                }
                else
                {
                    // when offline
                    const firebasePayloadSignature = {
                        id: null,
                        orderId: this.order.id,
                        img: {
                            base64: data
                        }
                    }
                    this.$store.commit("addToOfflineCallsQueue",
                    {
                        done: false,
                        callClient: "firebase",
                        call: {
                        firebase: {
                            ref: {
                                collection: "Signatures",
                                doc: '' 
                            },
                            action: "set", // set or update
                            payload: {...firebasePayloadSignature} // body
                        },
                        axios: null,
                        },
                        routeId: null,  // id lub null
                        orderId: this.order.id, // id lub null
                        originalFunction: {
                            name: "saveSignature", // nazwa oryginalnej funkcji
                            payload: this.order // oryginalne argumenty wykonywanej funkcji 
                        }
                    })

                    let date = null;
                    date = {
                        asText: moment().format("YYYY-MM-DD"),
                        unix: moment().unix(),
                        dateObj: moment().toDate(),
                        dayOfWeek: moment().day(),
                        dayNum: moment().date(),
                        monthNum: moment().month() + 1,
                        year: moment().year(),
                        weekNum: moment().week() 
                    }

                    const firebasePayloadOrder = {"statusId": 6, "delivery.date.confirmed": date}

                    this.$store.commit("addToOfflineCallsQueue",
                    {
                        done: false,
                        callClient: "firebase",
                        call: {
                            firebase: {
                            ref: {
                                collection: "Orders",
                                doc: this.order.id 
                            },
                            action: "update", // set or update
                            payload: {...firebasePayloadOrder} // body
                            },
                            axios: null,
                        },
                        routeId: null,  // id lub null
                        orderId: this.order.id, // id lub null
                        originalFunction: {
                            name: "saveSignature", // nazwa oryginalnej funkcji
                            payload: this.order // oryginalne argumenty wykonywanej funkcji 
                        }
                    })


                    let historyPayload = {
                        message: "Zamówienie zostało dostarczone.",
                        details: [],
                        routeId: '',
                        meta: {
                            createdDate: moment().toDate(),
                            lastModificationDate: moment().toDate(),
                            createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            userId: this.$store.state.userData.id,
                        }
                    }

                    this.$store.commit("addToOfflineCallsQueue", {
                        done: false,
                        callClient: "firebase",
                        call: {
                            firebase: {
                                ref: {
                                    collection: "Orders",
                                    doc: this.order.id,
                                },
                                action: "set",
                                payload: historyPayload
                            },
                            axios: null,
                        },
                        routeId: null,
                        orderId: this.order.id,
                        originalFunction: {
                            name: "updateHistory",
                            payload: historyPayload
                        }
                    })
                    this.$store.state.callForSignature.taken = true;
                    // order.statusId = 300;
                }
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Zapisano podpis.",
                    subheader: ``,
                    success: true
                }); 
                this.$store.state.route = this.$route.params.id
                this.$router.push("/dashboard/drivers/routes")
            } catch (error) {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się zapisać podpisu.",
                    subheader: ``,
                    success: false
                }); 
            }
            this.loading = false
        },
    },
    async created() {
        this.moment = moment;
        moment.locale('pl')
        if(!this.$store.state.isDriverOnline)
        {
            for(let i=0; i<this.$store.state.offlineModeState.dataSnapshot.orders.length; i++)
            {
                let order = this.$store.state.offlineModeState.dataSnapshot.orders[i];
                if(this.$store.state.callForSignature.order.id === order.id)
                {
                    this.order = order;
                    break;
                }
            };
        }
        else
        {
            this.order = db.collection("Orders").doc(this.$route.params.id)
        }
    },
    mounted() {
        document.querySelector("footer").classList.add("hidden")
        signaturePad = new SignaturePad(this.$refs.signature, {
            minWidth: 1,
            maxWidth: 2,
            penColor: "rgb(64, 64, 64)"
        })
        this.signaturePad = signaturePad

        window.addEventListener('resize', (event) => {
            this.expandPad()
        }, true);
        window.addEventListener('orientationchange', (event) => {
            this.expandPad()
        }, true);

        this.expandPad()
     },
     beforeUnmount() {
        document.querySelector("footer").classList.remove("hidden")
     }
}
</script>
<style scoped>
.no-select{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari, Chrome, Opera, Samsung */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge, IE */
  user-select: none; /* Modern browsers */
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 0px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>