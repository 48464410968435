<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
            <div class="flex sm:items-start justify-between">
              <div class="text-center sm:text-left select-none">
                <span class="text-base font-semibold leading-6 text-gray-900">Import pliku CSV</span>
                <div class="mt-1">
                  <p class="text-sm text-gray-500">Wybierz rodzaj importu, który chcesz aktualnie użyć.</p>
                </div>
              </div>
              <div>
                <XIcon @click="close()" class="w-7 h-7 hover:opacity-50 cursor-pointer" />
              </div>
            </div>
            <div class="py-8">
              <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <li @click="emitAccept('add')" class="group shadow-lg col-span-1 flex flex-col border divide-y divide-gray-200 rounded-lg hover:bg-gray-100 bg-white text-center cursor-pointer transition-all duration-300">
                  <div class="flex flex-1 flex-col p-8 select-none">
                    <ViewGridAddIcon class="mx-auto h-14 w-14 flex-shrink-0 group-hover:text-gray-400 text-indigo-500 transition-all duration-300" />
                    <h3 class="mt-6 text-sm font-medium text-gray-900">Dodaj produkty</h3>
                    <dl class="mt-1 flex flex-grow flex-col justify-between ">
                      <dt class="sr-only">Title</dt>
                      <dd class="text-sm text-gray-500">Wprowadzanie nowych produktów</dd>
                      <dt class="sr-only">Role</dt>
                    </dl>
                  </div>
                </li>
                <li @click="emitAccept('update')" class="group shadow-lg col-span-1 flex flex-col border divide-y divide-gray-200 rounded-lg bg-white text-center hover:bg-gray-100 cursor-pointer transition-all duration-300">
                  <div class="flex flex-1 flex-col p-8 select-none">
                    <ViewGridIcon class="mx-auto h-14 w-14 flex-shrink-0 group-hover:text-gray-400 text-indigo-500 transition-all duration-300" />
                    <h3 class="mt-6 text-sm font-medium text-gray-900">Uaktualnij wymiary</h3>
                    <dl class="mt-1 flex flex-grow flex-col justify-between">
                      <dt class="sr-only">Title</dt>
                      <dd class="text-sm text-gray-500">Aktualizowanie aktualnych produktów</dd>
                      <dt class="sr-only">Role</dt>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
            <p class="uppercase text-xs text-gray-500 text-center select-none">Wybierz kafelek, aby kontynuować</p>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon, ViewGridAddIcon, ViewGridIcon, XIcon } from '@heroicons/vue/outline'
export default {
  name: 'SelectCSVImportType',
  components: {
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, ExclamationIcon, ViewGridAddIcon, ViewGridIcon, XIcon
  },
  props: {
    closeModalAfterAccept: {
      type: Boolean,
      default: true
    } 
  },
  data() {
    return {
      open: true,
    }
  },
  methods: {
    emitClose()
    {
      this.$emit("closeModal");
    },
    emitAccept(type)
    {
      this.$emit("accept", type);
      if(this.closeModalAfterAccept) {
        this.$emit("closeModal");
      }
    },
    close()
    {
      this.emitClose();
      this.open = false
    }
  },
}
</script>
