<template>
    <div class="pr-3 sm:pr-0" id="topOfDriverApp">


        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb" v-if="$store.isDriverOnline === true" v-auto-animate>
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/drivers" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł kierowcy</router-link>
                </div>
            </li>

            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div @click="DriverModal.open = false" :class="[DriverModal.open ? 'text-gray-500 hover:text-gray-700 cursor-pointer' : 'text-gray-400']" class="ml-4 text-xs font-medium cursor-default" aria-current="page">Lista tras</div>
                </div>
            </li>
            <li v-if="DriverModal.open" class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">{{DriverModal.route.title.length === 0 ? "---" : DriverModal.route.title}}</div>
                </div>
            </li>
            </ol>
        </nav>

        <router-link v-if="!DriverModal.open" to="/dashboard/drivers" :class="{'block w-1/6 md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium select-none': true, 'text-gray-400 pointer-events-none': offlineMode.active, 'text-blue-500 hover:text-blue-700': !offlineMode.active}" aria-current="page">
            &larr; Cofnij
        </router-link>
        <div v-if="DriverModal.open" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium select-none" aria-current="page">
            <span @click="DriverModal.open = false" :class="{'text-blue-500 hover:text-blue-700 cursor-pointer': !offlineMode.active, 'text-gray-400 pointer-events-none': offlineMode.active}">&larr; Cofnij</span>
        </div>
    </div>

    <div v-if="!connectionChecked" class="opacity-70 select-none flex flex-col items-center" v-auto-animate>
        <div class="text-center">
            <div class="flex items-center justify-center">
                <div class="loader mt-4"></div>      
                <!-- <WifiIcon class="h-10 w-10 text-gray-400"></WifiIcon> -->
            </div>
            <h3 class="mt-2 text-xs font-semibold text-gray-600">Sprawdzamy twoje połączenie internetowe..</h3>
            <p class="mt-1 text-xs text-gray-500">Zajmie to do 10 sekund..</p>
        </div> 
        
    </div>


    <div class="fixed inset-x-0 bottom-0 z-20 select-none">
        <div class="flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:px-3.5 sm:before:flex-1" v-auto-animate>
            <div class="w-1/6 flex items-center justify-center" v-if="$store.state.sendingCallsFromQueue === true">
                <span class="loaderLine"></span>
            </div>
            <div v-if="connectionChecked" class="w-full">
                <div class="flex justify-between items-between">
                    <p class="text-xs leading-6 block text-white">
                        <strong class="font-semibold">Połączenie <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg></strong> <span :class="{'text-red-500': offlineMode.active === true, 'text-green-500': offlineMode.active === false}">{{offlineMode.active === false ? "ONLINE" : "OFFLINE"}} </span> 
                    </p>
                    <p class="text-xs leading-6 block text-gray-500" v-if="offlineMode.active">
                        Nie wyłączaj tej karty..
                    </p>
                </div>
            </div>
            <div v-if="!connectionChecked">
                <p class="text-xs leading-6 text-gray-500"><span class="mr-2">Sprawdzam połączenie..</span> <span class="awaitingConnectionSpinner text-red-500"></span> </p>
            </div>
        </div>
    </div>

    <div v-auto-animate class="h-full">
        <!-- OFFLINE INFO -->
        <div v-if="offlineMode.active" id="offlineModeInfo" class="flex flex-col select-none mb-4 justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:pr-3.5 lg:pl-8 rounded-lg">
            <p class="text-xs block leading-6 text-white">
                <strong class="font-semibold">Połączenie</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Twoja aplikacja działa teraz w trybie <span class="text-red-500">OFFLINE</span>.
            </p>
            <p class="text-xs block leading-6 text-white">Wszystkie akcje zostaną zapisane dopiero po odzyskaniu połączenia.</p>
            <p class="text-xs block leading-6 text-gray-500 mt-2">Nie zamykaj tej karty, aby uniknąć problemów.</p>
        </div>

        <div v-if="!DriverModal.open">
            <!-- <div v-if="routesWithOrders.length > 0 && isLoaded" class="flex justify-center sm:justify-center lg:justify-end pt-2 pb-6 sm:pt-2 sm:pb-6">
                <button @click="cameraVisible = !cameraVisible" type="button" class="transition-all duration-300 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 select-none">{{!cameraVisible ? 'Zeskanuj z listy' : 'Wyłącz skaner'}}
                    <QrcodeIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </button>
            </div> -->
            <div v-if="routesWithOrders.length > 0 && isLoaded" v-auto-animate class="flex justify-end pt-2 pb-6 sm:pt-2 sm:pb-6">
                <div v-if="!cameraVisible" class="flex flex-col items-center justify-center select-none hover:opacity-80 cursor-pointer" @click="cameraVisible = true">
                    <div type="button" class="rounded-full bg-gradient-to-r from-cyan-500 to-blue-600 p-3 text-white shadow-md  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <QrcodeIcon class="h-6 w-6" aria-hidden="true" /> 
                    </div>
                    <div class="text-xs text-gray-500 font-medium mt-1 whitespace-nowrap">Do zamówienia</div>
                </div>
                <div v-if="cameraVisible"   class="flex flex-col items-center justify-center select-none hover:opacity-80 cursor-pointer" @click="cameraVisible = false">
                    <div type="button" class="rounded-full bg-gradient-to-r to-cyan-500 from-blue-600 p-3 text-white shadow-md  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <XIcon class="h-6 w-6" aria-hidden="true" /> 
                    </div>
                    <div class="text-xs text-gray-500 font-medium mt-1 whitespace-nowrap">Zamknij</div>
                </div>

            </div>
            <div v-if="cameraVisible" v-auto-animate id="qr-container" class="flex justify-center max-w-md items-center p-1.5 m-auto mb-10 h-96">
                <qrcode-stream :class="[cameraLoaded && cameraVisible ? 'shadow-lg' : 'hidden']" @decode="onDecode" @init="onInit" />
                <div v-if="!cameraLoaded && cameraVisible" class="spinner"></div>
            </div>
            <p class="error">{{ error }}</p>

            <LoadingSpinnerHub v-if="!isLoaded && connectionChecked" />

            <ul v-if="isLoaded && !cameraVisible" role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 select-none">
                <li v-for="(route, index) in routesWithOrders" :key="route.id" class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg">
                <div class="flex flex-1 flex-col py-8 px-6">
                    <div class="flex items-center justify-between">
                        <h3 v-tooltip="`Możliwość rozpoczęcia trasy w dniu`" class="truncate text-sm font-semibold text-gray-600">{{moment.unix(route.date.start._seconds).format('LL')}}</h3>
                        <span v-tooltip="`Status: ${findStatus(route.statusId)?.name} - Informacje: ${findStatus(route.statusId)?.info}`" :class="['border inline-flex flex-shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-1 text-xs font-medium text-gray-700', route.statusId === 1 ? 'bg-yellow-100 text-yellow-800 border-yellow-500 border-opacity-20' : '', route.statusId === 2 ? 'bg-indigo-100 text-indigo-800 border-indigo-500 border-opacity-20' : '', route.statusId === 3 ? 'bg-green-100 text-green-800 border-green-500 border-opacity-20' : '']">
                        {{ findStatus(route.statusId)?.name }}
                        </span>
                    </div>
                    <div class="relative py-4">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                    </div>
                    <div class="mt-1 flex flex-grow flex-col">
                        <span class="sr-only">Truck</span>
                        <div class="flex flex-row justify-between">
                            <label class="text-xs text-gray-500 font-semibold uppercase">Nazwa trasy:</label>
                            <span class="text-xs text-gray-500">{{route.title !== undefined && route.title !== null && route.title.length > 0 ? route.title.length > 25 ? route.title.slice(0, 25) + '...' : route.title : '---'}}</span>
                        </div>
                        <div class="flex flex-row justify-between mt-2.5">
                            <label class="text-xs text-gray-500 font-semibold uppercase">Pojazd:</label>
                            <span class="text-xs text-gray-500" v-tooltip="`Tablica rejestracyjna: ${route.vehicle.attribs.licensePlate.value} - Przestrzeń transportowa: ${route.vehicle.attribs.payloadCapacity.value} m3`">{{route.vehicle.brand + ' ' + route.vehicle.model + ', ' + route.vehicle.attribs.licensePlate.value}}</span>
                        </div>
                        <span class="sr-only">Date</span>
                        <div class="flex flex-row justify-between mt-2.5">
                            <label class="text-xs text-gray-500 font-semibold uppercase">Data:</label>
                            <span class="text-xs text-gray-500">{{ moment.unix(route.date.start.seconds || route.date.start._seconds).format("ll") }} - {{ moment.unix(route.date.end.seconds || route.date.end._seconds).format("ll") }}</span>
                        </div>
                        <div class="flex flex-row justify-between mt-2.5">
                            <label class="text-xs text-gray-500 font-semibold uppercase">Ilość punktów trasy:</label>
                            <span class="text-xs text-gray-500 font-semibold">{{ route.orders.length }}</span>
                        </div>
                        <div class="flex flex-row justify-between mt-6 shadow rounded-md border border-gray-200">
                            <div class="ring-inset focus-within:ring-2 focus-within:ring-indigo-600">
                                <label for="comment" class="sr-only">Opis trasy</label>
                                <textarea disabled readonly name="routeDesc" id="routeDesc" v-model="route.description" rows="3" :class="[$route.path === '/dashboard/drivers/routes' ? '' : '', 'block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 placeholder-opacity-20 select-none']" placeholder="Brak opisu trasy..." />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="select-none">
                    <div class="flex divide-x divide-gray-200">
                    <div class="flex w-0 flex-1">
                        <button v-if="route.isInside && route.statusId === 1 && route.ableToStart && route.loadedOrders === route.totalOrders" @click="startRoute(routesWithOrders[index])" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-3 text-xs font-bold text-blue-500 uppercase hover:bg-gray-200 transition-all duration-300">
                            <TruckIcon class="h-6 w-6 text-blue-500" aria-hidden="true" />
                            Rozpocznij
                        </button>
                        <button v-if="(route.statusId === 2 && $store.state.isDriverOnline)" @click.prevent="DriverModal.orders = routesWithOrders[index].orders, DriverModal.route = routesWithOrders[index], DriverModal.open = true" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-3 text-xs font-bold text-blue-500 uppercase hover:bg-gray-200 transition-all duration-300">
                            <TruckIcon class="h-6 w-6 text-blue-500" aria-hidden="true" />
                            Zarządzaj
                        </button>
                        <button v-if="(route.statusId === 2 && !$store.state.isDriverOnline) && ($store.state.offlineModeState.lastSaveUnix !== null) && ($store.state.offlineModeState.dataSnapshot.route.id === route.id)" @click.prevent="DriverModal.orders = routesWithOrders[index].orders, DriverModal.route = routesWithOrders[index], DriverModal.open = true" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-3 text-xs font-bold text-blue-500 uppercase hover:bg-gray-200 transition-all duration-300">
                            <TruckIcon class="h-6 w-6 text-blue-500" aria-hidden="true" />
                            Zarządzaj offline
                        </button>
                        <!-- <button v-if="!$store.state.isDriverOnline && $store.state.offlineModeState.route !== null && route.statusId === 2 && ($store.state.offlineModeState.dataSnapshot.route.id !== route.id)" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-3 text-xs font-bold text-gray-600 uppercase transition-all duration-300 cursor-not-allowed">
                            <TruckIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                            Niedostępne w trybie offline
                        </button> -->
                        <div v-if="route.statusId === 1 && ((!route.ableToStart && route.isInside) || (route.ableToStart && !route.isInside) || (!route.ableToStart && !route.isInside))" class="flex-col w-full items-center justify-center border-t border-transparent">
                            <div class="py-2.5 text-xs text-gray-600 font-semibold flex justify-center">
                                <span class="uppercase mr-1">Załadowano:</span>
                                <div>
                                    <span :class="[route.loadedOrders === 0 ? 'text-red-600' : 'text-gray-600', route.loadedOrders > 0 ? 'text-yellow-600' : 'text-gray-600', route.loadedOrders === route.totalOrders ? 'text-green-600' : 'text-gray-600']">{{route.loadedOrders}}</span> z <span>{{route.totalOrders}}</span>
                                </div>
                            </div>
                            <div class="w-full h-3 bg-gray-200 ">
                                <div :class="[route.progress === 100 ? 'rounded-br-lg' : '', 'h-3 bg-green-500 rounded-bl-lg']" :style="`width: ${route.progress}%`"></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </li>
            </ul>
            <div class="flex items-center justify-center mt-4" v-if="queryLimitReached">
                <button type="button" @click.prevent="loadMoreProducts" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
            </div>   
        </div>

        <EmptyState v-if="routesWithOrders.length === 0 && isLoaded" class="mt-6 h-96 flex flex-col justify-center"/>
        <DriverModal v-if="DriverModal.open" :scannedOrder="DriverModal.scannedOrder" :route="DriverModal.route" :ordersProp="DriverModal.orders" @getRoutes="getOrders" @closeModal="DriverModal.open = false"/>
    </div>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import noInternet from 'no-internet';
import { QrcodeStream } from 'vue3-qrcode-reader';
import { HomeIcon } from '@heroicons/vue/solid';
import { WifiIcon,TruckIcon, SearchIcon, PlusSmIcon, XIcon, FilterIcon, ArchiveIcon, EyeIcon, EyeOffIcon, ChevronDownIcon, ChevronUpIcon, QrcodeIcon, InformationCircleIcon } from '@heroicons/vue/outline';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import axios from 'axios';
import moment from 'moment';
import ManageRoutesModal from '../components/ManageRoutesModal.vue';
import OrdersModal from '../components/OrdersModal.vue';
import TableRoutesList from '../components/TableRoutesList.vue';
import DriverModal from '../components/DriverRoutesListModal.vue';
import EmptyState from '../components/EmptyState.vue';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';

export default {
    components: {
        TruckIcon, WifiIcon, LoadingSpinnerHub, EmptyState, InformationCircleIcon, QrcodeIcon, ManageRoutesModal, TableRoutesList, HomeIcon, SearchIcon,DriverModal, PlusSmIcon, XIcon, FilterIcon, ArchiveIcon, OrdersModal, EyeIcon, EyeOffIcon, Menu, MenuButton, MenuItem, MenuItems, ChevronDownIcon, QrcodeStream, ChevronUpIcon
    },
    data() {
        return {
            connectionChecked: false,
            // offline indication
            offlineMode: {
                active: false,
                alreadyScrolledToInformation: false
            },
            cameraVisible: false,
            isLoaded:false,
            sortName: 'creationAsc',
            showStatusFilters: false,
            modal: {
                open: false
            },
            moment: moment,
            routes:[],
            askForAdd: false,
            orders: [],
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            vehicles: [],
            queryLimit: 25,
            dbListener: null,
            lastLoaded: null,
            queryLimitReached: false,
            user: null,
            toggleInformation: [],
            usersRoutes: [],
            routesWithOrders:[],
            statuses:[],
            date:null,
            isInside:false,
                modal: {
                open: false,
                route: null,
            },
            ordersModal:{
                open:false,
                orders: null,
                route: null
            },
            DriverModal:{
                open:false,
                orders:null,
                route:null,
                scannedOrder: null
            },
            result: '',
            error: '',
            positions: [],
            cameraLoaded: false,
            count: 0,
        }
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
        noInternet.clearInterval();
    },
    async created()
    {   
        moment.locale('pl');
        console.log('created() - check noInternet');
        noInternet({ callback: this.offlineCallback });

        // this.$store.commit("clearPersistStateDriver")
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.driver)
        {
            this.$router.push("/hub")
        }

    },
    methods: {
        async offlineInit()
        {
            if(this.$store.state.offlineModeState.lastSaveUnix === null) return;
            if(this.$store.state.offlineModeState.dataSnapshot.route === null) return;
            if(this.$store.state.offlineModeState.dataSnapshot.orders === null) return;

            let date = moment();
            // Get orders from offline backup
            this.usersRoutes = [{...this.$store.state.offlineModeState.dataSnapshot.route}];
            this.routesWithOrders = [{...this.$store.state.offlineModeState.dataSnapshot.route}];
            for(let i=0;i<this.routesWithOrders.length;i++)
            {
                let route = this.routesWithOrders[i];
                route.isInside = false;
                const start = moment.unix(route.date.start._seconds)
                const end = moment.unix(route.date.end._seconds)
                if(date.isBetween(start, end, 'days', '[]') === false)
                {
                    this.usersRoutes = [];
                    this.routesWithOrders = [];
                    this.$store.commit('clearPersistStateDriver')
                }
            }
            this.routesWithOrders.sort((a, b) => {
                if(a.date.start._seconds > b.date.start._seconds) return 1;
                else return -1
            });
            this.statuses = [...this.$store.state.offlineModeState.backupStatuses];
            this.isLoaded = true;
        },
        async standardInit()
        {
            await this.getOrders()
            const statuses = await db.collection("RouteStatuses").get();
            statuses.forEach(statusesList => {
                this.statuses = statusesList.data().list
            })
            this.$store.commit('setRouteStatusesToDriverOfflineBackup', this.statuses);

            if(this.$store.state.route !== null)
            {
                for(let i=0;i<this.routesWithOrders.length;i++)
                {
                    for(let j=0;j<this.routesWithOrders[i].orders.length;j++)
                    {
                        if(this.routesWithOrders[i].orders[j].id === this.$store.state.route)
                        {
                            if(this.routesWithOrders[i].statusId === 1)
                            {
                                this.$store.commit('setNotification',{
                                    show: true,
                                    head: "Trasa nie została rozpoczęta!",
                                    subheader: `Spróbuj zeskanować inną trasę.`,
                                    success: false
                                });
                            }
                            else if(this.routesWithOrders[i].statusId === 2)
                            {
                                this.DriverModal.route = this.routesWithOrders[i]
                                this.DriverModal.orders = this.routesWithOrders[i].orders
                                // this.DriverModal.scannedOrder = this.routesWithOrders[i].orders[j]
                                this.DriverModal.open = true
                            }
                            else if(this.routesWithOrders[i].statusId === 3)
                            {
                                this.$store.commit('setNotification',{
                                    show: true,
                                    head: "Akcja zakończona niepowodzeniem!",
                                    subheader: "Trasa została zakończona.",
                                    success: false
                                });
                            }
                        }
                    }
                }
            }
        },
        scrollToOfflineInfoBar()
        {
            const element = document.getElementById("topOfDriverApp");
            if(element !== null)
            {
            element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        async offlineCallback(offline)
        {
            if(offline)
            {
                // Start init once component is loaded and no-internet plugin starts working
                if(this.connectionChecked === false)
                {
                    await this.offlineInit();
                }

                this.connectionChecked = true;
                this.offlineMode.active = true;
                // this.$store.commit('setIsDriverOnline', !this.offlineMode.active);
                if(this.offlineMode.alreadyScrolledToInformation === false)
                {
                    this.offlineMode.alreadyScrolledToInformation = true;
                    this.scrollToOfflineInfoBar();
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Brak internetu!",
                        subheader: `Aplikacja przeszła w tryb offline.. Nie wyłączaj tej karty`,
                        success: false
                    });
                }
            }
            else
            {
                // Start init once component is loaded and no-internet plugin starts working
                if(this.connectionChecked === false)
                {
                    await this.standardInit();
                }
                this.connectionChecked = true;
                this.offlineMode.active = false;
                // this.$store.commit('setIsDriverOnline', !this.offlineMode.active);
                if(this.offlineMode.alreadyScrolledToInformation === true)
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Jesteś online!",
                    subheader: `Aplikacja wróciła do trybu online.`,
                    success: true
                });
                this.offlineMode.alreadyScrolledToInformation = false;
            };
            this.$store.commit('setIsDriverOnline', !this.offlineMode.active);
        },
        async onDecode(result)
        {
            this.cameraVisible = false;
            this.cameraLoaded = false;
            this.result = result
            if(!result) return
            for(let i=0;i<this.routesWithOrders.length;i++)
            {
                for(let j=0;j<this.routesWithOrders[i].orders.length;j++)
                {
                    if(this.routesWithOrders[i].orders[j].packages !== undefined && this.routesWithOrders[i].orders[j].packages !== null)
                    {
                        for(let y=0; y<this.routesWithOrders[i].orders[j].packages.length; y++)
                        {
                            if(this.routesWithOrders[i].orders[j].packages[y].id === result)
                            {
                                if(this.routesWithOrders[i].statusId === 2)
                                {
                                    this.cameraLoaded = false;
                                    this.cameraVisible = false;
                                    this.DriverModal.route = this.routesWithOrders[i]
                                    this.DriverModal.orders = this.routesWithOrders[i].orders
                                    this.DriverModal.scannedOrder = this.routesWithOrders[i].orders[j]
                                    this.DriverModal.open = true
                                }
                                else if(this.routesWithOrders[i].statusId === 3)
                                {
                                    this.$store.commit('setNotification',{
                                        show: true,
                                        head: "Trasa została już zakończona!",
                                        subheader: `Nie można już skanować paczek.`,
                                        success: false
                                    });
                                    setTimeout(() => {
                                        this.cameraLoaded = true;
                                        this.cameraVisible = true;
                                    }, 250);
                                }
                                else if(this.routesWithOrders[i].statusId === 1)
                                {
                                    this.$store.commit('setNotification',{
                                        show: true,
                                        head: "Trasa nie została rozpoczęta!",
                                        subheader: `Spróbuj zeskanować inną trasę.`,
                                        success: false
                                    });
                                    setTimeout(() => {
                                        this.cameraLoaded = true;
                                        this.cameraVisible = true;
                                    }, 250);
                                }
                                break;
                            }
                        }
                    }
                }
            }
        },
        async onInit(promise)
        {
            try
            {
                this.cameraLoaded = false
                await promise
                this.cameraLoaded = true
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        },
        startRoute(route)
        {
            this.updateStatusRoute(route)
            route.isInside = false
        },
        checkDate()
        {
            this.date = moment()
            this.routesWithOrders.forEach(route => {
                route.isInside = false;
                const start = moment.unix(route.date.start._seconds)
                const end = moment.unix(route.date.end._seconds)
                if(this.date.isBetween(start,end, 'days', '[]'))
                {
                    route.isInside = true;
                }
            })
        },
        openAdditionalInformation(indx)
        {
            if(this.toggleInformation[indx].open)
            {
                this.toggleInformation[indx].open = false
            }
            else
            {
            for (let i = 0; i < this.toggleInformation.length; i++) {
                this.toggleInformation[i].open = false
            }
            this.toggleInformation[indx].open = true
            }
        },
        openOrdersModal(route)
        {
            this.modal.open = false
            this.ordersModal.route = route
            this.ordersModal.orders = route.orders
            this.ordersModal.open = true
        },
        showFilters()
        {
            this.showStatusFilters = !this.showStatusFilters
        },
        pickStatus(){
            this.dbListener()
        },

        findStatus(statusId)
        {
            return this.statuses.find(el => el.id === statusId)
        },
        
        async updateStatusRoute(route)
        {
            try {
                const res = await axios.post(`${this.$store.state.apiLink}/route/status/update`, {
                    route: route
                })

                if(res.data.success)
                {
                    for(let i=0; i<route.orders.length; i++)
                    {
                        await this.$store.dispatch('updateHistory', {
                            collectionName: "Orders",
                            docId: route.orders[i].id,
                            message: "Zamówienie wyruszyło w trasę.",
                            routeId: route.id,
                            details: [route.id]
                        })  
                    }
                    this.getOrders()
                }
            } catch (error)
            {
                console.log(error);
            }
        },
        async getOrders()
        {
            try {
                this.isLoaded = false;
                this.usersRoutes = [];
                this.routesWithOrders = [];
                let date = moment();
                const result = await axios.post(`${this.$store.state.apiLink}/route/drivers`, {
                    user: this.$store.state.userData.id,
                })
                if(result.data.success)
                {
                    this.usersRoutes = result.data.routes
                    this.usersRoutes.forEach(route => {
                        let routeCopy = JSON.parse(JSON.stringify(route))
                        this.routesWithOrders.push(routeCopy) 
                    })
                    //route check progress;
                    for(let i=0;i<this.routesWithOrders.length;i++)
                    {
                        let route = this.routesWithOrders[i];
                        route.isInside = false;
                        const start = moment.unix(route.date.start._seconds)
                        const end = moment.unix(route.date.end._seconds)
                        if(date.isBetween(start, end, 'days', '[]'))
                        {
                            route.isInside = true;
                        };
                        route.totalOrders = 0
                        route.loadedOrders = 0
                        route.ableToStart = true;
                        route.orders.forEach(order => {
                            route.totalOrders++
                            if(order.statusId === 7 || order.statusId === 300 || order.statusId === 5)
                            {
                                route.loadedOrders++
                            };
                            if(order.statusId !== 7)
                            {
                                route.ableToStart = false;
                            };
                        })
                        route.progress = route.loadedOrders/route.totalOrders*100
                    }
                    this.routesWithOrders.sort((a, b) => {
                        if(a.date.start._seconds > b.date.start._seconds) return 1;
                        else return -1
                    });
                    
                    this.isLoaded = true
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona pomyślnie!",
                        subheader: `Pobrano pomyślnie trasy.`,
                        success: true
                    });
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona niepowodzeniem!",
                        subheader: `Wystąpił błąd przy pobieraniu tras.`,
                        success: false
                    });
                    this.isLoaded = true;
                }
            } catch (error)
            {
                console.log(error);   
            }
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 10;
            this.getRoutes()
        },
        loadMoreProducts()
        {
            this.dbListener();
            this.continueListening();      
        },
    },
}
</script>
<style scoped>

.awaitingConnectionSpinner {
    width: 10px;
    height: 10px;
    border: 2px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 


.spinner {
   width: 3rem;
   height: 3rem;
   border-radius: 50%;
   background: radial-gradient(farthest-side,#374151 94%,#0000) top/9px 9px no-repeat,
          conic-gradient(#0000 30%,#374151);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
   animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}


.loader {
  width: 96px;
  height: 48px;
  display: inline-block;
  position: relative;
  background: #FFF;
  border-radius: 48px 48px 0 0;
  box-sizing: border-box;
  overflow: hidden;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 12px;
  border-radius: 24px 24px 0 0;
  background: #0d9488;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.loader::before {
  content: '';  
  position: absolute;
  width: 4px;
  height: 32px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  background: #2dd4bf;
  transform-origin: 50% 100%;
  box-sizing: border-box;
  animation: animloader 2s linear infinite alternate;
}

@keyframes animloader {
  0% {
    transform: rotate(-70deg);
  }
  10% {
    transform: rotate(-40deg);
  }
  20%, 45%, 35% {
    transform: rotate(-10deg);
  }
  40%, 30% {
    transform: rotate(-30deg);
  }
  50%, 60% {
    transform: rotate(20deg);
  }
  55%, 65%, 75% {
    transform: rotate(40deg);
  }
  70% {
    transform: rotate(45deg);
  }
  85%, 90% {
    transform: rotate(50deg);
  }
  95% {
    transform: rotate(75deg);
  }
  100%, 93% {
    transform: rotate(70deg);
  }
}
  


 #qr-container {
  background:
    linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
 .lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #d4d4d4;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.loaderLine {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}
.loaderLine::after {
  content: '';
  width: 192px;
  height: 4.8px;
  background: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: anloader 2s linear infinite;
}

@keyframes anloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
    

 </style>

