<template>
   <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/drivers" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł kierowcy</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Zdjęcie paczek</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/drivers/routes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>
        <LoadingSpinnerHub v-show="!openedCamera" />
        <!-- <Transition name="fade"> -->
        <div v-show="openedCamera" class="disable-dbl-tap-zoom">
            <div ref="cameraContainer" class="flex justify-center fullscreen">
                <Camera ref="camera" />
            </div>
            <div v-if="images.length > 0" class="select-none">
                <div class="absolute z-20 top-0 right-0 left-0 m-2 flex flex-col space-y-2 flex-wrap">
                       <div class="text">
                    <span class="font-semibold uppercase">{{images.length}} z 4 zdjęć</span>
                </div>
                    <div v-for="(snap, index) in images" :key="index">
                        <div class="flex">
                            <div @click.prevent="deletePhoto(snap, index)" class="relative flex justify-center items-center flex-wrap">
                                <TrashIcon class="absolute z-20 w-8 text-red-500 hover:text-red-200 transition-all duration-300"/>
                                <img class="w-24 object-cover rounded-lg opacity-80 shadow-lg" :src="snap" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="images.length === 0" class="absolute z-20 top-0 right-0 left-0 m-4 flex items-center text-white select-none">
                <div class="pl-2 text">
                    <span class="font-semibold">BRAK ZDJĘĆ</span>
                </div>
            </div>
            <div class="fullscreen-button z-20 mb-10 select-none">
                <div class="group flex justify-center">
                    <Transition name="fade">
                    <button v-if="images.length < 4" @click="snapshot" class="border border-white rounded-full w-16 h-16 p-1.5 group-hover:border-gray-500 transition-all duration-300">
                        <div class="bg-white w-full h-full rounded-full group-hover:bg-gray-500 transition-all duration-300" />
                    </button>
                    </Transition>
                    <Transition name="fade">
                    <button v-if="images.length === 4" @click="uploadImageToStorage()" :disabled="uploadImage" type="button" :class="[uploadImage ? 'px-5 bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-indigo-100 text-indigo-500 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']" class="justify-center gap-x-2 transition-all duration-300 rounded-md text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-5 py-4 select-none">
                        <div v-if="uploadImage" class="spinner"></div>
                        <CheckCircleIcon v-if="!uploadImage" class="-ml-0.5 h-6 w-6" aria-hidden="true" />
                        <span v-if="!uploadImage" class="whitespace-nowrap">Akceptuj zdjęcia</span>
                    </button>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { db, storage } from "@/firebase/gfbconf.js";
import Camera from "simple-vue-camera";
import { HomeIcon } from '@heroicons/vue/solid'
import { TrashIcon, CheckCircleIcon } from "@heroicons/vue/outline"
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import axios from 'axios';
import randomstring from 'randomstring';

export default {
    components: {
        HomeIcon, CheckCircleIcon, TrashIcon, Camera, LoadingSpinnerHub
    },
    data() {
        return {
            url: '',
            picture: null,
            stream: null,
            loading: true,
            btnBlock: false,
            stream_width: null,
            stream_height: null,
            openedCamera: false,
            images: [],
            snapshots: [],
            location: {
                coords: '',
                checkedAt: '',
            },
            order: null,
            uploadImage: false,
            streamSettings: false,
        }
    },
    methods: {
        async snapshot()
        {
            const {cameraContainer} = this.$refs
            const { camera } = this.$refs

            if(this.stream_width &&  this.stream_height)
            {
                camera.canvas.width = this.stream_width;
                camera.canvas.height = this.stream_height;
            }

            const context = camera.canvas.getContext("2d");
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const data = camera.canvas.toDataURL("image/png");
            if(this.images.length < 4)
            {
                this.images.push(data)
            }

            // cameraContainer.setAttribute("src", data);
            // const actualRatio = cameraContainer.offsetHeight / cameraContainer.offsetWidth
            this.picture = await camera.snapshot({height: this.stream_height, width: this.stream_width});

            if(this.snapshots.length < 4)
            {
                this.snapshots.push(this.picture)
            }

        },
        deletePhoto(index)
        {
            this.images.splice(index, 1)
            this.snapshots.splice(index, 1)
        },
        async openCamera()
        {
            try
            {
                if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices)
                {
                    this.openedCamera = false;

                    this.stream = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            facingMode: 'environment',
                        },
                    })
                    video.srcObject = this.stream

                    let {width, height} = this.stream.getTracks()[0].getSettings();
                    this.streamSettings = this.stream.getTracks();

                    this.stream_width = width;
                    this.stream_height = height;

                    this.openedCamera = true;
                }
            }
            catch (error)
            {
                console.log(error);
            }   
        },
        async uploadImageToStorage() {
            try {
                this.btnBlock = true;
                this.uploadImage = true;

                const getLocation = () => {
                    try {
                        let getPosition = (position) => {
                            this.location = {
                                coords: {
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude,
                                },
                                checkedAt: position.timestamp,
                            }
                        };
    
                        let geoLocationError = (error) => {
                            console.log(error);
                        };
    
                        if(navigator.geolocation)
                        {
                            navigator.geolocation.getCurrentPosition(getPosition, geoLocationError ,{
                                timeout: 5000,
                                enableHighAccuracy: true,
                            });
                        }
                        else
                        {
                            console.log("Geolocation is not supported by this browser");
                        }
                    }
                    catch (error) {
                        console.log(error);    
                    }
                }
                getLocation();

                const formData = new FormData();
                // Assuming `blobs` is an array of Blob objects
                for (let i = 0; i < this.snapshots.length; i++)
                {
                    let id = randomstring.generate(10);
                    formData.append('files', this.snapshots[i], `${i+1}_${this.$route.params.id}_${id}.png`);
                }

                formData.append('order', JSON.stringify({
                    id: this.$route.params.id
                }));
                formData.append('location', JSON.stringify(this.location));
                formData.append('by', JSON.stringify(this.$store.state.userData));

                if(formData.getAll('files').length < 4)
                {
                    throw 'Brakuje zdjęć. Wymagane są 4 zdjęcia.';
                }

                if(this.$store.state.isDriverOnline)
                {
                    const res = await axios.post(`${this.$store.state.apiLink}/photo/package`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (!res.data.success) {
                        throw "An error occurred while uploading photos. Please report this error.";
                    } else {
                        this.$store.commit('setNotification', {
                            show: true,
                            head: "Action completed successfully!",
                            subheader: "Package photo saved.",
                            success: true
                        });
                        this.btnBlock = false;
                        this.uploadImage = false;
                        this.stream.getVideoTracks()[0].stop();
                        this.$router.push("/dashboard/drivers/routes");
                    }
                    if(!res.data.success)
                    {
                        throw "Powstał błąd podczas upload'u zdjęć, zgłoś ten błąd."
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Akcja zakończona sukcesem!",
                            subheader: "Zapisano zdjęcie paczki.",
                            success: true
                        });
                        this.btnBlock = false;
                        this.uploadImage = false;
                        this.stream.getVideoTracks()[0].stop();
                        this.$router.push("/dashboard/drivers/routes")
                    }
                }
                else
                {
                    this.$store.commit("addToOfflineCallsQueue", {
                        done: false,
                        callClient: "axios",
                        call: {
                            firebase: null,
                            axios: {
                                config: {
                                    method: 'post',
                                    url: `${this.$store.state.apiLink}/photo/package`,
                                    data: formData,
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            }
                        },
                        routeId: null,
                        orderId: this.$route.params.id,
                        originalFunction: {
                            name: "uploadImageToStorage",
                            payload: null
                        }
                    })
                    this.$store.state.callForPhotos.taken = true;
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: "Zapisano zdjęcie paczki.",
                        success: true
                    });
                    this.btnBlock = false;
                    this.uploadImage = false;
                    this.stream.getVideoTracks()[0].stop();
                    this.$router.push("/dashboard/drivers/routes")
                }
            } catch (error)
            {
                this.btnBlock = false;
                this.uploadImage = false;
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się zapisać zdjęcia.",
                    subheader: error,
                    success: false
                }); 
            }
            this.loading = false
        },
        filenameGenerator(length) {
            let result = `prod_`;
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for ( let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
                charactersLength));
            }
            return result;
        },
    },
    async created()
    {
        this.$store.state.route = this.$route.params.id;
    },
    async mounted()
    {
        this.openCamera();
        if(!this.$store.state.isDriverOnline)
        {
            if(this.$store.state.offlineModeState.dataSnapshot.orders != null)
            {
                for(let i=0; i<this.$store.state.offlineModeState.dataSnapshot.orders.length; i++)
                {
                    let order = this.$store.state.offlineModeState.dataSnapshot.orders[i];
                    if(this.$store.state.callForPhotos.order.id === order.id)
                    {
                        this.order = order;
                        break;
                    }
                };
            }
        }
    },
    beforeUnmount()
    {
        this.stream.getVideoTracks()[0].stop();
    },
}
</script>
<style scoped>
.text {
  color: #fff;
  display: inline-block;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 1);
}
.fullscreen {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.fullscreen-button{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    /* margin: 1rem; */
}
</style>

<style>

#video {
    object-fit: cover;
}


.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 0px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3.8px solid #616161;
    animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
    spinner-oaa3wk 2.2399999999999998s infinite linear;
}

@keyframes spinner-bulqg1 {
0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
}

12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
}

25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
}

50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
}

62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
}

75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
}

100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
}
}

@keyframes spinner-oaa3wk {
0% {
    transform: scaleY(1) rotate(0deg);
}

49.99% {
    transform: scaleY(1) rotate(135deg);
}

50% {
    transform: scaleY(-1) rotate(0deg);
}

100% {
    transform: scaleY(-1) rotate(-135deg);
}
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}
</style>