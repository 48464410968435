
<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">Zarządzaj grupą kodów</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Zarządzaj grupą {{ this.data.name }}</p>
                    </div>
                  </div>

                  <div class="px-4 sm:px-6 lg:px-8 mt-5">
                    <label for="name" class="block text-sm font-medium text-gray-700">Nazwa grupy<span class="text-red-500">*</span></label>
                    <div class="mt-1">
                      <input v-model="group.name" type="text" name="name" id="name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Nazwa grupy" />
                    </div>
                    <div v-if="errors.name.show" class="text-red-500 text-xs ml-1 mt-1">{{errors.name.message}}</div>
                  </div>

                  <div class="px-4 sm:px-6 lg:px-8 mt-5">
                    <label for="name" class="block text-sm font-medium text-gray-700">Państwo<span class="text-red-500">*</span></label>
                    <div class="mt-1">
                      <SelectInput @selectedItem="selectCountry" :data="countries" :preselectedItem="this.group.country" ></SelectInput>
                    </div>
                    <div v-if="errors.country.show" class="text-red-500 text-xs ml-1 mt-1">{{errors.country.message}}</div>
                  </div>

                <div class="grid grid-cols-4 gap-4 items-end ">
                    <div class="px-4 sm:px-6 lg:px-8 mt-5 col-span-3">
                        <label for="name" class="block text-sm font-medium text-gray-700">Początek kodu pocztowego<span class="text-red-500">*</span></label>
                        <div class="mt-1">
                          <input v-model.trim="postcode"  onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' maxlength="40" type="text" name="name" id="name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Początek kodu" />
                        </div>
                      <div v-if="errors.codes.show" class="text-red-500 text-xs ml-1 mt-1">{{errors.codes.message}}</div>
                    </div>

                    <div class="flex justify-center mt-4 sm:mt-0">
                        <button @click.prevent="addPostcode()" class="inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <PlusSmIconOutline class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>


                <div class="px-4 sm:px-6 lg:px-8 mt-5">
                    <div>
                        <p class="text-sm font-medium text-gray-700">Kody pocztowe w tej grupie:</p>
                        <p class="text-xs mt-1 text-gray-400">Kliknij w kod aby usunąć</p>
                    </div>
                    <div class="mt-4 sm:mt-0 col-span-2 flex-1 flex justify-start">
                        <div v-if="group.postcodes.length !== 0" class="flex gap-3 flex-wrap" >
                            <span @click="deletePostcode(index)" v-for="postcode, index in group.postcodes" :key="postcode.id" class="bg-gray-200 text-gray-500 px-2 rounded-lg mt-3 font-semibold hover:bg-red-200 hover:text-red-500 cursor-pointer">{{ postcode }}</span>
                        </div>
                        <div v-else class="w-full mt-4">
                          <EmptyState/>
                        </div>
                    </div>
                </div>
                <div class="px-4 sm:px-6 mt-4">
                    <div class="mb-3 font-medium text-red-500">Usuń grupe</div>
                    <div class="flex items-center mt-1 mb-4">
                      <input id="delete-route-checkbox" v-model="deleteRouteCheckbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500">
                      <label for="delete-route-checkbox" class="text-xs ml-2 select-none text-">Chcę usunąć tę grupe kodów - wiem co robię.</label>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <div v-if="!saving">
                    <button @click.prevent="emitClose" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                    <button v-if="!deleteRouteCheckbox" @click.prevent="updateGroup()" class="ml-2 rounded-md border border-gray-300  py-2 px-4 text-sm font-medium text-gray-700 shadow-sm  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Aktualizuj</button>
                    <button v-else type="button" @click.prevent="emitDelete(group.id)" class="ml-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń grupę </button>
                  </div>    
                  <div v-else>
                    <p>Zapisywanie ...</p>
                  </div>
                </div>
              
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
    import EmptyState from './EmptyState.vue'
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch } from '@headlessui/vue';
    import { QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/vue/solid';
    import SelectInput from '../components/SelectInput.vue'
    import { db } from "@/firebase/gfbconf.js";

    export default {
        name: "ClientsModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            EmptyState,
            Switch,
            SelectInput,
            QuestionMarkCircleIcon,
            XCircleIcon,
            PlusSmIconOutline,
        },
        props: ['data', 'saving'],
        data () {
            return {
              open: false,
              group: JSON.parse(JSON.stringify(this.data)),
              originalName: JSON.parse(JSON.stringify(this.data.name)), //stroring original name so it's possible to set the same name back again
              postcode: '',
              postcodesLimit: 20,
              postcodesLimitReaced: false,
              deleteRouteCheckbox: false,
              errors: {
                    name: {
                        message: "",
                        show: false
                    },
                    country: {
                        message: "",
                        show: false
                    },
                    codes: {
                        message: "",
                        show: false
                    }
                },
              countries: [
                  {
                      SelectInputDisplayName: 'Polska',
                      name: 'Poland',
                      code: 'PL',
                      flagUrl: 'https://flagcdn.com/w320/pl.png',
                  },
                  {
                      SelectInputDisplayName: 'Niemcy',
                      name: 'Germany',
                      code: 'DE',
                      flagUrl: 'https://flagcdn.com/w320/de.png',
                  },
                  {
                      SelectInputDisplayName: 'Francja',
                      name: 'France',
                      code: 'FR',
                      flagUrl: 'https://flagcdn.com/w320/fr.png',
                  },
                  {
                      SelectInputDisplayName: 'Luksemburg',
                      name: 'Luxembourg',
                      code: 'LU',
                      flagUrl: 'https://flagcdn.com/w320/lu.png',
                  },
                  {
                      SelectInputDisplayName: 'Włochy',
                      name: 'Italy',
                      code: 'IT',
                      flagUrl: 'https://flagcdn.com/w320/it.png',
                  },
                  {
                      SelectInputDisplayName: 'Holandia',
                      name: 'Netherlands',
                      code: 'NL',
                      flagUrl: 'https://flagcdn.com/w320/nl.png',
                  },
                                      {
                      SelectInputDisplayName: 'Belgia',
                      name: 'Belgium',
                      code: 'BE',
                      flagUrl: 'https://flagcdn.com/w320/be.png',
                  },
                  {
                      SelectInputDisplayName: 'Austria',
                      name: 'Austria',
                      code: 'AT',
                      flagUrl: 'https://flagcdn.com/w320/at.png',
                  },
              ],
          }
        },
        created()
        {
        },
        methods: {
          clearErrors() {
                this.errors.name.show = false
                this.errors.country.show = false
                this.errors.codes.show = false
            },
            async validate(){
                this.clearErrors()
                let validationSuccess = true

                if(this.group.country === '' || this.group.country.length === 0) {
                    this.errors.country.message = 'Państwo jest wymagane.'
                    this.errors.country.show = true
                    validationSuccess = false
                }
                if(this.group.postcodes.length === 0) {
                    this.errors.codes.message = 'Grupa musi zawierać przynajmniej jeden kod.'
                    this.errors.codes.show = true
                    validationSuccess= false
                }
                if(this.group.name === '' || this.group.name.length === 0) {
                    this.errors.name.message = 'Nazwa jest wymagana.'
                    this.errors.name.show = true
                    validationSuccess = false
                }

                const res = await db.collection("PostcodeGroups").where("name", "==", this.group.name).get()
                if(res.size && this.group.name !== this.originalName) {
                    this.errors.name.message = 'Grupa z taką nazwą już istnieje.'
                    this.errors.name.show = true
                    validationSuccess = false
                }

                return validationSuccess
            },
            validateCode(){
                this.errors.codes.show = false

                if(this.postcode.length !== 2) {
                    this.errors.codes.message = 'Kod musi zawierać 2 znaki'
                    this.errors.codes.show = true
                    return false
                }
                
                //create a regex that only contains numbers
                const reg = new RegExp('^[0-9]+$');

                if(!reg.test(this.postcode)) {
                    this.errors.codes.message = 'Kod może zawierać tylko liczby'
                    this.errors.codes.show = true
                    return false
                }

                //check if code exists in the array 
                if(this.group.postcodes.includes(this.postcode)) {
                    this.errors.codes.message = 'Kody w grupie nie mogą się powtarzać'
                    this.errors.codes.show = true
                    return false
                }

                return true
            },
            selectCountry(country){
                this.group.country = country
            },
            async updateGroup(){
                if(!await this.validate()) return
                this.emitAccept(this.group)
            },
            addPostcode(){
                if(!this.validateCode()) return
                this.group.postcodes.unshift(this.postcode)
                this.postcode = ""
            },
            deletePostcode(index){
                this.group.postcodes.splice(index, 1)
            },
            callNotificationOnError(translatedCode)
            {
                this.emitClose();
                this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: translatedCode,
                success: false
                });
            },
            emitClose()
            {
              this.$emit("close");
            },
            emitAccept(data)
            {
                this.$emit("accept", data);
            },
            emitDelete(data){
              this.$emit("delete", data)
            },
            close()
            {
              setTimeout(() => {
                this.emitClose();
                this.open = false
              }, 1500);
            }
        },
    };
</script>