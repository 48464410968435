<template>
    <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Moduł magazyn</div>
                </div>
            </li>
            </ol>
        </nav>
        
        <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 mb-4 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
            &larr; Wybierz inny moduł
        </router-link>

        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-green-50 text-green-700 group-hover:bg-green-100 group-hover:text-green-900 rounded-lg inline-flex p-3']">
                    <DocumentAddIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/scan"  class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Przyjęcia produktów
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zarządzaj przyjęciami produktów, skanując etykiety paczek.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-red-50 text-red-700 group-hover:bg-red-100 group-hover:text-red-900 rounded-lg inline-flex p-3']">
                    <DocumentDownloadIcon class="h-6 w-6" aria-hidden="true" />
                    </span> 
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/substract"  class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Wydawanie produktów
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zarządzaj wydaniami produktów, skanując etykiety paczek.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div> 
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <DocumentDuplicateIcon class="h-6 w-6" aria-hidden="true" />
                    </span> 
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/relocation"  class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Przenoszenie produtków
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zarządzaj lokalizacjami produktów, skanując etykiety paczek.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div> 
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-orange-50 text-orange-700 group-hover:bg-orange-100 group-hover:text-orange-900 rounded-lg inline-flex p-3']">
                    <QrcodeIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/warehouse"  class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Sprawdź magazyn
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zeskanuj kod magazynu, aby sprawdzić jakie paczki znajdują się w magazynie.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-orange-50 text-orange-700 group-hover:bg-orange-100 group-hover:text-orange-900 rounded-lg inline-flex p-3']">
                    <ViewGridAddIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/connect"  class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Łączenie między paczkami
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Połącz warianty produktu, aby trzymać wspólny stan magazynowy.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-yellow-50 text-yellow-700 group-hover:bg-yellow-100 group-hover:text-yellow-900 rounded-lg inline-flex p-3']">
                    <ShoppingCartIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link to="/dashboard/storage/process" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Przetwarzanie nowych zamówień
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Przetwórz nowe zamówienie od klienta.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <PuzzleIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/storage/completion" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Kompletowanie
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Kompletowanie zamówień.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-blue-50 text-blue-700 group-hover:bg-blue-100 group-hover:text-blue-900 rounded-lg inline-flex p-3']">
                    <CollectionIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/storage/packing" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Pakowanie
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Wydrukuj etykiete zamówień</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <!-- <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-blue-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <CollectionIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/storage/packing" class="focus:outline-none"> -->
                        <!-- Extend touch target to entire panel -->
                        <!-- <span class="absolute inset-0" aria-hidden="true" />
                        Załadunek
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Załadowane produkty</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div> -->
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-blue-50 text-blue-700 group-hover:bg-blue-100 group-hover:text-blue-900 rounded-lg inline-flex p-3']">
                    <ViewListIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link to="/dashboard/storage/list" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Produkty w magazynie 
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zarządzaj ilością produktów w magazynie.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-green-50 text-green-700 group-hover:bg-green-100 group-hover:text-green-900 rounded-lg inline-flex p-3']">
                    <ViewListIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link to="storage/orders/list" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Lista zamówionych produktów
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zobacz liste zamówionych produktów.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-teal-50 text-teal-700 group-hover:bg-teal-100 group-hover:text-teal-900 rounded-lg inline-flex p-3']">
                    <ShoppingCartIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link to="/dashboard/storage/products/order" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Zamówienia u producenta 
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zamów produkty prosto od producenta.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <TruckIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled to="/dashboard/storage/scan"  class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Wydaj na samochód
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zeskanuj paczki i przygotuj do wyjazdu.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
  
            <div :class="['transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-teal-50 text-teal-700 group-hover:bg-teal-100 group-hover:text-teal-700 rounded-lg inline-flex p-3']">
                        <ViewGridIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link to="/dashboard/products/storage" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Zarządzanie magazynami
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Sprawdź listę lub utwórz nowy segement magazynowy.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-green-50 text-green-700 group-hover:bg-green-100 group-hover:text-green-900 rounded-lg inline-flex p-3']">
                    <ArchiveIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link to="storage/orders/archives" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Archiwum zamówionych produktów
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Zobacz archiwum zamówionych produktów.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="['transition ease-in-out duration-300 rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-indigo-50 text-indigo-700 group-hover:bg-indigo-100 group-hover:text-indigo-900 rounded-lg inline-flex p-3']">
                    <ChatAlt2Icon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <router-link disabled :to="{ path: 'storage/events', query: { type: 'received' }}"  class="focus:outline-none">
                    <!-- <router-link disabled :to="/dashboard/events"  class="focus:outline-none"> -->
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Podgląd wydarzeń
                    </router-link>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Sprawdź wszystkie wydarzenia, logi, które odbyły sie w magazynie.</p>
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>

        </div>
    </div>
</template>

<script>
import { HomeIcon } from '@heroicons/vue/solid'
import { DocumentDuplicateIcon, ChatAlt2Icon, DocumentDownloadIcon, DocumentAddIcon, QrcodeIcon, ViewGridAddIcon, ViewGridIcon, ViewListIcon, CollectionIcon, ShoppingCartIcon, TruckIcon, ArchiveIcon, PuzzleIcon, CubeIcon } from '@heroicons/vue/outline'

export default {
    components: {
        DocumentDuplicateIcon, ChatAlt2Icon, DocumentDownloadIcon, DocumentAddIcon, QrcodeIcon, ViewGridAddIcon, ViewGridIcon, HomeIcon, ArchiveIcon, ViewListIcon, CollectionIcon, TruckIcon, ShoppingCartIcon, PuzzleIcon, CubeIcon
    },
    created() {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storage)
        {
            this.$router.push("/hub")
        }
    }

 }
</script>