<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł tras</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Tworzenie pojazdu</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/routes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>


    <form class="space-y-8 divide-y divide-gray-200 mt-12">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                </div>
                <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Marka</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input placeholder="np. OPEL" v-model="vehicle.brand" type="text" maxlength="40" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="errors.brand" class="text-red-500 text-xs ml-1 mt-1">Marka jest wymagana</div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Model</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input placeholder="np. VIVARO" v-model="vehicle.model" type="text" maxlength="40" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="errors.model" class="text-red-500 text-xs ml-1 mt-1">Nazwa modelu jest wymagana</div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Rejestracja</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input placeholder="np. PY 0000" v-model="vehicle.attribs.licensePlate.value" type="text" maxlength="40" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="errors.brand" class="text-red-500 text-xs ml-1 mt-1">Rejestracja jest wymagana</div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Zdjęcie </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2" >
                            <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                            </div>
                            <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                            </div>
                            
                            
                            <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                <div class="space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex items-center justify-center text-sm text-gray-600 pointer-events-none">
                                    <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                    <span>Wgraj zdjęcie</span>
                                    <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                    </label>
                                </div>
                                <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Wymiary</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-3 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Długość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkLength()" name="dimension" v-model="vehicle.attribs.dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkHeight()" name="dimension" v-model="vehicle.attribs.dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkWidth()" name="dimension" v-model="vehicle.attribs.dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Pojemność strefy załadunku</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                            <input disabled @change="validateCapacity()" @blur="validateCapacity()"  v-model="vehicle.attribs.payloadCapacity.value" type="number" min="0" class="opacity-40 pointer-events-none flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="np. 3.5" aria-describedby="length-unit" />
                                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm" id="price-currency">m<sup class="ml-0">3</sup> </span>
                                            </div>
                                        </div>
                                        <p class="mt-2 text-xs text-gray-500">Pojemność wylicza się automatycznie, podczas zmiany wymiarów paczek.</p>
                                        <div v-if="errors.payload" class="text-red-500 text-xs ml-1 mt-1">Pojemność musi mieścić się w przedziale od 0 do 100000 m3.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>            
        </div>
        <div class="pt-5">
            <div class="flex flex-col sm:flex-row justify-end">
                <button type="submit" @click.prevent="validate" :class="{'transition ease-in-out duration-300 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': true}">Dodaj pojazd</button>
            </div>
        </div>
    </form>

</template>

<script>
    import moment from 'moment';
    import axios from 'axios';
    import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
    import { HomeIcon } from '@heroicons/vue/solid'
    import {db,storage} from "@/firebase/gfbconf.js";
    import AttribListModal from '@/components/AttribListModal.vue';
    import EmptyState from '../components/EmptyState.vue';
    export default {
        name: "ProductAdd",
        data() {
            return {
                vehicle: {
                    id: null,
                    active: true,
                    meta: {
                        createdBy: null,
                        createdDate: null,
                        lastModificationDate: null,
                        listOfChanges: []
                    },
                    model: '',
                    brand: '',
                    img: '',
                    ref: null,
                    attribs: {
                        dimensions: {
                            unit: "cm",
                            height: 0,
                            width: 0,
                            length: 0
                        },
                        weight: {
                            unit: "kg",
                            amount: 0,
                        },
                        licensePlate: {
                            name: 'Tablice rejestracyjne',
                            value: '',
                        },
                        payloadCapacity: {
                            name: 'Pojemność transportowa',
                            value: 0,
                        },
                    },
                },
                errors: {
                    brand: false,
                    model: false,
                    payload: false,
                    plate: false
                },
                fileLimitSize: 2097152,
                imgPreviewSrc: "",
            }
        },
        components: {
            PlusSmIconOutline,
            AttribListModal,
            EmptyState,
            HomeIcon
        },
        created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes)
            {
                this.$router.push("/hub")
            }
        },
        watch: {
            'vehicle.attribs.dimensions.height': function (val) {
                this.checkHeight()
            },
            'vehicle.attribs.dimensions.width': function (val) {
                this.checkWidth()
            },
            'vehicle.attribs.dimensions.length': function (val) {
                this.checkLength()
            },
            'vehicle.attribs.weight.amount': function (val) {
                this.checkWeight()
            },
        },
        methods:
        {
            clearErrors(){
                this.errors.brand = false
                this.errors.model = false
                this.errors.payload = false
                this.errors.plate = false
            },
            validate(){
                this.clearErrors()
                let errors = []
                if(this.vehicle.brand.length === 0){
                this.errors.brand = true
                errors.push('brand')
                }
                if(this.vehicle.model.length === 0){
                this.errors.model = true
                errors.push('model')
                }
                if(this.vehicle.attribs.payloadCapacity.value.toString().length === 0 || this.vehicle.attribs.payloadCapacity.value < 0 || this.vehicle.attribs.payloadCapacity.value > 100000){
                this.errors.payload = true
                errors.push('payload')
                }
                if(this.vehicle.attribs.licensePlate.value.length === 0){
                this.errors.plate = true
                errors.push('plate')
                }
                if(errors.length === 0){
                this.addVehicleToDb()
                }
            },
            validateCapacity()
            {
                const { capacity } = this.vehicle 
                parseFloat(capacity);

                if(isNaN(capacity)) return this.vehicle.capacity = 0

                if(capacity < 0) return this.vehicle.capacity = 0
            },
            calculatePayloadCapacity()
            {
                let payloadCapacity = (this.vehicle.attribs.dimensions.height * this.vehicle.attribs.dimensions.width * this.vehicle.attribs.dimensions.length) / 1000000;
                this.vehicle.attribs.payloadCapacity.value = payloadCapacity.toFixed(2);
            },
            checkHeight()
            {
                if(this.vehicle.attribs.dimensions.height.length === 0)
                {
                    this.vehicle.attribs.dimensions.height = 0;
                }
                if(this.vehicle.attribs.dimensions.height < 0)
                {
                    this.vehicle.attribs.dimensions.height = 0;
                }
                else
                {
                    this.vehicle.attribs.dimensions.height = parseFloat(this.vehicle.attribs.dimensions.height);
                }
                this.vehicle.attribs.dimensions.height = this.vehicle.attribs.dimensions.height.toFixed(2);
                this.calculatePayloadCapacity();
            },
            checkWidth()
            {
                if(this.vehicle.attribs.dimensions.width.length === 0)
                {
                    this.vehicle.attribs.dimensions.width = 0;
                }
                if(this.vehicle.attribs.dimensions.width < 0)
                {
                    this.vehicle.attribs.dimensions.width = 0;
                }
                else
                {
                    this.vehicle.attribs.dimensions.width = parseFloat(this.vehicle.attribs.dimensions.width);
                }
                this.vehicle.attribs.dimensions.width = this.vehicle.attribs.dimensions.width.toFixed(2);
                this.calculatePayloadCapacity();
            },
            checkLength()
            {
                if(this.vehicle.attribs.dimensions.length.length === 0)
                {
                    this.vehicle.attribs.dimensions.length = 0;
                }
                if(this.vehicle.attribs.dimensions.length < 0)
                {
                    this.vehicle.attribs.dimensions.length = 0;
                }
                else
                {
                    this.vehicle.attribs.dimensions.length = parseFloat(this.vehicle.attribs.dimensions.length);
                }
                this.vehicle.attribs.dimensions.length = this.vehicle.attribs.dimensions.length.toFixed(2);
                this.calculatePayloadCapacity();
            },
            checkWeight()
            {
                if(this.vehicle.attribs.weight.amount.length === 0)
                {
                    this.vehicle.attribs.weight.amount = 0;
                }
                if(this.vehicle.attribs.weight.amount < 0)
                {
                    this.vehicle.attribs.weight.amount = 0;
                }
                else
                {
                    this.vehicle.attribs.weight.amount = parseFloat(this.vehicle.attribs.weight.amount);
                }
                this.vehicle.attribs.weight.amount = this.vehicle.attribs.weight.amount.toFixed(2);
            },
            async addVehicleToDb()
            {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Dodawanie nowego pojazdu..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                try {
                    // Upload an image
                    if(this.$refs.mainImgUploader.files.length>0)
                    {
                        const storageRef = storage.ref();
                        const filename = this.filenameGenerator(10);
                        const path = `vehicles/${filename}`;
                        const imageRef = storageRef.child(path);
                        await imageRef.put(this.$refs.mainImgUploader.files[0]);
                        const fileUrl = await imageRef.getDownloadURL();
                        this.vehicle.img = fileUrl;
                        this.vehicle.ref = path;
                    }

                    let vehicleDoc = await db.collection("Vehicles").doc() 

                    this.vehicle.id = vehicleDoc.id
                    this.vehicle.meta.createdDate = moment().toDate();
                    this.vehicle.meta.lastModificationDate = moment().toDate();
                    this.vehicle.meta.createdBy = `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`;
                    this.vehicle.meta.userId = this.$store.state.userData.id;
                    this.vehicle.bookings = []

                    await db.collection("Vehicles").doc(vehicleDoc.id).set(
                        this.vehicle
                    );  

                    let document = this.prepareElasticDocument(this.vehicle);

                    let elasticRes = await axios.post(`${this.$store.state.apiLink}/elastic/add`, {
                        index: "jan-art-vehicles",
                        collection: "Vehicles",
                        firestoreId: vehicleDoc.id,
                        document: document
                    })

                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Pojazd został dodany!",
                        subheader: `Twój pojazd jest gotowy.`,
                        success: true
                        });

                    this.$router.push(`/dashboard/vehicles/list`)

                }
                catch(error) {
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wystąpił nieoczekiwany błąd!",
                        subheader: ``,
                        success: false
                    });
                }
            },
            dropFile(e)
            {
                validate
                if(e.dataTransfer.files.length>0)
                {
                    if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
                    {
                        if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                        {
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.dataTransfer.files[0]);
                            fileReader.addEventListener("load", () =>
                            {
                                this.imgPreviewSrc = fileReader.result;
                            })
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Zdjęcie waży powyżej 2MB",
                                subheader: `Plik jest zbyt ciężki.`,
                                success: false
                            }); 
                        }
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Plik nie jest zdjęciem.",
                            subheader: `Plik powinien być zdjęciem.`,
                            success: false
                        });   
                    }
                }
                
            },
            triggerImgClick()
            {
                this.$refs.mainImgUploader.click();
            },
            clearImg()
            {
                this.imgPreviewSrc = '';
                this.$refs.mainImgUploader.value = "";
            },
            setMainImage()
            {
                if(this.$refs.mainImgUploader.files.length>0)
                {
                    if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                    {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                        fileReader.addEventListener("load", () =>
                        {
                            this.imgPreviewSrc = fileReader.result;
                        })
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Zdjęcie waży powyżej 2MB",
                            subheader: `Plik jest zbyt ciężkie.`,
                            success: false
                        });                 
                    }
                }
            },
            filenameGenerator(length)
            {
                let result           = `prod_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
            prepareElasticDocument(vehicle)
            {
                let document = {
                    brands: [vehicle.brand],
                    models: [vehicle.model],
                    licensePlates: [vehicle.attribs.licensePlate.value],
                    id: vehicle.id,
                }

                return document;
            },
        },
    }
</script>
<style lang="scss" scoped>

</style>