<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Moduł produkty</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 mb-4 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Wybierz inny moduł</router-link>

    <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div :class="['transition ease-in-out duration-300 rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-blue-50 text-blue-700 group-hover:bg-blue-100 group-hover:text-blue-900 rounded-lg inline-flex p-3']">
                 <ViewListIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/products/list" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Lista produktów
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Sprawdź listę dostępnych produktów firmy oraz zarządzaj nimi.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 sm:rounded-tr-lg relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-green-50 text-green-700 group-hover:bg-green-100 group-hover:text-green-900 rounded-lg inline-flex p-3']">
                    <DocumentAddIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/products/add" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Dodaj produkt
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj nowy produkt do systemu.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-yellow-50 text-yellow-700 group-hover:bg-yellow-100 group-hover:text-yellow-900 rounded-lg inline-flex p-3']">
                    <PuzzleIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/products/attributes" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Atrybuty
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj lub zarządzaj dostępnymi atrybutami produktów.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 rounded-bl-lg sm:rounded-bl-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-purple-50 text-purple-700 group-hover:bg-purple-100 group-hover:text-purple-900 rounded-lg inline-flex p-3']">
                    <CollectionIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/products/categories" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Kategorie
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj lub zarządzaj dostępnymi kategoriami produktów.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="['transition ease-in-out duration-300 rounded-bl-lg sm:rounded-bl-none relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-gray-100 text-gray-700 group-hover:bg-gray-200 group-hover:text-gray-900 rounded-lg inline-flex p-3']">
                    <UserGroupIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <router-link to="/dashboard/manufacturers" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Producenci
                </router-link>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Dodaj lub zarządzaj producentami produktów.</p>
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div />
        <div :class="['transition ease-in-out duration-300 sm:rounded-bl-lg relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span :class="['transition ease-in-out duration-300 bg-teal-50 text-teal-700 group-hover:bg-teal-100 group-hover:text-teal-700 rounded-lg inline-flex p-3']">
                    <ViewGridIcon class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-medium">
                <!-- <div class="focus:outline-none cursor-pointer" @click="this.$refs['csvUploader'].click()"> -->
                <div class="focus:outline-none cursor-pointer" @click="csvModal.open = true;">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    Import pliku CSV
                </div>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Wgraj plik CSV z danymi produktów do bazy.</p>
                <input type="file" v-show="false" ref="csvUploaderImport" accept=".csv" @change="csvProductUpload">
            </div>
            <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
        <div :class="[exportInProgress ? 'bg-gray-300 focus:ring-gray-500 select-none cursor-default pointer-events-none' : '', 'transition ease-in-out duration-300 relative group bg-white hover:bg-gray-50 p-8 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div v-if="!exportInProgress">
                <div>
                    <span :class="['transition ease-in-out duration-300 bg-red-50 text-red-700 group-hover:bg-red-100 group-hover:text-red-700 rounded-lg inline-flex p-3']">
                        <ViewGridIcon class="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div class="mt-6">
                    <h3 class="text-lg font-medium">
                    <div class="focus:outline-none cursor-pointer" @click="csvProductExport">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Eksportuj do CSV
                    </div>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Wyekspotruj produkty do pliku CSV.</p>
                    <!-- <p class="mt-1 text-xs italic text-gray-400 uppercase">Produkty, które już posiadają paczki, ale nie posiadają wymiarów.</p> -->
                    <input type="file" v-show="false" ref="csvUploaderImportExisting" accept=".csv" @change="csvUploaderImportExisting">
                </div>
                <span class="transition ease-in-out duration-300 pointer-events-none absolute top-8 right-8 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
            <div :class="[exportInProgress ? 'select-none cursor-default pointer-events-none' : '', 'flex items-center justify-center h-20 w-20 right-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition ease-in-out duration-300']"> 
                <div v-if="exportInProgress" class="spinner"></div>
            </div>
        </div>

    </div>
    <SelectCSVImportType v-if="csvModal.open" @closeModal="csvModal.open = false" @accept="selectType" />
</template>
<script>
import axios from 'axios';
import { db } from "@/firebase/gfbconf.js"
import { ViewListIcon, DocumentAddIcon, CollectionIcon, PuzzleIcon, ViewGridIcon, UserGroupIcon } from '@heroicons/vue/outline'
import { HomeIcon } from '@heroicons/vue/solid'
import pparse from 'papaparse';
import SelectCSVImportType from '../components/SelectCSVImportType.vue';
export default {
    name: "productHub",
    data()
    {
        return {
            exportInProgress: false,
            csvModal: {
                open: false
            }
        }
    },
    components: {
        ViewListIcon, SelectCSVImportType, DocumentAddIcon, CollectionIcon, PuzzleIcon, HomeIcon, ViewGridIcon, UserGroupIcon
    },
    async created()
    {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
        {
            this.$router.push("/hub")
        }
    },
    methods:
    {
        selectType(val)
        {
            if(val === 'add')
            {
                this.$refs['csvUploaderImport'].click()
            }
            else if(val === 'update')
            {
                this.$refs['csvUploaderImportExisting'].click()
            }
        },
        async csvProductUpload()
        {
            if(this.$refs['csvUploaderImport'] === undefined) return;
            let fl = this.$refs['csvUploaderImport'].files[0]
            if(fl.type === 'text/csv')
            {
                this.exportInProgress = true;
                let resultOfParsing = [];
                let step = 0;
                let objDefinition = null;
                let currentMainProduct = null;

                pparse.parse(fl, {
                    step: (result,parser) =>
                    {
                        step = step + 1;
                        if(step === 1)
                        {
                            for(let i=0; i < result.data.length; i++)
                            {
                                let suspectedDefinitions = ["isMainProduct","name","model","manufacturer","sku","ean","description","amountOfPackages","heightCm","widthCm","lengthCm","weightKg","linkHref",
                                    "storageLocation","storageAdditionalInfo","extraField1","extraField2","extraField3","categoryId","attribIds","quantity","taxRate","currency",
                                    "complaintCost","sellPrice","productionCost","imgLink"]
                                if(!suspectedDefinitions.includes(result.data[i]))
                                {
                                    console.log("CSV Aborted - no definition.")
                                    this.$store.commit('setNotification',{
                                        show: true,
                                        head: "Błąd - Plik .CSV nie posiada definicji!",
                                        subheader: `Zobacz czy plik CSV posiada pierwszy wiersz z wartościami typu: name,model,manufacturer itd. Jeśli posiada inne dodatkowe pola - usuń je.`,
                                        success: false
                                    });
                                    return;
                                }
                            }
                            objDefinition = result.data;
                        }
                        else
                        {
                            if(objDefinition !== null)
                            {
                                let product = {};
                                let current = result.data;
                                this.$store.commit('setGlobalLoader',{
                                    show: true,
                                    head: `Zlecanie importu dla produktu (${step-1}/${parser.parse.length-1})`,
                                    subheader: `Nie zamykaj tego okna. Może to potrwać parę minut.`,
                                });
                                if(current[0] === 'TRUE')
                                {
                                    product.variants = [];
                                    currentMainProduct = product;
                                }
                                for(let i=0; i < objDefinition.length;i++)
                                {                                     
                                    product[objDefinition[i]] = this.getValueByDefinition(result.data[i],objDefinition[i], current[0] === 'TRUE' ? true : false ,currentMainProduct);
                                }
                                
                                if(product.isMainProduct)
                                {
                                    resultOfParsing.push(product);
                                }
                                else
                                {
                                    currentMainProduct.variants.push(product);
                                }
                            }
                            else
                            {
                                console.log("CSV aborted - objDefinition is null.")
                                return;
                            }
                        }
                    },
                    complete: () => {
                        axios.post(`${this.$store.state.apiLink}/csv/import`, {
                            origin: "csv",
                            userName: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            userId: this.$store.state.userData.id,
                            products: resultOfParsing
                        })
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Import zakolejkowany na serwerze!",
                            subheader: `W ciągu kilku minut serwer zaindeksuje produkty do bazy.`,
                            success: true
                        });
                        this.$refs['csvUploaderImport'].value = '';
                        this.$store.commit("resetGlobalLoader");
                        this.exportInProgress = false;
                    }
                });
            }
        },
        async csvUploaderImportExisting()
        {
            if(this.$refs['csvUploaderImportExisting'] === undefined) return;
            let fl = this.$refs['csvUploaderImportExisting'].files[0]
            if(fl.type === 'text/csv')
            {
                let resultOfParsing = [];
                let step = 0;
                let objDefinition = null;
                let currentMainProduct = null;

                pparse.parse(fl, {
                    step: (result,parser) =>
                    {
                        step = step + 1;
                        if(step === 1)
                        {
                            for(let i=0; i < result.data.length; i++)
                            {
                                let suspectedDefinitions = ["name", "model", "id", "variant_id", 'X (DŁUGOŚĆ) CM', 'Y (WYSOKOŚĆ) CM', 'Z (SZEROKOŚĆ) CM', 'WAGA KG']
                                if(!suspectedDefinitions.includes(result.data[i]))
                                {
                                    console.log("CSV Aborted - no definition.")
                                    this.$store.commit('setNotification',{
                                        show: true,
                                        head: "Błąd - Plik .CSV nie posiada definicji!",
                                        subheader: `Zobacz czy plik CSV posiada pierwszy wiersz z wartościami typu: name,model,id itd. Jeśli posiada inne dodatkowe pola - usuń je.`,
                                        success: false
                                    });
                                    return;
                                }
                            }
                            objDefinition = result.data;
                        }
                        else
                        {
                            if(objDefinition !== null)
                            {
                                let product = {};
                                let current = result.data;
                                this.$store.commit('setGlobalLoader',{
                                    show: true,
                                    head: `Zlecanie importu wymiarów dla produktów`,
                                    subheader: `Nie zamykaj tego okna. Może to potrwać parę minut.`,
                                });
                                if(current[3].lastIndexOf('_'))
                                {
                                    let floatedX = parseFloat(current[4]);
                                    let floatedY = parseFloat(current[5]);
                                    let floatedZ = parseFloat(current[6]);
                                    let floatedWEIGHT = parseFloat(current[7]);
                                    if(floatedX > 0 && floatedY > 0 && floatedZ > 0 && floatedWEIGHT > 0)
                                    {
                                        let str = current[3];
                                        let num = str.lastIndexOf('_');
                                        let str2 = str.substring(0, num);
                                        product = {
                                            id: current[2],
                                            variant_id: str2,
                                            part_id: current[3].substring(current[3].lastIndexOf('_')+1),
                                            dimensions: {
                                                x: floatedX,
                                                y: floatedY,
                                                z: floatedZ
                                            },
                                            weight: floatedWEIGHT 
                                        }
                                        resultOfParsing.push(product);
                                    }
                                }
                            }
                            else
                            {
                                console.log("CSV aborted - objDefinition is null.")
                                return;
                            }
                        }
                    },
                    complete: () => {
                        axios.post(`${this.$store.state.apiLink}/csv/import-dimensions`, {
                            origin: "csv",
                            userName: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            userId: this.$store.state.userData.id,
                            products: resultOfParsing
                        })
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Import zakolejkowany na serwerze!",
                            subheader: `W ciągu kilku minut serwer zaindeksuje produkty do bazy.`,
                            success: true
                        });
                        this.$refs['csvUploaderImportExisting'].value = '';
                        this.$store.commit("resetGlobalLoader");
                    }
                });
            }
        },
        async csvProductExport()
        {
            try {
                this.exportInProgress = true;
                
                const resultOfCollected = await axios.post(`${this.$store.state.apiLink}/csv/export-products`);
                if(!resultOfCollected.data.success)
                {
                    throw "Error while exporting products to CSV.";
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Produkty wyeksportowane do pliku CSV!",
                        subheader: `Plik CSV został pobrany na Twój komputer.`,
                        success: true
                    });
                    this.exportInProgress = false;
                    const csvMaker = (data) => {
                        let csvRows = [];
                        for(let i=0;i<data.length; i++)
                        {
                            const values = Object.values(data[i]).join(';');
                            csvRows.push(values)
                        }
                        return csvRows.join('\n')
                    };
        
                    const download = (data) => {
                        let result = '';
                        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                        let counter = 0;
                        while (counter < 8)
                        {
                            result += characters.charAt(Math.floor(Math.random() * characters.length));
                            counter += 1;
                        }
                        const blob = new Blob([data], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.setAttribute('href', url)
                        a.setAttribute('download', `produkty-${result}.csv`);
                        a.click()
                    }
        
                    let definitions = {
                        name: 'name',
                        model: 'model',
                        id: 'id',
                        id_variant: 'variant_id',
                        x: 'X (DŁUGOŚĆ) CM',
                        y: 'Y (WYSOKOŚĆ) CM',
                        z: 'Z (SZEROKOŚĆ) CM',
                        weight: 'WAGA KG',
                    }
        
                    const productsParsed = [
                        definitions,
                        ...resultOfCollected.data.products
                    ];
        
                    const csvdata = csvMaker(productsParsed);
                    download(csvdata);
                    this.exportInProgress = false;
                }
    
            }
            catch (error) {
                console.log(error);
                this.exportInProgress = false;
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Brak produktów do wyeksportowania!",
                    subheader: `Nie ma produktów, które spełniają kryteria eksportu.`,
                    success: false
                });
            }
        },
        getValueByDefinition(value,key,isMain,currentMain)
        {
            let parsedValue = null;
            switch (key) {
            case 'isMainProduct':
                parsedValue = value === "TRUE" ? true : false; 
                break;
            case 'name':
                if(value.length === 0)
                {
                    parsedValue = 'Produkt bez nazwy';
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.length >= 60)
                    {
                        parsedValue = value.substring(0,60);
                    }
                    else
                    {
                        parsedValue = value;
                    }
                }
                break;
            case 'model':
                if(value.length === 0)
                {
                    parsedValue = '';
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.length >= 40)
                    {
                        parsedValue = value.substring(0,40);
                    }
                    else
                    {
                        parsedValue = value;
                    }
                }
                break;
            case 'manufacturer':
                if(value.length === 0)
                {
                    parsedValue = '';
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.toLowerCase().includes("jan") && value.toLowerCase().includes("art"))
                    {
                        parsedValue = "Jan-Art Sp. z o.o.";
                    }
                    else
                    {
                        if(value.length >= 40)
                        {
                            parsedValue = value.substring(0,40);
                        }
                        else
                        {
                            parsedValue = value;
                        }
                    }
                }
                break;
            case 'sku':
                if(value.length === 0)
                {
                    parsedValue = '';
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.length >= 14)
                    {
                        parsedValue.substring(0,14);
                    }
                    else
                    {
                        parsedValue = value;
                    }
                }
                break;
            case 'ean':
                value = value.toString();
                if(value.length === 0)
                {
                    parsedValue = '';
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.length >= 14)
                    {
                        parsedValue = value;
                    }
                    else
                    {
                        parsedValue = value;
                    }
                }
                break;
            case 'description':
                if(value.length === 0)
                {
                    parsedValue = '';
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    parsedValue = value;
                }
                break;
            case 'amountOfPackages':
                if(value.length === 0)
                {
                    parsedValue = 1;
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    let num = parseInt(value)
                    if(typeof num === 'number')
                    {
                        parsedValue = num;
                    }
                    else
                    {
                        parsedValue = 1;
                    }
                }
                break;
            case 'heightCm':
                if(value.length === 0)
                {
                    parsedValue = 0;
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    let num = parseInt(value)
                    if(typeof num === 'number')
                    {
                        parsedValue = num;
                    }
                    else
                    {
                        parsedValue = 0;
                    }
                }
                break;
            case 'widthCm':
                if(value.length === 0)
                {
                    parsedValue = 0;
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    let num = parseInt(value)
                    if(typeof num === 'number')
                    {
                        parsedValue = num;
                    }
                    else
                    {
                        parsedValue = 0;
                    }
                }
                break;
            case 'lengthCm':
                if(value.length === 0)
                {
                    parsedValue = 0;
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    let num = parseInt(value)
                    if(typeof num === 'number')
                    {
                        parsedValue = num;
                    }
                    else
                    {
                        parsedValue = 0;
                    }
                }
                break;
            case 'weightKg':
                if(value.length === 0)
                {
                    parsedValue = 0;
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    let num = parseInt(value)
                    if(typeof num === 'number')
                    {
                        parsedValue = num;
                    }
                    else
                    {
                        parsedValue = 0;
                    }
                }
                break;
            case 'linkHref':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.toLowerCase().includes("http"))
                    {
                        parsedValue = value;
                    }
                }
                break;
            case 'imgLink':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.toLowerCase().includes("http"))
                    {
                        parsedValue = value;
                    }
                }
                break;
            case 'storageLocation':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.length >= 40)
                    {
                        parsedValue = value.substring(0,40);
                    }
                    else
                    {
                        parsedValue = value;
                    }                            
                }
                break;
            case 'storageAdditionalInfo':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    if(value.length >= 65)
                    {
                        parsedValue = value.substring(0,65);
                    }
                    else
                    {
                        parsedValue = value;
                    }                            
                }
                break;
            case 'extraField1':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    parsedValue = value;                         
                }
                break;
            case 'extraField2':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    parsedValue = value;                         
                }
                break;
            case 'extraField3':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    parsedValue = value;                         
                }
                break;
            case 'categoryId':
                if(value.length === 0)
                {
                    parsedValue = "";
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    parsedValue = value;
                }
                break;
            case 'attribIds':
                if(value.length === 0)
                {
                    parsedValue = [];
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    else
                    {
                        parsedValue = value.split(',');
                        for(let y=0;y<parsedValue.length;y++)
                        {
                            parsedValue[y] = parsedValue[y].replaceAll("\n","");
                            parsedValue[y] = parsedValue[y].trim();
                        }

                    }
                }
                break;
            case 'quantity':
                if(isMain)
                {
                    parsedValue = null;
                }
                else
                {
                    let num = parseInt(value);
                    if(typeof num === 'number')
                    {
                        parsedValue = num;
                    }
                    else
                    {
                        parsedValue = 0;
                    }
                }
                break;
            case 'taxRate':
                if(isMain)
                {
                    parsedValue = null;
                }
                else
                {
                    if(value.length === 0)
                    {
                        parsedValue = 0;
                    }
                    else
                    {
                        let num = parseInt(value);
                        if(typeof num === 'number')
                        {
                            parsedValue = num;
                        }
                        else
                        {
                            parsedValue = 0;
                        }
                    }
                }
                break;
            case 'currency':
                if(isMain)
                {
                    parsedValue = null
                }
                else
                {
                    if(value.length === 0)
                    {
                        parsedValue = 'PLN';
                    }
                    else
                    {
                        let allowedCurrencies = ['PLN','EUR','GBP'];
                        let currency = value.toUpperCase();
                        if(allowedCurrencies.includes(currency))
                        {
                            parsedValue = currency;
                        }
                        else
                        {
                            parsedValue = 'PLN';
                        }
                    }
                }
                break;
            case 'complaintCost':
                if(isMain)
                {
                    parsedValue = null
                }
                else
                {
                    if(value.length === 0)
                    {
                        parsedValue = 0;
                    }
                    else
                    {
                        let num = parseFloat(value);
                        if(typeof num === 'number')
                        {
                            parsedValue = num;
                        }
                        else
                        {
                            parsedValue = 0;
                        }
                    }
                }
                break;
            case 'sellPrice':
                if(isMain)
                {
                    parsedValue = null
                }
                else
                {
                    if(value.length === 0)
                    {
                        parsedValue = 0;
                    }
                    else
                    {
                        let num = parseFloat(value);
                        if(typeof num === 'number')
                        {
                            parsedValue = num;
                        }
                        else
                        {
                            parsedValue = 0;
                        }
                    }
                }
                break;
            case 'productionCost':
                if(isMain)
                {
                    parsedValue = null
                }
                else
                {
                    if(value.length === 0)
                    {
                        parsedValue = 0;
                    }
                    else
                    {
                        let num = parseFloat(value);
                        if(typeof num === 'number')
                        {
                            parsedValue = num;
                        }
                        else
                        {
                            parsedValue = 0;
                        }
                    }
                }
                break;
            case 'imgBase64Code':
                if(value.length === 0)
                {
                    parsedValue = null;
                }
                else
                {
                    if(!isMain && value.toLowerCase() === 'inherit')
                    {
                        value = currentMain[key];
                    }
                    parsedValue = value;
                }
                break;
            default:
                parsedValue = "";
            }
                
            return parsedValue;   
        }
    },
    computed:
    {

    },
    beforeUnmount() {
    }
}
</script>
<style lang="scss" scoped>

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
  spinner-oaa3wk 2.2399999999999998s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

</style>